import React, { useState } from 'react'
import { useQuery } from 'urql'
import { getFeedbacksQuery } from '../queries'
import FeedbackList from '../components/FeedbackList'

const Archive = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [feedbacksQuery] = useQuery(
    getFeedbacksQuery({
      pagination: { page: currentPage, limit: 20 },
      filter: {
        isArchived: true,
      },
    })
  )
  return (
    <FeedbackList
      feedbacksQuery={feedbacksQuery}
      setCurrentPage={setCurrentPage}
    />
  )
}

export default Archive
