import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import {
  Button,
  TextInputField,
  Label,
  Pane,
  SelectField,
  Textarea,
  majorScale,
  toaster,
} from 'evergreen-ui'
import { withRouter } from 'react-router-dom'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import DealBadge from 'components/DealBadge'
import { SIZES_OPTIONS } from 'constants/product'
import { DEAL_STATUSES } from 'constants/constants'
import AdditionalImages from 'components/AdditionalImages'
import Translations from 'components/Translations'
import MainImage from 'components/MainImage'
import SellerInfo from 'components/SellerInfo'
import SellersSelect from 'components/SellersSelect'
import Changelog from 'components/Changelog'
import Competitors from 'components/Competitors'
import { getPathToThemeDealDetail } from '../utils'

const { DRAFT, APPROVAL_NEEDED, DECLINED, APPROVED, LIVE, FINISHED } =
  DEAL_STATUSES

export const formatDataIntoInput = ({
  isDraft = false,
  isCopy = false,
  productId,
  title,
  shortDescription,
  longDescription,
  sellerId,
  productConditionId,
  reviewScore,
  stockQuantity,
  size,
  price,
  warehouseDeliveryTimeInDays,
  competitors,
  translations,
  mainImage,
  additionalImages,
  defaultCompetitorId,
  comment,
  externalPid,
  modelName,
}) => {
  const toSave = {
    isDraft,
    product: {
      id: productId,
      title,
      shortDescription,
      longDescription,
      sellerId: sellerId && parseInteger(sellerId),
      productConditionId:
        productConditionId && parseInteger(productConditionId),
      reviewScore: reviewScore && parseFloat(reviewScore),
      size,
      warehouseDeliveryTimeInDays:
        warehouseDeliveryTimeInDays &&
        parseInteger(warehouseDeliveryTimeInDays),
      translations:
        translations &&
        translations.map(({ __typename, language, ...rest }) => ({
          ...rest,
          languageIsoCode: language.isoCode,
        })),
      mainImageId: mainImage && parseInteger(mainImage.id),
      additionalImagesIds: additionalImages
        ? additionalImages.map(({ id }) => parseInteger(id))
        : [],
      externalPid,
      modelName,
    },
    defaultCompetitorId,
    stockQuantity: stockQuantity && parseInteger(stockQuantity),
    price: price && Number.parseFloat(price),
    competitors:
      competitors &&
      competitors.map((competitor) => ({
        id: parseInteger(competitor.id),
        price: Number.parseFloat(competitor.price),
      })),
    comment,
  }
  Object.keys(toSave).forEach((key) => {
    if (toSave[key] === undefined || (isCopy && toSave[key] === null)) {
      if (isDraft) {
        delete toSave[key]
      } else {
        toSave[key] = null
      }
    }
  })
  Object.keys(toSave.product).forEach((key) => {
    if (
      key !== 'id' &&
      (toSave.product[key] === undefined ||
        (isCopy && toSave.product[key] === null))
    ) {
      if (isDraft) {
        delete toSave.product[key]
      } else {
        toSave.product[key] = null
      }
    }
  })
  return toSave
}

const navigateToNewDealOnSuccess = ({ result, history, themeOfTheMonthId }) => {
  if (!result.error) {
    history.replace(
      getPathToThemeDealDetail({
        themeOfTheMonthId,
        themeDealId: result.data.createThemeDeal.id,
      })
    )
  }
}

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnDraftSave = (error) => {
  displayToast(error, 'Draft saved')
}

const displayToastOnSaveForApproval = (error) => {
  displayToast(error, 'Saved for approval')
}

const displayToastOnLiveSave = (error) => {
  displayToast(error, 'Live saved')
}

const displayToastOnLiveSaveAndResume = (error) => {
  displayToast(error, 'Live saved and resumed')
}

const Form = (props) => {
  const {
    data = {},
    isReadOnly,
    history,
    isCompetitorReadOnly,
    isTranslationEditReadOnly,
    isTranslationAddReadOnly,
    match,
  } = props
  const { product = {} } = data
  const [title, setTitle] = useState(product.title || undefined)
  const [modelName, setModelName] = useState(product.modelName || undefined)
  const [shortDescription, setShortDescription] = useState(
    product.shortDescription || undefined
  )
  const [longDescription, setLongDescription] = useState(
    product.longDescription || undefined
  )
  const [reviewScore, setReviewScore] = useState(
    product.reviewScore || undefined
  )
  const [stockQuantity, setInitialStockQuantity] = useState(
    data.stockQuantity || undefined
  )
  const [size, setSize] = useState(product.size || undefined)
  const [price, setPrice] = useState(data.price || undefined)
  const [warehouseDeliveryTimeInDays, setWarehouseDeliveryTimeInDays] =
    useState(product.warehouseDeliveryTimeInDays || undefined)
  const [productConditionId, setProductConditionId] = useState(
    product.productCondition ? product.productCondition.id : undefined
  )
  const [sellerId, setSellerId] = useState(
    product.seller ? product.seller.id : undefined
  )
  const [competitors, setCompetitors] = useState(data.competitors || [])
  const [defaultCompetitorId, setDefaultCompetitorId] = useState(
    data.defaultCompetitorId || undefined
  )
  const [translations, setTranslations] = useState(product.translations || [])
  const [mainImage, setMainImage] = useState(product.mainImage || undefined)
  const [additionalImages, setAdditionalImages] = useState(
    product.additionalImages || []
  )
  const changelog = data.changelog || []
  const [comment, setComment] = useState(data.comment || undefined)
  const [externalPid, setExternalPid] = useState(
    product.externalPid || undefined
  )
  const [productConditionsQuery] = useQuery({
    query: `
      query {
        productConditions {
          id
          title
        }
      }
    `,
  })
  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateThemeDealInput!) {
      createThemeDeal(input: $input) {
        id
      }
    }
  `)

  const [, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateThemeDealInput!) {
      updateThemeDeal(input: $input) {
        id
      }
    }
  `)

  const [, executeResumeMutation] = useMutation(`
    mutation resumeThemeDeal($id: Int!) {
      resumeThemeDeal(id: $id) {
        id
      }
    }
  `)
  const dataToSave = {
    productId: product.id,
    defaultCompetitorId,
    title,
    shortDescription,
    longDescription,
    sellerId,
    productConditionId,
    reviewScore,
    stockQuantity,
    size,
    price,
    warehouseDeliveryTimeInDays,
    competitors,
    translations,
    mainImage,
    additionalImages,
    comment,
    externalPid,
    modelName,
  }
  return (
    <Pane width="100%" display="flex" flexDirection="column">
      <Pane>
        {[APPROVED, LIVE, FINISHED, DECLINED, APPROVAL_NEEDED, DRAFT].includes(
          data.status
        ) && <Changelog data={changelog} />}
      </Pane>
      <Pane>
        <TextInputField
          label="Title"
          disabled={isReadOnly}
          value={title || ''}
          onChange={({ target }) => {
            setTitle(target.value === '' ? undefined : target.value)
          }}
        />
        <TextInputField
          label="Model Name"
          disabled={isReadOnly}
          value={modelName || ''}
          onChange={({ target }) => {
            setModelName(target.value === '' ? undefined : target.value)
          }}
        />
        {data.status && <DealBadge status={data.status} />}
        <MainImage
          mainImage={mainImage}
          setMainImage={setMainImage}
          isDisabled={isReadOnly}
        />
        <TextInputField
          label="Product ID"
          disabled={isReadOnly}
          value={externalPid || ''}
          onChange={({ target }) => {
            setExternalPid(target.value === '' ? undefined : target.value)
          }}
        />
        <Pane>
          <Label
            htmlFor="textarea-short-description"
            marginBottom={majorScale(1)}
            display="block"
          >
            Short Description
          </Label>
          <Textarea
            id="textarea-short-description"
            disabled={isReadOnly}
            value={shortDescription || ''}
            onChange={({ target }) => {
              setShortDescription(
                target.value === '' ? undefined : target.value
              )
            }}
          />
        </Pane>
        <Pane>
          <Label
            htmlFor="textarea-long-description"
            marginBottom={majorScale(1)}
            display="block"
          >
            Long Description
          </Label>
          <Textarea
            id="textarea-long-description"
            disabled={isReadOnly}
            value={longDescription || ''}
            onChange={({ target }) => {
              setLongDescription(target.value === '' ? undefined : target.value)
            }}
          />
        </Pane>
        <TextInputField
          label="Review Score"
          type="number"
          disabled={isReadOnly}
          step={0.5}
          min={0}
          max={5}
          value={reviewScore || ''}
          onChange={({ target }) => {
            setReviewScore(target.value === '' ? undefined : target.value)
          }}
        />
        <TextInputField
          label="Stock Quantity"
          type="number"
          disabled={isReadOnly}
          step={1}
          value={stockQuantity || ''}
          onChange={({ target }) => {
            setInitialStockQuantity(
              target.value === '' ? undefined : target.value
            )
          }}
        />
        <SelectField
          label="Size"
          disabled={isReadOnly}
          value={size || ''}
          onChange={({ target }) => {
            setSize(target.value === '' ? undefined : target.value)
          }}
        >
          <option value="">---Select size---</option>
          {SIZES_OPTIONS.map((sizeOption) => (
            <option key={sizeOption} value={sizeOption}>
              {sizeOption}
            </option>
          ))}
        </SelectField>
        <TextInputField
          label="Price"
          type="number"
          disabled={isReadOnly}
          value={price || ''}
          onChange={({ target }) => {
            setPrice(target.value === '' ? undefined : target.value)
          }}
        />
        <TextInputField
          label="Warehouse Delivery Time In Days"
          type="number"
          disabled={isReadOnly}
          step={1}
          value={warehouseDeliveryTimeInDays || ''}
          onChange={({ target }) => {
            setWarehouseDeliveryTimeInDays(
              target.value === '' ? undefined : target.value
            )
          }}
        />
        <SelectField
          label="Product Condition"
          value={productConditionId || ''}
          disabled={isReadOnly}
          onChange={({ target }) => {
            setProductConditionId(
              target.value === '' ? undefined : target.value
            )
          }}
        >
          <option value="">---Select Product Condition---</option>
          {productConditionsQuery.data &&
            productConditionsQuery.data.productConditions.map((condition) => (
              <option key={condition.id} value={condition.id}>
                {condition.title}
              </option>
            ))}
        </SelectField>
        {isReadOnly ? (
          <SellerInfo sellerId={sellerId} />
        ) : (
          <SellersSelect
            sellerId={sellerId}
            setSellerId={setSellerId}
            isReadOnly={isReadOnly}
          />
        )}
        <AdditionalImages
          isDisabled={isReadOnly}
          additionalImages={additionalImages}
          setAdditionalImages={setAdditionalImages}
        />
        <Translations
          isAddDisabled={isTranslationAddReadOnly}
          isEditDisabled={isTranslationEditReadOnly}
          translations={translations}
          handleSaveTranslations={setTranslations}
        />
        <Competitors
          isDisabled={isCompetitorReadOnly}
          competitors={competitors}
          handleSaveCompetitors={setCompetitors}
          defaultCompetitorId={defaultCompetitorId}
          handleChangeDefaultCompetitor={setDefaultCompetitorId}
        />
        <Pane>
          <Label
            htmlFor="textarea-comment"
            marginBottom={majorScale(1)}
            display="block"
          >
            Comment
          </Label>
          <Textarea
            id="textarea-comment"
            disabled={isReadOnly}
            value={comment || ''}
            onChange={({ target }) => {
              setComment(target.value)
            }}
          />
        </Pane>
        <Pane marginTop={majorScale(2)} justifyContent="center" display="flex">
          {[undefined, DRAFT, APPROVAL_NEEDED, APPROVED].includes(
            data.status
          ) && (
            <Button
              onClick={async () => {
                if (data.id) {
                  const { error } = await executeUpdateMutation({
                    input: {
                      id: parseInteger(data.id),
                      ...formatDataIntoInput({
                        ...dataToSave,
                        isDraft: true,
                      }),
                    },
                  })
                  displayToastOnDraftSave(error)
                } else {
                  const { themeOfTheMonthId } = match.params
                  const result = await executeCreateMutation({
                    input: {
                      ...formatDataIntoInput({
                        ...dataToSave,
                        isDraft: true,
                      }),
                      themeOfTheMonthId: parseInteger(themeOfTheMonthId),
                    },
                  })
                  displayToastOnDraftSave(result.error)
                  navigateToNewDealOnSuccess({
                    result,
                    history,
                    themeOfTheMonthId,
                  })
                }
              }}
            >
              Save as Draft
            </Button>
          )}
          {[undefined, DRAFT, APPROVAL_NEEDED, DECLINED, APPROVED].includes(
            data.status
          ) &&
            !isReadOnly && (
              <Button
                marginLeft={majorScale(2)}
                onClick={async () => {
                  if (data.id) {
                    const { error } = await executeUpdateMutation({
                      input: {
                        id: parseInteger(data.id),
                        ...formatDataIntoInput({
                          ...dataToSave,
                          isDraft: false,
                        }),
                      },
                    })
                    displayToastOnSaveForApproval(error)
                  } else {
                    const { themeOfTheMonthId } = match.params
                    const result = await executeCreateMutation({
                      input: {
                        ...formatDataIntoInput({
                          ...dataToSave,
                          isDraft: false,
                        }),
                        themeOfTheMonthId: parseInteger(themeOfTheMonthId),
                      },
                    })
                    displayToastOnSaveForApproval(result.error)
                    navigateToNewDealOnSuccess({
                      result,
                      history,
                      themeOfTheMonthId,
                    })
                  }
                }}
              >
                Save for Approval
              </Button>
            )}
          {LIVE === data.status && data.isPaused && (
            <Pane>
              <Button
                marginRight={5}
                marginLeft={majorScale(2)}
                onClick={async () => {
                  const { error } = await executeUpdateMutation({
                    input: {
                      id: parseInteger(data.id),
                      ...formatDataIntoInput({
                        ...dataToSave,
                        isDraft: false,
                      }),
                    },
                  })
                  displayToastOnLiveSave(error)
                }}
              >
                Save
              </Button>
              <Button
                marginLeft={5}
                iconBefore="play"
                intent="success"
                appearance="primary"
                onClick={async () => {
                  const { error: updateError } = await executeUpdateMutation({
                    input: {
                      id: parseInteger(data.id),
                      ...formatDataIntoInput({
                        ...dataToSave,
                        isDraft: false,
                      }),
                    },
                  })
                  let resumeError = null
                  if (!updateError) {
                    const { error } = await executeResumeMutation({
                      id: parseInteger(data.id),
                    })
                    resumeError = error
                  }
                  displayToastOnLiveSaveAndResume(updateError || resumeError)
                }}
              >
                Save and resume
              </Button>
            </Pane>
          )}
        </Pane>
      </Pane>
    </Pane>
  )
}

Form.propTypes = {
  data: PropTypes.object,
  isReadOnly: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
  isCompetitorReadOnly: PropTypes.bool.isRequired,
  isTranslationEditReadOnly: PropTypes.bool.isRequired,
  isTranslationAddReadOnly: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      themeOfTheMonthId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default withRouter(Form)
