import React from 'react'
import PropTypes from 'prop-types'
import { BackButton } from 'evergreen-ui'
import { useQuery } from 'urql'
import { parseInteger } from 'utils/numbers'
import SideNavPage from 'sharedComponents/SideNavPage'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import Form from '../../components/Form'

const EditAdditionalService = ({ match, history }) => {
  const id = parseInteger(match.params.id)
  const [userAdditionalServiceQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query($id: Int!) {
        userAdditionalService(id: $id) {
          id
          title
          termsAndConditions
          shortDescription
          longDescription
          translations {
            language {
              isoCode
              title
            }
            languageIsoCode
            title
            shortDescription
            longDescription
          }
          price
        }
      }
    `,
    variables: {
      id,
    },
  })
  const additionalService = userAdditionalServiceQuery.data
    ? userAdditionalServiceQuery.data.userAdditionalService
    : null
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Edit Additional Service #{id}</SideNavPage.Title>
        <BackButton onClick={() => history.goBack()} />
      </SideNavPage.Header>
      <SideNavPage.Content>
        <FullScreenLoading isVisible={userAdditionalServiceQuery.fetching} />
        {additionalService && <Form data={additionalService} />}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

EditAdditionalService.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default EditAdditionalService
