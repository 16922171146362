import React, { useState } from 'react'
import { useQuery } from 'urql'
import { SORT_DIRECTION_ENUM, WISH_STATUSES } from 'constants/constants'
import { getWishesQuery } from '../queries'
import WishList from '../components/WishList'
import { WISH_SORTER_FIELD_ENUM } from '../constants'

const Accepted = () => {
  const [sort, setSort] = useState({
    field: WISH_SORTER_FIELD_ENUM.CREATED_AT_UTC,
    direction: SORT_DIRECTION_ENUM.DESC,
  })
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [wishesQuery, reexecuteWishesQuery] = useQuery(
    getWishesQuery(
      {
        pagination: { page: currentPage, limit: 20 },
        sorter: sort,
        filter: {
          statuses: [WISH_STATUSES.ACCEPTED],
          ...(selectedCategoryIds && { categoryIds: selectedCategoryIds }),
        },
      },
      `
      thread {
        id
        isSeenByUser
      }
    `
    )
  )
  return (
    <WishList
      wishesQuery={wishesQuery}
      setCurrentPage={setCurrentPage}
      handleSortChange={setSort}
      currentSort={sort}
      onCategorySelectChange={setSelectedCategoryIds}
      selectedCategoryIds={selectedCategoryIds}
      onChange={reexecuteWishesQuery}
    />
  )
}

export default Accepted
