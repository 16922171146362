import PropTypes from 'prop-types'
import React from 'react'
import { Pane, Text } from 'evergreen-ui'
import { withUser } from 'UserProvider'
import LinkParser from 'components/LinkParser'
import styled from 'styled-components/macro'

const StyledText = styled(Text)`
  white-space: pre-line;
`

const SystemUpdateMessage = (props) => {
  const { text, id } = props
  return (
    <Pane
      id={id}
      display="flex"
      alignSelf="center"
      maxWidth="100%"
      marginBottom={10}
    >
      {text && (
        <Pane display="flex" alignSelf="center" flexDirection="column">
          <StyledText color="gray">
            <LinkParser>{text}</LinkParser>
          </StyledText>
        </Pane>
      )}
    </Pane>
  )
}

SystemUpdateMessage.propTypes = {
  text: PropTypes.string,
  id: PropTypes.number.isRequired,
}

export default withUser(SystemUpdateMessage)
