import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from 'evergreen-ui'

const Title = ({ children }) => <Heading size={600}>{children}</Heading>

Title.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Title
