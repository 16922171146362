import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import {
  Table,
  Spinner,
  Switch,
  Button,
  Pane,
  Tooltip,
  Icon,
  majorScale,
  Badge,
} from 'evergreen-ui'
import SearchInput from 'sharedComponents/SearchInput'
import styled from 'styled-components/macro'
import urql from 'utils/urql'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import AttributesFilter from 'sharedComponents/AttributesFilter'
import NoData from 'sharedComponents/NoData'
import Pagination from 'components/Pagination'
import ACTIONS from 'constants/actions'
import ROUTES from 'constants/routes'
import { parseInteger } from 'utils/numbers'
import DeleteDialog from '../../components/DeleteDialog'
import { getPathToProduct } from '../../utils'

const TABLE_MIN_HEIGHT = 500

const PreviewImage = styled.div`
  width: 120px;
  height: 90%;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

const ProductList = ({ history, user, match }) => {
  const [filteredValues, setFilteredValues] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const attributeFilter = filteredValues.reduce(
    (acc, { values, id }) => [
      ...acc,
      {
        attributeId: parseInteger(id),
        attributeValuesIds: values.map(({ value }) => parseInteger(value)),
      },
    ],
    []
  )
  const pageNumber = parseInteger(match.params.pageNumber)
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false)
  const [productToDelete, setProductToDelete] = useState({})
  let paginationInfoQuery = { page: pageNumber, limit: 20 }
  if (searchValue) {
    paginationInfoQuery = { page: 1, limit: -1 }
  }
  const [productsQuery] = useQuery({
    requestPolicy: 'cache-and-network',
    query: `
      query products($pagination: PaginationInput, $sorter: ProductSorterInput, $filter: ProductFilterInput) {
        products(pagination: $pagination, sorter: $sorter, filter: $filter) {
          paginationInfo {
            totalCount
            firstPage
            lastPage
            currentPage
            nextPage
            previousPage
          }
          data {
            id
            title
            mainImage {
              url
            }
            category {
              title
            }
            isPhoneServiceEnabled
            productVariantsCount
            isDraft
          }
        }
      }
    `,
    variables: {
      pagination: paginationInfoQuery,
      filter: {
        isCatalogProduct: true,
        attributeFilter,
        search: searchValue,
      },
    },
  })
  useEffect(() => {
    if (productsQuery.error) {
      urql.handleError(productsQuery.error)
    }
  }, [productsQuery.error])
  const paginationInfo =
    productsQuery.data && productsQuery.data.products.paginationInfo
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Products</SideNavPage.Title>
        {user.hasAction(ACTIONS.ATTRIBUTE.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => history.push(`${ROUTES.PRODUCTS}/new`)}
          >
            Create Product
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Pane display="flex" justifyContent="flex-center" marginBottom={20}>
          <SearchInput
            label="Search"
            onChange={setSearchValue}
            values={searchValue}
          />
        </Pane>
        <Pane display="flex" justifyContent="flex-end">
          <AttributesFilter
            onChange={setFilteredValues}
            values={filteredValues}
          />
        </Pane>
        <Table border>
          <Table.Head height="auto" paddingY={12}>
            <Table.TextHeaderCell flexBasis={50} flexGrow={0}>
              ID
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={150} flexGrow={0}>
              Image
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={180} flexGrow={0}>
              Title
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Category</Table.TextHeaderCell>
            <Table.TextHeaderCell>
              Enabled for Phone Service
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Product Variants Count</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={120} flexGrow={0} />
            <Table.TextHeaderCell align="center">Actions</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {productsQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                size={80}
              />
            )}
            {!productsQuery.fetching &&
              productsQuery.data &&
              productsQuery.data.products.data.map(
                ({
                  id: productId,
                  title,
                  mainImage,
                  category,
                  isPhoneServiceEnabled,
                  productVariantsCount,
                  isDraft,
                }) => (
                  <Table.Row
                    height="100px"
                    key={productId}
                    isSelectable={user.hasAction(ACTIONS.PRODUCT.DETAIL)}
                    onSelect={() =>
                      history.push(getPathToProduct({ productId }))
                    }
                  >
                    <Table.TextCell flexBasis={50} flexGrow={0}>
                      {productId}
                    </Table.TextCell>
                    <Table.Cell flexBasis={150} flexGrow={0}>
                      {mainImage && <PreviewImage src={mainImage.url} />}
                    </Table.Cell>
                    <Table.TextCell flexBasis={180} flexGrow={0}>
                      {title}
                    </Table.TextCell>
                    <Table.TextCell>
                      {category && category.title}
                    </Table.TextCell>
                    <Table.TextCell>
                      <Switch disabled checked={isPhoneServiceEnabled} />
                    </Table.TextCell>
                    <Table.TextCell>
                      {productVariantsCount}
                      {!productVariantsCount && (
                        <Tooltip content="This product has no variant. It will not be displayed.">
                          <Icon
                            marginLeft={majorScale(1)}
                            icon="warning-sign"
                            color="warning"
                          />
                        </Tooltip>
                      )}
                    </Table.TextCell>
                    <Table.Cell flexBasis={120} flexGrow={0}>
                      {isDraft && (
                        <Badge
                          color="purple"
                          isSolid
                          marginBottom={majorScale(1)}
                        >
                          Draft
                        </Badge>
                      )}
                    </Table.Cell>
                    <Table.Cell justifyContent="center">
                      {user.hasAction(ACTIONS.PRODUCT.DELETE) && (
                        <Button
                          intent="danger"
                          onClick={(e) => {
                            e.stopPropagation()
                            setProductToDelete({ id: productId, title })
                            setIsDeleteDialogOpened(true)
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            {!productsQuery.fetching &&
              paginationInfo &&
              paginationInfo.totalCount === 0 && <NoData padding={60} />}
          </Table.Body>
        </Table>
        {isDeleteDialogOpened && productToDelete.id && (
          <DeleteDialog
            itemToDelete={productToDelete}
            onClose={() => {
              setIsDeleteDialogOpened(false)
            }}
          />
        )}
        {!searchValue && paginationInfo && paginationInfo.totalCount > 0 && (
          <Pagination
            {...paginationInfo}
            onPageChange={(page) => {
              history.push(`/products/${page}`)
            }}
          />
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

ProductList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      pageNumber: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(ProductList)
