import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import MultiSelectMenu from 'components/MultiSelectMenu'

const WishCategoriesSelectMenu = ({ selectedCategoryIds, onChange }) => {
  const [categoriesQuery] = useQuery({
    query: `
      query {
        userWishCategories {
          id
          name
        }
      }
    `,
  })
  return (
    <MultiSelectMenu
      title="Select multiple categories:"
      placeholder="Select multiple categories..."
      options={
        categoriesQuery.data
          ? categoriesQuery.data.userWishCategories.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : []
      }
      onChange={onChange}
      selectedValues={selectedCategoryIds}
    />
  )
}

WishCategoriesSelectMenu.propTypes = {
  selectedCategoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default WishCategoriesSelectMenu
