import React from 'react'
import request, { requestFileDownload } from 'utils/request'
import PropTypes from 'prop-types'
import { parseInteger } from 'utils/numbers'
import styled from 'styled-components/macro'
import {
  Pane,
  Spinner,
  BackButton,
  Button,
  majorScale,
  Label,
  Link,
} from 'evergreen-ui'
import format from 'date-fns/format'
import SideNavPage from 'sharedComponents/SideNavPage'

import ROUTES from 'constants/routes'
import { TIME_FORMAT } from 'constants/constants'
import { withUser, getAccessToken } from 'UserProvider'
import FlagIcon from 'components/FlagIcon'
import ACTIONS from 'constants/actions'
import { getCountryCodeFromLanguageCode } from 'utils/flag'
import MyMessage from './components/MyMessage'
import SystemUpdateMessage from './components/SystemUpdateMessage'
import OthersMessage from './components/OthersMessage'
import MessageInput from './components/MessageInput'
import { THREAD_QUERY, MESSAGE_DETAIL_QUERY } from './graphql'
import { TicketStatus } from './components/TicketStatus'
import { ThreadCategory } from './components/ThreadCategory'
import { ThreadAssignedUser } from './components/ThreadAssignedUser'

const TABLE_MIN_HEIGHT = 500
const POOLING_TIMEOUT = 20000
const NUMBER_OF_MESSAGES_ON_ONE_LOAD = 20

const LoadMore = styled.span`
  font-size: 12px;
  text-decoration: underline;
  color: #00a8ff;
  &:hover {
    color: #0090da;
    cursor: pointer;
  }
`

const StyledPane = styled(Pane)`
  border-bottom: 1px solid #ddd;
  width: calc(100% - 200px);
`

const scrollToMessageById = (id) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView()
  } else {
    console.error('no element found')
  }
}

class MessageDetail extends React.Component {
  static navigationOptions = {
    title: 'Message',
  }

  timeoutId = null

  timeoutIdForMessagesUpdate = null

  blurListener = null

  state = {
    messages: [],
    ticketId: null,
    orderId: null,
    isLoading: true,
    paginationInfo: {},
    customer: null,
    feedbackId: null,
    downloadingAttachmentId: null,
    wishId: null,
    userThread: {},
    threadCategories: [],
    threadStatuses: [],
    users: [],
  }

  listElement = null

  componentDidMount() {
    const {
      match: {
        params: { threadId },
      },
    } = this.props
    this.fetchInitialMessages(threadId)
    this.timeoutIdForMessagesUpdate = setTimeout(() => {
      this.fetchNewMessages()
    }, POOLING_TIMEOUT)
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    if (this.timeoutIdForMessagesUpdate) {
      clearTimeout(this.timeoutIdForMessagesUpdate)
    }
  }

  fetchInitialMessages = async (threadId) => {
    this.setState({
      isLoading: true,
    })
    const {
      messages,
      paginationInfo,
      orderId,
      ticketId,
      customer,
      feedbackId,
      wishId,
      userThread,
      threadCategories,
      threadStatuses,
      users,
    } = await this.fetchMessages(threadId)
    this.setState(
      {
        messages,
        paginationInfo,
        isLoading: false,
        orderId,
        ticketId,
        customer,
        feedbackId,
        wishId,
        userThread,
        threadCategories,
        threadStatuses,
        users,
      },
      () => {
        if (messages.length !== 0) {
          scrollToMessageById(messages[messages.length - 1].id)
        }
      }
    )
  }

  handleClickAttachment = async ({ url, filename, messageId }) => {
    try {
      this.setState({
        downloadingAttachmentId: messageId,
      })
      await requestFileDownload({
        url,
        accessToken: getAccessToken(),
        filename,
        method: 'GET',
      })
    } catch (e) {
      // do nothing
    } finally {
      this.setState({
        downloadingAttachmentId: null,
      })
    }
  }

  fetchOlderMessages = async () => {
    const {
      match: {
        params: { threadId },
      },
    } = this.props
    const {
      paginationInfo: { nextPage },
    } = this.state
    if (nextPage) {
      this.setState({
        isLoading: true,
      })
      const { messages, paginationInfo } = await this.fetchMessages(
        threadId,
        nextPage
      )
      this.setState((state) => ({
        messages: [...messages, ...state.messages],
        paginationInfo,
        isLoading: false,
      }))
    }
  }

  fetchMessages = async (threadId, page) => {
    let offset = 0
    if (page) {
      const { messages } = this.state
      const expectedLength = (page - 1) * NUMBER_OF_MESSAGES_ON_ONE_LOAD
      offset = messages.length - expectedLength
    }
    const {
      user: { accessToken },
    } = this.props
    const { userThread, threadStatuses, threadCategories, users } =
      await request({
        accessToken,
        isIgnoreErrorsEnabled: true,
        body: {
          query: `query userThread ($input: ThreadInput!){
          userThread(input: $input) {
            ${THREAD_QUERY}
          }
          threadStatuses {
            id
            name
          }
          threadCategories {
            id 
            name
          }
          users {
            id
            name
            email
            isActive
          }
        }`,
          variables: {
            input: {
              id: parseInteger(threadId),
              offset,
              pagination: {
                ...(page ? { page } : {}),
                limit: NUMBER_OF_MESSAGES_ON_ONE_LOAD,
              },
            },
          },
        },
      })

    const {
      messages: { data: messagesArray, paginationInfo },
      firstMessage,
      id,
      order,
      ticket,
      customer,
      feedback,
      wish,
    } = userThread
    return {
      orderId: order ? order.id : null,
      ticketId: ticket ? ticket.id : null,
      feedbackId: feedback ? feedback.id : null,
      wishId: wish ? wish.id : null,
      messages: messagesArray,
      threadId: id,
      paginationInfo,
      firstMessageId: firstMessage ? firstMessage.id : null,
      customer,
      userThread,
      threadStatuses,
      threadCategories,
      users,
    }
  }

  fetchNewMessages = async () => {
    try {
      if (!this.timeoutIdForMessagesUpdate) {
        throw new Error()
      }
      const {
        match: {
          params: { threadId },
        },
        user: { accessToken },
      } = this.props
      const { messages } = this.state
      const lastMessageId =
        messages.length !== 0 && messages[messages.length - 1].id
      const { userNewMessagesInThread: newMessages } = await request({
        accessToken,
        isIgnoreErrorsEnabled: true,
        body: {
          query: `query userNewMessagesInThread($input: NewMessagesInThreadInput!){
            userNewMessagesInThread(input: $input) {
             ${MESSAGE_DETAIL_QUERY}
            }
          }`,
          variables: {
            input: {
              id: parseInteger(threadId),
              lastMessageId: lastMessageId ? parseInteger(lastMessageId) : null,
            },
          },
        },
      })
      const newMessagesForState = [...messages, ...newMessages]
      this.setState(
        {
          messages: newMessagesForState,
        },
        () => {
          if (newMessages.length !== 0)
            scrollToMessageById(
              newMessagesForState[newMessagesForState.length - 1].id
            )
        }
      )

      this.timeoutIdForMessagesUpdate = setTimeout(
        this.fetchNewMessages,
        POOLING_TIMEOUT
      )
    } catch (error) {
      // do nothing
    }
  }

  render() {
    const {
      history,
      match: {
        params: { threadId },
      },
      user,
    } = this.props
    const {
      messages,
      orderId,
      ticketId,
      isLoading,
      paginationInfo,
      customer,
      feedbackId,
      downloadingAttachmentId,
      wishId,
      userThread,
    } = this.state
    const { email } = customer || {}
    return (
      <React.Fragment>
        <Pane display="flex" flexDirection="column" height="100%" flex={1}>
          <StyledPane position="fixed" backgroundColor="#fff">
            <SideNavPage.Header flexDirection="column">
              <Pane
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <SideNavPage.Title>Message detail:</SideNavPage.Title>
                <Pane display="flex">
                  <Pane marginRight={20}>
                    {orderId && user.hasAction(ACTIONS.ORDER.DETAIL) && (
                      <Button
                        onClick={() =>
                          history.push(`/${ROUTES.ORDERS}/${orderId}`)
                        }
                      >
                        {`Detail order #${orderId}`}
                      </Button>
                    )}
                    {wishId && user.hasAction(ACTIONS.WISH.DETAIL) && (
                      <Button
                        onClick={() =>
                          history.push(`/${ROUTES.WISHES}/${wishId}`)
                        }
                      >
                        {`Detail wish #${wishId}`}
                      </Button>
                    )}
                    {ticketId && user.hasAction(ACTIONS.TICKET.DETAIL) && (
                      <Button
                        onClick={() =>
                          history.push(`/${ROUTES.TICKETS}/${ticketId}`)
                        }
                      >
                        {`Detail ticket #${ticketId}`}
                      </Button>
                    )}
                    {feedbackId && user.hasAction(ACTIONS.FEEDBACK.DETAIL) && (
                      <Button
                        onClick={() =>
                          history.push(`/${ROUTES.FEEDBACKS}/${feedbackId}`)
                        }
                      >
                        {`Detail feedback #${feedbackId}`}
                      </Button>
                    )}
                  </Pane>
                  <BackButton onClick={() => history.goBack()} />
                </Pane>
              </Pane>
            </SideNavPage.Header>
          </StyledPane>
          <div
            style={{
              marginTop: 160,
              flexDirection: 'row',
              display: 'flex',
              height: 'calc(100% - 180px)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                backgroundColor: '#e2e2e2',
                flexDirection: 'column',
                padding: 20,
              }}
            >
              <div>
                <p>Ticket #{threadId}</p>
                <p>
                  Created at{' '}
                  {format(userThread.createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                </p>
              </div>

              <div>
                <TicketStatus
                  threadId={threadId}
                  threadStatus={userThread.threadStatus}
                  threadStatuses={this.state.threadStatuses}
                />
              </div>
              <div>
                <ThreadCategory
                  threadId={threadId}
                  threadCategory={userThread.threadCategory}
                  threadCategories={this.state.threadCategories}
                />
              </div>
              <div>
                <ThreadAssignedUser
                  threadId={threadId}
                  threadUser={userThread.user}
                  users={this.state.users}
                />
              </div>

              <hr style={{ width: '100%', height: 2 }} />
              {customer && (
                <div>
                  <p>Customer:&nbsp;{customer.fullName}</p>
                  <p>
                    Country:&nbsp;
                    <FlagIcon
                      code={getCountryCodeFromLanguageCode(
                        customer.country.isoCode.toLowerCase()
                      )}
                    />
                    <Label marginLeft={majorScale(1)}>
                      {customer.country.title}
                    </Label>
                  </p>
                  <p>
                    Email:&nbsp;
                    <Link
                      marginRight={majorScale(1)}
                      onClick={(e) => {
                        e.stopPropagation()
                        history.push(`/${ROUTES.CUSTOMERS}/${customer.id}`)
                      }}
                    >
                      {email}
                    </Link>
                  </p>
                  <p>
                    Language:&nbsp;
                    <FlagIcon
                      code={getCountryCodeFromLanguageCode(
                        customer.language.isoCode
                      )}
                    />
                    <Label marginLeft={majorScale(1)}>
                      {customer.language.title}
                    </Label>
                  </p>
                  <p>
                    Order:&nbsp;
                    <Link href={`/${ROUTES.ORDERS}/${orderId}`}>
                      {`#${orderId}`}
                    </Link>
                  </p>
                </div>
              )}
            </div>
            <div style={{ display: 'flex', flex: 1, paddingBottom: 50 }}>
              {isLoading && messages.length === 0 ? (
                <Spinner
                  marginX="auto"
                  marginY={TABLE_MIN_HEIGHT / 2}
                  delay={200}
                  size={80}
                />
              ) : (
                <>
                  <Pane display="flex" flex={1} flexDirection="column">
                    <Pane
                      display="flex"
                      flexDirection="column"
                      overflow="scroll"
                      flexGrow={1}
                      marginBottom={20}
                    >
                      {paginationInfo.nextPage && (
                        <Pane
                          display="flex"
                          justifyContent="center"
                          paddingBottom={5}
                          paddingTop={5}
                        >
                          <LoadMore
                            onClick={async () => {
                              await this.fetchOlderMessages()
                            }}
                          >
                            Load older messages
                          </LoadMore>
                        </Pane>
                      )}
                      {messages &&
                        messages.map((message) => {
                          const {
                            id,
                            isMine,
                            userId,
                            customerId,
                            messageType,
                          } = message
                          if (
                            messageType !== 'message' &&
                            messageType !== 'attachment'
                          ) {
                            return <SystemUpdateMessage key={id} {...message} />
                          }
                          if (isMine || (!userId && !customerId)) {
                            return (
                              <MyMessage
                                key={id}
                                onClickAttachment={this.handleClickAttachment}
                                isDownloadingAttachment={
                                  downloadingAttachmentId === id
                                }
                                {...message}
                              />
                            )
                          }
                          return (
                            <OthersMessage
                              key={id}
                              onClickAttachment={this.handleClickAttachment}
                              isDownloadingAttachment={
                                downloadingAttachmentId === id
                              }
                              {...message}
                            />
                          )
                        })}
                    </Pane>
                  </Pane>
                  {user.hasAction(ACTIONS.MESSAGE.CREATE) && (
                    <MessageInput
                      threadId={threadId}
                      onSubmit={() => {
                        clearTimeout(this.timeoutIdForMessagesUpdate)
                        this.fetchNewMessages()
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Pane>
      </React.Fragment>
    )
  }
}

MessageDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      threadId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
    accessToken: PropTypes.string.isRequired,
  }).isRequired,
}

export default withUser(MessageDetail)
