import React from 'react'
import PropTypes from 'prop-types'
import { Button, Pane } from 'evergreen-ui'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES, { DOW_ROUTES, MANAGEMENT_ROUTES } from 'constants/routes'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import Tablist from 'components/Tablist'
import ApprovalNeeded from './ApprovalNeeded'
import Next from './Next'
import General from './General'
import Archived from './Archived'
import WorkInProgress from './WorkInProgress'
import SoftDeleted from './SoftDeleted'

const { GENERAL, NEXT, APPROVAL_NEEDED, ARCHIVED, DELETED, WORK_IN_PROGRESS } =
  MANAGEMENT_ROUTES
const tabsData = [
  {
    tab: GENERAL,
    name: 'General',
    component: General,
    requiredAction: ACTIONS.DEAL_OF_THE_WEEK.TABS,
  },
  {
    tab: NEXT,
    name: 'Next',
    component: Next,
    requiredAction: ACTIONS.DEAL_OF_THE_WEEK.TABS,
  },
  {
    tab: WORK_IN_PROGRESS,
    name: 'Work in progress',
    component: WorkInProgress,
    requiredAction: ACTIONS.DEAL_OF_THE_WEEK.TABS,
  },
  {
    tab: APPROVAL_NEEDED,
    name: 'Approval needed',
    component: ApprovalNeeded,
    requiredAction: ACTIONS.DEAL_OF_THE_WEEK.APPROVE,
  },
  {
    tab: ARCHIVED,
    name: 'Archived',
    component: Archived,
    requiredAction: ACTIONS.DEAL_OF_THE_WEEK.ARCHIVED,
  },
  {
    tab: DELETED,
    name: 'Deleted',
    component: SoftDeleted,
    requiredAction: ACTIONS.DEAL_OF_THE_WEEK.DESTROY,
  },
]

const Management = ({ match, history, user }) => {
  const tabs = tabsData.filter(({ requiredAction }) =>
    user.hasAction(requiredAction)
  )
  const selectedTab = match.params.tab || (tabs.length && tabs[0].tab)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Deal of the Weeks</SideNavPage.Title>
        {user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.CREATE) && (
          <Button
            marginLeft="0.5em"
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              history.push(`/${ROUTES.DEAL_OF_THE_WEEKS}/${DOW_ROUTES.NEW}`)
            }}
          >
            Create new DoW
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Tablist
          history={history}
          selectedTab={selectedTab}
          tabs={tabs}
          rootUrl={ROUTES.DEAL_OF_THE_WEEKS}
        />
        <Pane marginTop="1em" flex={1}>
          {tabs.map(
            ({ tab, component: Comp }) =>
              tab === selectedTab && <Comp key={tab} />
          )}
        </Pane>
      </SideNavPage.Content>
    </React.Fragment>
  )
}

Management.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Management)
