import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Pane, TextInputField, Button, Text, majorScale } from 'evergreen-ui'
import FullCenterPage from 'components/FullCenterPage'
import ROUTES from 'constants/routes'
import { withUser } from 'UserProvider'

const SignIn = ({ user }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  return (
    <FullCenterPage>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          user.signIn({ email, password })
        }}
      >
        <Pane display="flex" flexDirection="column" alignItems="center">
          <TextInputField
            width="100%"
            label="Email"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
          <TextInputField
            width="100%"
            type="password"
            label="Password"
            required
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <Button disabled={user.isSigningIn} marginTop={majorScale(1)}>
            Sign In
          </Button>
        </Pane>
      </form>
      <Pane marginTop={majorScale(5)} textAlign="center">
        <NavLink to={`/${ROUTES.FORGOTTEN_PASSWORD}`}>
          <Text>Forgot password?</Text>
        </NavLink>
      </Pane>
    </FullCenterPage>
  )
}

SignIn.propTypes = {
  user: PropTypes.shape({
    signIn: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(SignIn)
