const ACTIONS = {
  USER: {
    PROFILE: 'userMe',
    DETAIL: 'user',
    LIST: 'users',
    ROLES: 'userRoles',
    CREATE: 'createUser',
    UPDATE: 'updateUser',
    DELETE: 'deleteUser',
    TEST: 'currencyRateWarningNotification',
    PAGE: 'uiViewUsersPage',
    COUNTRY: 'selectUserCountries',
    ACTIVATE: 'activateUser',
    DEACTIVATE: 'deactivateUser',
    IS_ABLE_TO_DEACTIVATE: 'isAbleToDeactivate',
    IS_ABLE_TO_ACTIVATE: 'isAbleToActivate',
    IS_ABLE_TO_ACTIVATE_CUSTOMER_SUPPORT_NOTIFICATIONS:
      'isAbleToActivateCustomerSupportNotifications',
    IS_ABLE_TO_DEACTIVATE_CUSTOMER_SUPPORT_NOTIFICATIONS:
      'isAbleToDeactivateCustomerSupportNotifications',
    IS_ABLE_TO_SELF_ACTIVATE_CUSTOMER_SUPPORT_NOTIFICATIONS:
      'isAbleToSelfActivateCustomerSupportNotifications',
    IS_ABLE_TO_SELF_DEACTIVATE_CUSTOMER_SUPPORT_NOTIFICATIONS:
      'isAbleToSelfDeactivateCustomerSupportNotifications',
    IS_ABLE_TO_ACTIVATE_PRODUCT_MANAGER_NOTIFICATIONS:
      'isAbleToActivateProductManagerNotifications',
    IS_ABLE_TO_DEACTIVATE_PRODUCT_MANAGER_NOTIFICATIONS:
      'isAbleToDeactivateProductManagerNotifications',
    IS_ABLE_TO_SELF_ACTIVATE_PRODUCT_MANAGER_NOTIFICATIONS:
      'isAbleToSelfActivateProductManagerNotifications',
    IS_ABLE_TO_SELF_DEACTIVATE_PRODUCT_MANAGER_NOTIFICATIONS:
      'isAbleToSelfDeactivateProductManagerNotifications',
  },
  TICKET: {
    DETAIL: 'userTicket',
    LIST: 'userTickets',
  },
  THREAD: {
    DETAIL: 'userThread',
    LIST: 'userThreads',
    NEW_MESSAGES: 'userNewMessagesInThread',
    PAGE: 'uiViewThreadsPage',
    CREATE_FOR_FEEDBACK: 'createFeedbackThread',
  },
  MESSAGE: {
    CREATE: 'userCreateMessage',
    CREATE_ATTACHMENT: 'userCreateAttachment',
    VIEW_ATTACHMENT: 'userViewAttachment',
    DOWNLOAD_ATTACHMENT: 'userDownloadAttachment',
  },
  SELLER: {
    DETAIL: 'seller',
    LIST: 'sellers',
    CREATE: 'createSeller',
    UPDATE: 'updateSeller',
    DELETE: 'deleteSeller',
    PAGE: 'uiViewSellersPage',
    OPERATIONS: 'sellerOperations',
    BALANCE: 'sellerBalance',
  },
  PRODUCT_IMAGE: {
    DETAIL: 'productImage',
    LIST: 'productImages',
    CREATE: 'createProductImage',
    UPDATE: 'updateProductImage',
    DELETE: 'deleteProductImage',
    PAGE: 'uiViewProductImagesPage',
  },
  PRODUCT_CONDITION: {
    CREATE: 'createProductCondition',
    UPDATE: 'updateProductCondition',
    DELETE: 'deleteProductCondition',
    PAGE: 'uiViewProductConditionsPage',
  },
  ORDER: {
    DETAIL: 'userOrder',
    LIST: 'userOrders',
    UPDATE: 'updateOrder',
    CHANGE_STATUS: 'changeOrderStatus',
    UPDATE_TRACKING_NUMBER: 'updateOrderTrackingNumber',
    CHANGE_RETURN_STATUS: 'changeReturnOrderStatus',
    RETURN_ORDER: 'returnOrder',
    UPDATE_RETURN_ORDER: 'updateReturnOrder',
    CANCELED_RETURN: 'canceledReturnOrder',
    EXPORT_CSV: 'exportOrdersToCsv',
    PAGE: 'uiViewOrdersPage',
    EXPORT_TO_PACKETA: 'exportOrdersToPacketa',
  },
  DEAL_OF_THE_DAY: {
    DETAIL: 'dealOfTheDay',
    LIST: 'dealOfTheDays',
    STATISTICS: 'dealOfTheDayStatistics',
    CREATE: 'createDealOfTheDay',
    UPDATE: 'updateDealOfTheDay',
    DELETE: 'deleteDealOfTheDay',
    UPDATE_PUBLISH_POSITION: 'updateDealOfTheDayPublishPositions',
    APPROVE: 'approveDealOfTheDay',
    DECLINE: 'declineDealOfTheDay',
    RESTORE: 'restoreDealOfTheDay',
    PAUSE: 'pauseDealOfTheDay',
    RESUME: 'resumeDealOfTheDay',
    ADD_TRANSLATIONS: 'addDealOfTheDayTranslations',
    UPDATE_TRANSLATIONS: 'updateDealOfTheDayTranslations',
    ADD_COMPETITOR: 'addDealOfTheDayCompetitor',
    EXPORT_CSV: 'exportDoDOrdersToCsv',
    PAGE: 'uiViewDealOfTheDaysPage',
    TABS: 'uiViewDealOfTheDaysTabs',
    ARCHIVED: 'uiViewDealOfTheDaysArchived',
    DESTROY: 'uiDestroyDealOfTheDay',
    COPY: 'uiCopyDealOfTheDay',
    SET_NEW: 'setNewDealOfTheDay',
    PUBLISH: 'publishDealOfTheDay',
  },
  DEAL_OF_THE_WEEK: {
    DETAIL: 'dealOfTheWeek',
    LIST: 'dealOfTheWeeks',
    STATISTICS: 'dealOfTheWeekStatistics',
    CREATE: 'createDealOfTheWeek',
    UPDATE: 'updateDealOfTheWeek',
    DELETE: 'deleteDealOfTheWeek',
    UPDATE_PUBLISH_POSITION: 'updateDealOfTheWeekPublishPositions',
    APPROVE: 'approveDealOfTheWeek',
    DECLINE: 'declineDealOfTheWeek',
    RESTORE: 'restoreDealOfTheWeek',
    PAUSE: 'pauseDealOfTheWeek',
    RESUME: 'resumeDealOfTheWeek',
    ADD_TRANSLATIONS: 'addDealOfTheWeekTranslations',
    UPDATE_TRANSLATIONS: 'updateDealOfTheWeekTranslations',
    ADD_COMPETITOR: 'addDealOfTheWeekCompetitor',
    EXPORT_CSV: 'exportDoWOrdersToCsv',
    PAGE: 'uiViewDealOfTheWeeksPage',
    TABS: 'uiViewDealOfTheWeeksTabs',
    ARCHIVED: 'uiViewDealOfTheWeeksArchived',
    DESTROY: 'uiDestroyDealOfTheWeek',
    COPY: 'uiCopyDealOfTheWeek',
    SET_NEW: 'setNewDealOfTheWeek',
    PUBLISH: 'publishDealOfTheWeek',
    UPDATE_END: 'updateDealOfTheWeekEnd',
  },
  DEAL_OF_THE_MONTH: {
    DETAIL: 'dealOfTheMonth',
    LIST: 'dealOfTheMonths',
    STATISTICS: 'dealOfTheMonthStatistics',
    CREATE: 'createDealOfTheMonth',
    UPDATE: 'updateDealOfTheMonth',
    DELETE: 'deleteDealOfTheMonth',
    UPDATE_PUBLISH_POSITION: 'updateDealOfTheMonthPublishPositions',
    APPROVE: 'approveDealOfTheMonth',
    DECLINE: 'declineDealOfTheMonth',
    RESTORE: 'restoreDealOfTheMonth',
    PAUSE: 'pauseDealOfTheMonth',
    RESUME: 'resumeDealOfTheMonth',
    ADD_TRANSLATIONS: 'addDealOfTheMonthTranslations',
    UPDATE_TRANSLATIONS: 'updateDealOfTheMonthTranslations',
    ADD_COMPETITOR: 'addDealOfTheMonthCompetitor',
    EXPORT_CSV: 'exportDoMOrdersToCsv',
    PAGE: 'uiViewDealOfTheMonthsPage',
    TABS: 'uiViewDealOfTheMonthsTabs',
    ARCHIVED: 'uiViewDealOfTheMonthsArchived',
    DESTROY: 'uiDestroyDealOfTheMonth',
    COPY: 'uiCopyDealOfTheMonth',
    SET_NEW: 'setNewDealOfTheMonth',
    PUBLISH: 'publishDealOfTheMonth',
  },
  CUSTOMER: {
    CHANGE_TESTER_FLAG: 'changeTesterFlag',
    DETAIL: 'customer',
    LIST: 'customers',
    PAGE: 'uiViewCustomersPage',
    EXPORT_CSV: 'exportCustomersToCsv',
  },
  PERSONALISATION: {
    PAGE: 'uiViewPersonalisationPage',
    TABS: 'uiViewPersonalisationTabs',
    LIST: 'listPersonalisations',
    CREATE: 'createPersonalisations',
    UPDATE: 'editPersonalisations',
    DELETE: 'deletePersonalisations',
  },
  CURRENCY_RATE: {
    LIST: 'currencyRates',
    LIST_FOR_DATETIME: 'currencyRatesForDatetime',
    FAILURE_NOTIFICATION: 'currencyRateFailureNotification',
    PAGE: 'uiViewCurrencyRatesPage',
  },
  CURRENCY: {
    CREATE: 'createCurrency',
    UPDATE: 'updateCurrency',
    DELETE: 'deleteCurrency',
    PAGE: 'uiViewCurrencyPage',
  },
  COUNTRY: {
    CREATE: 'createCountry',
    UPDATE: 'updateCountry',
    DELETE: 'deleteCountry',
    PAGE: 'uiViewCountryPage',
    EDIT_SHIPPING: 'editCountryShipping',
    EDIT_VAT: 'editCountryVat',
    EDIT_DEFAULT_CURRENCY: 'editCountryDefaultCurrency',
  },
  ATTRIBUTE: {
    CREATE: 'createAttribute',
    UPDATE: 'updateAttribute',
    DELETE: 'deleteAttribute',
    PAGE: 'uiViewAttributePage',
  },
  COMPETITOR: {
    DETAIL: 'competitor',
    LIST: 'competitors',
    CREATE: 'createCompetitor',
    UPDATE: 'updateCompetitor',
    DELETE: 'deleteCompetitor',
  },
  CATEGORY: {
    CREATE: 'createCategory',
    UPDATE: 'editCategory',
    DELETE: 'deleteCategory',
    MOVE: 'moveCategoryAndReorderChildren',
    PAGE: 'uiViewCategoryPage',
  },
  LANGUAGE: {
    CREATE: 'createLanguage',
    UPDATE: 'updateLanguage',
    DELETE: 'deleteLanguage',
    PAGE: 'uiViewLanguagePage',
  },
  DOCUMENT: {
    LIST: 'documents',
    UPDATE: 'updateDocument',
    PAGE: 'uiViewDocumentPage',
  },
  WISH: {
    DETAIL: 'userWish',
    LIST: 'userWishes',
    PAGE: 'uiViewWishesPage',
    ACCEPT: 'acceptWish',
    DECLINE: 'declineWish',
    DECIDE_LATER: 'decideLaterWish',
    CREATE_WISH_THREAD: 'createWishThread',
  },
  LOGS: {
    EXPORT: 'exportLogsToCsv',
  },
  WISH_CATEGORY: {
    CREATE: 'createWishCategory',
    UPDATE: 'editWishCategory',
    DELETE: 'deleteWishCategory',
    PAGE: 'uiViewWishCagetoryPage',
    UPDATE_POSITION: 'updateWishCategoryPosition',
  },
  WISH_OFFER: {
    DETAIL: 'userWishOffer',
    CREATE: 'createWishOffer',
    UPDATE: 'updateWishOffer',
  },
  FEEDBACK: {
    DETAIL: 'feedback',
    LIST: 'feedbacks',
    UPDATE_COMMENT: 'updateFeedbackComment',
    MOVE_TO_PRODUCT_FEEDBACK: 'moveFeedbackToProductFeedback',
    MOVE_TO_CUSTOMER_SUPPORT: 'moveFeedbackToCustomerSupport',
    MOVE_TO_ARCHIVE: 'moveFeedbackToArchive',
    PAGE: 'uiViewFeedbacksPage',
  },
  THEME_OF_THE_MONTH: {
    DETAIL: 'themeOfTheMonth',
    PAGE: 'uiViewThemeOfTheMonthPage',
    LIST: 'listThemeOfTheMonth',
    CREATE: 'createThemeOfTheMonth',
    UPDATE: 'updateThemeOfTheMonth',
    DELETE: 'deleteThemeOfTheMonth',
    ADD_TRANSLATIONS: 'addTranslationsThemeOfTheMonth',
    UPDATE_TRANSLATIONS: 'updateTranslationsThemeOfTheMonth',
  },
  THEME_DEAL: {
    DETAIL: 'themeDeal',
    LIST: 'themeDeals',
    STATISTICS: 'themeDealStatistics',
    CREATE: 'createThemeDeal',
    UPDATE: 'updateThemeDeal',
    DELETE: 'deleteThemeDeal',
    APPROVE: 'approveThemeDeal',
    DECLINE: 'declineThemeDeal',
    RESTORE: 'restoreThemeDeal',
    ADD_TRANSLATIONS: 'addThemeDealTranslations',
    UPDATE_TRANSLATIONS: 'updateThemeDealTranslations',
    ADD_COMPETITOR: 'addThemeDealCompetitor',
    EXPORT_CSV: 'exportThemeDealOrdersToCsv',
    DESTROY: 'uiDestroyThemeDeal',
    COPY: 'uiCopyThemeDeal',
    PAUSE: 'pauseThemeDeal',
    RESUME: 'resumeThemeDeal',
    PUBLISH: 'publishThemeDeal',
  },
  ADDITIONAL_SERVICE: {
    PAGE: 'uiViewAdditionalServicePage',
    DETAIL: 'userAdditionalService',
    LIST: 'userAdditionalServices',
    CREATE: 'createAdditionalService',
    UPDATE: 'updateAdditionalService',
    DELETE: 'deleteAdditionalService',
  },
  ATTRIBUTE_VALUE: {
    CREATE: 'createAttributeValue',
    UPDATE: 'updateAttributeValue',
    DELETE: 'deleteAttributeValue',
    PAGE: 'uiViewAttributeValuePage',
  },
  FEED: {
    CREATE: 'createFeed',
    UPDATE: 'updateFeed',
    DELETE: 'deleteFeed',
    PAGE: 'uiViewFeedPage',
  },
  PRODUCT: {
    PAGE: 'uiViewProductPage',
    DETAIL: 'product',
    LIST: 'products',
    CREATE: 'createProduct',
    UPDATE: 'updateProduct',
    DELETE: 'deleteProduct',
  },
  PRODUCT_VARIANT: {
    PAGE: 'uiViewProductVariantPage',
    DETAIL: 'productVariant',
    LIST: 'productVariants',
    CREATE: 'createProductVariant',
    UPDATE: 'updateProductVariant',
    DELETE: 'deleteProductVariant',
  },
  PHONE_SERVICE: {
    EDIT_ATTRIBUTES_MAPPING: 'editAttributesMapping',
    PAGE: 'uiViewAttributesMapping',
  },
  SETTINGS: {
    PAGE: 'uiViewSettingsPage',
    BANK: 'bank',
    CREATE_BANK: 'createBank',
    UPDATE_BANK: 'updateBank',
    DELETE_BANK: 'deleteBank',
  },
  RETURN_ADDRESS: {
    DETAIL: 'returnAddress',
    CREATE: 'createReturnAddress',
    UPDATE: 'updateReturnAddress',
    DELETE: 'deleteReturnAddress',
    PAGE: 'uiViewReturnAddress',
  },
}

export default ACTIONS
