import React from 'react'
import PropTypes from 'prop-types'
import SideNavPage from 'sharedComponents/SideNavPage'
import { BackButton } from 'evergreen-ui'
import ProductForm from '../../components/ProductForm'

const NewProduct = ({ history }) => (
  <React.Fragment>
    <SideNavPage.Header>
      <SideNavPage.Title>Create Product</SideNavPage.Title>
      <BackButton onClick={() => history.goBack()} />
    </SideNavPage.Header>
    <SideNavPage.Content>
      <ProductForm />
    </SideNavPage.Content>
  </React.Fragment>
)

NewProduct.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
}
export default NewProduct
