import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import filter from 'lodash/filter'
import size from 'lodash/size'
import {
  Button,
  Checkbox,
  Pane,
  Pill,
  Spinner,
  Strong,
  Table,
  majorScale,
  PaperclipIcon,
} from 'evergreen-ui'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns'
import ROUTES from 'constants/routes'
import { TIME_FORMAT, THREAD_STATUSES } from 'constants/constants'
import { ThreadsConsumer } from 'ThreadsProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import CountriesSelectMenu from 'sharedComponents/CountriesSelectMenu'
import ThreadStatusesSelectMenu from 'sharedComponents/ThreadStatusesSelectMenu'
import ThreadCategoriesSelectMenu from 'sharedComponents/ThreadCategoriesSelectMenu'
import { withUser } from 'UserProvider'
import ACTIONS from 'constants/actions'

const TABLE_MIN_HEIGHT = 500

const getThreadStatusColor = (code) => {
  switch (code) {
    case THREAD_STATUSES.URGENT:
    case THREAD_STATUSES.OPEN:
      return 'red'
    case THREAD_STATUSES.REOPENED:
      return 'yellow'
    default:
    case THREAD_STATUSES.CLOSED:
    case THREAD_STATUSES.PROCESSING:
      return 'neutral'
  }
}

const Messages = ({ history, user }) => (
  <React.Fragment>
    <SideNavPage.Header>
      <SideNavPage.Title>Messages</SideNavPage.Title>
    </SideNavPage.Header>
    <SideNavPage.Content>
      <ThreadsConsumer>
        {({ threads, refetch }) => {
          const [startDate, endDate] = threads.filter.selectedDateRange
          return (
            <>
              <Pane
                display="flex"
                justifyContent="space-between"
                marginBottom={majorScale(2)}
              >
                <DatePicker
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(date) => threads.filter.setSelectedDateRange(date)}
                />
                <CountriesSelectMenu
                  onChange={threads.filter.setSelectedCountryIsoCodes}
                  selectedIsoCodes={threads.filter.selectedCountryIsoCodes}
                />
                <ThreadCategoriesSelectMenu
                  onChange={threads.filter.setSelectedThreadCategories}
                  selectedThreadCategories={
                    threads.filter.selectedThreadCategories
                  }
                />
                <ThreadStatusesSelectMenu
                  onChange={threads.filter.setSelectedThreadStatuses}
                  selectedThreadStatuses={threads.filter.selectedThreadStatuses}
                />
                <Checkbox
                  checked={threads.filter.selectedWithoutClosed}
                  onChange={(e) =>
                    threads.filter.setSelectedWithoutClosed(e.target.checked)
                  }
                  value={!threads.filter.selectedWithoutClosed}
                  label="Hide closed"
                />
              </Pane>
              <Table border>
                <Table.Head height="auto" paddingY={12}>
                  <Table.TextHeaderCell
                    flexBasis={80}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    Thread Id
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    flexBasis={100}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    Country
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    flexBasis={125}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    Category
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell>Last message</Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    flexBasis={150}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    Assigned to
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    flexBasis={80}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    Attached
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    flexBasis={100}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    Ticket status
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    flexBasis={200}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    Last update
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell
                    flexBasis={200}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    Action
                  </Table.TextHeaderCell>
                </Table.Head>
                <Table.Body minHeight={TABLE_MIN_HEIGHT}>
                  {threads.isFetching && (
                    <Spinner
                      marginX="auto"
                      marginY={TABLE_MIN_HEIGHT / 2}
                      delay={200}
                      size={80}
                    />
                  )}
                  {!threads.isFetching && threads.arrayList.length === 0 ? (
                    <Pane display="flex" justifyContent="center" marginTop={20}>
                      No messages
                    </Pane>
                  ) : (
                    threads.arrayList.map(
                      ({
                        id,
                        country,
                        threadCategory,
                        threadStatus,
                        order,
                        ticket,
                        messages,
                        user: assignedUser,
                        lastMessage,
                        lastMessageAddedAtUtc,
                        lastUserSeenAtUtc,
                        feedback,
                        wish,
                      }) => {
                        const attachmentsCount = size(
                          filter(
                            get(messages, 'data'),
                            (message) => message.attachmentName
                          )
                        )
                        const isSeen =
                          lastUserSeenAtUtc &&
                          lastUserSeenAtUtc >= lastMessageAddedAtUtc
                        const isGotoButtonDisplayed =
                          (ticket && user.hasAction(ACTIONS.TICKET.DETAIL)) ||
                          (order && user.hasAction(ACTIONS.ORDER.DETAIL)) ||
                          (wish && user.hasAction(ACTIONS.WISH.DETAIL)) ||
                          (feedback && user.hasAction(ACTIONS.FEEDBACK.DETAIL))
                        const routeObject = {
                          ...(ticket
                            ? {
                                route: `/${ROUTES.TICKETS}/${ticket.id}`,
                                title: `Go to ticket #${ticket.id}`,
                              }
                            : {}),
                          ...(order
                            ? {
                                route: `/${ROUTES.ORDERS}/${order.id}`,
                                title: `Go to order #${order.id}`,
                              }
                            : {}),
                          ...(feedback
                            ? {
                                route: `/${ROUTES.FEEDBACKS}/${feedback.id}`,
                                title: `Go to feedback #${feedback.id}`,
                              }
                            : {}),
                          ...(wish
                            ? {
                                route: `/${ROUTES.WISHES}/${wish.id}`,
                                title: `Go to wish #${wish.id}`,
                              }
                            : {}),
                        }
                        return (
                          <Table.Row
                            key={id}
                            intent={
                              threadStatus &&
                              threadStatus.code === THREAD_STATUSES.URGENT &&
                              'danger'
                            }
                            isSelectable={user.hasAction(ACTIONS.THREAD.DETAIL)}
                            onSelect={() => {
                              history.push(`/${ROUTES.MESSAGES}/${id}`)
                              refetch()
                            }}
                          >
                            <Table.TextCell
                              flexBasis={80}
                              flexShrink={0}
                              flexGrow={0}
                            >
                              {id}
                            </Table.TextCell>
                            <Table.TextCell
                              flexBasis={100}
                              flexShrink={0}
                              flexGrow={0}
                            >
                              {country.title}
                            </Table.TextCell>
                            <Table.TextCell
                              flexBasis={125}
                              flexShrink={0}
                              flexGrow={0}
                            >
                              <Pill color={threadCategory ? 'red' : 'neutral'}>
                                {threadCategory
                                  ? threadCategory.name
                                  : 'Not set'}
                              </Pill>
                            </Table.TextCell>
                            <Table.TextCell>
                              {isSeen ? (
                                lastMessage && lastMessage.text
                              ) : (
                                <Strong>
                                  {lastMessage && lastMessage.text}
                                </Strong>
                              )}
                            </Table.TextCell>
                            <Table.TextCell
                              flexBasis={150}
                              flexShrink={0}
                              flexGrow={0}
                            >
                              {assignedUser ? (
                                <a href={`/users/${assignedUser.id}`}>
                                  {assignedUser.name}
                                </a>
                              ) : (
                                'Not assigned'
                              )}
                            </Table.TextCell>
                            <Table.TextCell
                              flexBasis={80}
                              flexShrink={0}
                              flexGrow={0}
                            >
                              {attachmentsCount} <PaperclipIcon />
                            </Table.TextCell>
                            <Table.TextCell
                              flexBasis={100}
                              flexShrink={0}
                              flexGrow={0}
                            >
                              <Pill
                                isSolid={
                                  threadStatus &&
                                  threadStatus.code !== THREAD_STATUSES.OPEN
                                }
                                color={getThreadStatusColor(
                                  threadStatus ? threadStatus.code : ''
                                )}
                              >
                                {threadStatus ? threadStatus.name : 'Not set'}
                              </Pill>
                            </Table.TextCell>
                            <Table.TextCell
                              flexBasis={200}
                              flexShrink={0}
                              flexGrow={0}
                            >
                              {format(
                                lastMessageAddedAtUtc,
                                TIME_FORMAT.LOCAL_DATETIME
                              )}
                            </Table.TextCell>
                            <Table.TextCell
                              flexBasis={200}
                              flexShrink={0}
                              flexGrow={0}
                            >
                              {isGotoButtonDisplayed && (
                                <Button
                                  appearence="minimal"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    history.push(routeObject.route)
                                  }}
                                >
                                  {routeObject.title}
                                </Button>
                              )}
                            </Table.TextCell>
                          </Table.Row>
                        )
                      }
                    )
                  )}
                </Table.Body>
              </Table>
              {threads.nextPage && (
                <Pane display="flex" justifyContent="center" marginTop={10}>
                  <Button
                    onClick={() => {
                      threads.onLoadMore(threads.nextPage)
                    }}
                  >
                    Load More
                  </Button>
                </Pane>
              )}
            </>
          )
        }}
      </ThreadsConsumer>
    </SideNavPage.Content>
  </React.Fragment>
)

Messages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Messages)
