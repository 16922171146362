import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  TextInputField,
  TextareaField,
  SelectField,
  Table,
  Spinner,
  Pane,
  majorScale,
  Button,
  toaster,
} from 'evergreen-ui'
import { useQuery, useMutation } from 'urql'
import urql from 'utils/urql'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import Pagination from 'components/Pagination'
import { compose } from 'recompose'
import ACTIONS from 'constants/actions'
import { parseInteger } from 'utils/numbers'

const TABLE_MIN_HEIGHT = 200
const ACTIONS_WIDTH = 250

const returnAddressFields = `{
  id
  seller {
    id
    companyName
  }
  country {
    title
    isoCode
  }
  houseNumber
  street
  city
  zipCode
  phoneNumber
}`

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const SellerReturnAddresses = ({ sellerId, user }) => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)

  const [id, setId] = useState('')
  const [houseNumber, setHouseNumber] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [country, setCountry] = useState('')
  const [countryIsoCode, setCountryIsoCode] = useState('')

  const [countriesQuery] = useQuery({
    query: `
      query($sellerId: Int!) {
        countryReturnAddress(sellerId: $sellerId) {
          isoCode
          title
        }
      }
    `,
    variables: {
      sellerId: parseInteger(sellerId),
    },
  })

  const [updateMutation, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateReturnAddressInput!) {
      updateReturnAddress(input: $input) ${returnAddressFields}
    }
  `)

  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: Int!) {
      deleteReturnAddress(id: $id) ${returnAddressFields}
    }
  `)

  const [currentPage, setCurrentPage] = useState(0)

  const context = useMemo(
    () => ({ additionalTypenames: ['ReturnAddress'] }),
    []
  )
  const [returnAddressesQuery] = useQuery({
    query: `
    query($input: ReturnAddressesInput!) {
      returnAddresses(input: $input) {
        paginationInfo {
          totalCount
          firstPage
          currentPage
          nextPage
          previousPage
          lastPage
        }
        data {
          id
          seller {
            id
            companyName
          }
          country {
            title
            isoCode
          }
          houseNumber
          street
          city
          zipCode
          phoneNumber
        }
      }
    }
    `,
    variables: {
      input: {
        pagination: {
          page: currentPage,
        },
        sellerId: parseInteger(sellerId),
      },
    },
    context,
  })

  const returnAddresses =
    returnAddressesQuery.data && returnAddressesQuery.data.returnAddresses.data
  const paginationInfo =
    returnAddressesQuery.data &&
    returnAddressesQuery.data.returnAddresses.paginationInfo
  return (
    <Pane marginTop={majorScale(3)}>
      <Table border>
        <Table.Head>
          <Table.TextHeaderCell>Id</Table.TextHeaderCell>
          <Table.TextHeaderCell>Country</Table.TextHeaderCell>
          <Table.TextHeaderCell>House Number</Table.TextHeaderCell>
          <Table.TextHeaderCell>Street</Table.TextHeaderCell>
          <Table.TextHeaderCell>City</Table.TextHeaderCell>
          <Table.TextHeaderCell>Zip Code</Table.TextHeaderCell>
          <Table.TextHeaderCell>Phone Number</Table.TextHeaderCell>
          <Table.TextHeaderCell minWidth={ACTIONS_WIDTH}>
            Action
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {returnAddressesQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {returnAddresses &&
            returnAddresses.map(
              ({
                id: raId,
                seller: raSeller,
                country: raCountry,
                houseNumber: raHouseNumber,
                street: raStreet,
                city: raCity,
                zipCode: raZipCode,
                phoneNumber: raPhoneNumber,
              }) => (
                <Table.Row key={`${raId}-${raSeller.id}`}>
                  <Table.TextCell>{raId}</Table.TextCell>
                  <Table.TextCell>{raCountry.title}</Table.TextCell>
                  <Table.TextCell>{raHouseNumber}</Table.TextCell>
                  <Table.TextCell>{raStreet}</Table.TextCell>
                  <Table.TextCell>{raCity}</Table.TextCell>
                  <Table.TextCell>{raZipCode}</Table.TextCell>
                  <Table.TextCell>{raPhoneNumber}</Table.TextCell>
                  <Table.Cell minWidth={ACTIONS_WIDTH} justifyContent="center">
                    {raId && user.hasAction(ACTIONS.RETURN_ADDRESS.UPDATE) && (
                      <Button
                        marginRight={12}
                        marginTop={3}
                        marginBottom={3}
                        iconBefore="edit"
                        onClick={() => {
                          setId(raId)
                          setHouseNumber(raHouseNumber)
                          setStreet(raStreet)
                          setCity(raCity)
                          setZipCode(raZipCode)
                          setPhoneNumber(raPhoneNumber)
                          setCountryIsoCode(raCountry.isoCode)
                          setCountry(raCountry)
                          setIsUpdateModalOpen(true)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    {raId && user.hasAction(ACTIONS.RETURN_ADDRESS.DELETE) && (
                      <Button
                        marginRight={12}
                        marginTop={3}
                        marginBottom={3}
                        iconBefore="trash"
                        intent="danger"
                        onClick={() => {
                          setId(raId)
                          setIsConfirmDeleteModalOpen(true)
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              )
            )}
        </Table.Body>
      </Table>
      {paginationInfo && returnAddresses.length > 0 && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}

      <Dialog
        isShown={isUpdateModalOpen}
        title="Edit Create Return Address"
        hasCancel={false}
        confirmLabel="Edit"
        isConfirmLoading={updateMutation.fetching}
        onCloseComplete={() => {
          setIsUpdateModalOpen(false)
        }}
        onConfirm={async (close) => {
          const input = {
            id: parseInteger(id),
            sellerId: parseInteger(sellerId),
            countryIsoCode,
            houseNumber,
            street,
            city,
            zipCode,
            phoneNumber,
          }
          if (isUpdateModalOpen) {
            const { error } = await executeUpdateMutation({
              input,
            })
            displayToastOnSave(error)
            if (!error) {
              close()
            }
          }
        }}
      >
        <SelectField
          label="Country"
          required
          value={countryIsoCode}
          onChange={(e) => {
            setCountryIsoCode(e.target.value)
          }}
        >
          <option value="">---Select Country---</option>
          {country && (
            <option key={country.isoCode} value={country.isoCode}>
              {country.title}
            </option>
          )}
          {countriesQuery.data &&
            countriesQuery.data.countryReturnAddress.map((item) => (
              <option key={item.isoCode} value={item.isoCode}>
                {item.title}
              </option>
            ))}
        </SelectField>
        <TextInputField
          label="House Number"
          required
          value={houseNumber}
          onChange={(e) => {
            setHouseNumber(e.target.value)
          }}
        />
        <TextareaField
          label="Street"
          required
          value={street}
          onChange={(e) => {
            setStreet(e.target.value)
          }}
        />
        <TextInputField
          label="City"
          required
          value={city}
          onChange={(e) => {
            setCity(e.target.value)
          }}
        />
        <TextInputField
          label="Zip Code"
          required
          value={zipCode}
          onChange={(e) => {
            setZipCode(e.target.value)
          }}
        />
        <TextInputField
          label="Phone Number"
          required
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value)
          }}
        />
      </Dialog>

      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete return address"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id: parseInteger(id),
          })
          displayToastOnSave(error)
          if (!error) {
            setId('')
            close()
          }
        }}
      >
        Are you sure you want to delete return address??
      </Dialog>
    </Pane>
  )
}

SellerReturnAddresses.propTypes = {
  sellerId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withUser, withRouter)(SellerReturnAddresses)
