import { toaster } from 'evergreen-ui'

const request = async ({
  accessToken,
  headers = {},
  isIgnoreErrorsEnabled,
  body,
  method = 'POST',
  ...rest
} = {}) => {
  const res = await fetch(process.env.REACT_APP_API_URL, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...headers,
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
    ...rest,
  })
  const { data, errors } = await res.json()
  if (errors && errors.length > 0) {
    const { message } = errors[0]
    if (!isIgnoreErrorsEnabled) {
      toaster.danger(message)
    }
    throw new Error(message)
  }
  return data
}

export const requestFileDownload = async ({
  url = process.env.REACT_APP_API_URL,
  accessToken,
  headers = {},
  body,
  isIgnoreErrorsEnabled,
  method = 'POST',
  filename,
  ...rest
} = {}) => {
  const res = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...headers,
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
    ...rest,
  })
  const contentType = res.headers.get('Content-Type')
  if (contentType.indexOf('application/json') !== -1) {
    const { data, errors } = await res.json()
    if (errors && errors.length > 0) {
      const { message } = errors[0]
      if (!isIgnoreErrorsEnabled) {
        toaster.danger(message)
      }
      throw new Error(message)
    }
    return data
  }
  const blob = await res.blob()
  const URL = window.URL || window.webkitURL
  const downloadUrl = URL.createObjectURL(blob)

  // use HTML5 a[download] attribute to specify filename
  const a = document.createElement('a')
  // safari doesn't support this yet
  if (typeof a.download === 'undefined') {
    window.location = downloadUrl
  } else {
    a.href = downloadUrl
    a.download = filename
    document.body.appendChild(a)
    a.click()
  }
  setTimeout(() => {
    URL.revokeObjectURL(downloadUrl)
  }, 100) // cleanup
  return { filename }
}

export default request
