import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Spinner,
  BackButton,
  Pane,
  majorScale,
  Switch,
  toaster,
} from 'evergreen-ui'
import { useQuery } from 'urql'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import UserStatusButton from 'components/UserStatusButton'
import CSEmailNotificationsButton from 'components/CSEmailNotificationsButton'
import PMEmailNotificationsButton from 'components/PMEmailNotificationsButton'
import ACTIONS from 'constants/actions'

export const USER_NOTIFICATION_TYPES = {
  CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT',
  PRODUCT_MANAGER: 'PRODUCT_MANAGER',
}

const ACTIONS_FOR_NOTIFICATION_TYPES = {
  [USER_NOTIFICATION_TYPES.CUSTOMER_SUPPORT]: {
    SELF_ACTIVATE:
      ACTIONS.USER.IS_ABLE_TO_SELF_ACTIVATE_CUSTOMER_SUPPORT_NOTIFICATIONS,
    SELF_DEACTIVATE:
      ACTIONS.USER.IS_ABLE_TO_SELF_DEACTIVATE_CUSTOMER_SUPPORT_NOTIFICATIONS,
    ACTIVATE: ACTIONS.USER.IS_ABLE_TO_ACTIVATE_CUSTOMER_SUPPORT_NOTIFICATIONS,
    DEACTIVATE:
      ACTIONS.USER.IS_ABLE_TO_DEACTIVATE_CUSTOMER_SUPPORT_NOTIFICATIONS,
  },
  [USER_NOTIFICATION_TYPES.PRODUCT_MANAGER]: {
    SELF_ACTIVATE:
      ACTIONS.USER.IS_ABLE_TO_SELF_ACTIVATE_PRODUCT_MANAGER_NOTIFICATIONS,
    SELF_DEACTIVATE:
      ACTIONS.USER.IS_ABLE_TO_SELF_DEACTIVATE_PRODUCT_MANAGER_NOTIFICATIONS,
    ACTIVATE: ACTIONS.USER.IS_ABLE_TO_DEACTIVATE_PRODUCT_MANAGER_NOTIFICATIONS,
    DEACTIVATE:
      ACTIONS.USER.IS_ABLE_TO_DEACTIVATE_CUSTOMER_SUPPORT_NOTIFICATIONS,
  },
}

export const isEnableNotificationButtonVisible = ({
  loggedUser,
  user,
  notificationType = USER_NOTIFICATION_TYPES.CUSTOMER_SUPPORT,
}) => {
  const userToEditHasRequiredAction =
    user &&
    user.actionIds.some(
      (userAction) =>
        userAction ===
          ACTIONS_FOR_NOTIFICATION_TYPES[notificationType].SELF_ACTIVATE ||
        userAction ===
          ACTIONS_FOR_NOTIFICATION_TYPES[notificationType].SELF_DEACTIVATE
    )
  const loggedUserHasRequiredAction = loggedUser.hasAction([
    ACTIONS_FOR_NOTIFICATION_TYPES[notificationType].ACTIVATE,
    ACTIONS_FOR_NOTIFICATION_TYPES[notificationType].DEACTIVATE,
  ])
  const loggedUserIsSameAsUserToEdit = user && user.id === loggedUser.data.id
  return (
    (userToEditHasRequiredAction && loggedUserHasRequiredAction) ||
    (userToEditHasRequiredAction && loggedUserIsSameAsUserToEdit)
  )
}

const TABLE_MIN_HEIGHT = 500

const UserDetail = (props) => {
  const { match, history } = props
  const [userQuery] = useQuery({
    query: `
      query($id: ID!){
        user(id: $id) {
          id
          name
          email
          isActive
          isCustomerSupportEmailEnabled
          isProductManagerEmailEnabled
          roles {
            title
            id
            actions {
              id
            }
          }
          actionIds
          countries {
            isoCode
            title
          }
        }
      }
    `,
    variables: {
      id: match.params.id,
    },
  })
  const user = (userQuery.data && userQuery.data.user) || false
  const isProductManagerNotificationButtonVisible =
    isEnableNotificationButtonVisible({
      loggedUser: props.user,
      user,
      notificationType: USER_NOTIFICATION_TYPES.PRODUCT_MANAGER,
    })
  const isCustomerSupportNotificationButtonVisible =
    isEnableNotificationButtonVisible({
      loggedUser: props.user,
      user,
      notificationType: USER_NOTIFICATION_TYPES.CUSTOMER_SUPPORT,
    })
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>User detail:</SideNavPage.Title>
        <Pane display="flex">
          {isProductManagerNotificationButtonVisible && (
            <Pane marginRight={10}>
              <PMEmailNotificationsButton
                userId={match.params.id}
                isProductManagerEmailEnabled={user.isProductManagerEmailEnabled}
                onStatusChanged={() => {
                  toaster.success('User status changed')
                }}
              />
            </Pane>
          )}
          {isCustomerSupportNotificationButtonVisible && (
            <Pane marginRight={10}>
              <CSEmailNotificationsButton
                userId={match.params.id}
                isCustomerSupportEmailEnabled={
                  user.isCustomerSupportEmailEnabled
                }
                onStatusChanged={() => {
                  toaster.success('User status changed')
                }}
              />
            </Pane>
          )}
          {props.user.hasAction([
            ACTIONS.USER.DEACTIVATE,
            ACTIONS.USER.ACTIVATE,
          ]) && (
            <Pane marginRight={10}>
              {user && (
                <UserStatusButton
                  userId={match.params.id}
                  isActive={user.isActive}
                  onStatusChanged={() => {
                    toaster.success('User status changed')
                  }}
                />
              )}
            </Pane>
          )}
          <BackButton onClick={() => history.goBack()} />
        </Pane>
      </SideNavPage.Header>
      <SideNavPage.Content>
        {userQuery.fetching && (
          <Spinner
            marginX="auto"
            marginY={TABLE_MIN_HEIGHT / 2}
            delay={200}
            size={80}
          />
        )}
        {user && (
          <Table border>
            <Table.Body>
              <Table.Row>
                <Table.TextHeaderCell>id</Table.TextHeaderCell>
                <Table.TextCell>{user.id}</Table.TextCell>
              </Table.Row>
              <Table.Row>
                <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                <Table.TextCell>{user.name}</Table.TextCell>
              </Table.Row>
              <Table.Row>
                <Table.TextHeaderCell>Email</Table.TextHeaderCell>
                <Table.TextCell>{user.email}</Table.TextCell>
              </Table.Row>
              <Table.Row>
                <Table.TextHeaderCell>Is Active</Table.TextHeaderCell>
                <Table.TextCell>
                  <Switch disabled checked={user.isActive} />
                </Table.TextCell>
              </Table.Row>
              {isCustomerSupportNotificationButtonVisible && (
                <Table.Row>
                  <Table.TextHeaderCell>
                    Is Customer Support Email Notification Enabled
                  </Table.TextHeaderCell>
                  <Table.TextCell>
                    <Switch
                      disabled
                      checked={user.isCustomerSupportEmailEnabled}
                    />
                  </Table.TextCell>
                </Table.Row>
              )}
              {isProductManagerNotificationButtonVisible && (
                <Table.Row>
                  <Table.TextHeaderCell>
                    Is Product Manager Email Notification Enabled
                  </Table.TextHeaderCell>
                  <Table.TextCell>
                    <Switch
                      disabled
                      checked={user.isProductManagerEmailEnabled}
                    />
                  </Table.TextCell>
                </Table.Row>
              )}
              <Table.Row height="auto">
                <Table.TextHeaderCell>Roles</Table.TextHeaderCell>
                <Table.TextCell>
                  <Pane marginTop={majorScale(2)} marginBottom={majorScale(2)}>
                    {user.roles.map(({ title }) => (
                      <Pane key={title}>{title}</Pane>
                    ))}
                  </Pane>
                </Table.TextCell>
              </Table.Row>
              {user.actionIds.includes(ACTIONS.USER.COUNTRY) && (
                <Table.Row height="auto">
                  <Table.TextHeaderCell>
                    Customer Support Specialist Countries
                  </Table.TextHeaderCell>
                  <Table.TextCell>
                    <Pane
                      marginTop={majorScale(2)}
                      marginBottom={majorScale(2)}
                    >
                      {user.countries.map((country) => (
                        <Pane>
                          {country.isoCode} - {country.title}
                        </Pane>
                      ))}
                    </Pane>
                  </Table.TextCell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

UserDetail.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(UserDetail)
