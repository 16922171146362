import React from 'react'
import PropTypes from 'prop-types'
import { Table, Label, Pane, SelectField, majorScale } from 'evergreen-ui'
import find from 'lodash.find'

const ProductVariantAttributeValues = ({
  productId,
  productVariantAttributeValues,
  variantCreatorAttributes,
  handleSave,
}) => (
  <Pane marginTop={majorScale(1)}>
    <Label marginBottom={majorScale(1)} display="block">
      Variant Creator Attributes
    </Label>
    <Table marginTop={majorScale(1)}>
      <Table.Head>
        <Table.TextHeaderCell>Attribute</Table.TextHeaderCell>
        <Table.TextHeaderCell>Attribute Value</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {variantCreatorAttributes &&
          variantCreatorAttributes.map((variantCreatorAttribute) => {
            const attributeId = variantCreatorAttribute.attribute.id
            const currentAttributeValue = find(productVariantAttributeValues, {
              attributeValue: { attributeId },
            })
            return (
              <Table.Row key={variantCreatorAttribute.attribute.id}>
                <Table.TextCell>
                  {variantCreatorAttribute.attribute.title}
                </Table.TextCell>
                <Table.TextCell>
                  <SelectField
                    label="Attribute Value"
                    value={
                      currentAttributeValue &&
                      currentAttributeValue.attributeValue.id
                    }
                    onChange={(e) => {
                      handleSave([
                        ...productVariantAttributeValues.filter(
                          (productVariantAttributeValue) =>
                            productVariantAttributeValue.attributeValue
                              .attributeId !== attributeId
                        ),
                        ...(e.target.value
                          ? [
                              {
                                productId,
                                attributeValue: {
                                  id: Number(e.target.value),
                                  attributeId,
                                },
                              },
                            ]
                          : []),
                      ])
                    }}
                  >
                    <option value="">---Select Attribute Value---</option>
                    {variantCreatorAttribute.attribute.values &&
                      variantCreatorAttribute.attribute.values.map(
                        (variantCreatorAttributeValue) => (
                          <option
                            key={variantCreatorAttributeValue.id}
                            value={variantCreatorAttributeValue.id}
                          >
                            {variantCreatorAttributeValue.title}
                          </option>
                        )
                      )}
                  </SelectField>
                </Table.TextCell>
              </Table.Row>
            )
          })}
      </Table.Body>
    </Table>
  </Pane>
)

ProductVariantAttributeValues.propTypes = {
  productId: PropTypes.number.isRequired,
  handleSave: PropTypes.func.isRequired,
  productVariantAttributeValues: PropTypes.arrayOf({
    attributeValue: PropTypes.shape({
      id: PropTypes.number,
      attributeId: PropTypes.number,
      title: PropTypes.string,
    }),
  }),
  variantCreatorAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: {
        id: PropTypes.number,
        title: PropTypes.string,
        values: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
          })
        ),
      },
    })
  ),
}

export default ProductVariantAttributeValues
