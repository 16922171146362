import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, toaster } from 'evergreen-ui'
import { useMutation } from 'urql'
import urql from 'utils/urql'

const deleteTexts = {
  title: 'Delete deal of the week',
  confirmLabel: 'Delete',
  successToast: 'Deal of the week successfully deleted.',
  getBodyText: ({ product: { title } = {}, id }) =>
    `Do you really want to delete Deal of the week: "${title}" with id: "${id}"?`,
}

const destroyTexts = {
  title: 'Destroy deal of the week',
  confirmLabel: 'Destroy',
  successToast: 'Deal of the week successfully destroyed.',
  getBodyText: ({ product: { title } = {}, id }) =>
    `Do you really want to destroy "${title}" with id: "${id}"? Once, destroyed, data is lost permanently.`,
}

const DeleteDoWDialog = ({
  onClose,
  itemToDelete,
  onSuccess,
  isDestroyDialog,
}) => {
  const [, executeDeleteMutation] = useMutation(`
    mutation($input: DeleteDealOfTheWeekInput!) {
      deleteDealOfTheWeek(input: $input) {
        id
      }
    }
  `)
  if (!itemToDelete) {
    return null
  }
  const texts = isDestroyDialog ? destroyTexts : deleteTexts
  return (
    <Dialog
      isShown={!!itemToDelete}
      title={texts.title}
      intent="danger"
      confirmLabel={texts.confirmLabel}
      onCloseComplete={onClose}
      onConfirm={async (close) => {
        const { error } = await executeDeleteMutation({
          input: {
            id: itemToDelete.id,
            isSoft: !isDestroyDialog,
          },
        })
        if (error) {
          urql.handleError(error)
        } else {
          toaster.success(texts.successToast)
          if (onSuccess) {
            onSuccess()
          }
        }
        close()
      }}
    >
      {itemToDelete && texts.getBodyText(itemToDelete)}
    </Dialog>
  )
}

DeleteDoWDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  itemToDelete: PropTypes.shape({
    product: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
  }),
  onSuccess: PropTypes.func,
  isDestroyDialog: PropTypes.bool,
}
export default DeleteDoWDialog
