import React from 'react'
import PropTypes from 'prop-types'
import NoData from 'sharedComponents/NoData'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import Pagination from 'components/Pagination'
import { extractDataFromDealOfTheWeeksQuery } from '../queries'

const DoWList = ({ dealOfTheWeeksQuery, children, setCurrentPage }) => {
  const { dealOfTheWeeks, paginationInfo } =
    extractDataFromDealOfTheWeeksQuery(dealOfTheWeeksQuery)
  return (
    <React.Fragment>
      <FullScreenLoading isVisible={dealOfTheWeeksQuery.fetching} />
      {children({ dealOfTheWeeks })}
      {!dealOfTheWeeksQuery.fetching && dealOfTheWeeks.length === 0 && (
        <NoData padding={60} />
      )}
      {paginationInfo && dealOfTheWeeks.length > 1 && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </React.Fragment>
  )
}

DoWList.propTypes = {
  dealOfTheWeeksQuery: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      dealOfTheWeeks: PropTypes.shape({
        data: PropTypes.array.isRequired,
        paginationInfo: PropTypes.object.isRequired,
      }),
    }),
  }),
  children: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}

export default DoWList
