import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import { useQuery, useMutation } from 'urql'
import { getSpaceInPx } from 'utils/styled-system'
import urql from 'utils/urql'
import { DEAL_STATUSES } from 'constants/constants'
import ACTIONS from 'constants/actions'
import ActionsRow from 'components/ActionsRow'
import DeleteDoWDialog from '../../../components/DeleteDoWDialog'
import DoWItem from '../components/DoWItem'
import { DOW_ITEM_Y_MARGIN } from '../constants'
import { getPathToDealOfTheWeekDetail } from '../../../utils'
import {
  getDealOfTheWeeksQuery,
  restoreDealOfTheWeekMutation,
} from '../queries'
import DoWList from '../components/DoWList'

const { DELETED } = DEAL_STATUSES

const SoftDeleted = ({ history, user }) => {
  const [itemToDelete, setItemToDelete] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [dealOfTheWeeksQuery] = useQuery(
    getDealOfTheWeeksQuery({
      status: [DELETED],
      pagination: { page: currentPage, limit: 20 },
    })
  )
  const [restoreMutation, executeRestoreMutation] = useMutation(
    restoreDealOfTheWeekMutation
  )
  useEffect(() => {
    const error = dealOfTheWeeksQuery.error || restoreMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [dealOfTheWeeksQuery, dealOfTheWeeksQuery.error, restoreMutation.error])
  return (
    <Pane>
      <DeleteDoWDialog
        itemToDelete={itemToDelete}
        onClose={() => {
          setItemToDelete(null)
        }}
        isDestroyDialog
      />
      <ActionsRow />
      <DoWList
        dealOfTheWeeksQuery={dealOfTheWeeksQuery}
        setCurrentPage={setCurrentPage}
      >
        {({ dealOfTheWeeks }) =>
          dealOfTheWeeks.map((item) => (
            <DoWItem
              key={item.id}
              {...item}
              my={getSpaceInPx(DOW_ITEM_Y_MARGIN)}
              onDetailClick={() => {
                history.push(getPathToDealOfTheWeekDetail(item.id))
              }}
              onDeleteButtonClicked={() => {
                setItemToDelete(item)
              }}
              deleteIcon="delete"
              deleteIconDescription="Destroy deal of the week"
              onRevertButtonClicked={() => {
                executeRestoreMutation({
                  id: item.id,
                })
              }}
              isWithDeleteButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_WEEK.DESTROY
              )}
              isWithRestoreButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_WEEK.RESTORE
              )}
            />
          ))
        }
      </DoWList>
    </Pane>
  )
}

SoftDeleted.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withRouter, withUser)(SoftDeleted)
