import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import {
  Table,
  Button,
  Dialog,
  Label,
  Pane,
  SelectField,
  majorScale,
  TextareaField,
  TextInputField,
  Alert,
} from 'evergreen-ui'
import find from 'lodash.find'

// Editor
import { EditorState, convertFromRaw } from 'draft-js'
import { convertEditorStateToRawJson } from 'utils/convertToRaw'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import EditorDeleteCustomOption from './EditorDeleteCustomOption'

const Translations = ({
  translations,
  handleSaveTranslations,
  isEditDisabled,
  isAddDisabled,
  actionOnClick,
}) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )
  const [isAddTranslationDialogOpen, setIsAddTranslationDialogOpen] =
    useState(false)
  const [isEditTranslationDialogOpen, setIsEditTranslationDialogOpen] =
    useState(false)
  const [language, setLanguage] = useState({})
  const [titleTranslation, setTitleTranslation] = useState('')
  const [webpageTranslation, setWebpageTranslation] = useState(null)
  const [subtitleTranslation, setSubtitleTranslation] = useState('')

  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })

  const getOnClickValue = () => {
    if (actionOnClick === 'webpage') {
      return webpageTranslation
    }
    if (actionOnClick === 'modal') {
      return convertEditorStateToRawJson(editorState)
    }

    return ''
  }

  return (
    <Pane>
      <Label marginBottom={majorScale(1)} display="block">
        Translations
      </Label>
      {!isAddDisabled && (
        <Button
          marginBottom={majorScale(1)}
          onClick={() => {
            setLanguage({})
            setTitleTranslation('')
            setSubtitleTranslation('')
            setWebpageTranslation(null)
            setWebpageTranslation(null)
            setEditorState(() => EditorState.createEmpty())
            setIsAddTranslationDialogOpen(true)
          }}
        >
          Add Translation
        </Button>
      )}
      <Table marginTop={majorScale(1)}>
        <Table.Head>
          <Table.TextHeaderCell>Language</Table.TextHeaderCell>
          <Table.TextHeaderCell>Title</Table.TextHeaderCell>
          <Table.TextHeaderCell>Subtitle</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {translations &&
            translations.map((translation) => (
              <Table.Row key={translation.language.isoCode}>
                <Table.TextCell>{translation.language.title}</Table.TextCell>
                <Table.TextCell>{translation.title}</Table.TextCell>
                <Table.TextCell>{translation.subtitle}</Table.TextCell>
                <Table.Cell>
                  <Button
                    disabled={isEditDisabled}
                    marginRight={majorScale(2)}
                    iconBefore="edit"
                    onClick={(e) => {
                      e.stopPropagation()
                      setLanguage(translation.language)
                      setTitleTranslation(translation.title)
                      if (
                        actionOnClick === 'modal' &&
                        translation.onClickValue
                      ) {
                        setEditorState(() =>
                          EditorState.createWithContent(
                            convertFromRaw(JSON.parse(translation.onClickValue))
                          )
                        )
                      }
                      if (actionOnClick === 'webpage') {
                        setWebpageTranslation(translation.onClickValue)
                      }
                      setSubtitleTranslation(translation.subtitle)
                      setIsEditTranslationDialogOpen(true)
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isEditDisabled}
                    marginRight={majorScale(2)}
                    iconBefore="trash"
                    intent="danger"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleSaveTranslations(
                        translations.filter(
                          (addedTranslation) =>
                            addedTranslation.language.isoCode !==
                            translation.language.isoCode
                        )
                      )
                    }}
                  >
                    Delete
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Dialog
        isShown={isAddTranslationDialogOpen || isEditTranslationDialogOpen}
        title={
          isAddTranslationDialogOpen ? 'Add Translation' : `Edit Translation`
        }
        hasCancel={false}
        confirmLabel={isAddTranslationDialogOpen ? 'Add' : 'Edit'}
        onCloseComplete={() => {
          setIsAddTranslationDialogOpen(false)
          setIsEditTranslationDialogOpen(false)
        }}
        onConfirm={(close) => {
          if (isAddTranslationDialogOpen && language.isoCode) {
            handleSaveTranslations([
              ...translations,
              {
                language,
                title: titleTranslation,
                subtitle: subtitleTranslation,
                onClickValue: getOnClickValue(),
              },
            ])
            close()
          }
          if (isEditTranslationDialogOpen) {
            handleSaveTranslations(
              translations.map((addedTranslation) => {
                if (addedTranslation.language.isoCode === language.isoCode) {
                  return {
                    language,
                    title: titleTranslation,
                    subtitle: subtitleTranslation,
                    onClickValue: getOnClickValue(),
                  }
                }
                return addedTranslation
              })
            )
            close()
          }
        }}
      >
        <Alert
          intent="info"
          title="Please remember to fill ALL fields to save translation"
          marginBottom={20}
          style={{ width: '100%' }}
        />
        <SelectField
          label="Language"
          value={language.isoCode}
          disabled={isEditTranslationDialogOpen}
          onChange={(e) => {
            const useLanguage = find(languagesQuery.data.languages, {
              isoCode: e.target.value,
            })
            setLanguage(useLanguage)
          }}
        >
          <option value="">---Select language---</option>
          {languagesQuery.data &&
            languagesQuery.data.languages.map((useLanguage) => (
              <option key={useLanguage.isoCode} value={useLanguage.isoCode}>
                {useLanguage.title}
              </option>
            ))}
        </SelectField>
        <TextareaField
          label="Title"
          required
          value={titleTranslation}
          onChange={(e) => {
            setTitleTranslation(e.target.value)
          }}
        />

        <TextareaField
          label="Subtitle"
          required
          value={subtitleTranslation}
          onChange={(e) => {
            setSubtitleTranslation(e.target.value)
          }}
        />
        {actionOnClick === 'modal' && (
          <Pane>
            <p>Modal Content</p>
            <Editor
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'fontSize',
                  'list',
                  'textAlign',
                  'image',
                ],
              }}
              editorState={editorState}
              editorStyle={{
                border: '1px solid #e2e2e2',
                height: 300,
              }}
              onEditorStateChange={setEditorState}
              toolbarCustomButtons={[<EditorDeleteCustomOption />]}
            />
          </Pane>
        )}

        {actionOnClick === 'webpage' && (
          <Pane>
            <TextInputField
              label="URL"
              required
              placeholder="https://google.com"
              disabled={false}
              value={webpageTranslation || ''}
              onChange={({ target }) => {
                setWebpageTranslation(
                  target.value === '' ? undefined : target.value
                )
              }}
            />
          </Pane>
        )}
      </Dialog>
    </Pane>
  )
}

Translations.propTypes = {
  actionOnClick: PropTypes.string,
  handleSaveTranslations: PropTypes.func.isRequired,
  isEditDisabled: PropTypes.bool.isRequired,
  isAddDisabled: PropTypes.bool.isRequired,
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.shape({
        title: PropTypes.string,
        isoCode: PropTypes.string,
      }),
      title: PropTypes.string,
      shortDescription: PropTypes.string,
      longDescription: PropTypes.string,
      languageIsoCode: PropTypes.string,
    })
  ),
}

export default Translations
