import { parseInteger } from 'utils/numbers'

export const getThemeDealsQuery = (
  { status, pagination = { page: 1 }, search, sorter, themeOfTheMonthId },
  additionalFields = ''
) => ({
  query: `
  fragment themeDeal on ThemeDeal {
    id
    product {
      title
      shortDescription
      mainImage {
        url
      }
    }
    status
    currentStockQuantity
    stockQuantity
    isPaused
  }
  query themeDeals($input: ThemeDealsInput!) {
    themeDeals(input: $input) {
      paginationInfo {
        totalCount
        firstPage
        currentPage
        nextPage
        previousPage
        lastPage
      }
      data {
        ...themeDeal
        ${additionalFields}
      }
    }
  }
`,
  variables: {
    input: {
      status,
      pagination,
      search,
      sorter,
      themeOfTheMonthId,
    },
  },
  requestPolicy: 'network-only',
})

export const extractDataFromThemeDealsQuery = (themeDealsQuery) => {
  const themeDeals = themeDealsQuery.data
    ? themeDealsQuery.data.themeDeals.data.map((themeDeal) => ({
        ...themeDeal,
        id: parseInteger(themeDeal.id),
      }))
    : []
  const paginationInfo =
    themeDealsQuery.data && themeDealsQuery.data.themeDeals.paginationInfo
  return { themeDeals, paginationInfo }
}

export const restoreThemeDealMutation = `
  mutation restoreThemeDeal($id: Int!) {
    restoreThemeDeal(id: $id) {
      id
      status
    }
  }
`

export const pauseThemeDealMutation = `
  mutation pauseThemeDeal($id: Int!) {
    pauseThemeDeal(id: $id) {
      id
    }
  }
`
export const resumeThemeDealMutation = `
  mutation resumeThemeDeal($id: Int!) {
    resumeThemeDeal(id: $id) {
      id
    }
  }
`
