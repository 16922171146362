import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Spinner, Pane, Heading, majorScale } from 'evergreen-ui'
import { useQuery } from 'urql'
import format from 'date-fns/format'
import urql from 'utils/urql'
import { formatPrice } from 'utils/money'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import Pagination from 'components/Pagination'
import { TIME_FORMAT } from 'constants/constants'
import OrderStatus from 'components/OrderStatus'
import { compose } from 'recompose'
import ACTIONS from 'constants/actions'
import ROUTES from 'constants/routes'

const TABLE_MIN_HEIGHT = 200

const CustomerOrders = ({ customerId, user, history }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [userOrdersQuery] = useQuery({
    query: `
      query($pagination: PaginationInput, $filter: UserOrdersFilter) {
        userOrders(pagination: $pagination, filter: $filter) {
          paginationInfo {
            totalCount
            firstPage
            currentPage
            nextPage
            previousPage
            lastPage
          }
          data {
            id
            totalPriceTotal
            totalPricePaid
            currencyIsoCode
            status
            createdAtUtc
          }
        }
      }
    `,
    variables: {
      pagination: { page: currentPage, limit: 20 },
      filter: {
        customerId,
      },
    },
  })
  useEffect(() => {
    if (userOrdersQuery.error) {
      urql.handleError(userOrdersQuery.error)
    }
  }, [userOrdersQuery.error])
  const userOrders =
    userOrdersQuery.data && userOrdersQuery.data.userOrders.data
  const paginationInfo =
    userOrdersQuery.data && userOrdersQuery.data.userOrders.paginationInfo
  return (
    <Pane marginTop={majorScale(3)}>
      <Heading is="h3" marginBottom={majorScale(2)}>
        Customer Orders
      </Heading>
      <Table border>
        <Table.Head>
          <Table.TextHeaderCell>ID</Table.TextHeaderCell>
          <Table.TextHeaderCell>Status</Table.TextHeaderCell>
          <Table.TextHeaderCell textAlign="right">Price</Table.TextHeaderCell>
          <Table.TextHeaderCell textAlign="right">Paid</Table.TextHeaderCell>
          <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {userOrdersQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {userOrders &&
            userOrders.map(
              ({
                id,
                status,
                createdAtUtc,
                totalPricePaid,
                totalPriceTotal,
                currencyIsoCode,
              }) => (
                <Table.Row
                  key={id}
                  isSelectable={user.hasAction(ACTIONS.ORDER.DETAIL)}
                  onSelect={() => history.push(`/${ROUTES.ORDERS}/${id}`)}
                >
                  <Table.TextCell>{id}</Table.TextCell>
                  <Table.Cell>
                    <OrderStatus status={status} />
                  </Table.Cell>
                  <Table.TextCell textAlign="right">
                    {formatPrice({ value: totalPriceTotal, currencyIsoCode })}
                  </Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {formatPrice({ value: totalPricePaid, currencyIsoCode })}
                  </Table.TextCell>
                  <Table.TextCell>
                    {format(createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                  </Table.TextCell>
                </Table.Row>
              )
            )}
        </Table.Body>
      </Table>
      {paginationInfo && userOrders.length > 0 && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </Pane>
  )
}

CustomerOrders.propTypes = {
  customerId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withUser, withRouter)(CustomerOrders)
