import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import ROUTES, { THEME_DEAL_MANAGEMENT_ROUTES } from 'constants/routes'
import NewThemeDeal from './routes/NewThemeDeal'
import EditThemeDeal from './routes/EditThemeDeal'
import Management from './routes/Management'

const ThemeDeal = () => (
  <Switch>
    <Route
      path={`/${ROUTES.THEME_OF_THE_MONTH}/:themeOfTheMonthId/new`}
      component={NewThemeDeal}
    />
    <Route
      path={`/${ROUTES.THEME_OF_THE_MONTH}/:themeOfTheMonthId/edit/:id`}
      component={EditThemeDeal}
    />
    <Route
      component={Management}
      path={`/${
        ROUTES.THEME_OF_THE_MONTH
      }/:themeOfTheMonthId/:tab(${Object.values(
        THEME_DEAL_MANAGEMENT_ROUTES
      ).join('|')})?`}
    />
  </Switch>
)

ThemeDeal.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default ThemeDeal
