export const getSingleBestSellerQuery = `
query($id: Int!) {
  singleBestSeller(id: $id) {
    id
    product {
      id
      title
      shortDescription
      longDescription
      translations {
        language {
          isoCode
          title
        }
        languageIsoCode
        title
        shortDescription
        longDescription
      }
      reviewScore
      size
      warehouseDeliveryTimeInDays
      productCondition {
        id
        title
      }
      mainImage {
        id
        url
        name
      }
      additionalImages {
        id
        url
        name
      }
      seller {
        id
        accountName
      }
      externalPid
      modelName
    }
    stockQuantity
    price
    status
    competitors {
      id
      title
      price
      country {
        isoCode
        title
      }
    }
    defaultCompetitorId
    changelog {
      createdByUser {
        name
      }
      oldStatus
      newStatus
      message
      createdAtUtc
    }
    comment
    isPaused
  }
}
`
