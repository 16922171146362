import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import styled from 'styled-components/macro'
import {
  Button,
  TextInputField,
  Pane,
  majorScale,
  toaster,
  IconButton,
} from 'evergreen-ui'
import { withRouter } from 'react-router-dom'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import MainImage from 'components/MainImage'
import AdditionalImages from 'components/AdditionalImages'
import ProductVariantAttributeValues from './ProductVariantAttributeValues'
import { getPathToProductVariant } from '../utils'

const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
`

export const formatDataIntoInput = ({
  mainImage,
  ean,
  productVariantAttributeValues,
  additionalImages,
  externalPid,
}) => {
  const toSave = {
    mainImageId: mainImage && parseInteger(mainImage.id),
    ean,
    externalPid,
    productVariantAttributeValues:
      productVariantAttributeValues &&
      productVariantAttributeValues.map(
        ({ __typename, attributeValue, ...rest }) => ({
          ...rest,
          attributeId: attributeValue && attributeValue.attributeId,
          attributeValueId: attributeValue && attributeValue.id,
        })
      ),
    additionalImagesIds: additionalImages
      ? additionalImages.map(({ id: imageId }) => parseInteger(imageId))
      : [],
  }

  return toSave
}

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const ProductVariantForm = (props) => {
  const { data = {}, isReadOnly, history } = props
  const productId = parseInteger(data.productId)
  const [mainImage, setMainImage] = useState(data.mainImage || undefined)
  const [ean, setEan] = useState(data.ean || null)
  const [externalPid, setExternalPid] = useState('')
  const [externalNote, setExternalNote] = useState('')
  const [internalBenekoPid, setInternalBenekoPid] = useState(
    data.externalPid || null
  )
  const [productVariantAttributeValues, setProductVariantAttributeValues] =
    useState(data.productVariantAttributeValues || [])
  const [additionalImages, setAdditionalImages] = useState(
    data.additionalImages || []
  )
  const [productQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query($id: Int!, $isVariantCreator: Boolean) {
        product(id: $id) {
          productAttributeValues(isVariantCreator: $isVariantCreator) {
            attribute {
              id
              title
              values {
                id
                title
              }
            }
          }
        }
      }
    `,
    variables: {
      id: productId,
      isVariantCreator: true,
    },
  })
  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateProductVariantInput!) {
      createProductVariant(input: $input) {
        id
      }
    }
  `)

  const [, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateProductVariantInput!) {
      updateProductVariant(input: $input) {
        id
      }
    }
  `)

  const [, executeDeleteSingleExternalId] = useMutation(`
    mutation deleteExternalId($id: Int!) {
      deleteProductVariantExternalId(id: $id)
    }
  `)

  const [, executeCreateSingleExternalPid] = useMutation(`
    mutation($input: CreateExternalIdInput!) {
      createProductVariantExternalId(input: $input) {
        externalPid
      }
    }
  `)

  const renderExternalPidsSection = () => {
    if (data.id) {
      return (
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextInputField
            label="New External Product ID"
            disabled={isReadOnly}
            value={externalPid}
            onChange={({ target }) => {
              setExternalPid(target.value === '' ? null : target.value)
            }}
          />
          <div style={{ marginLeft: 15 }}>
            <TextInputField
              label="Supplier name (i.e yukatel)"
              disabled={isReadOnly}
              value={externalNote}
              onChange={({ target }) => {
                setExternalNote(target.value === '' ? null : target.value)
              }}
            />
          </div>
          <Button
            style={{ marginLeft: 5 }}
            intent="success"
            appearance="primary"
            onClick={async () => {
              const { error } = await executeCreateSingleExternalPid({
                input: {
                  productVariantId: data.id,
                  externalPid,
                  externalNote,
                },
              })
              displayToastOnSave(error)
              if (!error) {
                window.location.reload()
              }
            }}
          >
            Add external Id
          </Button>
        </div>
      )
    }
    return <div>Save variant to add external product id</div>
  }

  const dataToSave = {
    mainImage,
    ean,
    productVariantAttributeValues,
    additionalImages,
    externalPid: internalBenekoPid,
  }
  const variantCreatorAttributeValues = productQuery.data
    ? productQuery.data.product.productAttributeValues
    : []

  const externalPids = data.externalPids || []
  return (
    <Pane>
      <Pane maxWidth={780}>
        <MainImage
          mainImage={mainImage}
          setMainImage={setMainImage}
          isDisabled={isReadOnly}
        />
        <TextInputField
          marginTop={majorScale(1)}
          label="EAN"
          disabled={isReadOnly}
          required
          value={ean || ''}
          onChange={({ target }) => {
            setEan(target.value === '' ? null : target.value)
          }}
        />
        <TextInputField
          label="Beneko Internal Product ID"
          disabled={isReadOnly}
          value={internalBenekoPid || ''}
          required
          onChange={({ target }) => {
            setInternalBenekoPid(target.value === '' ? null : target.value)
          }}
        />
        <AdditionalImages
          isDisabled={isReadOnly}
          additionalImages={additionalImages}
          setAdditionalImages={setAdditionalImages}
        />
        <ProductVariantAttributeValues
          productId={productId}
          productVariantAttributeValues={productVariantAttributeValues}
          variantCreatorAttributes={variantCreatorAttributeValues}
          handleSave={setProductVariantAttributeValues}
        />
        <Pane marginTop={majorScale(2)} justifyContent="center" display="flex">
          <Button
            intent="success"
            appearance="primary"
            onClick={async () => {
              if (data.id) {
                const { error } = await executeUpdateMutation({
                  input: {
                    id: parseInteger(data.id),
                    ...formatDataIntoInput(dataToSave),
                  },
                })
                displayToastOnSave(error)
              } else {
                const result = await executeCreateMutation({
                  input: {
                    productId,
                    ...formatDataIntoInput(dataToSave),
                  },
                })
                displayToastOnSave(result.error)
                if (!result.error) {
                  history.replace(
                    getPathToProductVariant({
                      id: result.data.createProductVariant.id,
                      productId,
                    })
                  )
                }
              }
            }}
          >
            Save
          </Button>
        </Pane>
      </Pane>
      <Pane>
        <hr />
        External Product IDs Sections
        {externalPids.map((item) => (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextInputField
              key={item.id}
              label="External Product ID"
              disabled
              value={item.externalPid || ''}
            />
            <div style={{ marginLeft: 15 }}>
              <TextInputField
                key={item.id}
                label="Supplier name"
                disabled
                value={item.externalNote || ''}
              />
            </div>
            <StyledIconButton
              intent="danger"
              icon="trash"
              onClick={async () => {
                const { error } = await executeDeleteSingleExternalId({
                  id: parseInteger(item.id),
                })
                displayToastOnSave(error)
                if (!error) {
                  window.location.reload()
                }
              }}
            />
          </div>
        ))}
        {renderExternalPidsSection()}
      </Pane>
    </Pane>
  )
}

ProductVariantForm.propTypes = {
  data: PropTypes.object,
  isReadOnly: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
}

export default withRouter(ProductVariantForm)
