import React from 'react'
import PropTypes from 'prop-types'
import {
  FormField,
  Table,
  Paragraph,
  Icon,
  Pane,
  majorScale,
} from 'evergreen-ui'
import DealBadge from 'components/DealBadge'
import { formatToHumanReadable } from 'utils/datetime'

const Changelog = ({ data }) => (
  <FormField disabled label="Changelog" paddingBottom={majorScale(2)}>
    <Table border>
      <Table.Head>
        <Table.TextHeaderCell>User</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status Change</Table.TextHeaderCell>
        <Table.TextHeaderCell>Message</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {data.map(
          ({ createdByUser, oldStatus, newStatus, createdAtUtc, message }) => (
            <Table.Row height="auto" key={createdAtUtc}>
              <Table.TextCell>
                <Pane>
                  {createdByUser.name}
                  <Pane />
                  {formatToHumanReadable({
                    date: createdAtUtc,
                    isTimeShown: true,
                  })}
                </Pane>
              </Table.TextCell>
              <Table.Cell>
                <DealBadge status={oldStatus} />
                <Icon icon="arrow-right" />
                <DealBadge status={newStatus} />
              </Table.Cell>
              <Table.Cell>
                <Paragraph marginY={24}>{message}</Paragraph>
              </Table.Cell>
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  </FormField>
)

Changelog.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      oldStatus: PropTypes.string.isRequired,
      newStatus: PropTypes.string.isRequired,
      createdAtUtc: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      createdByUser: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
}

export default Changelog
