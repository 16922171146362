import React from 'react'
import PropTypes from 'prop-types'
import MultiSelectMenu from 'components/MultiSelectMenu'
import { RETURN_ORDER_STATUSES } from 'constants/constants'
import { getOrderLabelByStatus } from 'components/ReturnOrderStatus'

const ReturnStatusesSelectMenu = ({ selectedStatuses, onChange }) => (
  <MultiSelectMenu
    selectedValues={selectedStatuses}
    onChange={onChange}
    title="Select multiple statuses:"
    placeholder="Select multiple statuses..."
    options={Object.values(RETURN_ORDER_STATUSES).map((status) => ({
      value: status,
      label: getOrderLabelByStatus(status),
    }))}
  />
)

ReturnStatusesSelectMenu.propTypes = {
  selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ReturnStatusesSelectMenu
