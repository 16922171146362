import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter, NavLink } from 'react-router-dom'
import { useMutation } from 'urql'
import { Pane, TextInputField, Button, majorScale } from 'evergreen-ui'
import urql from 'utils/urql'
import FullCenterPage from 'components/FullCenterPage'
import ROUTES from 'constants/routes'

const SetPassword = ({ match }) => {
  const { setPasswordToken } = match.params
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [isInvalid, setIsInvalid] = useState(false)
  const [isSuccess, setIsSuccess] = useState('')
  const [userResetPasswordMutation, executeUserResetPasswordMutation] =
    useMutation(`
    mutation($input: UserResetPasswordInput!) {
      userResetPassword(input: $input)
    }
  `)
  useEffect(() => {
    const { error, data } = userResetPasswordMutation
    if (error) {
      urql.handleError(error)
    }
    if (data && data.userResetPassword) {
      setIsSuccess(true)
    }
  }, [userResetPasswordMutation])
  return (
    <FullCenterPage>
      {isSuccess && (
        <Pane>
          Your password has been set. Now you can{' '}
          <NavLink to={`/${ROUTES.SIGN_IN}`}>sign in</NavLink>.
        </Pane>
      )}
      {!isSuccess && (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            if (password !== passwordRepeat) {
              setIsInvalid(true)
            } else {
              setIsInvalid(false)
              executeUserResetPasswordMutation({
                input: {
                  resetPasswordToken: setPasswordToken,
                  password,
                },
              })
            }
          }}
        >
          <Pane display="flex" flexDirection="column" alignItems="center">
            <TextInputField
              width="100%"
              type="password"
              label="Password"
              required
              isInvalid={isInvalid}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
            <TextInputField
              width="100%"
              type="password"
              label="Password Repeat"
              required
              isInvalid={isInvalid}
              value={passwordRepeat}
              onChange={(e) => {
                setPasswordRepeat(e.target.value)
              }}
            />
            <Button
              disabled={userResetPasswordMutation.fetching}
              marginTop={majorScale(1)}
            >
              Set Password
            </Button>
          </Pane>
        </form>
      )}
    </FullCenterPage>
  )
}

SetPassword.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      setPasswordToken: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default withRouter(SetPassword)
