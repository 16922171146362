import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import format from 'date-fns/format'
import {
  Table,
  Avatar,
  Spinner,
  Button,
  Dialog,
  TextInputField,
  Text,
  Pane,
  Label,
  Checkbox,
  Switch,
  toaster,
} from 'evergreen-ui'
import urql from 'utils/urql'
import find from 'lodash.find'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES from 'constants/routes'
import { TIME_FORMAT } from 'constants/constants'
import { withUser } from 'UserProvider'
import UserStatusButton from 'components/UserStatusButton'
import CSEmailNotificationsButton from 'components/CSEmailNotificationsButton'
import PMEmailNotificationsButton from 'components/PMEmailNotificationsButton'
import ACTIONS from 'constants/actions'
import {
  isEnableNotificationButtonVisible,
  USER_NOTIFICATION_TYPES,
} from 'routes/UserDetail/UserDetail'

const TABLE_MIN_HEIGHT = 500

let isInitialMount = true

const Users = (props) => {
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false)
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [lastUpdateId, setLastUpdatedUserId] = useState('')

  const [id, setId] = useState('')
  const [isActiveUser, setIsActiveUser] = useState(false)
  const [isCustomerSupportEmailEnabled, setIsCustomerSupportEmailEnable] =
    useState(false)
  const [isProductManagerEmailEnabled, setIsProductManagerEmailEnabled] =
    useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [countries, setCountries] = useState([])
  const [actionIds, setActionIds] = useState([])
  const [roles, setRoles] = useState([])
  const [usersQuery, executeUsersQuery] = useQuery({
    query: `
      query {
        users {
          id
          name
          email
          createdAtUtc
          isActive
          isCustomerSupportEmailEnabled
          isProductManagerEmailEnabled
          actionIds
          roles {
            id
            title
            actions {
              id
            }
          }
          countries {
            isoCode
            title
          }
        }
      }
    `,
  })
  const [createMutation, executeCreateMutation] = useMutation(`
    mutation($input: CreateUserInput!) {
      createUser(input: $input) {
        id
      }
    }
  `)
  const lastCreatedId = createMutation.data && createMutation.data.createUser.id

  const [updateMutation, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateUserInput!) {
      updateUser(input: $input) {
        id
      }
    }
  `)
  const [rolesQuery] = useQuery({
    query: `
      query {
        userRoles {
          id
          title
          actions {
            id
          }
        }
      }
    `,
  })
  const [countriesQuery] = useQuery({
    query: `
      query {
        userCountries {
          isoCode
          title
        }
      }
    `,
  })
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: ID!) {
      deleteUser(id: $id) {
        id
      }
    }
  `)
  useEffect(() => {
    if (updateMutation.data && updateMutation.data.updateUser.id) {
      setLastUpdatedUserId(updateMutation.data.updateUser.id)
    }
  }, [updateMutation.data])
  const lastDeletedId = deleteMutation.data && deleteMutation.data.deleteUser.id
  useEffect(() => {
    if (!isInitialMount) {
      executeUsersQuery({ requestPolicy: 'network-only' })
    } else {
      isInitialMount = false
    }
  }, [
    props.user.data.id,
    lastCreatedId,
    lastUpdateId,
    lastDeletedId,
    executeUsersQuery,
  ])
  useEffect(() => {
    const error =
      usersQuery.error || createMutation.error || updateMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [
    usersQuery.error,
    createMutation.error,
    updateMutation.error,
    deleteMutation.error,
  ])
  const isWithStatusButton =
    (!isActiveUser && props.user.hasAction(ACTIONS.USER.ACTIVATE)) ||
    (isActiveUser && props.user.hasAction(ACTIONS.USER.DEACTIVATE))
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Users</SideNavPage.Title>
        {props.user.hasAction(ACTIONS.USER.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setId('')
              setEmail('')
              setName('')
              setRoles([])
              setCountries([])
              setActionIds([])
              setIsCreateUserModalOpen(true)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head height="auto" paddingY={12}>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Email</Table.TextHeaderCell>
            <Table.TextHeaderCell>Role</Table.TextHeaderCell>
            <Table.TextHeaderCell>Created At</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
              Is Active
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={225} flexShrink={0} flexGrow={0}>
              Actions
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {usersQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                delay={200}
                size={80}
              />
            )}
            {usersQuery.data &&
              usersQuery.data.users.map((user) => (
                <Table.Row
                  height="auto"
                  minHeight="45px"
                  paddingY={12}
                  key={user.id}
                  isSelectable={props.user.hasAction(ACTIONS.USER.DETAIL)}
                  onSelect={() =>
                    props.history.push(`${ROUTES.USERS}/${user.id}`)
                  }
                >
                  <Table.Cell display="flex" alignItems="center">
                    <Avatar name={user.name} />
                    <Text marginLeft={8} size={300} fontWeight={500}>
                      {user.name}
                    </Text>
                  </Table.Cell>
                  <Table.TextCell>{user.email}</Table.TextCell>
                  <Table.TextCell>
                    {user.roles.map((role) => (
                      <Pane key={role.id}>{role.title}</Pane>
                    ))}
                  </Table.TextCell>
                  <Table.TextCell>
                    {format(user.createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    <Switch disabled checked={user.isActive} />
                  </Table.TextCell>
                  <Table.Cell flexBasis={225} flexShrink={0} flexGrow={0}>
                    {(props.user.hasAction(ACTIONS.USER.UPDATE) ||
                      props.user.hasAction(ACTIONS.USER.ACTIVATE) ||
                      props.user.hasAction(ACTIONS.USER.DEACTIVATE)) && (
                      <Button
                        marginRight={12}
                        iconBefore="edit"
                        onClick={(e) => {
                          e.stopPropagation()
                          setId(user.id)
                          setEmail(user.email)
                          setName(user.name)
                          setRoles(user.roles)
                          setCountries(user.countries)
                          setActionIds(user.actionIds)
                          setIsActiveUser(user.isActive)
                          setIsCustomerSupportEmailEnable(
                            user.isCustomerSupportEmailEnabled
                          )
                          setIsProductManagerEmailEnabled(
                            user.isProductManagerEmailEnabled
                          )
                          setIsUpdateUserModalOpen(true)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    {props.user.data.id !== user.id &&
                      props.user.hasAction(ACTIONS.USER.DELETE) && (
                        <Button
                          marginRight={12}
                          iconBefore="trash"
                          intent="danger"
                          onClick={(e) => {
                            e.stopPropagation()
                            setId(user.id)
                            setEmail(user.email)
                            setIsConfirmDeleteModalOpen(true)
                          }}
                        >
                          Delete
                        </Button>
                      )}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </SideNavPage.Content>
      <Dialog
        isShown={isCreateUserModalOpen || isUpdateUserModalOpen}
        title={isCreateUserModalOpen ? 'Create user' : 'Update user'}
        hasCancel={false}
        confirmLabel={isCreateUserModalOpen ? 'Create' : 'Update'}
        isConfirmLoading={createMutation.fetching || updateMutation.fetching}
        onCloseComplete={() => {
          if (isCreateUserModalOpen) {
            setIsCreateUserModalOpen(false)
          }
          if (isUpdateUserModalOpen) {
            setIsUpdateUserModalOpen(false)
          }
        }}
        onConfirm={async (close) => {
          const input = {
            name,
            roles: roles.map(({ id: roleId }) => roleId),
            countries: countries.map(({ isoCode }) => isoCode),
          }
          if (isCreateUserModalOpen) {
            const { error } = await executeCreateMutation({
              input: {
                ...input,
                email,
              },
            })
            if (!error) {
              close()
            }
          }
          if (isUpdateUserModalOpen) {
            const { error } = await executeUpdateMutation({
              input: {
                id,
                ...input,
              },
            })
            if (!error) {
              close()
            }
          }
        }}
      >
        <TextInputField
          label="Email"
          required={isCreateUserModalOpen}
          disabled={isUpdateUserModalOpen}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />
        <TextInputField
          label="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value)
          }}
        />
        <Pane>
          <Label>Role</Label>
          <Pane marginTop="10px">
            {rolesQuery.data &&
              rolesQuery.data.userRoles.map((role) => (
                <Checkbox
                  key={role.id}
                  label={role.title}
                  checked={find(roles, { id: role.id })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setRoles((prevStateRoles) => [...prevStateRoles, role])
                    } else {
                      setRoles((prevStateRoles) =>
                        prevStateRoles.filter(
                          (prevStateRole) => prevStateRole.id !== role.id
                        )
                      )
                    }
                  }}
                />
              ))}
          </Pane>
        </Pane>
        {roles.find(
          (role) =>
            role.actions && find(role.actions, { id: ACTIONS.USER.COUNTRY })
        ) && (
          <Pane>
            <Label>Customer Support Specialist Countries</Label>
            <Pane marginTop="10px">
              {countriesQuery.data &&
                countriesQuery.data.userCountries.map((country) => (
                  <Checkbox
                    key={country.isoCode}
                    label={country.title}
                    checked={find(countries, {
                      isoCode: country.isoCode,
                    })}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCountries((prevStateCountries) => [
                          ...prevStateCountries,
                          country,
                        ])
                      } else {
                        setCountries((prevStateCountries) =>
                          prevStateCountries.filter(
                            ({ isoCode }) => isoCode !== country.isoCode
                          )
                        )
                      }
                    }}
                  />
                ))}
            </Pane>
          </Pane>
        )}
        {isUpdateUserModalOpen &&
          (props.user.hasAction(ACTIONS.USER.ACTIVATE) ||
            props.user.hasAction(ACTIONS.USER.DEACTIVATE)) && (
            <Pane display="flex" flexDirection="column" marginBottom="15px">
              <Label>
                {isActiveUser ? 'User is ACTIVATED' : 'User is DEACTIVATED'}
              </Label>
              <Pane display="flex" marginTop={5}>
                {isWithStatusButton && (
                  <UserStatusButton
                    userId={id}
                    isActive={isActiveUser}
                    onStatusChanged={(userId) => {
                      toaster.success('User status changed')
                      setLastUpdatedUserId(userId)
                      if (isCreateUserModalOpen) {
                        setIsCreateUserModalOpen(false)
                      }
                      if (isUpdateUserModalOpen) {
                        setIsUpdateUserModalOpen(false)
                      }
                    }}
                  />
                )}
              </Pane>
            </Pane>
          )}
        {isUpdateUserModalOpen &&
          isEnableNotificationButtonVisible({
            loggedUser: props.user,
            user: { id, actionIds },
            notificationType: USER_NOTIFICATION_TYPES.CUSTOMER_SUPPORT,
          }) && (
            <Pane display="flex" flexDirection="column">
              <Label>
                {isCustomerSupportEmailEnabled
                  ? 'User Customer Support email notification is ACTIVATED'
                  : 'User Customer Support email notification is DEACTIVATED'}
              </Label>
              <Pane display="flex" marginTop={5}>
                {isWithStatusButton && (
                  <CSEmailNotificationsButton
                    userId={id}
                    isCustomerSupportEmailEnabled={
                      isCustomerSupportEmailEnabled
                    }
                    onStatusChanged={(userId) => {
                      toaster.success('User status changed')
                      setLastUpdatedUserId(userId)
                      if (isCreateUserModalOpen) {
                        setIsCreateUserModalOpen(false)
                      }
                      if (isUpdateUserModalOpen) {
                        setIsUpdateUserModalOpen(false)
                      }
                    }}
                  />
                )}
              </Pane>
            </Pane>
          )}
        {isUpdateUserModalOpen &&
          isEnableNotificationButtonVisible({
            loggedUser: props.user,
            user: { id, actionIds },
            notificationType: USER_NOTIFICATION_TYPES.PRODUCT_MANAGER,
          }) && (
            <Pane display="flex" flexDirection="column">
              <Label>
                {isProductManagerEmailEnabled
                  ? 'User Product Manager email notification is ACTIVATED'
                  : 'User Product Manager email notification is DEACTIVATED'}
              </Label>
              <Pane display="flex" marginTop={5}>
                {isWithStatusButton && (
                  <PMEmailNotificationsButton
                    userId={id}
                    isProductManagerEmailEnabled={isProductManagerEmailEnabled}
                    onStatusChanged={(userId) => {
                      toaster.success('User status changed')
                      setLastUpdatedUserId(userId)
                      if (isCreateUserModalOpen) {
                        setIsCreateUserModalOpen(false)
                      }
                      if (isUpdateUserModalOpen) {
                        setIsUpdateUserModalOpen(false)
                      }
                    }}
                  />
                )}
              </Pane>
            </Pane>
          )}
      </Dialog>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete user"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id,
          })
          if (!error) {
            setId('')
            setEmail('')
            close()
          }
        }}
      >
        Are you sure you want to delete user: {email} (#{id}) ??
      </Dialog>
    </React.Fragment>
  )
}

Users.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Users)
