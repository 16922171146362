import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { useMutation, useQuery } from 'urql'
import styled from 'styled-components/macro'
import { BackButton, Button, majorScale, toaster } from 'evergreen-ui'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import ROUTES from 'constants/routes'
import { DEAL_STATUSES } from 'constants/constants'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import DeleteDialog from './DeleteDialog'
import Form from '../NewBestSeller/Form'
import withApproveDeclineMutations, {
  toastResultOfApproval,
} from './withAprroveDeclineMutations'
import { pauseBestSellerMutation, resumeBestSellerMutation } from './queries'
import { getSingleBestSellerQuery } from './graphql'

const { DRAFT, APPROVAL_NEEDED, APPROVED, DECLINED, DELETED, LIVE, FINISHED } =
  DEAL_STATUSES

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const BUTTON_LEFT_MARGIN = `${majorScale(1)}px`
const StyledButton = styled(Button)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const StyledBackButton = styled(BackButton)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const getIsApprovalButtonDisplayed = (bestSeller, user) =>
  bestSeller.status === APPROVAL_NEEDED &&
  user.hasAction(ACTIONS.THEME_DEAL.APPROVE)

const getIsDeleteButtonDisplayed = (bestSeller, user) =>
  ([DRAFT, APPROVAL_NEEDED, APPROVED, DECLINED].includes(bestSeller.status) &&
    user.hasAction(ACTIONS.THEME_DEAL.DELETE)) ||
  (bestSeller.status === DELETED && user.hasAction(ACTIONS.THEME_DEAL.DESTROY))

const EditBestSeller = ({
  match,
  history,
  user,
  executeApproveBestSellerMutation,
}) => {
  const id = parseInteger(match.params.id)
  const [bestSellerQuery, reExecuteBestSellerQuery] = useQuery({
    requestPolicy: 'network-only',
    query: getSingleBestSellerQuery,
    variables: {
      id,
    },
  })

  const [, executePauseMutation] = useMutation(pauseBestSellerMutation)
  const [, executeResumeMutation] = useMutation(resumeBestSellerMutation)
  const bestSeller = bestSellerQuery.data
    ? bestSellerQuery.data.singleBestSeller
    : null
  const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false)
  const isBestSellerEditable =
    bestSeller &&
    (![LIVE, FINISHED].includes(bestSeller.status) || bestSeller.isPaused)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Edit BestSeller</SideNavPage.Title>
        {bestSeller && (
          <div>
            {LIVE === bestSeller.status &&
              bestSeller.isPaused &&
              user.hasAction(ACTIONS.THEME_DEAL.RESUME) && (
                <StyledButton
                  intent="success"
                  appearance="primary"
                  iconBefore="play"
                  onClick={async () => {
                    const { error } = await executeResumeMutation({
                      id: bestSeller.id,
                    })
                    displayToast(error, 'Resumed')
                  }}
                >
                  RESUME
                </StyledButton>
              )}
            {LIVE === bestSeller.status &&
              !bestSeller.isPaused &&
              user.hasAction(ACTIONS.THEME_DEAL.PAUSE) && (
                <StyledButton
                  intent="warning"
                  appearance="primary"
                  iconBefore="pause"
                  onClick={async () => {
                    const { error } = await executePauseMutation({
                      id: bestSeller.id,
                    })
                    reExecuteBestSellerQuery()
                    displayToast(error, 'Paused')
                  }}
                >
                  PAUSE
                </StyledButton>
              )}
            {getIsDeleteButtonDisplayed(bestSeller, user) && (
              <StyledButton
                intent="danger"
                appearance="primary"
                onClick={() => {
                  setDeleteDialogOpened(true)
                }}
              >
                {bestSeller.status === DELETED ? 'Destroy' : 'Delete'}
              </StyledButton>
            )}
            {getIsApprovalButtonDisplayed(bestSeller, user) && (
              <StyledButton
                intent="success"
                appearance="primary"
                onClick={async () => {
                  const result = await executeApproveBestSellerMutation({
                    id,
                  })
                  reExecuteBestSellerQuery()
                  toastResultOfApproval(result)
                }}
              >
                Approve
              </StyledButton>
            )}
            <StyledBackButton onClick={() => history.goBack()} pl="0.5rem" />
          </div>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <FullScreenLoading isVisible={bestSellerQuery.fetching} />
        {bestSeller && (
          <React.Fragment>
            {isDeleteDialogOpened && (
              <DeleteDialog
                itemToDelete={{ id, product: bestSeller.product }}
                isDestroyDialog={bestSeller.status === DELETED}
                onClose={() => {
                  setDeleteDialogOpened(false)
                }}
                onSuccess={() => {
                  if (
                    bestSeller.status === DELETED ||
                    !user.hasAction(ACTIONS.THEME_DEAL.DESTROY)
                  ) {
                    history.replace(`/${ROUTES.BEST_SELLERS}`)
                  }
                }}
              />
            )}
            <Form
              key={bestSeller.id}
              data={bestSeller}
              isReadOnly={
                !isBestSellerEditable ||
                !user.hasAction(ACTIONS.THEME_DEAL.UPDATE)
              }
              isCompetitorReadOnly={
                !isBestSellerEditable ||
                !user.hasAction(ACTIONS.THEME_DEAL.ADD_COMPETITOR)
              }
              isTranslationEditReadOnly={
                !isBestSellerEditable ||
                !user.hasAction(ACTIONS.THEME_DEAL.UPDATE_TRANSLATIONS)
              }
              isTranslationAddReadOnly={
                !isBestSellerEditable ||
                !user.hasAction(ACTIONS.THEME_DEAL.ADD_TRANSLATIONS)
              }
            />
          </React.Fragment>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

EditBestSeller.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withApproveDeclineMutations, withUser)(EditBestSeller)
