import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import {
  Table,
  Button,
  Dialog,
  TextInputField,
  Label,
  Pane,
  SelectField,
  majorScale,
} from 'evergreen-ui'
import find from 'lodash.find'
import urql from 'utils/urql'
import { parseInteger } from 'utils/numbers'

const Competitors = ({
  competitors,
  handleSaveCompetitors,
  defaultCompetitorId,
  handleChangeDefaultCompetitor,
  isDisabled,
}) => {
  const [isAddCompetitorDialogOpen, setIsAddCompetiorDialogOpen] =
    useState(false)
  const [isEditCompetitorDialogOpen, setIsEditCompetiorDialogOpen] =
    useState(false)
  const [addNewCompetitor, setAddNewCompetitor] = useState(false)
  const [competitorId, setCompetitorId] = useState('')
  const [countryIsoCode, setCountryIsoCode] = useState('')
  const [countryTitle, setCountryTitle] = useState('')
  const [competitorTitle, setCompetitorTitle] = useState('')
  const [competitorPrice, setCompetitorPrice] = useState('0')
  const [competitorsQuery] = useQuery({
    query: `
      query {
        competitors {
          id
          title
          country {
            title
          }
        }
      }
    `,
  })
  const [countriesQuery] = useQuery({
    query: `
      query {
        userCountries {
          isoCode
          title
        }
      }
    `,
  })
  const [, executeCreateCompetitorMutation] = useMutation(`
    mutation($input: CreateCompetitorInput!) {
      createCompetitor(input: $input) {
        id
      }
    }
  `)

  const allCompetitors =
    (competitorsQuery.data && competitorsQuery.data.competitors) || []
  const idsOfSelectedCompetitors = competitors.map(
    (competitor) => competitor.id
  )
  const listOfFilteredCompetitors = allCompetitors.filter(
    (competitor) => !idsOfSelectedCompetitors.includes(competitor.id)
  )
  return (
    <Pane marginTop={majorScale(2)}>
      <Label marginBottom={majorScale(1)} display="block">
        Competitors
      </Label>
      {!isDisabled && (
        <Button
          marginBottom={majorScale(1)}
          onClick={() => {
            setCompetitorId('')
            setCompetitorTitle('')
            setCompetitorPrice('0')
            setCountryTitle('')
            setCountryIsoCode('')
            setAddNewCompetitor(false)
            setIsAddCompetiorDialogOpen(true)
          }}
        >
          Add Competitor
        </Button>
      )}
      <Table marginTop={majorScale(1)}>
        <Table.Head>
          <Table.TextHeaderCell>Title</Table.TextHeaderCell>
          <Table.TextHeaderCell>Country</Table.TextHeaderCell>
          <Table.TextHeaderCell>Price</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {competitors &&
            competitors.map((competitor) => (
              <Table.Row key={competitor.id}>
                <Table.TextCell>{competitor.title}</Table.TextCell>
                <Table.TextCell>
                  {competitor.country && competitor.country.title}
                </Table.TextCell>
                <Table.TextCell>{competitor.price}</Table.TextCell>
                <Table.Cell>
                  <Button
                    disabled={isDisabled}
                    style={{ flexShrink: 0 }}
                    marginRight={majorScale(2)}
                    iconBefore="edit"
                    onClick={(e) => {
                      e.stopPropagation()
                      setAddNewCompetitor(false)
                      setCompetitorId(competitor.id)
                      setCompetitorPrice(competitor.price)
                      setCompetitorTitle(competitor.title)
                      setIsEditCompetiorDialogOpen(true)
                      setCountryTitle(competitor.country.title)
                      setCountryIsoCode(competitor.country.isoCode)
                    }}
                  >
                    Edit
                  </Button>
                  {competitor.id !== defaultCompetitorId && (
                    <Button
                      disabled={isDisabled}
                      style={{ flexShrink: 0 }}
                      marginRight={12}
                      iconBefore="trash"
                      intent="danger"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleSaveCompetitors(
                          competitors.filter(
                            (addedCompetitor) =>
                              addedCompetitor.id !== competitor.id
                          )
                        )
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Dialog
        isShown={isAddCompetitorDialogOpen || isEditCompetitorDialogOpen}
        title={isAddCompetitorDialogOpen ? 'Add competitor' : `Edit Competitor`}
        hasCancel={false}
        confirmLabel={isAddCompetitorDialogOpen ? 'Add' : 'Edit'}
        onCloseComplete={() => {
          setIsAddCompetiorDialogOpen(false)
          setIsEditCompetiorDialogOpen(false)
        }}
        onConfirm={async (close) => {
          if (isAddCompetitorDialogOpen) {
            if (!competitorId) {
              const { error, data: successResult } =
                await executeCreateCompetitorMutation({
                  input: {
                    title: competitorTitle,
                    countryIsoCode,
                  },
                })
              if (error) {
                urql.handleError(error)
                return
              }
              handleSaveCompetitors([
                ...competitors,
                {
                  id: successResult.createCompetitor.id,
                  title: competitorTitle,
                  price: competitorPrice,
                  country: {
                    isoCode: countryIsoCode,
                    title: countryTitle,
                  },
                },
              ])

              close()
            } else {
              handleSaveCompetitors([
                ...competitors,
                {
                  id: competitorId,
                  title: competitorTitle,
                  price: competitorPrice,
                  country: {
                    isoCode: countryIsoCode,
                    title: countryTitle,
                  },
                },
              ])
              close()
            }
          }
          if (isEditCompetitorDialogOpen) {
            handleSaveCompetitors(
              competitors.map((addedCompetitor) => {
                if (addedCompetitor.id === competitorId) {
                  return {
                    id: competitorId,
                    title: competitorTitle,
                    price: competitorPrice,
                    country: {
                      isoCode: countryIsoCode,
                      title: countryTitle,
                    },
                  }
                }
                return addedCompetitor
              })
            )
            close()
          }
        }}
      >
        {isAddCompetitorDialogOpen && addNewCompetitor && (
          <Button
            onClick={() => {
              setCompetitorId('')
              setCompetitorTitle('')
              setCountryIsoCode('')
              setCountryTitle('')
              setAddNewCompetitor(false)
            }}
          >
            Use existing competitor
          </Button>
        )}
        {isAddCompetitorDialogOpen && !addNewCompetitor && (
          <Button
            onClick={() => {
              setCompetitorId('')
              setCompetitorTitle('')
              setCountryIsoCode('')
              setCountryTitle('')
              setAddNewCompetitor(true)
            }}
          >
            New competitor
          </Button>
        )}
        {!addNewCompetitor && (
          <SelectField
            label="Competitor"
            value={competitorId}
            disabled={isEditCompetitorDialogOpen}
            onChange={(e) => {
              const competitor = find(competitorsQuery.data.competitors, {
                id: parseInteger(e.target.value),
              })
              setCompetitorId(competitor ? competitor.id : null)
              setCountryIsoCode(
                competitor && competitor.country
                  ? competitor.country.isoCode
                  : null
              )
              setCountryTitle(
                competitor && competitor.country ? competitor.country.title : ''
              )
              setCompetitorTitle(competitor ? competitor.title : '')
            }}
          >
            <option value="">---Select competitor---</option>
            {(isEditCompetitorDialogOpen
              ? allCompetitors
              : listOfFilteredCompetitors
            ).map((competitor) => (
              <option key={competitor.id} value={competitor.id}>
                {`${competitor.title} - ${competitor.country.title}`}
              </option>
            ))}
          </SelectField>
        )}
        {addNewCompetitor && (
          <Pane>
            <TextInputField
              label="Competitor title"
              value={competitorTitle}
              onChange={(e) => {
                setCompetitorTitle(e.target.value)
              }}
            />
            <SelectField
              label="Country"
              value={countryIsoCode}
              onChange={(e) => {
                setCountryIsoCode(e.target.value)
                const competitor = find(countriesQuery.data.userCountries, {
                  isoCode: e.target.value,
                })
                if (competitor) {
                  setCountryTitle(competitor.title)
                }
              }}
            >
              <option value="">---Select country---</option>
              {countriesQuery.data &&
                countriesQuery.data.userCountries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.title}
                  </option>
                ))}
            </SelectField>
          </Pane>
        )}
        <TextInputField
          label="Price"
          type="number"
          required="true"
          value={competitorPrice}
          onChange={(e) => {
            setCompetitorPrice(Number.parseFloat(e.target.value))
          }}
        />
      </Dialog>
      <Pane marginY="20px">
        <SelectField
          label="Default competitor"
          value={defaultCompetitorId || ''}
          disabled={isEditCompetitorDialogOpen}
          onChange={(event) => {
            if (event.target.value) {
              handleChangeDefaultCompetitor(parseInteger(event.target.value))
            } else {
              handleChangeDefaultCompetitor(undefined)
            }
          }}
        >
          <option value="">---Select default competitor---</option>
          {competitors.map((competitor) => (
            <option key={competitor.id} value={competitor.id}>
              {`${competitor.title} - ${competitor.country.title}`}
            </option>
          ))}
        </SelectField>
      </Pane>
    </Pane>
  )
}

Competitors.propTypes = {
  handleSaveCompetitors: PropTypes.func.isRequired,
  handleChangeDefaultCompetitor: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  defaultCompetitorId: PropTypes.number,
  competitors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.integer,
      price: PropTypes.number,
      title: PropTypes.string,
    })
  ),
}

export default Competitors
