import PropTypes from 'prop-types'
import React from 'react'
import LinkParser from 'components/LinkParser'
import { Pane, Text, Icon, Spinner } from 'evergreen-ui'
import { formatToHumanReadable } from 'utils/datetime'
import styled from 'styled-components/macro'

const StyledPane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #e4e7eb;
  padding: 15px;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
`

const OthersMessage = (props) => {
  const {
    text,
    createdAtUtc,
    id,
    attachmentUrl,
    attachmentName,
    onClickAttachment,
    isDownloadingAttachment,
  } = props
  return (
    <Pane
      id={id}
      maxWidth="80%"
      display="flex"
      alignSelf="flex-start"
      flexDirection="column"
    >
      {text && (
        <Pane
          id={id}
          border
          backgroundColor="white"
          color="black"
          padding={15}
          borderRadius={10}
          marginBottom={10}
        >
          <Text
            color="#000"
            size={300}
            marginBottom={10}
            style={{ whiteSpace: 'pre-wrap' }}
          >
            <LinkParser>{text}</LinkParser>
          </Text>
          <Pane display="flex" justifyContent="flex-start">
            <Text color="#000" size={300}>
              {formatToHumanReadable({ date: createdAtUtc, isTimeShown: true })}
            </Text>
          </Pane>
        </Pane>
      )}

      {attachmentUrl && (
        <Pane
          backgroundColor="white"
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginBottom={10}
        >
          <StyledPane
            border
            onClick={() => {
              onClickAttachment({
                url: attachmentUrl,
                filename: attachmentName,
                messageId: id,
              })
            }}
          >
            <Pane display="flex" flexDirection="row" alignItems="center">
              <Icon icon="download" size={18} marginRight={10} color="#000" />
              <Text color="#000" textDecoration="underline">
                {attachmentName}
              </Text>
            </Pane>
            <Pane display="flex" justifyContent="flex-start">
              <Text color="#000" size={300}>
                {formatToHumanReadable({
                  date: createdAtUtc,
                  isTimeShown: true,
                })}
              </Text>
            </Pane>
          </StyledPane>
          {isDownloadingAttachment && (
            <Pane marginLeft="10px">
              <Spinner delay={200} size={20} />
            </Pane>
          )}
        </Pane>
      )}
    </Pane>
  )
}

OthersMessage.propTypes = {
  text: PropTypes.string,
  createdAtUtc: PropTypes.string,
  id: PropTypes.number.isRequired,
  attachmentUrl: PropTypes.string,
  attachmentName: PropTypes.string,
  onClickAttachment: PropTypes.func,
  isDownloadingAttachment: PropTypes.bool,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default OthersMessage
