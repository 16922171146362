import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import { useQuery, useMutation } from 'urql'
import { getSpaceInPx } from 'utils/styled-system'
import urql from 'utils/urql'
import { DEAL_STATUSES } from 'constants/constants'
import ACTIONS from 'constants/actions'
import ActionsRow from 'components/ActionsRow'
import DeleteDoDDialog from '../../../components/DeleteDoDDialog'
import DoDItem from '../components/DoDItem'
import { DOD_ITEM_Y_MARGIN } from '../constants'
import { getPathToDealOfTheDayDetail } from '../../../utils'
import { getDealOfTheDaysQuery, restoreDealOfTheDayMutation } from '../queries'
import DoDList from '../components/DoDList'

const { DELETED } = DEAL_STATUSES

const SoftDeleted = ({ history, user }) => {
  const [itemToDelete, setItemToDelete] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [dealOfTheDaysQuery] = useQuery(
    getDealOfTheDaysQuery({
      status: [DELETED],
      pagination: { page: currentPage, limit: 20 },
    })
  )
  const [restoreMutation, executeRestoreMutation] = useMutation(
    restoreDealOfTheDayMutation
  )
  useEffect(() => {
    const error = dealOfTheDaysQuery.error || restoreMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [dealOfTheDaysQuery, dealOfTheDaysQuery.error, restoreMutation.error])
  return (
    <Pane>
      <DeleteDoDDialog
        itemToDelete={itemToDelete}
        onClose={() => {
          setItemToDelete(null)
        }}
        isDestroyDialog
      />
      <ActionsRow />
      <DoDList
        dealOfTheDaysQuery={dealOfTheDaysQuery}
        setCurrentPage={setCurrentPage}
      >
        {({ dealOfTheDays }) =>
          dealOfTheDays.map((item) => (
            <DoDItem
              key={item.id}
              {...item}
              my={getSpaceInPx(DOD_ITEM_Y_MARGIN)}
              onDetailClick={() => {
                history.push(getPathToDealOfTheDayDetail(item.id))
              }}
              onDeleteButtonClicked={() => {
                setItemToDelete(item)
              }}
              deleteIcon="delete"
              deleteIconDescription="Destroy deal of the day"
              onRevertButtonClicked={() => {
                executeRestoreMutation({
                  id: item.id,
                })
              }}
              isWithDeleteButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_DAY.DESTROY
              )}
              isWithRestoreButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_DAY.RESTORE
              )}
            />
          ))
        }
      </DoDList>
    </Pane>
  )
}

SoftDeleted.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withRouter, withUser)(SoftDeleted)
