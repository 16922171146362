import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import HeroBannersList from './routes/HeroBannersList'
import EditHeroBanner from './routes/EditHeroBanner'
import EditSingleSlide from './routes/EditSingleSlide'
import AddNewSingleSlide from './routes/AddNewSingleSlide'
import NewHeroBanner from './routes/NewHeroBanner'

const HeroBanners = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/:id/edit`} component={EditHeroBanner} />
    <Route
      path={`${match.url}/:id/slides/:slideId/edit`}
      component={EditSingleSlide}
    />
    <Route path={`${match.url}/:id/slides/new`} component={AddNewSingleSlide} />
    <Route path={`${match.url}/new`} component={NewHeroBanner} />
    <Route path={match.url} component={HeroBannersList} />
  </Switch>
)

HeroBanners.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default HeroBanners
