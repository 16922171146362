import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import { Dialog, toaster } from 'evergreen-ui'
import urql from 'utils/urql'

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const DeleteSellerDialog = (props) => {
  const {
    id,
    companyName,
    isConfirmDeleteModalOpen,
    setId,
    setIsConfirmDeleteModalOpen,
    setCompanyName,
    executeSellersQuery,
  } = props

  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: ID!) {
      deleteSeller(id: $id) {
        id
      }
    }
  `)

  return (
    <Dialog
      isShown={isConfirmDeleteModalOpen}
      title="Confirm delete seller"
      confirmLabel="Delete"
      isConfirmLoading={deleteMutation.fetching}
      onCloseComplete={() => {
        setIsConfirmDeleteModalOpen(false)
      }}
      onConfirm={async (close) => {
        const { error } = await executeDeleteMutation({
          id,
        })
        if (!error) {
          setId(null)
          setCompanyName('')
          close()
          executeSellersQuery({ requestPolicy: 'network-only' })
        } else {
          displayToastOnSave(error)
        }
      }}
    >
      Are you sure you want to delete seller: {companyName} (#{id}) ??
    </Dialog>
  )
}

DeleteSellerDialog.propTypes = {
  id: PropTypes.string,
  companyName: PropTypes.string,
  isConfirmDeleteModalOpen: PropTypes.bool,
  setId: PropTypes.func,
  setIsConfirmDeleteModalOpen: PropTypes.func,
  setCompanyName: PropTypes.func,
  executeSellersQuery: PropTypes.func,
}

export default DeleteSellerDialog
