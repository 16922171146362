import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import { Popover, Menu, Position } from 'evergreen-ui'
import { withUser } from 'UserProvider'
import { FEEDBACK_TYPES } from 'constants/constants'
import ACTIONS from 'constants/actions'
import urql from 'utils/urql'

const MoveFeedbackMenu = ({ children, user, id, type }) => {
  const [, executeMoveFeedbackToProductFeedbackMutation] = useMutation(`
    mutation moveFeedbackToProductFeedback($id: Int!) {
      moveFeedbackToProductFeedback(id: $id) {
        id
      }
    }
  `)
  const [, executeMoveFeedbackToCustomerSupportMutation] = useMutation(`
    mutation moveFeedbackToCustomerSupport($id: Int!) {
      moveFeedbackToCustomerSupport(id: $id) {
        id
      }
    }
  `)
  const renderMenu = () => (
    <Menu>
      <Menu.Group title="Change type to">
        {type !== FEEDBACK_TYPES.PRODUCT_FEEDBACK &&
          user.hasAction(ACTIONS.FEEDBACK.MOVE_TO_PRODUCT_FEEDBACK) && (
            <Menu.Item
              onClick={async (e) => {
                e.stopPropagation()
                const { error } =
                  await executeMoveFeedbackToProductFeedbackMutation({
                    id,
                  })
                if (error) {
                  urql.handleError(error)
                }
              }}
            >
              Product Feedback
            </Menu.Item>
          )}
        {type !== FEEDBACK_TYPES.CUSTOMER_SUPPORT &&
          user.hasAction(ACTIONS.FEEDBACK.MOVE_TO_CUSTOMER_SUPPORT) && (
            <Menu.Item
              onClick={async (e) => {
                e.stopPropagation()
                const { error } =
                  await executeMoveFeedbackToCustomerSupportMutation({
                    id,
                  })
                if (error) {
                  urql.handleError(error)
                }
              }}
            >
              Customer Support
            </Menu.Item>
          )}
      </Menu.Group>
    </Menu>
  )
  return (
    <Popover content={renderMenu()} position={Position.BOTTOM_RIGHT}>
      {children}
    </Popover>
  )
}

MoveFeedbackMenu.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(FEEDBACK_TYPES)).isRequired,
}

export default withUser(MoveFeedbackMenu)
