import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'evergreen-ui'
import { pickSpaceProps } from 'utils/styled-system'
import { DEAL_STATUSES } from 'constants/constants'

const { APPROVAL_NEEDED, APPROVED, DECLINED, FINISHED, LIVE, DELETED, DRAFT } =
  DEAL_STATUSES

const getBadgeColor = (status) => {
  switch (status) {
    case APPROVAL_NEEDED:
      return 'yellow'
    case APPROVED:
      return 'green'
    case DECLINED:
      return 'red'
    case FINISHED:
      return 'teal'
    case LIVE:
      return 'blue'
    case DELETED:
      return 'purple'
    case DRAFT:
    default:
      return 'neutral'
  }
}

const DealBadge = ({ status, ...rest }) => (
  <Badge color={getBadgeColor(status)} isSolid {...pickSpaceProps(rest)}>
    {status.replace(/_/g, ' ')}
  </Badge>
)

DealBadge.propTypes = {
  status: PropTypes.oneOf(Object.values(DEAL_STATUSES)).isRequired,
}

export default DealBadge
