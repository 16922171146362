import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { compose } from 'recompose'
import { withTheme, Pane, Button, Text, Pill, majorScale } from 'evergreen-ui'
import logo from 'assets/images/logo.png'
import production from 'assets/images/production.png'
import ROUTES from 'constants/routes'
import ACTIONS from 'constants/actions'
import { ENVIRONMENTS } from 'constants/constants'
import { withUser } from 'UserProvider'
import { ThreadsConsumer } from 'ThreadsProvider'
import NavLink from './NavLink'

const ROUTES_ICONS = {
  [ROUTES.DEAL_OF_THE_DAYS]: 'repeat',
  [ROUTES.DEAL_OF_THE_WEEKS]: 'moon',
  [ROUTES.THEME_OF_THE_MONTH]: 'calendar',
  [ROUTES.WISHES]: 'lightbulb',
  [ROUTES.ORDERS]: 'box',
  [ROUTES.PERSONALISATION]: 'people',
  [ROUTES.CUSTOMERS]: 'people',
  [ROUTES.USERS]: 'user',
  [ROUTES.MESSAGES]: 'chat',
  [ROUTES.SELLERS]: 'shop',
  [ROUTES.PRODUCT_IMAGES]: 'media',
  [ROUTES.CATEGORIES]: 'folder-close',
  [ROUTES.LANGUAGES]: 'translate',
  [ROUTES.COUNTRIES]: 'globe',
  [ROUTES.CURRENCIES]: 'euro',
  [ROUTES.CURRENCIES_RATES]: 'timeline-line-chart',
  [ROUTES.PRODUCT_CONDITIONS]: 'properties',
  [ROUTES.DOCUMENTS]: 'document',
  [ROUTES.LOGS]: 'history',
  [ROUTES.WISH_CATEGORIES]: 'duplicate',
  [ROUTES.FEEDBACKS]: 'comment',
  [ROUTES.ADDITIONAL_SERVICES]: 'add-to-artifact',
  [ROUTES.ATTRIBUTES]: 'graph',
  [ROUTES.PRODUCTS]: 'th',
  [ROUTES.FEEDS]: 'import',
  [ROUTES.PHONE_SERVICE]: 'mobile-phone',
  [ROUTES.SETTINGS]: 'settings',
  [ROUTES.HERO_BANNERS]: 'presentation',
}

const SignoutButton = styled(Button)`
  width: 100%;
  margin-top: 1em;
  justify-content: center;
`
const Layout = styled(Pane)`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 200px 1fr;
`

const Sidebar = styled(Pane)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 1em;
  height: 100%;
  overflow: hidden;
`

const Image = styled.img`
  max-width: 100%;
  height: auto;
`
const ProductionImage = styled.img`
  width: 32px;
  height: auto;
`

const NavLinks = styled.div`
  display: grid;
  grid-gap: 0.1em;
  overflow-y: auto;
  grid-template-rows: ${({ children }) =>
    children.filter(Boolean).reduce((acc) => `auto ${acc}`, '1fr')};
  height: 100%;
`

const SideNavPage = ({ children, theme, user }) => (
  <Layout height="100vh" overflow="hidden">
    <Sidebar
      background={theme.palette.neutral.lightest}
      height="100%"
      borderRight
    >
      <Pane textAlign="right" padding={majorScale(2)}>
        {process.env.REACT_APP_ENVIRONMENT === ENVIRONMENTS.PRODUCTION && (
          <ProductionImage src={production} alt="Production" />
        )}
        <Image src={logo} alt="Logo" />
      </Pane>
      <NavLinks>
        {user.hasAction(ACTIONS.DEAL_OF_THE_DAY.PAGE) && (
          <NavLink
            to={`/${ROUTES.DEAL_OF_THE_DAYS}`}
            label="Deal of the Days"
            iconName={ROUTES_ICONS[ROUTES.DEAL_OF_THE_DAYS]}
          />
        )}
        {user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.PAGE) && (
          <NavLink
            to={`/${ROUTES.DEAL_OF_THE_WEEKS}`}
            label="Deal of the Weeks"
            iconName={ROUTES_ICONS[ROUTES.DEAL_OF_THE_WEEKS]}
          />
        )}
        {user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.PAGE) && (
          <NavLink
            to={`/${ROUTES.BEST_SELLERS}`}
            label="Best Sellers"
            iconName={ROUTES_ICONS[ROUTES.THEME_OF_THE_MONTH]}
          />
        )}
        {user.hasAction(ACTIONS.THEME_OF_THE_MONTH.PAGE) && (
          <NavLink
            to={`/${ROUTES.THEME_OF_THE_MONTH}`}
            label="Themes of the Month"
            iconName={ROUTES_ICONS[ROUTES.THEME_OF_THE_MONTH]}
          />
        )}
        {user.hasAction(ACTIONS.WISH.PAGE) && (
          <ThreadsConsumer>
            {({ unseenWishesCount, unseenWishOffersCount }) => (
              <NavLink
                to={`/${ROUTES.WISHES}`}
                iconName={ROUTES_ICONS[ROUTES.WISHES]}
              >
                <Text size={500}>
                  Wishes
                  {unseenWishesCount !== 0 && (
                    <Pill display="inline-flex" margin={8} color="red" isSolid>
                      {unseenWishesCount}
                    </Pill>
                  )}
                  {unseenWishOffersCount !== 0 && (
                    <Pill
                      display="inline-flex"
                      margin={8}
                      color="purple"
                      isSolid
                    >
                      {unseenWishOffersCount}
                    </Pill>
                  )}
                </Text>
              </NavLink>
            )}
          </ThreadsConsumer>
        )}
        {user.hasAction(ACTIONS.ORDER.PAGE) && (
          <NavLink
            to={`/${ROUTES.ORDERS}`}
            label="Orders"
            iconName={ROUTES_ICONS[ROUTES.ORDERS]}
          />
        )}
        {user.hasAction(ACTIONS.ORDER.PAGE) && (
          <NavLink
            to={`/${ROUTES.OLD_ORDERS}`}
            label="Old Orders"
            iconName={ROUTES_ICONS[ROUTES.ORDERS]}
          />
        )}
        {user.hasAction(ACTIONS.CUSTOMER.PAGE) && (
          <NavLink
            to={`/${ROUTES.CUSTOMERS}`}
            label="Customers"
            iconName={ROUTES_ICONS[ROUTES.CUSTOMERS]}
          />
        )}
        {user.hasAction(ACTIONS.PERSONALISATION.PAGE) && (
          <NavLink
            to={`/${ROUTES.PERSONALISATION}`}
            label="Personalisation"
            iconName={ROUTES_ICONS[ROUTES.PERSONALISATION]}
          />
        )}
        {user.hasAction(ACTIONS.USER.PAGE) && (
          <NavLink
            to={`/${ROUTES.USERS}`}
            label="Users"
            iconName={ROUTES_ICONS[ROUTES.USERS]}
          />
        )}
        {user.hasAction(ACTIONS.THREAD.PAGE) && (
          <ThreadsConsumer>
            {({ unseenCount }) => (
              <NavLink
                to={`/${ROUTES.MESSAGES}`}
                iconName={ROUTES_ICONS[ROUTES.MESSAGES]}
              >
                <Text size={500}>
                  Messages
                  {unseenCount !== 0 && (
                    <Pill display="inline-flex" margin={8} color="red" isSolid>
                      {unseenCount}
                    </Pill>
                  )}
                </Text>
              </NavLink>
            )}
          </ThreadsConsumer>
        )}
        {user.hasAction(ACTIONS.SELLER.PAGE) && (
          <NavLink
            to={`/${ROUTES.SELLERS}`}
            label="Sellers"
            iconName={ROUTES_ICONS[ROUTES.SELLERS]}
          />
        )}
        {user.hasAction(ACTIONS.PRODUCT_IMAGE.PAGE) && (
          <NavLink
            to={`/${ROUTES.PRODUCT_IMAGES}`}
            label="Product Images"
            iconName={ROUTES_ICONS[ROUTES.PRODUCT_IMAGES]}
          />
        )}
        {user.hasAction(ACTIONS.LANGUAGE.PAGE) && (
          <NavLink
            to={`/${ROUTES.LANGUAGES}`}
            label="Languages"
            iconName={ROUTES_ICONS[ROUTES.LANGUAGES]}
          />
        )}
        {user.hasAction(ACTIONS.COUNTRY.PAGE) && (
          <NavLink
            to={`/${ROUTES.COUNTRIES}`}
            label="Countries"
            iconName={ROUTES_ICONS[ROUTES.COUNTRIES]}
          />
        )}
        {user.hasAction(ACTIONS.CURRENCY.PAGE) && (
          <NavLink
            to={`/${ROUTES.CURRENCIES}`}
            label="Currencies"
            iconName={ROUTES_ICONS[ROUTES.CURRENCIES]}
          />
        )}
        {user.hasAction(ACTIONS.CURRENCY_RATE.PAGE) && (
          <NavLink
            to={`/${ROUTES.CURRENCIES_RATES}`}
            label="Currencies Rates"
            iconName={ROUTES_ICONS[ROUTES.CURRENCIES_RATES]}
          />
        )}
        {user.hasAction(ACTIONS.PRODUCT_CONDITION.PAGE) && (
          <NavLink
            to={`/${ROUTES.PRODUCT_CONDITIONS}`}
            label="Product Conditions"
            iconName={ROUTES_ICONS[ROUTES.PRODUCT_CONDITIONS]}
          />
        )}
        {user.hasAction(ACTIONS.DOCUMENT.PAGE) && (
          <NavLink
            to={`/${ROUTES.DOCUMENTS}`}
            label="Documents"
            iconName={ROUTES_ICONS[ROUTES.DOCUMENTS]}
          />
        )}
        {user.hasAction(ACTIONS.LOGS.EXPORT) && (
          <NavLink
            to={`/${ROUTES.LOGS}`}
            label="Export Logs"
            iconName={ROUTES_ICONS[ROUTES.LOGS]}
          />
        )}
        {user.hasAction(ACTIONS.WISH_CATEGORY.PAGE) && (
          <NavLink
            to={`/${ROUTES.WISH_CATEGORIES}`}
            label="Wishlist Categories"
            iconName={ROUTES_ICONS[ROUTES.WISH_CATEGORIES]}
          />
        )}
        {user.hasAction(ACTIONS.FEEDBACK.PAGE) && (
          <NavLink
            to={`/${ROUTES.FEEDBACKS}`}
            label="Feedbacks"
            iconName={ROUTES_ICONS[ROUTES.FEEDBACKS]}
          />
        )}
        {user.hasAction(ACTIONS.ADDITIONAL_SERVICE.PAGE) && (
          <NavLink
            to={`/${ROUTES.ADDITIONAL_SERVICES}`}
            label="Additional Services"
            iconName={ROUTES_ICONS[ROUTES.ADDITIONAL_SERVICES]}
          />
        )}
        {user.hasAction(ACTIONS.ATTRIBUTE.PAGE) && (
          <NavLink
            to={`/${ROUTES.ATTRIBUTES}`}
            label="Attributes"
            iconName={ROUTES_ICONS[ROUTES.ATTRIBUTES]}
          />
        )}
        {user.hasAction(ACTIONS.FEED.PAGE) && (
          <NavLink
            to={`/${ROUTES.FEEDS}`}
            label="Feeds"
            iconName={ROUTES_ICONS[ROUTES.FEEDS]}
          />
        )}
        {user.hasAction(ACTIONS.CATEGORY.PAGE) && (
          <NavLink
            to={`/${ROUTES.CATEGORIES}`}
            label="Categories"
            iconName={ROUTES_ICONS[ROUTES.CATEGORIES]}
          />
        )}
        {user.hasAction(ACTIONS.PRODUCT.PAGE) && (
          <NavLink
            to={`/${ROUTES.PRODUCTS}`}
            label="Products"
            iconName={ROUTES_ICONS[ROUTES.PRODUCTS]}
          />
        )}
        {user.hasAction(ACTIONS.PHONE_SERVICE.PAGE) && (
          <NavLink
            to={`/${ROUTES.PHONE_SERVICE}`}
            label="Phone Services"
            iconName={ROUTES_ICONS[ROUTES.PHONE_SERVICE]}
          />
        )}
        <NavLink
          label="Hero Banners"
          to={`/${ROUTES.HERO_BANNERS}`}
          iconName={ROUTES_ICONS[ROUTES.HERO_BANNERS]}
        />
        {user.hasAction(ACTIONS.SETTINGS.PAGE) && (
          <NavLink
            to={`/${ROUTES.SETTINGS}`}
            label="Settings"
            iconName={ROUTES_ICONS[ROUTES.SETTINGS]}
          />
        )}
      </NavLinks>
      <Pane padding="1em" marginY="0.5em">
        <div>
          <Text>{user.data.name}</Text>
        </div>
        <div>
          <Text>{user.data.email}</Text>
        </div>
        <SignoutButton onClick={user.signOut}>Sign out</SignoutButton>
      </Pane>
    </Sidebar>
    <Pane overflowY="auto">{children}</Pane>
  </Layout>
)

SideNavPage.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    signOut: PropTypes.func.isRequired,
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withTheme, withUser)(SideNavPage)
