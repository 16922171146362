import { parseInteger } from 'utils/numbers'

export const getWishesQuery = (
  { pagination = { page: 1 }, filter, sorter },
  additionalFields = ''
) => ({
  query: `
  fragment wish on Wish {
    id
    category {
      name
    }
    customer {
      id
      email
    }
    currency {
      isoCode
    }
    lastSeenAtUtc
    status
    createdAtUtc
    updatedAtUtc
    hasUnseenOffers
  }
  query userWishes($pagination: PaginationInput, $sorter: WishSorterInput, $filter: UserWishFilter) {
    userWishes(pagination: $pagination, sorter: $sorter, filter: $filter) {
      paginationInfo {
        totalCount
        firstPage
        currentPage
        nextPage
        previousPage
        lastPage
      }
      data {
        ...wish
        ${additionalFields}
      }
    }
  }
`,
  variables: {
    pagination,
    sorter,
    filter,
  },
  requestPolicy: 'network-only',
})

export const extractDataFromWishesQuery = (wishesQuery) => {
  const wishes = wishesQuery.data
    ? wishesQuery.data.userWishes.data.map((wish) => ({
        ...wish,
        id: parseInteger(wish.id),
      }))
    : []
  const paginationInfo =
    wishesQuery.data && wishesQuery.data.userWishes.paginationInfo
  return { wishes, paginationInfo }
}
