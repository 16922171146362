import React, { useState, useEffect } from 'react'
import { useQuery } from 'urql'
import SideNavPage from 'sharedComponents/SideNavPage'
import { requestFileDownload } from 'utils/request'
import { Spinner, Pane, Table } from 'evergreen-ui'
import { getAccessToken } from 'UserProvider'
import format from 'date-fns/format'
import { Button } from 'evergreen-ui/commonjs/buttons'
import { TIME_FORMAT } from 'constants/constants'
import Pagination from 'components/Pagination'
import Picker from 'components/Datepicker'

const TABLE_MIN_HEIGHT = 500
const LIMIT = 100

const Logs = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [actionLogsQuery, executeActionLogsQuery] = useQuery({
    query: `
      query($input: ActionLogsInput!) {
        actionLogs(input: $input) {
            paginationInfo {
              totalCount
              firstPage
              lastPage
              currentPage
              nextPage
              previousPage
            }
            data {
              id
              type
              name
              userId
              userEmail
              createdAtUtc
              userRoles
              input
          }
        }
      }
    `,
    variables: {
      input: {
        startDate,
        endDate,
        pagination: {
          page: currentPage,
          limit: LIMIT,
        },
      },
    },
  })
  useEffect(() => {
    executeActionLogsQuery()
  }, [startDate, endDate, executeActionLogsQuery])
  const { data: { actionLogs: { data, paginationInfo } = {} } = {} } =
    actionLogsQuery
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Export logs</SideNavPage.Title>
        <Button
          marginRight={12}
          intent="success"
          appearance="primary"
          iconAfter="export"
          title="Export logs"
          onClick={async () => {
            setIsLoading(true)
            try {
              await requestFileDownload({
                url: `${process.env.REACT_APP_API_URL}/exports/backoffice-actions`,
                accessToken: getAccessToken(),
                filename: `action-logs.csv`,
                body: {
                  startDate,
                  endDate,
                  page: currentPage,
                  limit: paginationInfo.totalCount || LIMIT,
                },
              })
            } catch (error) {
              // do nothing
            } finally {
              setIsLoading(false)
            }
          }}
        >
          Export logs
        </Button>
      </SideNavPage.Header>
      <SideNavPage.Content>
        {isLoading ? (
          <Pane
            marginRight={12}
            width="100%"
            display="flex"
            justifyContent="center"
            marginTop={50}
          >
            <Spinner size={50} />
          </Pane>
        ) : (
          <Pane>
            <Pane
              flexDirection="row"
              display="flex"
              justifyContent="space-between"
            >
              <Pane width="49%" marginBottom="15px">
                <Picker.DateTime
                  onChangeDate={(date) => {
                    setCurrentPage(1)
                    setStartDate(date)
                  }}
                  date={startDate}
                  label="Date from"
                />
              </Pane>
              <Pane width="49%" marginBottom="15px">
                <Picker.DateTime
                  onChangeDate={(date) => {
                    setCurrentPage(1)
                    setEndDate(date)
                  }}
                  date={endDate}
                  label="Date to"
                />
              </Pane>
            </Pane>
          </Pane>
        )}
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Executed At</Table.TextHeaderCell>
            <Table.TextHeaderCell>User Id</Table.TextHeaderCell>
            <Table.TextHeaderCell>User email</Table.TextHeaderCell>
            <Table.TextHeaderCell>User roles</Table.TextHeaderCell>
            <Table.TextHeaderCell>Action type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Action name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Input</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body minHeight={TABLE_MIN_HEIGHT}>
            {actionLogsQuery.fetching ? (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                delay={200}
                size={80}
              />
            ) : (
              data &&
              data.map(
                ({
                  id,
                  createdAtUtc,
                  userId,
                  userEmail,
                  type,
                  name,
                  userRoles = [],
                  input,
                }) => (
                  <Table.Row key={id}>
                    <Table.TextCell>
                      {format(createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                    </Table.TextCell>
                    <Table.TextCell>{userId}</Table.TextCell>
                    <Table.TextCell>{userEmail}</Table.TextCell>
                    <Table.TextCell>{userRoles}</Table.TextCell>
                    <Table.TextCell>{type}</Table.TextCell>
                    <Table.TextCell>{name}</Table.TextCell>
                    <Table.TextCell>{input}</Table.TextCell>
                  </Table.Row>
                )
              )
            )}
          </Table.Body>
        </Table>
        {data && data.length > 0 && (
          <Pagination
            {...paginationInfo}
            onPageChange={(page) => {
              setCurrentPage(page)
            }}
          />
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

export default Logs
