import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import AdditionalServicesList from './routes/AdditionalServicesList'
import EditAdditionalService from './routes/EditAdditionalService'
import NewAdditionalService from './routes/NewAdditionalService'

const AdditionalServices = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/edit/:id`} component={EditAdditionalService} />
    <Route path={`${match.url}/new`} component={NewAdditionalService} />
    <Route path={match.url} component={AdditionalServicesList} />
  </Switch>
)

AdditionalServices.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default AdditionalServices
