import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { SelectField } from 'evergreen-ui'

const CurrencySelect = ({
  setCurrencyIsoCode,
  selectedIsoCode,
  isReadOnly,
  required,
  label,
}) => {
  const [currenciesQuery] = useQuery({
    query: `
      query {
        currencies {
          isoCode
          title
        }
      }
    `,
  })
  return (
    <SelectField
      disabled={isReadOnly}
      required={required}
      label={label}
      value={selectedIsoCode || ''}
      onChange={({ target }) => {
        setCurrencyIsoCode(target.value === '' ? undefined : target.value)
      }}
    >
      <option value="">---Select Currency---</option>
      {currenciesQuery.data &&
        currenciesQuery.data.currencies.map(({ isoCode, title }) => (
          <option key={isoCode} value={isoCode}>
            {title}
          </option>
        ))}
    </SelectField>
  )
}

CurrencySelect.defaultProps = {
  label: 'Currency',
}

CurrencySelect.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  setCurrencyIsoCode: PropTypes.func.isRequired,
  selectedIsoCode: PropTypes.string,
  isReadOnly: PropTypes.bool,
}

export default CurrencySelect
