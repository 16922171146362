import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Spinner, Pane, Heading, majorScale, Link } from 'evergreen-ui'
import { useQuery } from 'urql'
import format from 'date-fns/format'
import urql from 'utils/urql'
import { formatPrice } from 'utils/money'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import Pagination from 'components/Pagination'
import { TIME_FORMAT } from 'constants/constants'
import { compose } from 'recompose'
import ACTIONS from 'constants/actions'
import ROUTES from 'constants/routes'

const TABLE_MIN_HEIGHT = 200

const SellerFeedProducts = ({ sellerId, user, history }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [feedProductsQuery] = useQuery({
    query: `
      query($input: FeedProductsInput!) {
        feedProducts(input: $input) {
          paginationInfo {
            totalCount
            firstPage
            currentPage
            nextPage
            previousPage
            lastPage
          }
          data {
            stockQuantity
            feedProductId
            feed {
              lastSyncAtUtc
            }
            seller {
              id
            }
            price
            productVariant {
              id
              productId
            }
            feed {
              id
              lastSyncAtUtc
            }
          }
        }
      }
    `,
    variables: {
      input: {
        pagination: {
          page: currentPage,
        },
        sellerId,
      },
    },
  })
  useEffect(() => {
    if (feedProductsQuery.error) {
      urql.handleError(feedProductsQuery.error)
    }
  }, [feedProductsQuery.error])
  const feedProducts =
    feedProductsQuery.data && feedProductsQuery.data.feedProducts.data
  const paginationInfo =
    feedProductsQuery.data && feedProductsQuery.data.feedProducts.paginationInfo
  return (
    <Pane marginTop={majorScale(3)}>
      <Heading is="h3" marginBottom={majorScale(2)}>
        Product Variants
      </Heading>
      <Table border>
        <Table.Head>
          <Table.TextHeaderCell>Feed Product Id</Table.TextHeaderCell>
          <Table.TextHeaderCell textAlign="right">
            Product Variant Id
          </Table.TextHeaderCell>
          <Table.TextHeaderCell textAlign="right">Price</Table.TextHeaderCell>
          <Table.TextHeaderCell textAlign="right">
            Stock Quantity
          </Table.TextHeaderCell>
          <Table.TextHeaderCell textAlign="right">Feed Id</Table.TextHeaderCell>
          <Table.TextHeaderCell>Last sync at</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {feedProductsQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {feedProducts &&
            feedProducts.map(
              ({
                feedProductId,
                stockQuantity,
                feed,
                price,
                seller,
                productVariant,
              }) => (
                <Table.Row key={`${productVariant.id}-${feed.id}-${seller.id}`}>
                  <Table.TextCell>{feedProductId}</Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {user.hasAction(ACTIONS.PRODUCT_VARIANT.PAGE) ? (
                      <Link
                        onClick={(e) => {
                          e.stopPropagation()
                          history.push(
                            `/${ROUTES.PRODUCTS}/${productVariant.productId}/variants/${productVariant.id}`
                          )
                        }}
                      >
                        {feed.id}
                      </Link>
                    ) : (
                      feed.id
                    )}
                  </Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {formatPrice({
                      value: price,
                    })}
                  </Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {stockQuantity}
                  </Table.TextCell>
                  <Table.TextCell textAlign="right">
                    {user.hasAction(ACTIONS.FEED.PAGE) ? (
                      <Link
                        onClick={(e) => {
                          e.stopPropagation()
                          history.push(`/${ROUTES.FEEDS}/edit/${feed.id}`)
                        }}
                      >
                        {feed.id}
                      </Link>
                    ) : (
                      feed.id
                    )}
                  </Table.TextCell>

                  <Table.TextCell>
                    {feed.lastSyncAtUtc &&
                      format(feed.lastSyncAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                  </Table.TextCell>
                </Table.Row>
              )
            )}
        </Table.Body>
      </Table>
      {paginationInfo && feedProducts.length > 0 && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </Pane>
  )
}

SellerFeedProducts.propTypes = {
  sellerId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withUser, withRouter)(SellerFeedProducts)
