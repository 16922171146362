import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import {
  Table,
  Spinner,
  Button,
  Pane,
  Dialog,
  TextInputField,
  Heading,
  majorScale,
} from 'evergreen-ui'
import find from 'lodash.find'
import urql from 'utils/urql'
import { withUser } from 'UserProvider'
import ACTIONS from 'constants/actions'
import SideNavPage from 'sharedComponents/SideNavPage'

const TABLE_MIN_HEIGHT = 500

let isInitialMount = true

const currencyFields = `{
  isoCode
  title
  translations {
    language {
      isoCode
      title
    }
    value
  }
}`

const Currencies = ({ user }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [isoCode, setIsoCode] = useState('')
  const [title, setTitle] = useState('')
  const [translations, setTranslations] = useState([])
  const [currenciesQuery, executeCurrenciesQuery] = useQuery({
    query: `
      query {
        currencies ${currencyFields}
      }
    `,
  })
  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })
  const [createMutation, executeCreateMutation] = useMutation(`
    mutation($input: CreateCurrencyInput!) {
      createCurrency(input: $input) ${currencyFields}
    }
  `)
  const lastCreatedIsoCode =
    createMutation.data && createMutation.data.createCurrency.isoCode
  const [updateMutation, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateCurrencyInput!) {
      updateCurrency(input: $input) ${currencyFields}
    }
  `)
  const lastUpdatedIsoCode =
    updateMutation.data && updateMutation.data.updateCurrency.isoCode
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($isoCode: String!) {
      deleteCurrency(isoCode: $isoCode) {
        isoCode
      }
    }
  `)
  const lastDeletedIsoCode =
    deleteMutation.data && deleteMutation.data.deleteCurrency.isoCode
  const updateTranslationValue = (languageIsoCode, value) => {
    setTranslations([
      ...translations.filter(
        (translation) => translation.languageIsoCode !== languageIsoCode
      ),
      { languageIsoCode, value },
    ])
  }
  const getTranslationValue = (languageIsoCode) => {
    const translation = find(translations, {
      languageIsoCode,
    })
    return translation ? translation.value : ''
  }

  useEffect(() => {
    if (!isInitialMount) {
      if (lastCreatedIsoCode || lastUpdatedIsoCode || lastDeletedIsoCode) {
        executeCurrenciesQuery({ requestPolicy: 'network-only' })
      }
    } else {
      isInitialMount = false
    }
  }, [
    lastCreatedIsoCode,
    lastUpdatedIsoCode,
    lastDeletedIsoCode,
    executeCurrenciesQuery,
  ])
  useEffect(() => {
    const error =
      currenciesQuery.error ||
      createMutation.error ||
      updateMutation.error ||
      deleteMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [
    currenciesQuery.error,
    createMutation.error,
    updateMutation.error,
    deleteMutation.error,
  ])
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Currencies</SideNavPage.Title>
        {user.hasAction(ACTIONS.CURRENCY.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setIsoCode('')
              setTitle('')
              setTranslations([])
              setIsCreateModalOpen(true)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>ISO code</Table.TextHeaderCell>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body minHeight={TABLE_MIN_HEIGHT}>
            {currenciesQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                delay={200}
                size={80}
              />
            )}
            {currenciesQuery.data &&
              currenciesQuery.data.currencies.map((currency) => (
                <Table.Row key={currency.isoCode}>
                  <Table.TextCell>{currency.isoCode}</Table.TextCell>
                  <Table.TextCell>{currency.title}</Table.TextCell>
                  <Table.Cell>
                    {user.hasAction(ACTIONS.CURRENCY.UPDATE) && (
                      <Button
                        marginRight={12}
                        iconBefore="edit"
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsoCode(currency.isoCode)
                          setTitle(currency.title)
                          setTranslations(
                            currency.translations.map(
                              ({ language, value }) => ({
                                languageIsoCode: language.isoCode,
                                value,
                              })
                            )
                          )
                          setIsUpdateModalOpen(true)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    {user.hasAction(ACTIONS.CURRENCY.DELETE) && (
                      <Button
                        marginRight={12}
                        iconBefore="trash"
                        intent="danger"
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsoCode(currency.isoCode)
                          setIsConfirmDeleteModalOpen(true)
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </SideNavPage.Content>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete currency"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            isoCode,
          })
          if (!error) {
            setIsoCode('')
            close()
          }
        }}
      >
        Are you sure you want to delete Currency with isoCode: {isoCode} ??
      </Dialog>
      <Dialog
        isShown={isCreateModalOpen || isUpdateModalOpen}
        title={
          isCreateModalOpen ? 'Create currency' : `Edit currency: ${isoCode}`
        }
        hasCancel={false}
        confirmLabel={isCreateModalOpen ? 'Create' : 'Edit'}
        isConfirmLoading={createMutation.fetching || updateMutation.fetching}
        onCloseComplete={() => {
          setIsCreateModalOpen(false)
          setIsUpdateModalOpen(false)
        }}
        onConfirm={async (close) => {
          const input = {
            isoCode,
            title,
            translations: translations.filter(({ value }) => value !== ''),
          }
          if (isCreateModalOpen) {
            const { error } = await executeCreateMutation({
              input,
            })
            if (!error) {
              close()
            }
          }
          if (isUpdateModalOpen) {
            const { error } = await executeUpdateMutation({
              input,
            })
            if (!error) {
              close()
            }
          }
        }}
      >
        {isCreateModalOpen && (
          <TextInputField
            label="ISO code"
            required
            value={isoCode}
            onChange={(e) => {
              setIsoCode(e.target.value)
            }}
          />
        )}
        <TextInputField
          label="Title"
          required
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Translations
          </Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Language</Table.TextHeaderCell>
              <Table.TextHeaderCell>Value</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {languagesQuery.data &&
                languagesQuery.data.languages.map((language) => (
                  <Table.Row key={language.isoCode}>
                    <Table.TextCell>{language.title}</Table.TextCell>
                    <Table.Cell>
                      <TextInputField
                        label="Translation"
                        value={getTranslationValue(language.isoCode)}
                        onChange={(e) => {
                          updateTranslationValue(
                            language.isoCode,
                            e.target.value
                          )
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Pane>
      </Dialog>
    </React.Fragment>
  )
}

Currencies.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Currencies)
