export const createProductImageMutationString = `
  mutation($input: CreateProductImageInput!) {
    createProductImage(input: $input) {
      id
      name
      url
    }
  }
`

export const createReturnAttachmentMutationString = `
  mutation($input: CreateReturnAttachmentInput!) {
    createReturnAttachment(input: $input) {
      id
      name
      url
    }
  }
`
