import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'evergreen-ui'
import { pickSpaceProps } from 'utils/styled-system'
import { WISH_STATUSES } from 'constants/constants'

const {
  NEW,
  DECIDE_LATER,
  ACCEPTED,
  DECLINED,
  CUSTOMER_DELETED,
  OFFER_CREATED,
  ORDERED,
  OFFER_NOT_ACCEPTED,
} = WISH_STATUSES

const getBadgeColor = (status) => {
  switch (status) {
    case NEW:
      return 'yellow'
    case ACCEPTED:
      return 'green'
    case DECLINED:
      return 'red'
    case ORDERED:
      return 'teal'
    case OFFER_CREATED:
      return 'blue'
    case CUSTOMER_DELETED:
      return 'purple'
    case OFFER_NOT_ACCEPTED:
      return 'orange'
    case DECIDE_LATER:
    default:
      return 'neutral'
  }
}

const WishBadge = ({ status, ...rest }) => (
  <Badge color={getBadgeColor(status)} isSolid {...pickSpaceProps(rest)}>
    {status.replace(/_/g, ' ')}
  </Badge>
)

WishBadge.propTypes = {
  status: PropTypes.oneOf(Object.values(WISH_STATUSES)).isRequired,
}

export default WishBadge
