import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'evergreen-ui'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components/macro'

const getColor = ({ isDragAccept, isDragReject, isDragActive, theme }) => {
  if (isDragAccept) {
    return theme.colors.intent.success
  }
  if (isDragReject) {
    return theme.colors.intent.danger
  }
  if (isDragActive) {
    return theme.colors.intent.none
  }
  return theme.colors.border.default
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  margin-bottom: 8px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

const Dropzone = ({
  onDrop,
  multiple,
  accept,
  dragInactiveSingleFileLabel,
  dragInactiveMultipleFilesLabel,
  theme,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    multiple,
    onDrop,
  })

  return (
    <div>
      <Container
        theme={theme}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{multiple ? `Drop files here ...` : `Drop the file here ...`}</p>
        ) : (
          <p>
            {multiple
              ? dragInactiveMultipleFilesLabel
              : dragInactiveSingleFileLabel}
          </p>
        )}
      </Container>
    </div>
  )
}

Dropzone.defaultProps = {
  multiple: false,
  accept: 'image/*',
  dragInactiveMultipleFilesLabel:
    'Drag&drop some files here, or click to select files',
  dragInactiveSingleFileLabel: 'Drag&drop a file here, or click to select file',
}

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  dragInactiveSingleFileLabel: PropTypes.string,
  dragInactiveMultipleFilesLabel: PropTypes.string,
  theme: PropTypes.object.isRequired,
}

export default withTheme(Dropzone)
