import React from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES, { FEEDBACK_MANAGEMENT_ROUTES } from 'constants/routes'
import { withUser } from 'UserProvider'
import Tablist from 'components/Tablist'
import Inbox from './Inbox'
import ProductFeedback from './ProductFeedback'
import CustomerSupport from './CustomerSupport'
import Archive from './Archive'

const { INBOX, PRODUCT_FEEDBACK, CUSTOMER_SUPPORT, ARCHIVE } =
  FEEDBACK_MANAGEMENT_ROUTES

const tabsData = [
  {
    tab: INBOX,
    name: 'Inbox',
    component: Inbox,
  },
  {
    tab: PRODUCT_FEEDBACK,
    name: 'Product Feedback',
    component: ProductFeedback,
  },
  {
    tab: CUSTOMER_SUPPORT,
    name: 'Customer Support',
    component: CustomerSupport,
  },
  {
    tab: ARCHIVE,
    name: 'Archive',
    component: Archive,
  },
]

const Management = ({ match, history, user }) => {
  const tabs = tabsData.filter(
    ({ requiredAction }) => !requiredAction || user.hasAction(requiredAction)
  )
  const selectedTab = match.params.tab || (tabs.length && tabs[0].tab)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Feedbacks</SideNavPage.Title>
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Tablist
          history={history}
          selectedTab={selectedTab}
          tabs={tabs}
          rootUrl={ROUTES.FEEDBACKS}
        />
        <Pane marginTop="1em" flex={1}>
          {tabs.map(
            ({ tab, component: Comp }) =>
              tab === selectedTab && <Comp key={tab} />
          )}
        </Pane>
      </SideNavPage.Content>
    </React.Fragment>
  )
}

Management.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Management)
