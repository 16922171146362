import PropTypes from 'prop-types'
import React from 'react'
import { Pane, Text, Icon, Spinner } from 'evergreen-ui'
import { formatToHumanReadable } from 'utils/datetime'
import { withUser } from 'UserProvider'
import LinkParser from 'components/LinkParser'
import styled from 'styled-components/macro'
import ACTIONS from 'constants/actions'

const StyledText = styled(Text)`
  white-space: pre-line;
`

const StyledPane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  background-color: #0099ff;
  padding: 15px;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
`

const MyMessage = (props) => {
  const {
    text,
    createdAtUtc,
    id,
    attachmentUrl,
    attachmentName,
    onClickAttachment,
    user,
    isDownloadingAttachment,
  } = props
  return (
    <Pane
      id={id}
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      marginBottom={10}
      marginRight={40}
    >
      <Pane display="flex" alignSelf="flex-end" maxWidth="80%">
        {text && (
          <Pane
            border
            display="flex"
            alignSelf="flex-end"
            backgroundColor="#0099ff"
            padding={15}
            borderRadius={10}
            flexDirection="column"
          >
            <StyledText color="#fff" size={300} marginBottom={10}>
              <LinkParser>{text}</LinkParser>
            </StyledText>
            <Pane display="flex" justifyContent="flex-end">
              <Text color="#fff" size={300}>
                {formatToHumanReadable({
                  date: createdAtUtc,
                  isTimeShown: true,
                })}
              </Text>
            </Pane>
          </Pane>
        )}
      </Pane>
      {user.hasAction(ACTIONS.MESSAGE.VIEW_ATTACHMENT) && attachmentUrl && (
        <Pane display="flex" alignSelf="flex-end">
          {isDownloadingAttachment && (
            <Pane marginRight="10px">
              <Spinner delay={200} size={20} />
            </Pane>
          )}
          <StyledPane
            onClick={() => {
              if (user.hasAction(ACTIONS.MESSAGE.DOWNLOAD_ATTACHMENT)) {
                onClickAttachment({
                  url: attachmentUrl,
                  filename: attachmentName,
                  messageId: id,
                })
              }
            }}
          >
            <Pane display="flex" flexDirection="row" alignItems="center">
              <Icon icon="download" size={18} marginRight={10} color="#fff" />
              <Text color="#fff" textDecoration="underline">
                {attachmentName}
              </Text>
            </Pane>
            <Pane display="flex" justifyContent="flex-end">
              <Text color="#fff" size={300}>
                {formatToHumanReadable({
                  date: createdAtUtc,
                  isTimeShown: true,
                })}
              </Text>
            </Pane>
          </StyledPane>
        </Pane>
      )}
    </Pane>
  )
}

MyMessage.propTypes = {
  text: PropTypes.string,
  createdAtUtc: PropTypes.string,
  id: PropTypes.number.isRequired,
  attachmentUrl: PropTypes.string,
  attachmentName: PropTypes.string,
  onClickAttachment: PropTypes.func,
  isDownloadingAttachment: PropTypes.bool,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(MyMessage)
