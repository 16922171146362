import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import styled from 'styled-components/macro'
import { BackButton, majorScale, Pane, Button } from 'evergreen-ui'
import { parseInteger } from 'utils/numbers'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import DeleteDialog from '../../components/DeleteDialog'
import ProductVariantForm from '../../components/ProductVariantForm'
import { getPathToProduct } from '../../utils'

const BUTTON_LEFT_MARGIN = `${majorScale(1)}px`

const StyledButton = styled(Button)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const StyledBackButton = styled(BackButton)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const EditProductVariant = ({ match, history, user }) => {
  const id = parseInteger(match.params.id)
  const [productVariantQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query($id: Int!) {
        productVariant(id: $id) {
          id
          productId
          productVariantAttributeValues {
            productId
            attributeValue {
              id
              attributeId
              title
            }
          }
          mainImage {
            id
            url
            name
          }
          ean
          externalPid
          externalPids {
            productVariantId
            id
            externalNote
            externalPid
          }
          additionalImages {
            id
            url
            name
          }
        }
      }
    `,
    variables: {
      id,
    },
  })
  const productVariant = productVariantQuery.data
    ? productVariantQuery.data.productVariant
    : null
  const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Edit Product Variant</SideNavPage.Title>
        {productVariant && (
          <Pane>
            {user.hasAction(ACTIONS.PRODUCT_VARIANT.DELETE) && (
              <StyledButton
                intent="danger"
                appearance="primary"
                onClick={() => {
                  setDeleteDialogOpened(true)
                }}
              >
                Delete
              </StyledButton>
            )}
            <StyledBackButton onClick={() => history.goBack()} pl="0.5rem" />
          </Pane>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <FullScreenLoading isVisible={productVariantQuery.fetching} />
        {productVariant && (
          <React.Fragment>
            {isDeleteDialogOpened && (
              <DeleteDialog
                itemToDelete={{ id }}
                isProductVariantDialog
                onClose={() => {
                  setDeleteDialogOpened(false)
                }}
                onSuccess={() => {
                  history.replace(
                    getPathToProduct({
                      productId: productVariant.productId,
                    })
                  )
                }}
              />
            )}
            <ProductVariantForm key={productVariant.id} data={productVariant} />
          </React.Fragment>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

EditProductVariant.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(EditProductVariant)
