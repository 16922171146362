import React, { useState, useCallback } from 'react'
import Dropzone from 'components/Dropzone'

import PropTypes from 'prop-types'
import {
  Button,
  BackButton,
  TextareaField,
  Heading,
  Image,
  Pane,
  SelectField,
  majorScale,
  toaster,
  TextInputField,
} from 'evergreen-ui'
import { useMutation } from 'urql'
import urql from 'utils/urql'

// Editor
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { parseInteger } from 'utils/numbers'
import SideNavPage from 'sharedComponents/SideNavPage'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import Translations from 'components/TranslationsSlides'
import EditorDeleteCustomOption from 'components/EditorDeleteCustomOption'
import { convertEditorStateToRawJson } from 'utils/convertToRaw'

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error, success) => {
  displayToast(error, success)
}

const AddNewSlide = ({ match, history }) => {
  const heroBannerId = parseInteger(match.params.id)
  const [title, setTitle] = useState('')
  const [textColor, setTextColor] = useState(null)
  const [subtitle, setSubtitle] = useState('')
  const [bannerType, setBannerType] = useState('')
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )
  const [actionOnClick, setActionOnClick] = useState('noaction')
  const [webpageUrl, setWebpageUrl] = useState(null)
  const [translations, setTranslations] = useState([])
  const [image, setImage] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)

  const [createMutation, executeCreateMutation] = useMutation(`
    mutation($input: CreateHeroBannerSlideInput!) {
      createHeroBannerSlide(input: $input) {
        heroBannerId
      }
    }
  `)

  const updateSlide = async () => {
    const getOnClickValue = () => {
      if (actionOnClick === 'webpage') {
        return webpageUrl
      }
      if (actionOnClick === 'modal') {
        return convertEditorStateToRawJson(editorState)
      }

      return null
    }
    if (!title || !subtitle || !bannerType || !image) {
      toaster.danger('Fill all fields: title, subtitle, banner type, image')
    } else {
      const dataToSave = {
        title,
        subtitle,
        bannerType,
        textColor,
        image,
        heroBannerId,
        actionOnClick,
        onClickValue: getOnClickValue(),
        translations:
          translations &&
          translations.map(({ __typename, language, ...rest }) => ({
            ...rest,
            languageIsoCode: language.isoCode,
          })),
      }

      const { error } = await executeCreateMutation({ input: dataToSave })

      displayToastOnSave(error, 'Saved. Loading....')

      if (!error) {
        history.push(`/hero-banners/${heroBannerId}/edit`)
      }
    }
  }

  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>New Slide</SideNavPage.Title>

        <FullScreenLoading isVisible={createMutation.fetching} />
        <div>
          <Button
            style={{ marginRight: 20 }}
            appearance="primary"
            intent="success"
            onClick={updateSlide}
          >
            Save all changes
          </Button>
          <BackButton onClick={() => history.goBack()} />
        </div>
      </SideNavPage.Header>
      <SideNavPage.Content style={{ width: 1000 }}>
        <SelectField
          required
          label="Banner type"
          value={bannerType}
          onChange={(e) => {
            setBannerType(e.target.value)
          }}
        >
          <option value="">---Select Banner type---</option>
          <option value="BIG">Main (big one)</option>
          <option value="SMALL">Secondary (small one)</option>
        </SelectField>

        <TextareaField
          label="Title"
          required
          disabled={false}
          value={title || ''}
          onChange={({ target }) => {
            setTitle(target.value === '' ? undefined : target.value)
          }}
        />

        <TextareaField
          label="Subtitle"
          required
          disabled={false}
          value={subtitle || ''}
          onChange={({ target }) => {
            setSubtitle(target.value === '' ? undefined : target.value)
          }}
        />

        <p>https://htmlcolorcodes.com/color-picker/ </p>
        <TextInputField
          label="Text color (white = #FFFFFF) (black = #000000)"
          placeholder="#FFFFFF"
          disabled={false}
          value={textColor || ''}
          onChange={({ target }) => {
            setTextColor(target.value === '' ? null : target.value)
          }}
        />

        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Image *
          </Heading>
          <Dropzone
            onDrop={useCallback((acceptedFiles) => {
              const file = acceptedFiles[0]
              setImagePreview(URL.createObjectURL(file))
              setImage(file)
            }, [])}
          />
          {imagePreview && (
            <Image alt="preview" src={imagePreview} style={{ width: 200 }} />
          )}
        </Pane>

        <Pane>
          <SelectField
            label="On click behavior"
            value={actionOnClick}
            onChange={(e) => {
              if (actionOnClick !== e.target.value) {
                const newTranslations = translations.map((item) => ({
                  ...item,
                  onClickValue: null,
                }))
                setTranslations(newTranslations)
              }
              setActionOnClick(e.target.value)
            }}
          >
            <option value="">---Select on click behavior---</option>
            <option value="noaction">
              No action (nothing will happen after click)
            </option>
            <option value="webpage">Open webpage</option>
            <option value="modal">Show modal with text</option>
          </SelectField>

          {actionOnClick === 'modal' && (
            <Pane>
              <p>Modal Content</p>
              <Editor
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'list',
                    'textAlign',
                    'image',
                  ],
                }}
                editorState={editorState}
                editorStyle={{
                  border: '1px solid #e2e2e2',
                  height: 300,
                }}
                onEditorStateChange={setEditorState}
                toolbarCustomButtons={[<EditorDeleteCustomOption />]}
              />
            </Pane>
          )}

          {actionOnClick === 'webpage' && (
            <Pane>
              <TextInputField
                label="URL"
                required
                placeholder="https://google.com"
                disabled={false}
                value={webpageUrl || ''}
                onChange={({ target }) => {
                  setWebpageUrl(target.value === '' ? null : target.value)
                }}
              />
            </Pane>
          )}
        </Pane>

        <Pane marginTop={20}>
          <Translations
            actionOnClick={actionOnClick}
            isAddDisabled={false}
            isEditDisabled={false}
            translations={translations}
            handleSaveTranslations={setTranslations}
          />
        </Pane>

        <Button
          style={{ marginTop: 20 }}
          appearance="primary"
          intent="success"
          onClick={updateSlide}
        >
          Save all changes
        </Button>
      </SideNavPage.Content>
    </React.Fragment>
  )
}

AddNewSlide.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default AddNewSlide
