import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'evergreen-ui'
import { useQuery } from 'urql'
import urql from 'utils/urql'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import SellerFeedProducts from 'components/SellerFeedProducts'

const SellerDetail = ({ match }) => {
  const [sellerQuery] = useQuery({
    query: `
      query($id: ID!){
        seller(id: $id) {
          id
          }
      }
    `,
    variables: {
      id: match.params.id,
    },
  })
  useEffect(() => {
    if (sellerQuery.error) {
      urql.handleError(sellerQuery.error)
    }
  }, [sellerQuery.error])
  const seller = (sellerQuery.data && sellerQuery.data.seller) || false
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>
          Product variants of seller: {seller.id}
        </SideNavPage.Title>
      </SideNavPage.Header>
      <SideNavPage.Content>
        {sellerQuery.fetching && (
          <Spinner marginX="auto" delay={200} size={80} />
        )}
        {seller && <SellerFeedProducts sellerId={match.params.id} />}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

SellerDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(SellerDetail)
