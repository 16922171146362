import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import { Table, Spinner, Button, Dialog } from 'evergreen-ui'
import urql from 'utils/urql'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES from 'constants/routes'
import { withUser } from 'UserProvider'

const TABLE_MIN_HEIGHT = 500

const HeroBannersList = ({ history }) => {
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [heroBannersQuery, executeHeroBannersQuery] = useQuery({
    query: `
    query userHeroBanners {
      userHeroBanners{
        id
        isVisibleForUsers
        internalTitle
        heroBannersSlides {
          title
          id
          imageUrl
        }
      }
    }
    `,
  })
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: Int!) {
      deleteHeroBanner(id: $id)
    }
  `)

  useEffect(() => {
    const error = heroBannersQuery.error || deleteMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [heroBannersQuery.error, deleteMutation.error])

  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Hero Banners</SideNavPage.Title>
        <Button
          iconBefore="add"
          appearance="primary"
          intent="success"
          onClick={() => history.push(`${ROUTES.HERO_BANNERS}/new`)}
        >
          Add new
        </Button>
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>Internal title</Table.TextHeaderCell>
            <Table.TextHeaderCell>Is visible for users</Table.TextHeaderCell>
            <Table.TextHeaderCell>Slides count</Table.TextHeaderCell>
            <Table.TextHeaderCell align="center">Action</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {heroBannersQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                delay={200}
                size={80}
              />
            )}
            {heroBannersQuery.data &&
              heroBannersQuery.data.userHeroBanners.map((heroBanner) => (
                <Table.Row
                  height="auto"
                  minHeight="45px"
                  key={heroBanner.id}
                  onSelect={() =>
                    history.push(`hero-banners/${heroBanner.id}/edit`)
                  }
                >
                  <Table.TextCell>{heroBanner.id}</Table.TextCell>
                  <Table.TextCell>{heroBanner.internalTitle}</Table.TextCell>
                  <Table.TextCell>
                    <span
                      style={{
                        backgroundColor: heroBanner.isVisibleForUsers
                          ? '#689b2c'
                          : 'red',
                        color: 'white',
                        padding: 20,
                      }}
                    >
                      {heroBanner.isVisibleForUsers ? 'Yes' : 'No'}
                    </span>
                  </Table.TextCell>
                  <Table.TextCell>
                    {heroBanner.heroBannersSlides.length}
                  </Table.TextCell>
                  <Table.Cell align="center">
                    <Button
                      marginRight={10}
                      onClick={() => {
                        history.push(`/hero-banners/${heroBanner.id}/edit`)
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      iconBefore="trash"
                      intent="danger"
                      onClick={(e) => {
                        e.stopPropagation()
                        setId(heroBanner.id)
                        setTitle(heroBanner.internalTitle)
                        setIsConfirmDeleteModalOpen(true)
                      }}
                    >
                      Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </SideNavPage.Content>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete hero banner"
        confirmLabel="Delete"
        intent="danger"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          await executeDeleteMutation({ id })
          await executeHeroBannersQuery()
          close()
        }}
      >
        Are you sure you want to delete hero banner: {title} (#{id}) ?
      </Dialog>
    </React.Fragment>
  )
}

HeroBannersList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(HeroBannersList)
