import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import { withUser } from 'UserProvider'
import { Table, Spinner, Button, Dialog, Link } from 'evergreen-ui'
import Pagination from 'components/Pagination'
import urql from 'utils/urql'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES from 'constants/routes'
import { parseInteger } from 'utils/numbers'
import ACTIONS from 'constants/actions'

const TABLE_MIN_HEIGHT = 500

let isInitialMount = true

const FeedsList = ({ history, user }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [id, setId] = useState('')
  const [url, setUrl] = useState('')
  const [feedsQuery, executeFeedsQuery] = useQuery({
    query: `
      query Feeds($page: Int!) {
        feeds (pagination: {page: $page}) {
          paginationInfo {
            totalCount
            firstPage
            lastPage
            currentPage
            nextPage
            previousPage
          }
          data {
            id
            url
            seller {
              id
              accountName
            }
          }
        }
      }
    `,
    variables: {
      page: currentPage,
    },
  })
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: Int!) {
      deleteFeed(id: $id) {
        id
      }
    }
  `)
  const lastDeletedId = deleteMutation.data && deleteMutation.data.deleteFeed.id
  useEffect(() => {
    if (!isInitialMount) {
      if (lastDeletedId) {
        executeFeedsQuery({ requestPolicy: 'network-only' })
      }
    } else {
      isInitialMount = false
    }
  }, [lastDeletedId, executeFeedsQuery])
  useEffect(() => {
    const error = feedsQuery.error || deleteMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [deleteMutation.error, feedsQuery.error])
  const paginationInfo = feedsQuery.data && feedsQuery.data.feeds.paginationInfo
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Feeds</SideNavPage.Title>
        {user.hasAction(ACTIONS.FEED.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              history.push(`/${ROUTES.FEEDS}/new`)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Id</Table.TextHeaderCell>
            <Table.TextHeaderCell>URL</Table.TextHeaderCell>
            <Table.TextHeaderCell>Seller</Table.TextHeaderCell>
            <Table.TextHeaderCell minWidth="250px" align="center">
              Actions
            </Table.TextHeaderCell>
          </Table.Head>
        </Table>
        <Table.Body minHeight={TABLE_MIN_HEIGHT}>
          {feedsQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {feedsQuery.data &&
            feedsQuery.data.feeds.data.map((feed) => (
              <Table.Row
                key={feed.id}
                isSelectable
                onSelect={() =>
                  history.push(`/${ROUTES.FEEDS}/edit/${feed.id}`)
                }
              >
                <Table.TextCell>{feed.id}</Table.TextCell>
                <Table.TextCell>{feed.url}</Table.TextCell>
                <Table.Cell>
                  {user.hasAction(ACTIONS.SELLER.PAGE) ? (
                    <Link
                      onClick={(e) => {
                        e.stopPropagation()
                        history.push(`/${ROUTES.SELLERS}/${feed.seller.id}`)
                      }}
                    >
                      {feed.seller.accountName}
                    </Link>
                  ) : (
                    feed.seller.accountName
                  )}
                </Table.Cell>
                <Table.Cell minWidth="250px" justifyContent="center">
                  {user.hasAction(ACTIONS.FEED.DELETE) && (
                    <Button
                      marginRight={12}
                      iconBefore="trash"
                      intent="danger"
                      onClick={(e) => {
                        e.stopPropagation()
                        setId(feed.id)
                        setUrl(feed.url)
                        setIsConfirmDeleteModalOpen(true)
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </SideNavPage.Content>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete attribute"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id: parseInteger(id),
          })
          if (!error) {
            setId('')
            setUrl('')
            close()
          }
        }}
      >
        Are you sure you want to delete feed {url}?
      </Dialog>
      {paginationInfo && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </React.Fragment>
  )
}

FeedsList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(FeedsList)
