import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useMutation } from 'urql'
import {
  Pane,
  Textarea,
  IconButton,
  Dialog,
  majorScale,
  Text,
  Icon,
} from 'evergreen-ui'
import { parseInteger } from 'utils/numbers'
import { withUser } from 'UserProvider'
import Dropzone from 'components/Dropzone'
import uploadRequest, { getIsFileSizeExceeded } from 'utils/uploadRequest'
import ACTIONS from 'constants/actions'
import { MAX_UPLOAD_FILE_SIZE_IN_BYTES } from 'constants/constants'

const ICON_SIZE = 18

const InputWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: calc(50% - 200px);
  background-color: #fff;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  flex: 1;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  padding: ${majorScale(1)}px;
`

const defaultFile = { objectUrl: '', name: '' }

const MessageInput = ({ threadId, onSubmit, user }) => {
  const [newMessageText, setNewMessageText] = useState('')
  const [isMessageSent, setIsMessageSent] = useState(false)
  const [isAttachment, setIsAttachment] = useState(false)
  const [isImagePreviewError, setIsImagePreviewError] = useState(false)
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false)
  const [file, setFile] = useState(defaultFile)

  const [newMessageMutation, executeNewMessageMutation] = useMutation(`
    mutation($input: CreateMessageInput!) {
      userCreateMessage(input: $input) {
        id
      }
    }
  `)

  useEffect(() => {
    if (newMessageMutation.data && isMessageSent) {
      onSubmit(newMessageMutation.data.userCreateMessage.id)
      setIsMessageSent(false)
    }
  }, [isMessageSent, newMessageMutation, onSubmit])

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    if (newMessageText === '') {
      return
    }
    await executeNewMessageMutation({
      input: {
        threadId: parseInteger(threadId),
        text: newMessageText,
      },
    })
    setIsMessageSent(true)
    setNewMessageText('')
  }
  return (
    <InputWrapper>
      {isAttachment && <Pane />}
      <Pane display="flex" flex={1}>
        {user.hasAction(ACTIONS.MESSAGE.CREATE_ATTACHMENT) && (
          <IconButton
            onClick={() => {
              setIsAttachment(true)
            }}
            icon="folder-new"
            iconSize={18}
            appearance="minimal"
            width={50}
          />
        )}
        <StyledForm onSubmit={handleSubmitForm}>
          <Pane display="flex" flex={1}>
            <Textarea
              onChange={(e) => setNewMessageText(e.target.value)}
              value={newMessageText}
              width="100%"
              placeholder="Enter your message here"
              name="newMessageText"
            />
          </Pane>
          <IconButton
            type="submit"
            appearance="primary"
            icon="direction-right"
            intent="success"
          >
            Send
          </IconButton>
        </StyledForm>
      </Pane>
      <Dialog
        isShown={isAttachment}
        isConfirmLoading={isAttachmentLoading}
        title="Send file to customer"
        confirmLabel="Send file"
        onCloseComplete={() => {
          setIsAttachment(false)
        }}
        isConfirmDisabled={!file.objectUrl}
        onConfirm={async (close) => {
          try {
            setIsAttachmentLoading(true)
            const response = await uploadRequest({
              accessToken: user.accessToken,
              body: {
                query: `
                  mutation($input: CreateAttachmentInput!) {
                    createAttachment(input: $input) {
                      id
                    }
                  }
                `,
                variables: {
                  input: {
                    threadId: parseInteger(threadId),
                    file: file.file,
                    text: newMessageText,
                  },
                },
              },
            })
            setNewMessageText('')
            setFile(defaultFile)
            onSubmit(response.createAttachment.id)
            close()
          } catch (error) {
            // do nothing
          } finally {
            setIsAttachmentLoading(false)
          }
        }}
      >
        <Dropzone
          accept={null}
          onDrop={useCallback((acceptedFiles) => {
            const fileToUpload = acceptedFiles[0]
            if (
              getIsFileSizeExceeded({
                file: fileToUpload,
                fileMaxSize: MAX_UPLOAD_FILE_SIZE_IN_BYTES,
              })
            ) {
              setFile({
                file: fileToUpload,
                name: fileToUpload.name,
                objectUrl: URL.createObjectURL(fileToUpload),
              })
            }
          }, [])}
        />
        {isImagePreviewError && file.name && (
          <Pane
            marginTop="10px"
            minHeight="30px"
            backgroundColor="#EEE"
            borderRadius={3}
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            padding="10px"
            paddingRight="5px"
          >
            <Pane flex={1}>
              <Icon icon="document" size={ICON_SIZE} />
              <Text marginLeft="10px">{file.name}</Text>
            </Pane>
            <IconButton
              intent="danger"
              appearance="minimal"
              icon="cross"
              iconSize={ICON_SIZE}
              onClick={() => {
                setFile(defaultFile)
              }}
            />
          </Pane>
        )}
        {!isImagePreviewError && file.objectUrl && (
          <Pane
            display="flex"
            alignItems="center"
            padding="10px"
            borderRadius={3}
            marginTop="10px"
            backgroundColor="#EEE"
          >
            <Pane flex={1}>
              <Image
                onError={() => {
                  setIsImagePreviewError(true)
                }}
                alt="preview"
                src={file.objectUrl}
              />
            </Pane>
            <IconButton
              intent="danger"
              appearance="minimal"
              icon="cross"
              iconSize={ICON_SIZE}
              onClick={() => {
                setFile(defaultFile)
              }}
            />
          </Pane>
        )}
        <Pane display="flex" flex={1} marginTop="10px">
          <Textarea
            onChange={(e) => setNewMessageText(e.target.value)}
            value={newMessageText}
            width="100%"
            placeholder="Enter your message here"
            name="newMessageText"
          />
        </Pane>
      </Dialog>
    </InputWrapper>
  )
}

MessageInput.propTypes = {
  threadId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
  }).isRequired,
}

export default withUser(MessageInput)
