import React from 'react'
import PropTypes from 'prop-types'
import { Pane, Image, majorScale } from 'evergreen-ui'
import logo from 'assets/images/logo.png'

const FullCenterPage = ({ children }) => (
  <Pane
    minHeight="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Pane
      minWidth="450px"
      background="tint2"
      border="default"
      paddingTop="50px"
      paddingBottom="50px"
      paddingLeft="100px"
      paddingRight="100px"
    >
      <Pane display="flex" justifyContent="center" marginBottom={majorScale(5)}>
        <Image src={logo} alt="Logo" maxWidth="200px" />
      </Pane>
      {children}
    </Pane>
  </Pane>
)

FullCenterPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullCenterPage
