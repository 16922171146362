import React from 'react'
import { Modifier, EditorState, SelectionState, RichUtils } from 'draft-js'
import { getSelectedBlocksMap } from 'draftjs-utils'
import { flow } from 'lodash'
import PropTypes from 'prop-types'

const styles = [
  'BOLD',
  'ITALIC',
  'UNDERLINE',
  'STRIKETHROUGH',
  'CODE',
  'SUPERSCRIPT',
  'SUBSCRIPT',
]

const EditorDeleteCustomOption = (props) => {
  const removeInlineStyles = (editorState) => {
    const contentState = editorState.getCurrentContent()
    const contentWithoutStyles = styles.reduce(
      (newContentState, style) =>
        Modifier.removeInlineStyle(
          newContentState,
          editorState.getSelection(),
          style
        ),
      contentState
    )

    const newEditorState = EditorState.push(
      editorState,
      contentWithoutStyles,
      'change-inline-style'
    )

    return newEditorState
  }

  const removeEntities = (editorState) => {
    const contentState = editorState.getCurrentContent()
    const contentWithoutEntities = Modifier.applyEntity(
      contentState,
      editorState.getSelection(),
      null
    )

    const newEditorState = EditorState.push(
      editorState,
      contentWithoutEntities,
      'apply-entity'
    )

    return newEditorState
  }

  const removeBlockTypes = (editorState) => {
    const contentState = editorState.getCurrentContent()
    const blocksMap = getSelectedBlocksMap(editorState)
    const contentWithoutBlocks = blocksMap.reduce((oldContentState, block) => {
      const selectionState = SelectionState.createEmpty(block.getKey())
      const updatedSelection = selectionState.merge({
        focusOffset: 0,
        anchorOffset: block.getText().length,
      })

      let newContentState = Modifier.setBlockData(
        oldContentState,
        updatedSelection,
        {}
      )

      const blockType = block.getType()
      if (blockType !== 'unstyled') {
        newContentState = Modifier.setBlockType(
          newContentState,
          updatedSelection,
          'unstyled'
        )
      }

      return newContentState
    }, contentState)

    const newEditorState = EditorState.push(
      editorState,
      contentWithoutBlocks,
      'change-block-type'
    )

    return newEditorState
  }

  const resetFontSize = (editorState) => {
    const currentStyles = editorState.getCurrentInlineStyle()

    const styleToRemove = currentStyles.find((style) =>
      style.match(/^fontsize-/)
    )

    if (styleToRemove) {
      return RichUtils.toggleInlineStyle(editorState, styleToRemove)
    }

    return editorState
  }

  // const resetTextAlignment = (editorState) => {
  //   const currentStyles = editorState.get

  //   const styleToRemove = currentStyles.find((style) =>
  //     style.match(/^fontsize-/)
  //   )

  //   if (styleToRemove) {
  //     return RichUtils.toggleInlineStyle(editorState, styleToRemove)
  //   }

  //   return editorState
  // }

  const handleClick = () => {
    const stateWithoutEditing = flow([
      removeInlineStyles,
      removeEntities,
      resetFontSize,
      removeBlockTypes,
    ])(props.editorState)

    props.onChange(stateWithoutEditing)
  }

  return (
    <div className="rdw-inline-wrapper" aria-label="rdw-inline-control">
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className="rdw-option-wrapper"
        aria-selected="false"
        title="Clear"
        onClick={handleClick}
      >
        Clear
      </div>
    </div>
  )
}

EditorDeleteCustomOption.propTypes = {
  onChange: PropTypes.func,
  editorState: PropTypes.object,
}

export default EditorDeleteCustomOption
