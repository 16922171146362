import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import MultiSelectMenu from 'components/MultiSelectMenu'

const ThreadStatusesSelectMenu = ({
  selectedThreadStatuses = [],
  onChange,
}) => {
  const [threadStatusesQuery] = useQuery({
    query: `
      query {
        threadStatuses {
          name
          code
        }
      }
    `,
  })
  if (!threadStatusesQuery.data) {
    return null
  }
  return (
    <MultiSelectMenu
      title="All ticket statuses:"
      placeholder="All ticket statuses"
      options={
        threadStatusesQuery.data
          ? threadStatusesQuery.data.threadStatuses.map((status) => ({
              label: status.name,
              value: status.code,
            }))
          : []
      }
      onChange={onChange}
      selectedValues={selectedThreadStatuses}
    />
  )
}

ThreadStatusesSelectMenu.propTypes = {
  selectedThreadStatuses: PropTypes.arrayOf(PropTypes.string.isRequired)
    .isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ThreadStatusesSelectMenu
