import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { Dialog, FormField, Pane, SelectField } from 'evergreen-ui'
import Picker from 'components/Datepicker'

import { requestFileDownload } from 'utils/request'
import { getAccessToken } from 'UserProvider'
import { ORDER_STATUSES, RETURN_ORDER_STATUSES } from 'constants/constants'
import { getOrderLabelByStatus } from 'components/OrderStatus'
import { getOrderLabelByStatus as getOrderLabelByReturnStatus } from 'components/ReturnOrderStatus'
import MultiSelectMenu from 'components/MultiSelectMenu'

const statusesOptions = Object.values(ORDER_STATUSES).map((status) => ({
  label: getOrderLabelByStatus(status),
  value: status,
}))

const returnStatusesOptions = Object.values(RETURN_ORDER_STATUSES).map(
  (status) => ({
    label: getOrderLabelByReturnStatus(status),
    value: status,
  })
)

const initialSelectedStatuses = []
const initialSelectedReturnStatuses = []
const initialSellerId = ''
const initialDatetime = ''

const createBody = ({ statuses, returnStatuses, sellerId, from, to }) => {
  const body = {}
  if (statuses.length) {
    body.statuses = statuses
  }
  if (returnStatuses.length) {
    body.returnStatuses = returnStatuses
  }
  if (sellerId !== initialSellerId) {
    body.sellerId = sellerId
  }
  if (from !== initialDatetime) {
    body.from = new Date(from).toISOString()
  }
  if (to !== initialDatetime) {
    body.to = new Date(to).toISOString()
  }
  return body
}

const ExportTransactionCsvDialogOpen = ({ isShown, onCloseComplete }) => {
  const [selectedStatuses, setSelectedStatuses] = useState(
    initialSelectedStatuses
  )
  const [selectedReturnStatuses, setSelectedReturnStatuses] = useState(
    initialSelectedReturnStatuses
  )
  const [selectedSellerId, setSelectedSellerId] = useState(initialSellerId)
  const [from, setFrom] = useState(initialDatetime)
  const [to, setTo] = useState(initialDatetime)
  const [isExportFetching, setIsExportFetching] = useState(false)
  const [sellersQuery, executeSellersQuery] = useQuery({
    query: `
      query {
        sellers {
          id
          companyName
        }
      }
    `,
  })
  useEffect(() => {
    executeSellersQuery({ requestPolicy: 'network-only' })
  }, [executeSellersQuery])
  return (
    <Dialog
      isShown={isShown}
      title="Filter and export transactions to csv"
      onCloseComplete={() => {
        setSelectedStatuses(initialSelectedStatuses)
        setSelectedReturnStatuses(initialSelectedReturnStatuses)
        setSelectedSellerId(initialSellerId)
        setFrom(initialDatetime)
        setTo(initialDatetime)
        onCloseComplete()
      }}
      onConfirm={async (close) => {
        setIsExportFetching(true)
        try {
          await requestFileDownload({
            url: `${process.env.REACT_APP_API_URL}/exports/transactions`,
            accessToken: getAccessToken(),
            filename: `orders${Date.now()}.csv`,
            body: createBody({
              statuses: selectedStatuses,
              returnStatuses: selectedReturnStatuses,
              sellerId: selectedSellerId,
              from,
              to,
            }),
          })
          close()
        } catch (e) {
          // do nothing
        } finally {
          setIsExportFetching(false)
        }
      }}
      isConfirmLoading={isExportFetching}
      confirmLabel={isExportFetching ? 'Exporting...' : 'Export'}
    >
      <Pane>
        <Picker.DateTime
          onChangeDate={(date) => setFrom(date)}
          date={from}
          label="From Local Datetime"
        />
        <Picker.DateTime
          onChangeDate={(date) => setTo(date)}
          date={to}
          label="To Local Datetime"
        />
        <SelectField
          label="By Seller"
          onChange={(e) => setSelectedSellerId(e.target.value)}
          value={selectedSellerId}
        >
          <option value={initialSellerId}>All sellers</option>
          {!sellersQuery.fetching &&
            !sellersQuery.error &&
            sellersQuery.data.sellers.map(({ id, companyName }) => (
              <option value={id} key={id}>
                {companyName}
              </option>
            ))}
        </SelectField>
        <FormField label="By Order Statuses">
          <MultiSelectMenu
            title="Statuses"
            placeholder="Click to select multiple statuses"
            options={statusesOptions}
            selectedValues={selectedStatuses}
            onChange={setSelectedStatuses}
          />
        </FormField>
        <FormField label="By Return Order Statuses">
          <MultiSelectMenu
            title="Statuses"
            placeholder="Click to select multiple statuses"
            options={returnStatusesOptions}
            selectedValues={selectedReturnStatuses}
            onChange={setSelectedReturnStatuses}
          />
        </FormField>
      </Pane>
    </Dialog>
  )
}

ExportTransactionCsvDialogOpen.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onCloseComplete: PropTypes.func.isRequired,
}

export default ExportTransactionCsvDialogOpen
