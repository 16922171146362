import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import format from 'date-fns/format'
import addMonth from 'date-fns/add_months'
import endOfMonth from 'date-fns/end_of_month'
import styled from 'styled-components/macro'
import {
  Table,
  Spinner,
  Button,
  Pane,
  Dialog,
  TextInputField,
  Heading,
  majorScale,
} from 'evergreen-ui'
import find from 'lodash.find'
import { withUser } from 'UserProvider'
import ACTIONS from 'constants/actions'
import SideNavPage from 'sharedComponents/SideNavPage'
import urql from 'utils/urql'
import Pagination from 'components/Pagination'
import Dropzone from 'components/Dropzone'
import uploadRequest, { getIsFileSizeExceeded } from 'utils/uploadRequest'
import Picker from 'components/Datepicker'
import ROUTES from 'constants/routes'

const Image = styled.img`
  width: 100%;
  height: auto;
  padding: ${majorScale(1)}px;
`

const PreviewImage = styled.div`
  width: 200px;
  height: 90%;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

const TABLE_MIN_HEIGHT = 500

const themesOfTheMonthFields = `
  id
  title
  createdAtUtc
  startAtUtc
  updatedAtUtc
  mainImageUrl
  translations {
    languageIsoCode
    value
  }
`

const defaultFile = { objectUrl: '', name: '' }

const ThemesList = ({ user, history }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [image, setImage] = useState(defaultFile)
  const [activeMonth, setActiveMonth] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [translations, setTranslations] = useState([])
  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })
  const [themesOfTheMonthQuery, executeThemesListQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query themesOfTheMonth($page: Int!){
        themesOfTheMonths(pagination: { page: $page }) {
          data {
            ${themesOfTheMonthFields}
          }
          paginationInfo {
            totalCount
            firstPage
            currentPage
            nextPage
            previousPage
            lastPage
          }
        }
      }
    `,
    variables: {
      page: currentPage,
    },
  })

  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: Int!) {
      deleteThemeOfTheMonth(id: $id) {
        id
      }
    }
  `)

  const updateTranslationValue = (languageIsoCode, value) => {
    setTranslations([
      ...translations.filter(
        (translation) => translation.languageIsoCode !== languageIsoCode
      ),
      { languageIsoCode, value },
    ])
  }
  const getTranslationValue = (languageIsoCode) => {
    const translation = find(translations, {
      languageIsoCode,
    })
    return translation ? translation.value : ''
  }

  useEffect(() => {
    const error = themesOfTheMonthQuery.error || deleteMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [themesOfTheMonthQuery.error, deleteMutation.error])
  const paginationInfo =
    themesOfTheMonthQuery.data &&
    themesOfTheMonthQuery.data.themesOfTheMonths.paginationInfo
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Themes of the month</SideNavPage.Title>
        {user.hasAction(ACTIONS.THEME_OF_THE_MONTH.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setId('')
              setTitle('')
              setActiveMonth('')
              setImage(defaultFile)
              setTranslations([])
              setIsCreateModalOpen(true)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
              Id
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={140} flexShrink={0} flexGrow={0}>
              Active in month
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={140} flexShrink={0} flexGrow={0}>
              Image
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={210} flexShrink={0} flexGrow={0}>
              Actions
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body minHeight={TABLE_MIN_HEIGHT}>
            {themesOfTheMonthQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                delay={200}
                size={80}
              />
            )}
            {themesOfTheMonthQuery.data &&
              themesOfTheMonthQuery.data.themesOfTheMonths.data.map(
                (themeOfTheMonth) => (
                  <Table.Row
                    key={themeOfTheMonth.id}
                    height="100px"
                    isSelectable
                    onSelect={() =>
                      history.push(
                        `/${ROUTES.THEME_OF_THE_MONTH}/${themeOfTheMonth.id}`
                      )
                    }
                  >
                    <Table.TextCell flexBasis={50} flexShrink={0} flexGrow={0}>
                      {themeOfTheMonth.id}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={140} flexShrink={0} flexGrow={0}>
                      {format(themeOfTheMonth.startAtUtc, 'MMMM YYYY')}
                    </Table.TextCell>
                    <Table.Cell flexBasis={140} flexShrink={0} flexGrow={0}>
                      <PreviewImage src={themeOfTheMonth.mainImageUrl} />
                    </Table.Cell>
                    <Table.TextCell>{themeOfTheMonth.title}</Table.TextCell>
                    <Table.Cell flexBasis={210} flexShrink={0} flexGrow={0}>
                      {user.hasAction(ACTIONS.THEME_OF_THE_MONTH.UPDATE) && (
                        <Button
                          marginRight={12}
                          iconBefore="edit"
                          onClick={(e) => {
                            e.stopPropagation()
                            setId(themeOfTheMonth.id)
                            setImage({
                              objectUrl: themeOfTheMonth.mainImageUrl,
                            })
                            setActiveMonth(themeOfTheMonth.startAtUtc)
                            setTitle(themeOfTheMonth.title)
                            setTranslations(
                              themeOfTheMonth.translations.map(
                                ({ languageIsoCode, value }) => ({
                                  languageIsoCode,
                                  value,
                                })
                              )
                            )
                            setIsUpdateModalOpen(true)
                          }}
                        >
                          Edit
                        </Button>
                      )}
                      {user.hasAction(ACTIONS.THEME_OF_THE_MONTH.DELETE) && (
                        <Button
                          marginRight={12}
                          iconBefore="trash"
                          intent="danger"
                          onClick={(e) => {
                            e.stopPropagation()
                            setId(themeOfTheMonth.id)
                            setTitle(themeOfTheMonth.title)
                            setIsConfirmDeleteModalOpen(true)
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
          </Table.Body>
        </Table>
        {paginationInfo && (
          <Pagination
            {...paginationInfo}
            onPageChange={(page) => {
              setCurrentPage(page)
            }}
          />
        )}
      </SideNavPage.Content>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete theme of the month"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id,
          })
          if (!error) {
            setId('')
            setTitle('')
            setActiveMonth('')
            setImage(defaultFile)
            setTranslations([])
            close()
          }
        }}
      >
        Are you sure you want to delete theme of the month: {title} ??
      </Dialog>
      <Dialog
        isShown={isCreateModalOpen || isUpdateModalOpen}
        title={
          isCreateModalOpen
            ? 'Create theme of the month'
            : `Edit theme of the month: ${id}`
        }
        isConfirmLoading={isFetching}
        hasCancel={false}
        confirmLabel={isCreateModalOpen ? 'Create' : 'Edit'}
        onCloseComplete={() => {
          setIsCreateModalOpen(false)
          setIsUpdateModalOpen(false)
        }}
        onConfirm={async (close) => {
          const input = {
            title,
            mainImageFile: image.file,
            startAtUtc: format(activeMonth, 'YYYY-MM-DD'),
            translations: translations.filter(({ value }) => value !== ''),
          }
          if (isCreateModalOpen) {
            setIsFetching(true)
            try {
              await uploadRequest({
                accessToken: user.accessToken,
                body: {
                  query: `mutation($input: CreateThemeOfTheMonthInput!) {
                  createThemeOfTheMonth(input: $input) {
                    ${themesOfTheMonthFields}
                  }
                }`,
                  variables: {
                    input,
                  },
                },
              })
              setId('')
              setTitle('')
              setActiveMonth('')
              setImage(defaultFile)
              close()
              await executeThemesListQuery({
                requestPolicy: 'network-only',
              })
            } catch (error) {
              // do nothing
            } finally {
              setIsFetching(false)
            }
          }
          if (isUpdateModalOpen) {
            setIsFetching(true)
            try {
              await uploadRequest({
                accessToken: user.accessToken,
                body: {
                  query: `mutation($input: UpdateThemeOfTheMonthInput!) {
                  updateThemeOfTheMonth(input: $input) {
                    ${themesOfTheMonthFields}
                  }
                }`,
                  variables: {
                    input: { ...input, id },
                  },
                },
              })
              setId('')
              setTitle('')
              setActiveMonth('')
              setImage(defaultFile)
              close()
              await executeThemesListQuery({
                requestPolicy: 'network-only',
              })
            } catch (error) {
              // do nothing
            } finally {
              setIsFetching(false)
            }
          }
        }}
      >
        {isUpdateModalOpen && (
          <TextInputField
            label="Id"
            disabled
            value={id}
            onChange={(e) => {
              setId(e.target.value)
            }}
          />
        )}
        <TextInputField
          label="Title"
          required
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Image
          </Heading>
          <Dropzone
            onDrop={useCallback((acceptedFiles) => {
              const file = acceptedFiles[0]
              if (getIsFileSizeExceeded({ file })) {
                setImage({
                  file,
                  name: file.name,
                  objectUrl: URL.createObjectURL(file),
                })
              }
            }, [])}
          />
          {image.objectUrl && <Image alt="preview" src={image.objectUrl} />}
        </Pane>
        <Pane marginTop={majorScale(3)}>
          <Picker.MonthPicker
            onChangeDate={setActiveMonth}
            date={activeMonth}
            minDate={endOfMonth(addMonth(new Date(), -1))}
            label="Public in month *"
          />
        </Pane>
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Translations
          </Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Language</Table.TextHeaderCell>
              <Table.TextHeaderCell>Value</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {languagesQuery.data &&
                languagesQuery.data.languages.map((language) => (
                  <Table.Row key={language.isoCode}>
                    <Table.TextCell>{language.title}</Table.TextCell>
                    <Table.Cell>
                      <TextInputField
                        label="Translation"
                        value={getTranslationValue(language.isoCode)}
                        onChange={(e) => {
                          updateTranslationValue(
                            language.isoCode,
                            e.target.value
                          )
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Pane>
      </Dialog>
    </React.Fragment>
  )
}

ThemesList.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(ThemesList)
