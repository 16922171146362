import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Button,
  Dialog,
  Text,
  toaster,
  majorScale,
  Pane,
  Label,
  TextInputField,
} from 'evergreen-ui'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import Dropzone from 'components/Dropzone'
import uploadRequest, { getIsFileSizeExceeded } from 'utils/uploadRequest'
import { useQuery } from 'urql'

const TABLE_MIN_HEIGHT = 500
const FILE_EXTENSION = 'pdf'

let isInitialMount = true

const Documents = ({ user }) => {
  const [editDocumentFileName, setEditDocumentFileName] = useState(null)
  const [isSending, setIsSending] = useState(false)
  const [file, setFile] = useState(null)
  const [documents, executeDocumentQuery] = useQuery({
    query: `
    query Documents {
      documents {
        fileName
        fileUrl
      }
    }
  `,
  })
  useEffect(() => {
    if (!isInitialMount) {
      executeDocumentQuery({ requestPolicy: 'network-only' })
    } else {
      isInitialMount = false
    }
  }, [executeDocumentQuery])
  const onDrop = useCallback((acceptedFiles) => {
    const pdfFile = acceptedFiles[0]
    if (getIsFileSizeExceeded({ file: pdfFile })) {
      setFile({
        file: pdfFile,
        name: pdfFile.name,
        objectUrl: URL.createObjectURL(pdfFile),
      })
    }
  }, [])
  const editedDocument =
    documents &&
    documents.data &&
    documents.data.documents.find(
      ({ fileName }) => fileName === editDocumentFileName
    )
  const fileNameWithExtension = (name) => `${name}.${FILE_EXTENSION}`
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Documents</SideNavPage.Title>
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>File name</Table.TextHeaderCell>
            <Table.TextHeaderCell>File</Table.TextHeaderCell>
            <Table.TextHeaderCell>Action</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body minHeight={TABLE_MIN_HEIGHT}>
            {documents &&
              documents.data &&
              documents.data.documents.map(({ fileName, fileUrl }) => (
                <Table.Row key={fileUrl}>
                  <Table.Cell>
                    <Text ml={majorScale(1)}>
                      {fileNameWithExtension(fileName)}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      is="a"
                      marginRight={12}
                      iconBefore="download"
                      href={fileUrl}
                      target="_blank"
                    >
                      Download
                    </Button>
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      marginRight={12}
                      iconBefore="upload"
                      onClick={() => {
                        setEditDocumentFileName(fileName)
                      }}
                    >
                      Upload
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </SideNavPage.Content>
      <Dialog
        isShown={editDocumentFileName !== null}
        title={`${
          editedDocument ? fileNameWithExtension(editedDocument.fileName) : ''
        }`}
        hasCancel={false}
        shouldCloseOnEscapePress={false}
        confirmLabel="Upload"
        isConfirmDisabled={isSending}
        onCloseComplete={() => {
          setEditDocumentFileName(null)
          setFile(null)
        }}
        onConfirm={async (close) => {
          try {
            setIsSending(true)
            const response = await uploadRequest({
              accessToken: user.accessToken,
              body: {
                query: `
                  mutation UpdateDocument($input: UpdateDocumentInput!) {
                    updateDocument(input: $input)
                  }
                `,
                variables: {
                  input: {
                    documentName: editedDocument.fileName,
                    pdfFile: file,
                  },
                },
              },
            })
            if (response) {
              close()
              toaster.success(
                `Document '${fileNameWithExtension(
                  editedDocument.fileName
                )}' has been updated.`
              )
              executeDocumentQuery()
            }
          } catch (error) {
            // do nothing
          } finally {
            setIsSending(false)
          }
        }}
      >
        <Pane>
          <Label>Upload PDF</Label>
          <Dropzone onDrop={onDrop} accept="application/pdf" />
          <TextInputField label="" value={file ? file.name : ''} disabled />
        </Pane>
      </Dialog>
    </React.Fragment>
  )
}

Documents.propTypes = {
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
  }).isRequired,
}

export default withUser(Documents)
