import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Spinner } from 'evergreen-ui'
import { useQuery } from 'urql'
import Pagination from 'components/Pagination'
import ImagesGrid from '../ImagesGrid'
import { productImagesQueryString } from '../queries'

const SelectImageDialog = ({ onClose, isShown, mainImage, onSuccess }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [productImagesQuery, executeProductImagesQuery] = useQuery({
    query: productImagesQueryString,
    variables: {
      page: currentPage,
    },
  })
  const [selectedImage, setSelectedImage] = useState(mainImage || null)
  const paginationInfo =
    productImagesQuery.data &&
    productImagesQuery.data.productImages.paginationInfo
  const images =
    (productImagesQuery.data && productImagesQuery.data.productImages.data) ||
    []
  return (
    <Dialog
      isShown={isShown}
      title="Select main product image"
      onCloseComplete={onClose}
      confirmLabel="Select"
      onOpenComplete={() => {
        executeProductImagesQuery({ requestPolicy: 'network-only' })
      }}
      onConfirm={async (close) => {
        close()
        onSuccess(selectedImage)
      }}
    >
      {productImagesQuery.fetching && (
        <Spinner marginX="auto" marginY={250} delay={200} size={80} />
      )}
      {images.length && (
        <ImagesGrid>
          {images.map((image) => (
            <ImagesGrid.Item
              key={image.id}
              isSelected={selectedImage && image.id === selectedImage.id}
              alt={image.name}
              src={image.url}
              onClick={() => {
                setSelectedImage(image)
              }}
            />
          ))}
        </ImagesGrid>
      )}
      {paginationInfo && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </Dialog>
  )
}

SelectImageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  mainImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

export default SelectImageDialog
