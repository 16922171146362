import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import {
  Button,
  BackButton,
  Tooltip,
  Icon,
  TextInputField,
  Label,
  Text,
  Table,
  Pane,
  Alert,
  majorScale,
  Switch,
  toaster,
} from 'evergreen-ui'
import { useMutation, useQuery } from 'urql'
import { parseInteger } from 'utils/numbers'
import SideNavPage from 'sharedComponents/SideNavPage'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import urql from 'utils/urql'
import HeroBannerInstruction from './hero_banner_instruction.png'

const PreviewImage = styled.div`
  width: 120px;
  height: 90%;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error, success) => {
  displayToast(error, success)
}

const EditHeroBanner = ({ match, history }) => {
  const id = parseInteger(match.params.id)
  const [title, setTitle] = useState('')
  const [isVisibleForUsers, setIsVisibleForUsers] = useState(false)

  const [, executeDeleteMutation] = useMutation(`
  mutation($id: Int!) {
    deleteHeroBannerSlide(id: $id)
  }
`)

  const [, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateHeroBannerInput!) {
      updateHeroBanner(input: $input) {
        id
      }
    }
  `)

  const [userHeroBannerQuery, executeUserHeroBannerQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query($id: Int!) {
        userHeroBanner(id: $id) {
          id
          isVisibleForUsers
          internalTitle
          heroBannersSlides {
            title
            subtitle
            id
            bannerType
            imageUrl
          }
        }
      }
    `,
    variables: {
      id,
    },
  })

  const heroBanner = userHeroBannerQuery.data
    ? userHeroBannerQuery.data.userHeroBanner
    : null

  useEffect(() => {
    if (heroBanner) {
      setTitle(heroBanner.internalTitle)
      setIsVisibleForUsers(heroBanner.isVisibleForUsers)
    }
  }, [heroBanner])

  const deleteSlide = (slideId) => async () => {
    const { error } = await executeDeleteMutation({ id: slideId })
    executeUserHeroBannerQuery()
    displayToastOnSave(error, 'Deleted')
  }

  const update = async () => {
    const { error } = await executeUpdateMutation({
      input: {
        id,
        internalTitle: title,
        isVisibleForUsers,
      },
    })
    executeUserHeroBannerQuery()
    displayToastOnSave(error, 'Saved')
  }
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Edit Hero Banner #{id}</SideNavPage.Title>
        <BackButton onClick={() => history.goBack()} />
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Pane maxWidth={900}>
          {heroBanner && !heroBanner.isVisibleForUsers && (
            <Alert
              intent="danger"
              title="NOT VISIBLE FOR USERS"
              marginBottom={20}
              style={{ width: '100%' }}
            />
          )}

          <FullScreenLoading isVisible={userHeroBannerQuery.fetching} />
          <Label marginBottom={majorScale(1)} display="block">
            Slides
            <Tooltip content="Every banner need to have 3 slides: 1 big one, 2 small ones">
              <Icon marginLeft={majorScale(1)} icon="info-sign" color="info" />
            </Tooltip>
          </Label>

          <Button
            onClick={() => history.push(`/hero-banners/${id}/slides/new`)}
          >
            Add Slide
          </Button>
          <Pane maxWidth={1000}>
            <Table border marginTop={majorScale(1)}>
              <Table.Head>
                <Table.TextHeaderCell>Title</Table.TextHeaderCell>
                <Table.TextHeaderCell>Subtitle</Table.TextHeaderCell>
                <Table.TextHeaderCell>Banner Type</Table.TextHeaderCell>
                <Table.TextHeaderCell align="center">
                  Image
                </Table.TextHeaderCell>
                <Table.TextHeaderCell align="center">
                  Actions
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {heroBanner &&
                  heroBanner.heroBannersSlides.map((slide) => (
                    <Table.Row>
                      <Table.TextCell>{slide.title}</Table.TextCell>
                      <Table.TextCell>
                        <Text>{slide.subtitle}</Text>
                      </Table.TextCell>
                      <Table.TextCell>{slide.bannerType}</Table.TextCell>
                      <Table.Cell justifyContent="center">
                        {slide.imageUrl && (
                          <PreviewImage src={slide.imageUrl} />
                        )}
                      </Table.Cell>
                      <Table.Cell justifyContent="center">
                        <Button
                          iconBefore="edit"
                          marginRight={10}
                          onClick={() => {
                            history.push(
                              `/hero-banners/${id}/slides/${slide.id}/edit`
                            )
                          }}
                        >
                          Edit
                        </Button>
                        <Button intent="danger" onClick={deleteSlide(slide.id)}>
                          Delete
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Pane>

          <Pane marginTop={50}>
            <TextInputField
              label="Internal Title"
              required
              disabled={false}
              value={title || ''}
              onChange={({ target }) => {
                setTitle(target.value === '' ? undefined : target.value)
              }}
            />
            <Pane marginTop={majorScale(3)} marginBottom={30}>
              <Label>
                Is Visible for users:{' '}
                <small>
                  (Banner can be visible for users if banner has 1 big image and
                  2 smaller.)
                </small>
              </Label>
              <Switch
                checked={isVisibleForUsers}
                onChange={(e) => {
                  setIsVisibleForUsers(e.target.checked)
                }}
              />
            </Pane>
          </Pane>

          <Button appearance="primary" intent="success" onClick={update}>
            Save all changes
          </Button>
        </Pane>
        <Pane marginTop={50}>
          <hr />
          <p>Instruction with example</p>
          <p> Points marked as 1. - are connected with Big banner</p>
          <p>
            Points marked as 2. - are connected with small banner - you need to
            have 2 small banners
          </p>
          <p>
            Please remember to check Is Visible for users checkbox when your
            hero banner is ready
          </p>
          <p>
            Please remember about text length - banners will not scale up if you
            add to much text
          </p>
          <img
            src={HeroBannerInstruction}
            alt="Hero Banner instruction"
            style={{ maxWidth: 700 }}
          />
        </Pane>
      </SideNavPage.Content>
    </React.Fragment>
  )
}

EditHeroBanner.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default EditHeroBanner
