import React from 'react'
import PropTypes from 'prop-types'
import { Table, Pane, Heading, majorScale, Badge, Icon } from 'evergreen-ui'
import format from 'date-fns/format'
import isAfter from 'date-fns/is_after'
import { formatPrice } from 'utils/money'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import { TIME_FORMAT } from 'constants/constants'
import { compose } from 'recompose'
import ACTIONS from 'constants/actions'
import { getPathToWishOffer } from '../../../utils'

const WISH_PASS_REASON_LABELS = {
  NO_LONGER_WANT_THE_ITEM: 'No longer want the item',
  GOT_ITEM_FROM_SOMEWHERE_ELSE: 'Got item from somewhere else',
  OTHER_REASON: 'Other reason',
}

const WishOffers = ({ user, history, offers, wishId, lastSeenAtUtc }) => (
  <Pane marginTop={majorScale(3)}>
    <Heading is="h3" marginBottom={majorScale(2)}>
      Wish Offers
    </Heading>
    <Table border>
      <Table.Head>
        <Table.TextHeaderCell>ID</Table.TextHeaderCell>
        <Table.TextHeaderCell>Title</Table.TextHeaderCell>
        <Table.TextHeaderCell textAlign="right">Price</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Expires at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Passed at</Table.TextHeaderCell>
        <Table.TextHeaderCell>Pass Reason</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {offers.map(
          ({
            id,
            product,
            price,
            createdAtUtc,
            passedAtUtc,
            expiresAtUtc,
            passReason,
            customReasonAnswer,
          }) => {
            const isOfferExpired =
              !passedAtUtc && isAfter(new Date(), expiresAtUtc)
            const isOfferUnseen =
              (passedAtUtc && isAfter(passedAtUtc, lastSeenAtUtc)) ||
              (isOfferExpired && isAfter(expiresAtUtc, lastSeenAtUtc))
            return (
              <Table.Row
                key={id}
                isSelectable={user.hasAction(ACTIONS.WISH_OFFER.UPDATE)}
                onSelect={() =>
                  history.push(getPathToWishOffer({ wishId, offerId: id }))
                }
                height="auto"
                minHeight="45px"
              >
                <Table.TextCell>
                  {id}
                  {isOfferUnseen && (
                    <Icon
                      marginLeft={majorScale(1)}
                      icon="symbol-circle"
                      color="purple"
                    />
                  )}
                </Table.TextCell>
                <Table.TextCell>{product.title}</Table.TextCell>
                <Table.TextCell textAlign="right">
                  {formatPrice({ value: price })}
                </Table.TextCell>
                <Table.TextCell>
                  {format(createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                </Table.TextCell>
                <Table.TextCell>
                  {format(expiresAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                </Table.TextCell>
                <Table.TextCell>
                  {passedAtUtc &&
                    format(passedAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                </Table.TextCell>
                <Table.TextCell height="auto">
                  {isOfferExpired ? (
                    <Badge color="purple" isSolid>
                      Offer Expired
                    </Badge>
                  ) : (
                    <span style={{ whiteSpace: 'normal' }}>
                      {customReasonAnswer ||
                        WISH_PASS_REASON_LABELS[passReason]}
                    </span>
                  )}
                </Table.TextCell>
              </Table.Row>
            )
          }
        )}
      </Table.Body>
    </Table>
  </Pane>
)

WishOffers.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      product: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      price: PropTypes.number.isRequired,
      createdAtUtc: PropTypes.string.isRequired,
      passedAtUtc: PropTypes.string,
      expiresAtUtc: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  wishId: PropTypes.number.isRequired,
  lastSeenAtUtc: PropTypes.string.isRequired,
}

export default compose(withUser, withRouter)(WishOffers)
