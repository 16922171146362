import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { Pane, Table, Spinner } from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import urql from 'utils/urql'

const TABLE_MIN_HEIGHT = 500

const personalisationsStatisticsFields = `{
    id
    name
    totalPersonalisations
    brands {
      id
      name
      totalPersonalisations
    }
}`

const Statistics = () => {
  const [productCategoriesQuery] = useQuery({
    query: `
      query {
        personalisationsStatistics ${personalisationsStatisticsFields}
      }
    `,
  })

  useEffect(() => {
    const { error } = productCategoriesQuery
    if (error) {
      urql.handleError(error)
    }
  }, [productCategoriesQuery, productCategoriesQuery.error])

  return (
    <Pane>
      <Table border style={{ marginTop: '10px' }}>
        <Table.Head>
          <Table.TextHeaderCell>Statistics</Table.TextHeaderCell>
          <Table.TextHeaderCell />
        </Table.Head>
        <Table.Body minHeight={TABLE_MIN_HEIGHT}>
          {productCategoriesQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {productCategoriesQuery.data &&
            productCategoriesQuery.data.personalisationsStatistics.map(
              (item) => (
                <Table.Row key={item.id}>
                  <Table.TextCell>
                    {item.name} - {item.totalPersonalisations} people like it
                  </Table.TextCell>
                  <Table.TextCell>
                    {item.brands &&
                      item.brands.map((brand) => (
                        <li>
                          {brand.name} - {brand.totalPersonalisations} people
                          like it
                        </li>
                      ))}
                  </Table.TextCell>
                </Table.Row>
              )
            )}
        </Table.Body>
      </Table>
    </Pane>
  )
}

Statistics.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withRouter, withUser)(Statistics)
