import React from 'react'
import {
  Dialog,
  TextInputField,
  Textarea,
  Pane,
  Label,
  Switch,
  toaster,
  SelectField,
} from 'evergreen-ui'
import urql from 'utils/urql'
import format from 'date-fns/format'
import { TIME_FORMAT } from 'constants/constants'
import { parseInteger } from 'utils/numbers'
import Picker from 'components/Datepicker'
import uploadRequest from 'utils/uploadRequest'

import CountrySelect from 'sharedComponents/CountrySelect'
import { majorScale } from 'evergreen-ui/commonjs/scales'

// Editor
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import EditorDeleteCustomOption from 'components/EditorDeleteCustomOption'
import { convertEditorStateToRawJson } from 'utils/convertToRaw'

const VAT_GROUPS = ['VAT_0', 'VAT_1', 'VAT_2']

const createMutation = `
mutation($input: CreateSellerInput!) {
  createSeller(input: $input) {
    id
  }
}
`

const updateMutation = `
mutation($input: UpdateSellerInput!) {
  updateSeller(input: $input) {
    id
    gbcPdfUrl
  }
}
`

const AddEditDialog = (props) => {
  const {
    countryIsoCode,
    vatGroup,
    accountName,
    companyName,
    marketplacePercentageFee,
    registeredAddress,
    zipCode,
    companyRegistrationNumber,
    dateOfRegistration,
    phoneNumber,
    fax,
    email,
    web,
    warehouseAddress,
    bankName,
    iban,
    swiftCode,
    ordersSyncApiKey,
    responsiblePersonFirstName,
    responsiblePersonLastName,
    responsiblePersonPhoneNumber,
    responsiblePersonEmail,
    gbcFile,
    isActive,
    warehouseDeliveryTimeInDays,
    isSending,
    isUpdateUserModalOpen,
    isCreateUserModalOpen,
    setIsCreateUserModalOpen,
    setIsUpdateUserModalOpen,
    setIsSending,
    user,
    id,
    executeSellersQuery,
    setCountryIsoCode,
    setVatGroup,
    setAccountName,
    setCompanyName,
    setMarketplacePercentageFee,
    setRegisteredAddress,
    setZipCode,
    setCompanyRegistrationNumber,
    setDateOfRegistration,
    setPhoneNumber,
    setFax,
    setEmail,
    setWeb,
    setWarehouseAddress,
    setBankName,
    setIban,
    setSwiftCode,
    setResponsiblePersonFirstName,
    setResponsiblePersonLastName,
    setResponsiblePersonPhoneNumber,
    setResponsiblePersonEmail,
    setOrdersSyncApiKey,
    setIsActive,
    setWarehouseDeliveryTimeInDays,
    editorState,
    setEditorState,
  } = props
  return (
    <Dialog
      isShown={isCreateUserModalOpen || isUpdateUserModalOpen}
      title={isCreateUserModalOpen ? 'Create seller' : 'Update seller'}
      hasCancel={false}
      isConfirmDisabled={isSending}
      confirmLabel={isCreateUserModalOpen ? 'Create' : 'Update'}
      isConfirmLoading={createMutation.fetching || updateMutation.fetching}
      onCloseComplete={() => {
        if (isCreateUserModalOpen) {
          setIsCreateUserModalOpen(false)
        }
        if (isUpdateUserModalOpen) {
          setIsUpdateUserModalOpen(false)
        }
      }}
      onConfirm={async (close) => {
        const input = {
          countryIsoCode,
          vatGroup,
          accountName,
          companyName,
          marketplacePercentageFee,
          registeredAddress,
          zipCode,
          companyRegistrationNumber,
          phoneNumber,
          fax,
          email,
          web,
          warehouseAddress,
          bankName,
          iban,
          swiftCode,
          responsiblePersonFirstName,
          responsiblePersonLastName,
          responsiblePersonPhoneNumber,
          responsiblePersonEmail,
          gbcFile: gbcFile.file,
          ordersSyncApiKey,
          warehouseDeliveryTimeInDays,
          dateOfRegistration: format(
            dateOfRegistration,
            TIME_FORMAT.BACKEND_DATE
          ),
          generalBusinessConditionsText:
            convertEditorStateToRawJson(editorState),
        }

        const errorMessage = []
        const editorContent = editorState.getCurrentContent()
        const isEditorEmpty = !editorContent.hasText()
        if (isEditorEmpty) {
          errorMessage.push('GBC Text is required')
        }
        if (!accountName) {
          errorMessage.push('Account name is required')
        }

        if (!companyName) {
          errorMessage.push('Company name is required')
        }

        if (!vatGroup) {
          errorMessage.push('Vat group is required')
        }

        if (!countryIsoCode) {
          errorMessage.push('Country is required')
        }

        if (!marketplacePercentageFee) {
          errorMessage.push('Marketplace percentage fee is required')
        }

        if (!registeredAddress) {
          errorMessage.push('Registered address is required')
        }

        if (!zipCode) {
          errorMessage.push('Zip code is required')
        }

        if (!companyRegistrationNumber) {
          errorMessage.push('Company registration number is required')
        }

        if (!email) {
          errorMessage.push('Email is required')
        }

        if (!web) {
          errorMessage.push('Web is required')
        }

        if (!warehouseAddress) {
          errorMessage.push('Warehouse address is required')
        }

        if (!bankName) {
          errorMessage.push('Bank name is required')
        }

        if (!iban) {
          errorMessage.push('Iban is required')
        }

        if (!swiftCode) {
          errorMessage.push('Swift code is required')
        }

        if (!responsiblePersonFirstName) {
          errorMessage.push('Shareholder first name is required')
        }

        if (!responsiblePersonLastName) {
          errorMessage.push('Shareholder last name is required')
        }

        if (!responsiblePersonPhoneNumber) {
          errorMessage.push('Shareholder phone number is required')
        }

        if (!responsiblePersonEmail) {
          errorMessage.push('Shareholder email is required')
        }

        if (!gbcFile) {
          errorMessage.push('GBC file is required')
        }

        if (!warehouseDeliveryTimeInDays) {
          errorMessage.push('Warehouse delivery time is required')
        }

        if (errorMessage.length) {
          urql.handleError({
            networkError: {
              message: errorMessage,
            },
          })
        }

        if (!errorMessage.length) {
          setIsSending(true)
          try {
            if (isCreateUserModalOpen) {
              const response = await uploadRequest({
                accessToken: user.accessToken,
                body: {
                  query: createMutation,
                  variables: {
                    input: {
                      ...input,
                    },
                  },
                },
              })
              if (response) {
                close()
                toaster.success(`Seller - ${companyName} has been created.`)
              }
            }
            if (isUpdateUserModalOpen) {
              const response = await uploadRequest({
                accessToken: user.accessToken,
                body: {
                  query: updateMutation,
                  variables: {
                    input: {
                      id: Number.parseInt(id, 10),
                      isActive,
                      ...input,
                    },
                  },
                },
              })
              if (response) {
                close()
                toaster.success(`Seller - ${companyName} has been updated.`)
              }
            }
          } catch (error) {
            if (error) {
              alert(JSON.stringify(error))
            }
          } finally {
            setIsSending(false)
            executeSellersQuery({ requestPolicy: 'network-only' })
          }
        }
      }}
    >
      <TextInputField label="Account Id" value={id} disabled />
      <TextInputField
        label="Account name"
        value={accountName}
        required
        name="accountName"
        onChange={(e) => {
          setAccountName(e.target.value)
        }}
      />
      <TextInputField
        label="Company name"
        value={companyName}
        required
        onChange={(e) => {
          setCompanyName(e.target.value)
        }}
      />
      <SelectField
        label="VAT group"
        value={vatGroup}
        required
        onChange={(e) => {
          setVatGroup(e.target.value)
        }}
      >
        <option key="-" value="">
          ---Select VAT group---
        </option>
        {VAT_GROUPS.map((VAT_GROUP) => (
          <option key={VAT_GROUP} value={VAT_GROUP}>
            {VAT_GROUP}
          </option>
        ))}
      </SelectField>
      <CountrySelect
        value={countryIsoCode}
        handleChange={setCountryIsoCode}
        hasDeselectOption
        isRequired
      />
      <TextInputField
        type="number"
        step={0.1}
        label="Marketplace fee (%)"
        value={marketplacePercentageFee}
        required
        onChange={(e) => {
          setMarketplacePercentageFee(Number.parseFloat(e.target.value))
        }}
      />
      <Pane>
        <Label>Registered address *</Label>
        <Textarea
          value={registeredAddress}
          required
          onChange={(e) => {
            setRegisteredAddress(e.target.value)
          }}
        />
      </Pane>
      <TextInputField
        label="ZipCode"
        value={zipCode}
        required
        onChange={(e) => {
          setZipCode(e.target.value)
        }}
      />
      <TextInputField
        label="Reg. Number"
        value={companyRegistrationNumber}
        required
        onChange={(e) => {
          setCompanyRegistrationNumber(e.target.value)
        }}
      />
      <Picker.Date
        onChangeDate={setDateOfRegistration}
        date={dateOfRegistration}
        label="Date of registration"
      />
      <TextInputField
        label="Phone Number"
        value={phoneNumber}
        onChange={(e) => {
          setPhoneNumber(e.target.value)
        }}
      />
      <TextInputField
        label="Fax"
        value={fax}
        required
        onChange={(e) => {
          setFax(e.target.value)
        }}
      />
      <TextInputField
        label="Email"
        value={email}
        required
        onChange={(e) => {
          setEmail(e.target.value)
        }}
      />
      <TextInputField
        label="Web"
        value={web}
        required
        onChange={(e) => {
          setWeb(e.target.value)
        }}
      />
      <Pane>
        <Label>Warehouse Address *</Label>
        <Textarea
          value={warehouseAddress}
          required
          onChange={(e) => {
            setWarehouseAddress(e.target.value)
          }}
        />
      </Pane>
      <TextInputField
        label="Bank Name"
        value={bankName}
        required
        onChange={(e) => {
          setBankName(e.target.value)
        }}
      />
      <TextInputField
        label="Iban"
        value={iban}
        required
        onChange={(e) => {
          setIban(e.target.value)
        }}
      />
      <TextInputField
        label="Swift"
        value={swiftCode}
        required
        onChange={(e) => {
          setSwiftCode(e.target.value)
        }}
      />
      <TextInputField
        label="Shareholder First Name"
        value={responsiblePersonFirstName}
        required
        onChange={(e) => {
          setResponsiblePersonFirstName(e.target.value)
        }}
      />
      <TextInputField
        label="Shareholder Last Name"
        value={responsiblePersonLastName}
        required
        onChange={(e) => {
          setResponsiblePersonLastName(e.target.value)
        }}
      />
      <TextInputField
        label="Shareholder Phone Number"
        value={responsiblePersonPhoneNumber}
        required
        onChange={(e) => {
          setResponsiblePersonPhoneNumber(e.target.value)
        }}
      />
      <TextInputField
        label="Shareholder Email(will receive onboarding emails)"
        value={responsiblePersonEmail}
        required
        onChange={(e) => {
          setResponsiblePersonEmail(e.target.value)
        }}
      />
      <Pane marginBottom={20}>
        <p>GBC Text (required)</p>
        <Editor
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
          }}
          editorState={editorState}
          editorStyle={{
            border: '1px solid #e2e2e2',
            height: 300,
          }}
          onEditorStateChange={setEditorState}
          toolbarCustomButtons={[<EditorDeleteCustomOption />]}
        />
      </Pane>
      {!isCreateUserModalOpen && (
        <Pane marginTop={majorScale(3)}>
          <Label>
            Is active{' '}
            <small>
              (You can set seller as active, if there is added one or more
              return address.)
            </small>
          </Label>
          <Switch
            checked={isActive}
            onChange={(e) => {
              setIsActive(e.target.checked)
            }}
          />
        </Pane>
      )}
      <TextInputField
        label="Order sync API KEY"
        value={ordersSyncApiKey}
        onChange={(e) => {
          setOrdersSyncApiKey(e.target.value)
        }}
      />
      <TextInputField
        label="Warehouse Delivery Time In Days"
        type="number"
        value={warehouseDeliveryTimeInDays}
        required
        onChange={(e) => {
          setWarehouseDeliveryTimeInDays(parseInteger(e.target.value))
        }}
      />
    </Dialog>
  )
}

export default AddEditDialog
