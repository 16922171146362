import { convertToRaw } from 'draft-js'

export const convertEditorStateToRawJson = (state) => {
  if (typeof state === 'string') {
    return state
  }
  const raw = convertToRaw(state.getCurrentContent())
  const result = raw.blocks.reduce(
    (prev, cur) =>
      prev !== false &&
      Object.keys(cur.data || {}).length === 0 &&
      cur.depth === 0 &&
      cur.entityRanges.length === 0 &&
      cur.inlineStyleRanges.length === 0
        ? `${prev}${prev ? '\n' : ''}${cur.text}`
        : false,
    ''
  )
  return JSON.stringify(raw) || result
}
