import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { NavLink as ReactRouterNavLink, withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Text, withTheme, Icon, Pane } from 'evergreen-ui'

const activeClassName = 'active'

const StyledNavLink = styled(ReactRouterNavLink).attrs({
  activeClassName,
})`
  text-decoration: none;
  padding: 0.7em 2em;
  padding-left: 1em;
  border-left: 5px solid transparent;
  display: grid;
  align-items: center;
  &.${activeClassName}, &:hover {
    border-left: 5px solid ${({ theme }) => theme.palette.blue.base};
    background-color: ${({ theme }) => theme.palette.blue.light};
  }
`

const NavLink = ({
  label,
  children,
  iconName,
  to,
  location: { pathname },
  ...rest
}) => (
  <StyledNavLink {...rest} to={to}>
    <Pane display="flex" alignItems="center">
      {iconName && (
        <Icon
          icon={iconName}
          color={`/${pathname.split('/')[1]}` === to ? 'selected' : 'muted'}
          marginRight={10}
          size={15}
        />
      )}
      {label ? <Text size={500}>{label}</Text> : children}
    </Pane>
  </StyledNavLink>
)
NavLink.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  iconName: PropTypes.string,
  to: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(withTheme, withRouter)(NavLink)
