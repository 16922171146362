import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, RadioGroup, TextareaField, TextInputField } from 'evergreen-ui'
import { useMutation } from 'urql'
import urql from 'utils/urql'
import AdditionalReturnImages from 'components/AdditionalReturnImages'

const createInputHandler = (setState) => (e) => setState(e.target.value)

const UpdateReturnDetailDialog = ({
  id,
  close,
  trackingNumberCustomer,
  recipientNameCustomer,
  ibanCustomer,
  reasonReturn,
  descProblem,
  attachments,
}) => {
  const [trackingNumber, setTrackingNumber] = useState(
    trackingNumberCustomer || ''
  )
  const [recipientName, setRecipientName] = useState(
    recipientNameCustomer || ''
  )
  const [iban, setIban] = useState(ibanCustomer || '')

  const [reasonReturnOrder, setReasonReturnOrder] = useState(
    reasonReturn ? reasonReturn.id : ''
  )
  const [descriptionProblem, setDescriptionProblem] = useState(
    descProblem || ''
  )
  const [additionalImages, setAdditionalImages] = useState(attachments || [])

  const [updateReturnOrderMutation, executeUpdateReturnOrderMutation] =
    useMutation(`
    mutation($input: UpdateReturnOrderInput!) {
      updateReturnOrder(input: $input)
    }
  `)

  return (
    <Dialog
      isShown
      isConfirmLoading={updateReturnOrderMutation.fetching}
      title="Update return order detail"
      onCloseComplete={close}
      onConfirm={async (closeFn) => {
        const confirmAttachments = []
        if (additionalImages) {
          additionalImages.forEach((img) => {
            confirmAttachments.push({
              orderId: id,
              returnAttachmentId: img.id,
            })
          })
        }

        const { error } = await executeUpdateReturnOrderMutation({
          input: {
            orderId: id,
            trackingNumber,
            recipientName,
            iban,
            reasonId: reasonReturnOrder,
            describeProblem: descriptionProblem,
            attachments: confirmAttachments,
          },
        })
        if (error) {
          urql.handleError(error)
        } else {
          closeFn()
        }
      }}
    >
      <TextInputField
        onChange={createInputHandler(setTrackingNumber)}
        value={trackingNumber}
        label="Tracking Number Customer"
      />
      <TextInputField
        onChange={createInputHandler(setRecipientName)}
        value={recipientName}
        label="Recipient Name"
      />
      <TextInputField
        onChange={createInputHandler(setIban)}
        value={iban}
        label="IBAN Customer"
      />

      <RadioGroup
        label="Reasons Problem"
        value={reasonReturnOrder.toString()}
        defaultValue="1"
        size={16}
        options={[
          { label: 'I made a purchase by mistake', value: '1' },
          { label: 'The product was damaged during delivery', value: '2' },
          {
            label:
              'The product malfunction or damaged (not as a result of transportation)',
            value: '3',
          },
          {
            label: "The product doesn't correspond to the description",
            value: '4',
          },
          { label: 'Other', value: '5' },
        ]}
        required
        onChange={(value) => {
          setReasonReturnOrder(value)
        }}
      />
      <TextareaField
        onChange={createInputHandler(setDescriptionProblem)}
        value={descriptionProblem}
        label="Description Problem"
        inputHeight={10}
        required
      />
      <AdditionalReturnImages
        isDisabled={false}
        additionalImages={additionalImages}
        setAdditionalImages={setAdditionalImages}
      />
    </Dialog>
  )
}

UpdateReturnDetailDialog.propTypes = {
  id: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  trackingNumberCustomer: PropTypes.string,
  recipientNameCustomer: PropTypes.string,
  ibanCustomer: PropTypes.string,
  reasonReturn: PropTypes.string,
  descProblem: PropTypes.string,
  attachments: PropTypes.array,
}

export default UpdateReturnDetailDialog
