import { parseInteger } from 'utils/numbers'

export const getFeedbacksQuery = ({ pagination = { page: 1 }, filter }) => ({
  query: `query Feedbacks($pagination: PaginationInput, $filter: FeedbackFilter) {
      feedbacks (pagination: $pagination, filter: $filter) {
        paginationInfo {
          totalCount
          firstPage
          lastPage
          currentPage
          nextPage
          previousPage
        }
        data {
          id
          customer {
            id
            email
          }
          isPositive
          type
          text
          createdAtUtc
          isArchived
        }
      }
    }
  `,
  variables: {
    pagination,
    filter,
  },
  requestPolicy: 'network-only',
})

export const extractDataFromFeedbacksQuery = (feedbacksQuery) => {
  const feedbacks = feedbacksQuery.data
    ? feedbacksQuery.data.feedbacks.data.map((feedback) => ({
        ...feedback,
        id: parseInteger(feedback.id),
      }))
    : []
  const paginationInfo =
    feedbacksQuery.data && feedbacksQuery.data.feedbacks.paginationInfo
  return { feedbacks, paginationInfo }
}

export const moveFeedbackToArchiveMutation = `
  mutation moveFeedbackToArchive($id: Int!) {
    moveFeedbackToArchive(id: $id) {
      id
    }
  }
`
