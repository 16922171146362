import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Pane, toaster } from 'evergreen-ui'
import { compose } from 'recompose'
import { useMutation, useQuery } from 'urql'
import { withRouter } from 'react-router-dom'
import sortBy from 'lodash.sortby'
import { withUser } from 'UserProvider'
import { getSpaceInPx } from 'utils/styled-system'
import { DEAL_STATUSES, SORT_DIRECTION_ENUM } from 'constants/constants'
import ACTIONS from 'constants/actions'
import ROUTES, { MANAGEMENT_ROUTES } from 'constants/routes'
import NoData from 'sharedComponents/NoData'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import ActionsRow from 'components/ActionsRow'
import EditModeButton from 'components/EditModeButton'
import EditableList, {
  rearangeItemsBasedOnNewLayout,
  moveItemInItemsToTheTop,
  getIdsOrderedByPositionIndex,
} from 'components/EditableList'
import {
  extractDataFromDealOfTheWeeksQuery,
  getDealOfTheWeeksQuery,
  updateDealOfTheWeekPublishPositionsMutation,
  pauseDealOfTheWeekMutation,
  resumeDealOfTheWeekMutation,
} from '../queries'
import { getPathToDealOfTheWeekDetail } from '../../../utils'
import DoWItem from '../components/DoWItem'
import DeleteDoWDialog from '../../../components/DeleteDoWDialog'
import EarlyEndDialog from '../../../components/EarlyEndDialog'
import { DOW_ITEM_Y_MARGIN, DOW_ITEM_HEIGHT } from '../constants'
import withApproveDeclineMutations, {
  toastResultOfApproval,
  toastResultOfPublish,
} from '../../../components/withAprroveDeclineMutations'

const { APPROVAL_NEEDED, APPROVED, DECLINED, DRAFT, FINISHED, LIVE } =
  DEAL_STATUSES

const MAX_MOVABLE_DOWS = 10
const PUBLISHED_AT_UTC = 'publishedAtUtc'

const getIsNoData = (query, items) => !query.fetching && items.length === 0

const General = ({
  history,
  user,
  executeApproveDealOfTheWeekMutation,
  showDeclineDealOfTheWeekDialog,
  executePublishDealOfTheWeekMutation,
}) => {
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [earlyEndDeal, setEarlyEndDeal] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [rearrangedDealOfTheWeeks, setRearrangedDealOfTheWeeks] = useState([])
  const [dealOfTheWeeksQuery, executeDealOfTheWeeksQuery] = useQuery(
    getDealOfTheWeeksQuery(
      {
        status: [APPROVED, APPROVAL_NEEDED, DECLINED, DRAFT],
        sorter: {
          field: 'positionIndexWeight',
          direction: SORT_DIRECTION_ENUM.ASC,
        },
      },
      `
        positionIndexWeight
        estimatedStartAtUtc
      `
    )
  )
  const [finishedDealOfTheWeeksQuery] = useQuery(
    getDealOfTheWeeksQuery(
      {
        status: [FINISHED],
        pagination: { page: 1, limit: 3 },
        sorter: {
          field: 'publishedAtUtc',
          direction: SORT_DIRECTION_ENUM.DESC,
        },
      },
      PUBLISHED_AT_UTC
    )
  )
  const [liveDealOfTheWeeksQuery, executeLiveDealOfTheWeeksQuery] = useQuery(
    getDealOfTheWeeksQuery(
      {
        status: [LIVE],
        pagination: { page: 1, limit: 1 },
      },
      `
        ${PUBLISHED_AT_UTC}
        earlyEndUtc
      `
    )
  )
  const [, executeUpdateDealOfTheWeekPublishPositionsMutation] = useMutation(
    updateDealOfTheWeekPublishPositionsMutation
  )
  const [, executePauseDealOfTheWeekMutation] = useMutation(
    pauseDealOfTheWeekMutation
  )
  const [, executeResumeDealOfTheWeekMutation] = useMutation(
    resumeDealOfTheWeekMutation
  )
  const { dealOfTheWeeks: nextDealOfTheWeeks } =
    extractDataFromDealOfTheWeeksQuery(dealOfTheWeeksQuery)
  const finishedDealOfTheWeeks = finishedDealOfTheWeeksQuery.data
    ? finishedDealOfTheWeeksQuery.data.dealOfTheWeeks.data
    : []
  const { dealOfTheWeeks: liveDealOfTheWeeks } =
    extractDataFromDealOfTheWeeksQuery(liveDealOfTheWeeksQuery)
  const movableNextDealOfTheWeeks = nextDealOfTheWeeks.slice(
    0,
    MAX_MOVABLE_DOWS
  )
  const hiddenNextDealOfTheWeeks = nextDealOfTheWeeks.slice(
    MAX_MOVABLE_DOWS,
    nextDealOfTheWeeks.length
  )
  const currentlyDisplayedNextDoW = isEditMode
    ? rearrangedDealOfTheWeeks
    : movableNextDealOfTheWeeks
  const isFetching =
    dealOfTheWeeksQuery.fetching ||
    finishedDealOfTheWeeksQuery.fetching ||
    liveDealOfTheWeeksQuery.fetching
  const liveDealOfTheWeek = liveDealOfTheWeeks[0]
  const isWithEditButton =
    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE) ||
    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE_TRANSLATIONS) ||
    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_TRANSLATIONS) ||
    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_COMPETITOR)
  return (
    <Pane>
      <FullScreenLoading isVisible={isFetching} />
      <ActionsRow>
        {user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE_PUBLISH_POSITION) && (
          <EditModeButton
            isEditMode={isEditMode}
            onClick={async () => {
              if (isEditMode) {
                const { error } =
                  await executeUpdateDealOfTheWeekPublishPositionsMutation({
                    ids: [
                      ...getIdsOrderedByPositionIndex(rearrangedDealOfTheWeeks),
                      ...hiddenNextDealOfTheWeeks.map(({ id }) => id),
                    ],
                  })
                if (error) {
                  toaster.danger('Rearrangement failed.')
                } else {
                  executeDealOfTheWeeksQuery({ requestPolicy: 'network-only' })
                  toaster.success('Rearrangement succeeded.')
                }
              } else {
                setRearrangedDealOfTheWeeks(movableNextDealOfTheWeeks)
              }
              setIsEditMode(!isEditMode)
            }}
          />
        )}
      </ActionsRow>
      {!isFetching &&
        sortBy(finishedDealOfTheWeeks, [PUBLISHED_AT_UTC]).map((item) => (
          <DoWItem
            key={item.id}
            {...item}
            my={getSpaceInPx(DOW_ITEM_Y_MARGIN)}
            onDetailClick={() => {
              history.push(getPathToDealOfTheWeekDetail(item.id))
            }}
            isWithDeleteButton={user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.DELETE)}
            isWithEditButton={isWithEditButton}
          />
        ))}
      {!isFetching && liveDealOfTheWeek && (
        <DoWItem
          {...liveDealOfTheWeek}
          my={getSpaceInPx(DOW_ITEM_Y_MARGIN)}
          onDetailClick={() => {
            history.push(getPathToDealOfTheWeekDetail(liveDealOfTheWeek.id))
          }}
          isWithPauseButton={
            !liveDealOfTheWeek.isPaused &&
            user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.PAUSE)
          }
          onPauseButtonClicked={async () => {
            await executePauseDealOfTheWeekMutation({
              id: liveDealOfTheWeek.id,
            })
          }}
          isWithResumeButton={
            liveDealOfTheWeek.isPaused &&
            user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.RESUME)
          }
          isWithDeleteButton={user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.DELETE)}
          onResumeButtonClicked={async () => {
            await executeResumeDealOfTheWeekMutation({
              id: liveDealOfTheWeek.id,
            })
          }}
          isWithEditButton={isWithEditButton}
          isWithEarlyEndButton={user.hasAction(
            ACTIONS.DEAL_OF_THE_WEEK.UPDATE_END
          )}
          onEarlyEndButtonClicked={() => setEarlyEndDeal(liveDealOfTheWeek)}
        />
      )}
      <>
        <DeleteDoWDialog
          onSuccess={() => {
            executeDealOfTheWeeksQuery({ requestPolicy: 'network-only' })
          }}
          onClose={() => {
            setItemToSoftDelete(null)
          }}
          itemToDelete={itemToSoftDelete}
        />
        {earlyEndDeal && (
          <EarlyEndDialog
            deal={earlyEndDeal}
            onClose={() => {
              setEarlyEndDeal(null)
            }}
          />
        )}
        <EditableList
          items={currentlyDisplayedNextDoW}
          isEditMode={isEditMode}
          onDragStop={(newLayout) => {
            setRearrangedDealOfTheWeeks((currentNewDealOfTheWeeks) =>
              rearangeItemsBasedOnNewLayout({
                newLayout,
                items: currentNewDealOfTheWeeks,
              })
            )
          }}
          margin={[0, DOW_ITEM_Y_MARGIN]}
          rowHeight={DOW_ITEM_HEIGHT}
        >
          {(item) => (
            <div key={item.id}>
              <DoWItem
                {...item}
                isEditMode={isEditMode}
                isToTheTopButtonVisible={user.hasAction(
                  ACTIONS.DEAL_OF_THE_WEEK.UPDATE_PUBLISH_POSITION
                )}
                isWithApproveButton={
                  item.status === APPROVAL_NEEDED &&
                  user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.APPROVE)
                }
                isWithDeclineButton={
                  item.status === APPROVAL_NEEDED &&
                  user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.DECLINE)
                }
                onMoveItemToTopClick={async () => {
                  const { error } =
                    await executeUpdateDealOfTheWeekPublishPositionsMutation({
                      ids: getIdsOrderedByPositionIndex(
                        moveItemInItemsToTheTop({
                          items: nextDealOfTheWeeks,
                          idOfItemToTop: item.id,
                        })
                      ),
                    })
                  if (error) {
                    toaster.danger('Rearrangement failed.')
                  } else {
                    toaster.success('Rearrangement succeeded.')
                    executeDealOfTheWeeksQuery({
                      requestPolicy: 'network-only',
                    })
                  }
                }}
                onDetailClick={() =>
                  history.push(getPathToDealOfTheWeekDetail(item.id))
                }
                onDeleteButtonClicked={() => setItemToSoftDelete(item)}
                onApproveButtonClicked={async () => {
                  const result = await executeApproveDealOfTheWeekMutation({
                    id: item.id,
                  })
                  toastResultOfApproval(result)
                }}
                onDeclineButtonClicked={() => {
                  showDeclineDealOfTheWeekDialog({
                    dealOfTheWeekId: item.id,
                  })
                }}
                isWithDeleteButton={user.hasAction(
                  ACTIONS.DEAL_OF_THE_WEEK.DELETE
                )}
                isWithEditButton={
                  user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE) ||
                  user.hasAction(
                    ACTIONS.DEAL_OF_THE_WEEK.UPDATE_TRANSLATIONS
                  ) ||
                  user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_TRANSLATIONS) ||
                  user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_COMPETITOR)
                }
                isWithPublishButton={
                  !liveDealOfTheWeek &&
                  item.status === DEAL_STATUSES.APPROVED &&
                  user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.PUBLISH)
                }
                onPublishButtonClicked={async () => {
                  const result = await executePublishDealOfTheWeekMutation({
                    id: item.id,
                  })
                  toastResultOfPublish(result)
                  executeLiveDealOfTheWeeksQuery()
                }}
              />
            </div>
          )}
        </EditableList>
      </>
      {getIsNoData(dealOfTheWeeksQuery, nextDealOfTheWeeks) &&
        getIsNoData(finishedDealOfTheWeeksQuery, finishedDealOfTheWeeks) &&
        getIsNoData(liveDealOfTheWeeksQuery, liveDealOfTheWeeks) && (
          <NoData padding={60} />
        )}
      <ActionsRow>
        <Button
          appearance="minimal"
          onClick={() => {
            history.push(
              `/${ROUTES.DEAL_OF_THE_WEEKS}/${MANAGEMENT_ROUTES.NEXT}`
            )
          }}
        >
          Go to the `Next` tab to see all DoWs
        </Button>
      </ActionsRow>
    </Pane>
  )
}

General.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveDealOfTheWeekMutation: PropTypes.func.isRequired,
  showDeclineDealOfTheWeekDialog: PropTypes.func.isRequired,
  executePublishDealOfTheWeekMutation: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  withUser,
  withApproveDeclineMutations
)(General)
