import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Dialog, IconButton, TextInputField } from 'evergreen-ui'
import uploadRequest, { getIsFileSizeExceeded } from 'utils/uploadRequest'
import { withUser } from 'UserProvider'
import Dropzone from 'components/Dropzone'
import { borderRadius } from 'Theme'
import { createReturnAttachmentMutationString } from 'sharedComponents/queries'
import { getArrayWithoutItemOnIndex } from '../../utils/array'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 8px;
  align-items: center;
`
const PreviewImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${borderRadius.SMALL}px;
`

const getNewImagesWithUpdatedName = ({
  newImages,
  name,
  indexOfTheImageToBeRenamed,
}) =>
  newImages.map((item, index) => ({
    ...item,
    name: index === indexOfTheImageToBeRenamed ? name : item.name,
  }))
const newImagesDefaultState = []

const UploadImagesDialog = ({ onClose, isShown, user, onSuccess }) => {
  const [newImages, setNewImages] = useState(newImagesDefaultState)
  const [isCreatingImages, setIsCreatingImages] = useState(false)
  const onDrop = useCallback((acceptedFiles) => {
    setNewImages((currentNewImages) => [
      ...currentNewImages,
      ...acceptedFiles
        .filter((file) => getIsFileSizeExceeded({ file }))
        .map((acceptedFile) => ({
          file: acceptedFile,
          name: acceptedFile.name,
          path: acceptedFile.path,
          objectUrl: URL.createObjectURL(acceptedFile),
        })),
    ])
  }, [])
  return (
    <Dialog
      isShown={isShown}
      title="Upload Attachments"
      onCloseComplete={() => {
        setNewImages(newImagesDefaultState)
        onClose()
      }}
      confirmLabel="Upload"
      isConfirmLoading={isCreatingImages}
      onConfirm={async (close) => {
        try {
          setIsCreatingImages(true)
          const requests = newImages.map((newImage) =>
            uploadRequest({
              accessToken: user.accessToken,
              body: {
                query: createReturnAttachmentMutationString,
                variables: {
                  input: { name: newImage.name, file: newImage.file },
                },
              },
            })
          )
          const results = await Promise.all(requests)
          onSuccess(results.map((result) => result.createReturnAttachment))
          close()
        } catch (e) {
          // do nothing
        } finally {
          setIsCreatingImages(false)
        }
      }}
    >
      <Dropzone onDrop={onDrop} multiple />
      {newImages.length > 0 && (
        <Grid>
          {newImages.map((newImage, index) => (
            <React.Fragment key={newImage.path}>
              <PreviewImage src={newImage.objectUrl} />
              <TextInputField
                label="Name"
                value={newImage.name}
                onChange={(e) => {
                  setNewImages((currentNewImages) =>
                    getNewImagesWithUpdatedName({
                      newImages: currentNewImages,
                      indexOfTheImageToBeRenamed: index,
                      name: e.target.value,
                    })
                  )
                }}
              />
              <IconButton
                icon="trash"
                intent="danger"
                onClick={() => {
                  setNewImages(getArrayWithoutItemOnIndex(newImages, index))
                }}
              />
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Dialog>
  )
}

UploadImagesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
  }).isRequired,
}

export default withUser(UploadImagesDialog)
