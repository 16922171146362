import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import styled from 'styled-components/macro'
import { withUser } from 'UserProvider'
import {
  Table,
  Spinner,
  Button,
  Dialog,
  Label,
  TextInputField,
  Pane,
  Heading,
  majorScale,
} from 'evergreen-ui'
import Pagination from 'components/Pagination'
import find from 'lodash.find'
import urql from 'utils/urql'
import Dropzone from 'components/Dropzone'
import SideNavPage from 'sharedComponents/SideNavPage'
import { parseInteger } from 'utils/numbers'
import ACTIONS from 'constants/actions'
import uploadRequest, { getIsFileSizeExceeded } from 'utils/uploadRequest'

const TABLE_MIN_HEIGHT = 500

let isInitialMount = true

const Image = styled.img`
  width: 100%;
  height: auto;
  padding: ${majorScale(1)}px;
`

const AttributeValues = (props) => {
  const { match, user } = props
  const attributeId = parseInteger(match.params.attributeId)
  const [currentPage, setCurrentPage] = useState(1)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [value, setValue] = useState('')
  const [picture, setPicture] = useState(null)
  const [picturePreview, setPicturePreview] = useState(null)
  const [translations, setTranslations] = useState([])
  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })
  const [attributeValuessQuery, executeAttributeValuesQuery] = useQuery({
    query: `
      query AttributeValues($input: AttributeValuesInput!) {
        attributeValues (input: $input) {
          paginationInfo {
            totalCount
            firstPage
            lastPage
            currentPage
            nextPage
            previousPage
          }
          data {
            id
            title
            value
            attributeId
            pictureUrl
            translations {
              language {
                isoCode
                title
              }
              value
            }
          }
        }
      }
    `,
    variables: {
      input: {
        pagination: {
          page: currentPage,
        },
        attributeId,
      },
    },
  })
  const [attributeQuery] = useQuery({
    query: `
      query Attribute($id: Int!) {
        attribute (id: $id) {
          title
        }
      }
    `,
    variables: {
      id: attributeId,
    },
  })
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: Int!) {
      deleteAttributeValue(id: $id) {
        id
      }
    }
  `)
  const lastDeletedId =
    deleteMutation.data && deleteMutation.data.deleteAttributeValue.id
  const updateTranslationValue = (languageIsoCode, translationValue) => {
    setTranslations([
      ...translations.filter(
        (translation) => translation.languageIsoCode !== languageIsoCode
      ),
      { languageIsoCode, value: translationValue },
    ])
  }
  const getTranslationValue = (languageIsoCode) => {
    const translation = find(translations, {
      languageIsoCode,
    })
    return translation ? translation.value : ''
  }
  useEffect(() => {
    if (!isInitialMount) {
      if (lastDeletedId) {
        executeAttributeValuesQuery({ requestPolicy: 'network-only' })
      }
    } else {
      isInitialMount = false
    }
  }, [executeAttributeValuesQuery, lastDeletedId])
  useEffect(() => {
    const error =
      attributeValuessQuery.error ||
      deleteMutation.error ||
      languagesQuery.error
    if (error) {
      urql.handleError(error)
    }
  }, [deleteMutation.error, languagesQuery.error, attributeValuessQuery.error])
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    if (getIsFileSizeExceeded({ file })) {
      setPicture(file)
      setPicturePreview(URL.createObjectURL(file))
    }
  }, [])
  const isBasicEditDisabled =
    isUpdateModalOpen && !user.hasAction(ACTIONS.ATTRIBUTE_VALUE.UPDATE)
  const paginationInfo =
    attributeValuessQuery.data &&
    attributeValuessQuery.data.attributeValues.paginationInfo
  const attribute = attributeQuery.data && attributeQuery.data.attribute
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>
          {attribute && `${attribute.title} values`}
        </SideNavPage.Title>
        {user.hasAction(ACTIONS.ATTRIBUTE_VALUE.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setId('')
              setTitle('')
              setValue('')
              setPicture(null)
              setTranslations([])
              setIsCreateModalOpen(true)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Id</Table.TextHeaderCell>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell>Value</Table.TextHeaderCell>
            <Table.TextHeaderCell>Picture</Table.TextHeaderCell>
            <Table.TextHeaderCell minWidth="250px" align="center">
              Actions
            </Table.TextHeaderCell>
          </Table.Head>
        </Table>
        <Table.Body minHeight={TABLE_MIN_HEIGHT}>
          {attributeValuessQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {attributeValuessQuery.data &&
            attributeValuessQuery.data.attributeValues.data.map(
              (attributeValue) => (
                <Table.Row key={attributeValue.id}>
                  <Table.TextCell>{attributeValue.id}</Table.TextCell>
                  <Table.TextCell>{attributeValue.title}</Table.TextCell>
                  <Table.TextCell>{attributeValue.value}</Table.TextCell>
                  <Table.TextCell>
                    {attributeValue.pictureUrl ? (
                      <Image src={attributeValue.pictureUrl} />
                    ) : (
                      'N/A'
                    )}
                  </Table.TextCell>
                  <Table.Cell minWidth="250px" justifyContent="center">
                    {user.hasAction(ACTIONS.ATTRIBUTE_VALUE.UPDATE) && (
                      <Button
                        marginRight={12}
                        iconBefore="edit"
                        onClick={(e) => {
                          e.stopPropagation()
                          setId(attributeValue.id)
                          setTitle(attributeValue.title)
                          setValue(attributeValue.value)
                          setPicturePreview(attributeValue.pictureUrl)
                          setTranslations(
                            attributeValue.translations.map(
                              ({ language, value: translationValue }) => ({
                                languageIsoCode: language.isoCode,
                                value: translationValue,
                              })
                            )
                          )
                          setIsUpdateModalOpen(true)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    {user.hasAction(ACTIONS.ATTRIBUTE_VALUE.DELETE) && (
                      <Button
                        marginRight={12}
                        iconBefore="trash"
                        intent="danger"
                        onClick={(e) => {
                          e.stopPropagation()
                          setId(attributeValue.id)
                          setIsConfirmDeleteModalOpen(true)
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              )
            )}
        </Table.Body>
      </SideNavPage.Content>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete attribute"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id: parseInteger(id),
          })
          if (!error) {
            setId('')
            close()
          }
        }}
      >
        Are you sure you want to delete attribute value {title}?
      </Dialog>
      <Dialog
        isShown={isCreateModalOpen || isUpdateModalOpen}
        title={
          isCreateModalOpen
            ? 'Create attribute value'
            : `Edit attribute value: ${id}`
        }
        hasCancel={false}
        confirmLabel={isCreateModalOpen ? 'Create' : 'Edit'}
        isConfirmLoading={isFetching}
        onCloseComplete={() => {
          setIsFetching(false)
          setIsCreateModalOpen(false)
          setIsUpdateModalOpen(false)
          setPicture(null)
          setPicturePreview(null)
        }}
        onConfirm={async (close) => {
          setIsFetching(true)
          if (isCreateModalOpen) {
            await uploadRequest({
              accessToken: user.accessToken,
              body: {
                query: `
                  mutation($input: CreateAttributeValueInput!) {
                    createAttributeValue(input: $input) {
                      id
                    }
                  }
                `,
                variables: {
                  input: {
                    title,
                    attributeId,
                    value,
                    file: picture,
                    translations: translations.filter(
                      ({ value: translationValue }) => translationValue !== ''
                    ),
                  },
                },
              },
            })
          }
          if (isUpdateModalOpen) {
            await uploadRequest({
              accessToken: user.accessToken,
              body: {
                query: `
                  mutation($input: UpdateAttributeValueInput!) {
                    updateAttributeValue(input: $input) {
                      id
                    }
                  }
                `,
                variables: {
                  input: {
                    id,
                    title,
                    attributeId,
                    value,
                    file: picture,
                    translations: translations.filter(
                      ({ value: translationValue }) => translationValue !== ''
                    ),
                  },
                },
              },
            })
          }
          executeAttributeValuesQuery({ requestPolicy: 'network-only' })
          close()
        }}
      >
        <TextInputField
          label="Title"
          required
          value={title}
          disabled={isBasicEditDisabled}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <TextInputField
          label="Value"
          required
          value={value}
          disabled={isBasicEditDisabled}
          onChange={(e) => {
            setValue(e.target.value)
          }}
        />
        <Label>Picture</Label>
        <Dropzone onDrop={onDrop} />
        {picturePreview && <Image src={picturePreview} />}
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Translations
          </Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Language</Table.TextHeaderCell>
              <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {languagesQuery.data &&
                languagesQuery.data.languages.map((language) => (
                  <Table.Row key={language.isoCode}>
                    <Table.TextCell>{language.title}</Table.TextCell>
                    <Table.Cell>
                      <TextInputField
                        label="Translation"
                        value={getTranslationValue(language.isoCode)}
                        disabled={isBasicEditDisabled}
                        onChange={(e) => {
                          updateTranslationValue(
                            language.isoCode,
                            e.target.value
                          )
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Pane>
      </Dialog>
      {paginationInfo && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </React.Fragment>
  )
}

AttributeValues.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      attributeId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default withUser(AttributeValues)
