import React from 'react'
import PropTypes from 'prop-types'
import isRequiredIf from 'react-proptype-conditional-require'
import styled from 'styled-components/macro'
import {
  Button,
  Pane,
  Small,
  Strong,
  Text,
  IconButton,
  withTheme,
} from 'evergreen-ui'
import { space } from 'styled-system'
import { borderRadius } from 'Theme'
import { DEAL_STATUSES } from 'constants/constants'
import { pickSpaceProps } from 'utils/styled-system'
import DealBadge from 'components/DealBadge'
import { THEME_DEAL_ITEM_HEIGHT } from '../constants'

const getVisibility = (isVisible) => (isVisible ? 'visible' : 'hidden')

const ThemeDealBox = withTheme(styled(Pane)`
  background: ${({ theme }) => theme.colors.background.tint1};
  height: ${THEME_DEAL_ITEM_HEIGHT}px;
  padding: 0.5em;
  border-radius: ${borderRadius.SMALL}px;
  display: flex;
  align-items: center;
  ${space};
`)
const Thumb = styled.img`
  border-radius: ${borderRadius.SMALL}px;
  max-width: 100%;
  max-height: 100%;
`
const StyledButton = styled(Button)`
  margin: 0.25em 0;
  width: 82px;
  visibility: ${({ isVisible }) => getVisibility(isVisible)};
`
const { APPROVAL_NEEDED, APPROVED, DECLINED, FINISHED, LIVE, DELETED, DRAFT } =
  DEAL_STATUSES

const getIsSoftDeleteEnabledForThemeDealState = (status) =>
  [APPROVAL_NEEDED, APPROVED, DECLINED, DELETED, DRAFT].includes(status)

const ThemeDealItem = ({
  product: { title, shortDescription, mainImage },
  status,
  isWithApproveButton,
  isWithDeclineButton,
  onDeleteButtonClicked,
  onDetailClick,
  deleteIcon,
  deleteIconDescription,
  onApproveButtonClicked,
  onDeclineButtonClicked,
  onRevertButtonClicked,
  currentStockQuantity,
  isWithPauseButton,
  onPauseButtonClicked,
  isWithResumeButton,
  onResumeButtonClicked,
  isWithDeleteButton,
  isWithRestoreButton,
  isWithEditButton,
  isWithPublishButton,
  onPublishButtonClicked,
  isPublishButtonDisabled,
  ...rest
}) => (
  <ThemeDealBox {...pickSpaceProps(rest)}>
    <Pane
      height={100}
      width={133}
      minWidth={133}
      borderRadius={borderRadius.SMALL}
      alignItems="center"
      display="flex"
      justifyContent="center"
    >
      {mainImage && <Thumb src={mainImage.url} alt={title} />}
    </Pane>
    <Pane paddingX="0.5em">
      <div>
        <Strong>{title}</Strong>
      </div>
      <div>
        <DealBadge status={status} />
      </div>
      <div>
        <Text>Current stock quantity: </Text>
        <Text fontWeight={600}>{currentStockQuantity}</Text>
      </div>
      <div>
        <Small>{shortDescription}</Small>
      </div>
    </Pane>
    <div style={{ marginLeft: 'auto' }}>
      <Pane marginY="0.25em" display="flex" justifyContent="space-around">
        {isWithPauseButton && (
          <IconButton
            icon="pause"
            height={32}
            title="Pause Theme Deal"
            appearance="minimal"
            onClick={onPauseButtonClicked}
          />
        )}
        {isWithResumeButton && (
          <IconButton
            icon="play"
            height={32}
            title="Resume Theme Deal"
            appearance="minimal"
            onClick={onResumeButtonClicked}
          />
        )}
        {isWithDeleteButton && (
          <IconButton
            icon={deleteIcon}
            intent="danger"
            height={32}
            title={deleteIconDescription}
            appearance="minimal"
            onClick={onDeleteButtonClicked}
            disabled={!getIsSoftDeleteEnabledForThemeDealState(status)}
          />
        )}
        {isWithRestoreButton && status === DELETED && (
          <IconButton
            icon="undo"
            title="Restore"
            appearance="minimal"
            onClick={onRevertButtonClicked}
          />
        )}
        {isWithEditButton && (
          <IconButton
            icon={
              [FINISHED, LIVE, DELETED].includes(status) && !isWithResumeButton
                ? 'eye-open'
                : 'edit'
            }
            height={32}
            title="Detail"
            appearance="minimal"
            onClick={onDetailClick}
          />
        )}
      </Pane>
      <Pane display="flex" flexDirection="column" alignItems="center">
        {isWithPublishButton && (
          <StyledButton
            appearance="primary"
            intent="success"
            isVisible={isWithPublishButton}
            onClick={onPublishButtonClicked}
            disabled={isPublishButtonDisabled}
          >
            Publish
          </StyledButton>
        )}
        {!isWithPublishButton && (
          <StyledButton
            appearance="primary"
            intent="success"
            isVisible={isWithApproveButton}
            disabled={![APPROVAL_NEEDED, DECLINED].includes(status)}
            onClick={onApproveButtonClicked}
          >
            Approve
          </StyledButton>
        )}
        <StyledButton
          isVisible={isWithDeclineButton}
          intent="danger"
          disabled={![APPROVED, APPROVAL_NEEDED].includes(status)}
          onClick={onDeclineButtonClicked}
        >
          Decline
        </StyledButton>
      </Pane>
    </div>
  </ThemeDealBox>
)

ThemeDealItem.defaultProps = {
  deleteIcon: 'trash',
  deleteIconDescription: 'Delete Theme Deal',
}

ThemeDealItem.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
    mainImage: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
  status: PropTypes.oneOf(Object.values(DEAL_STATUSES)).isRequired,
  currentStockQuantity: PropTypes.number,
  isWithApproveButton: PropTypes.bool,
  isWithDeclineButton: PropTypes.bool,
  isWithPublishButton: PropTypes.bool,
  deleteIcon: PropTypes.string,
  deleteIconDescription: PropTypes.string,
  onDeleteButtonClicked: isRequiredIf(PropTypes.func, ({ status }) =>
    getIsSoftDeleteEnabledForThemeDealState(status)
  ),
  onApproveButtonClicked: isRequiredIf(
    PropTypes.func,
    ({ isWithApproveButton }) => isWithApproveButton
  ),
  isWithPauseButton: PropTypes.bool,
  onPauseButtonClicked: isRequiredIf(
    PropTypes.func,
    ({ isWithSuspendButton }) => isWithSuspendButton
  ),
  isWithResumeButton: PropTypes.bool,
  onResumeButtonClicked: isRequiredIf(
    PropTypes.func,
    ({ isWithSuspendButton }) => isWithSuspendButton
  ),
  onDeclineButtonClicked: isRequiredIf(
    PropTypes.func,
    ({ isWithDeclineButton }) => isWithDeclineButton
  ),
  onDetailClick: PropTypes.func.isRequired,
  onRevertButtonClicked: isRequiredIf(
    PropTypes.func,
    ({ status }) => status === DELETED
  ),
  isWithDeleteButton: PropTypes.bool,
  isWithRestoreButton: PropTypes.bool,
  isWithEditButton: PropTypes.bool,
  onPublishButtonClicked: isRequiredIf(
    PropTypes.func,
    ({ isWithPublishButton }) => isWithPublishButton
  ),
  isPublishButtonDisabled: isRequiredIf(
    PropTypes.bool,
    ({ isWithPublishButton }) => isWithPublishButton
  ),
}

export default ThemeDealItem
