import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import {
  Table,
  Button,
  Dialog,
  Label,
  Pane,
  SelectField,
  Switch,
  majorScale,
} from 'evergreen-ui'
import styled from 'styled-components/macro'
import find from 'lodash.find'

const StyledRow = styled(Table.Row)`
  border: ${({ isReorderMode }) =>
    isReorderMode ? '1px dashed' : '1px solid #EDF0F2'};
`

const ProductAttributeValues = ({
  productAttributeValues,
  productVariants,
  handleSave,
  isEditDisabled,
  isAddDisabled,
  productId,
}) => {
  const [
    isAddProductAttributeValueDialogOpen,
    setIsAddProductAttributeValueDialogOpen,
  ] = useState(false)
  const [
    isEditProductAttributeValueDialogOpen,
    setIsEditProductAttributeValueDialogOpen,
  ] = useState(false)
  const [attribute, setAttribute] = useState({})
  const [attributeValue, setAttributeValue] = useState({})
  const [isVariantCreator, setIsVariantCreator] = useState(false)
  const [updatedProductVariants, setUpdatedProductVariants] = useState(
    productVariants || []
  )

  const [attributesQuery] = useQuery({
    query: `
      query($pagination: PaginationInput)  {
        attributes(pagination: $pagination) {
          data {
            id
            title
          }
        }
      }
    `,
    variables: {
      pagination: {
        page: 1,
        limit: 1000,
      },
    },
  })
  const [attributeValuesQuery] = useQuery({
    query: `
      query($input: AttributeValuesInput!) {
        attributeValues(input: $input) {
          data {
            id
            attributeId
            title
          }
        }
      }
    `,
    variables: {
      input: {
        attributeId: attribute.id,
        pagination: {
          page: 1,
          limit: 1000,
        },
      },
    },
    pause: !attribute.id,
  })
  return (
    <Pane marginTop={majorScale(1)}>
      <Label marginBottom={majorScale(1)} display="block">
        Attributes
      </Label>
      {!isAddDisabled && (
        <Button
          marginBottom={majorScale(1)}
          onClick={() => {
            setAttribute({})
            setAttributeValue({})
            setIsVariantCreator(false)
            setIsAddProductAttributeValueDialogOpen(true)
          }}
        >
          Add Attribute
        </Button>
      )}
      <Table marginTop={majorScale(1)}>
        <Table.Head>
          <Table.TextHeaderCell>Attribute</Table.TextHeaderCell>
          <Table.TextHeaderCell>Attribute Value</Table.TextHeaderCell>
          <Table.TextHeaderCell>Is Variant Creator</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {productAttributeValues.map((productAttributeValue) => (
            <StyledRow key={productAttributeValue.attribute.id}>
              <Table.TextCell>
                {productAttributeValue.attribute.title}
              </Table.TextCell>
              <Table.TextCell>
                {productAttributeValue.attributeValue &&
                  productAttributeValue.attributeValue.title}
              </Table.TextCell>
              <Table.TextCell>
                <Switch
                  checked={productAttributeValue.isVariantCreator}
                  disabled
                />
              </Table.TextCell>
              <Table.Cell>
                <Button
                  disabled={isEditDisabled}
                  marginRight={majorScale(2)}
                  iconBefore="edit"
                  onClick={(e) => {
                    e.stopPropagation()
                    setAttribute(productAttributeValue.attribute)
                    setAttributeValue(productAttributeValue.attributeValue)
                    setIsVariantCreator(productAttributeValue.isVariantCreator)
                    setIsEditProductAttributeValueDialogOpen(true)
                  }}
                >
                  Edit
                </Button>
                <Button
                  disabled={isEditDisabled}
                  marginRight={majorScale(2)}
                  iconBefore="trash"
                  intent="danger"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSave({
                      attributeValues: productAttributeValues.filter(
                        (addedProductAttributeValue) =>
                          addedProductAttributeValue.attribute.id !==
                          productAttributeValue.attribute.id
                      ),
                      variants: updatedProductVariants.map(
                        (updatedProductVariant) => ({
                          ...updatedProductVariant,
                          productVariantAttributeValues:
                            updatedProductVariant.productVariantAttributeValues.filter(
                              (productVariantAttributeValue) =>
                                productVariantAttributeValue.attributeValue
                                  .attributeId !==
                                productAttributeValue.attribute.id
                            ),
                        })
                      ),
                    })
                  }}
                >
                  Delete
                </Button>
              </Table.Cell>
            </StyledRow>
          ))}
        </Table.Body>
      </Table>
      <Dialog
        isShown={
          isAddProductAttributeValueDialogOpen ||
          isEditProductAttributeValueDialogOpen
        }
        title={
          isAddProductAttributeValueDialogOpen
            ? 'Add Attribute'
            : `Edit Attribute`
        }
        hasCancel={false}
        confirmLabel={isAddProductAttributeValueDialogOpen ? 'Add' : 'Edit'}
        onCloseComplete={() => {
          setIsAddProductAttributeValueDialogOpen(false)
          setIsEditProductAttributeValueDialogOpen(false)
        }}
        onConfirm={(close) => {
          if (
            isAddProductAttributeValueDialogOpen &&
            attribute.id &&
            ((attributeValue && attributeValue.id) || isVariantCreator) &&
            (!isVariantCreator ||
              updatedProductVariants.every(
                ({ productVariantAttributeValues }) =>
                  find(
                    productVariantAttributeValues,
                    ({ attributeValue: currentAttributeValue }) =>
                      currentAttributeValue.id &&
                      currentAttributeValue.attributeId === attribute.id
                  )
              ))
          ) {
            handleSave({
              attributeValues: [
                ...productAttributeValues.filter(
                  (addedProductAttributeValue) =>
                    addedProductAttributeValue.attribute.id !== attribute.id
                ),
                {
                  attribute,
                  attributeValue,
                  isVariantCreator,
                },
              ],
              variants: updatedProductVariants,
            })
            close()
          }
          if (
            isEditProductAttributeValueDialogOpen &&
            ((attributeValue && attributeValue.id) || isVariantCreator) &&
            (!isVariantCreator ||
              updatedProductVariants.every(
                ({ productVariantAttributeValues }) =>
                  find(
                    productVariantAttributeValues,
                    ({ attributeValue: currentAttributeValue }) =>
                      currentAttributeValue.id &&
                      currentAttributeValue.attributeId === attribute.id
                  )
              ))
          ) {
            handleSave({
              attributeValues: productAttributeValues.map(
                (addedProductAttributeValue) => {
                  if (
                    addedProductAttributeValue.attribute.id === attribute.id
                  ) {
                    return {
                      attribute,
                      attributeValue,
                      isVariantCreator,
                    }
                  }
                  return addedProductAttributeValue
                }
              ),
              variants: updatedProductVariants,
            })
            close()
          }
        }}
      >
        <SelectField
          label="Attribute"
          value={attribute.id}
          disabled={isEditProductAttributeValueDialogOpen}
          onChange={(e) => {
            const useAttribute = find(attributesQuery.data.attributes.data, {
              id: Number(e.target.value),
            })
            setAttribute(useAttribute)
          }}
        >
          <option value="">---Select Attribute---</option>
          {attributesQuery.data &&
            attributesQuery.data.attributes.data
              .filter(
                (attributeToUse) =>
                  !find(productAttributeValues, {
                    attribute: { id: attributeToUse.id },
                  })
              )
              .map((attributeToUse) => (
                <option key={attributeToUse.id} value={attributeToUse.id}>
                  {attributeToUse.title}
                </option>
              ))}
        </SelectField>
        <SelectField
          label="Attribute Value"
          value={attributeValue && attributeValue.id}
          disabled={!attribute.id || isVariantCreator}
          onChange={(e) => {
            const useAttributeValue = find(
              attributeValuesQuery.data.attributeValues.data,
              {
                id: Number(e.target.value),
              }
            )
            setAttributeValue(useAttributeValue)
          }}
        >
          <option value="">---Select Attribute Value---</option>
          {attributeValuesQuery.data &&
            attributeValuesQuery.data.attributeValues.data.map(
              (attributeValueToUse) => (
                <option
                  key={attributeValueToUse.id}
                  value={attributeValueToUse.id}
                >
                  {attributeValueToUse.title}
                </option>
              )
            )}
        </SelectField>
        <Pane marginTop={majorScale(2)}>
          <Label>Is variant creator</Label>
          <Switch
            marginTop={majorScale(1)}
            checked={isVariantCreator}
            onChange={(e) => {
              setIsVariantCreator(e.target.checked)
              if (e.target.checked) {
                setAttributeValue({})
              } else {
                setUpdatedProductVariants([
                  ...updatedProductVariants.map((updatedProductVariant) => ({
                    ...updatedProductVariant,
                    productVariantAttributeValues:
                      updatedProductVariant.productVariantAttributeValues.filter(
                        (productVariantAttributeValue) =>
                          productVariantAttributeValue.attributeValue
                            .attributeId !== attribute.id
                      ),
                  })),
                ])
              }
            }}
          />
        </Pane>
        {attribute && attribute.id && isVariantCreator && (
          <Pane marginTop={majorScale(1)}>
            <Label>Variant attribute values</Label>
            <Table marginTop={majorScale(1)}>
              <Table.Head>
                <Table.TextHeaderCell flexBasis={50} flexGrow={0}>
                  ID
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={180} flexGrow={0}>
                  EAN
                </Table.TextHeaderCell>
                <Table.TextHeaderCell>Attribute Value</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {updatedProductVariants.map(
                  ({ id, ean, productVariantAttributeValues }) => {
                    const currentAttributeValue = find(
                      productVariantAttributeValues,
                      {
                        attributeValue: { attributeId: attribute.id },
                      }
                    )
                    return (
                      <Table.Row key={id}>
                        <Table.TextCell flexBasis={50} flexGrow={0}>
                          {id}
                        </Table.TextCell>
                        <Table.TextCell flexBasis={180} flexGrow={0}>
                          {ean}
                        </Table.TextCell>
                        <Table.TextCell>
                          <SelectField
                            label="Attribute Value"
                            value={
                              currentAttributeValue &&
                              currentAttributeValue.attributeValue.id
                            }
                            onChange={(e) => {
                              const useAttributeValue = find(
                                attributeValuesQuery.data.attributeValues.data,
                                {
                                  id: Number(e.target.value),
                                }
                              )
                              setUpdatedProductVariants(
                                updatedProductVariants.map(
                                  (updatedProductVariant) => {
                                    if (updatedProductVariant.id === id) {
                                      return {
                                        ...updatedProductVariant,
                                        productVariantAttributeValues: [
                                          ...productVariantAttributeValues.filter(
                                            (productVariantAttributeValue) =>
                                              productVariantAttributeValue
                                                .attributeValue.attributeId !==
                                              attribute.id
                                          ),
                                          ...(useAttributeValue
                                            ? [
                                                {
                                                  productId,
                                                  attributeValue:
                                                    useAttributeValue,
                                                },
                                              ]
                                            : []),
                                        ],
                                      }
                                    }
                                    return updatedProductVariant
                                  }
                                )
                              )
                            }}
                          >
                            <option value="">
                              ---Select Attribute Value---
                            </option>
                            {attributeValuesQuery.data &&
                              attributeValuesQuery.data.attributeValues.data.map(
                                (useAttributeValue) => (
                                  <option
                                    key={useAttributeValue.id}
                                    value={useAttributeValue.id}
                                  >
                                    {useAttributeValue.title}
                                  </option>
                                )
                              )}
                          </SelectField>
                        </Table.TextCell>
                      </Table.Row>
                    )
                  }
                )}
              </Table.Body>
            </Table>
          </Pane>
        )}
      </Dialog>
    </Pane>
  )
}

ProductAttributeValues.propTypes = {
  handleSave: PropTypes.func.isRequired,
  isEditDisabled: PropTypes.bool.isRequired,
  isAddDisabled: PropTypes.bool.isRequired,
  productAttributeValues: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      }),
      attributeValue: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      }),
      isVariantCreator: PropTypes.bool.isRequired,
    })
  ),
  productVariants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      mainImage: PropTypes.object,
      ean: PropTypes.string,
      productVariantAttributeValues: PropTypes.array,
    })
  ),
  productId: PropTypes.number.isRequired,
}

export default ProductAttributeValues
