import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { withUser } from 'UserProvider'
import { Spinner, BackButton } from 'evergreen-ui'
import urql from 'utils/urql'
import SideNavPage from 'sharedComponents/SideNavPage'
import { parseInteger } from 'utils/numbers'
import ACTIONS from 'constants/actions'
import From from '../components/Form'

let isInitialMount = true

const EditFeed = ({ user, match, history }) => {
  const [feedQuery, executeFeedQuery] = useQuery({
    query: `
      query Feed($id: Int!) {
        feed (id: $id) {
          id
          url
          seller {
            id
            accountName
          }
          defaultCurrencyIsoCode
          isEnabled
          type
          intervalInMinutes
          lastSyncAtUtc
          firstSyncAtUtc
          productFieldName
          sellerExternalNote
          productIdFieldName
          productPriceFieldName
          productCurrencyFieldName
          productQuantityFieldName
        }
      }
    `,
    variables: {
      id: parseInteger(match.params.feedId),
    },
  })

  useEffect(() => {
    if (!isInitialMount) {
      executeFeedQuery({ requestPolicy: 'network-only' })
    } else {
      isInitialMount = false
    }
  }, [executeFeedQuery])
  useEffect(() => {
    const { error } = feedQuery
    if (error) {
      urql.handleError(error)
    }
  }, [feedQuery, feedQuery.error])
  const feed = feedQuery.data && feedQuery.data.feed
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Edit feed</SideNavPage.Title>
        <BackButton onClick={() => history.goBack()} />
      </SideNavPage.Header>
      <SideNavPage.Content>
        {feedQuery.fetching && <Spinner marginX="auto" delay={200} size={80} />}
        {feed && (
          <From feed={feed} isDisabled={!user.hasAction(ACTIONS.FEED.UPDATE)} />
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

EditFeed.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      feedId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(EditFeed)
