import React from 'react'
import PropTypes from 'prop-types'
import { Button, Pane } from 'evergreen-ui'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES, { THEME_DEAL_MANAGEMENT_ROUTES } from 'constants/routes'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import Tablist from 'components/Tablist'
import Approved from './Approved'
import WorkInProgress from './WorkInProgress'
import ApprovalNeeded from './ApprovalNeeded'
import SoftDeleted from './SoftDeleted'

const { APPROVED, APPROVAL_NEEDED, DELETED, WORK_IN_PROGRESS } =
  THEME_DEAL_MANAGEMENT_ROUTES

const tabsData = [
  {
    tab: APPROVED,
    name: 'Approved',
    component: Approved,
  },
  {
    tab: WORK_IN_PROGRESS,
    name: 'Work in progress',
    component: WorkInProgress,
  },
  {
    tab: APPROVAL_NEEDED,
    name: 'Approval needed',
    component: ApprovalNeeded,
  },
  {
    tab: DELETED,
    name: 'Deleted',
    component: SoftDeleted,
    requiredAction: ACTIONS.THEME_DEAL.DESTROY,
  },
]

const Management = (props) => {
  const { match, history, user } = props
  const tabs = tabsData.filter(
    ({ requiredAction }) => !requiredAction || user.hasAction(requiredAction)
  )
  const selectedTab = match.params.tab || (tabs.length && tabs[0].tab)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Theme Deals</SideNavPage.Title>
        {user.hasAction(ACTIONS.THEME_DEAL.CREATE) && (
          <Button
            marginLeft="0.5em"
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              history.push(
                `/${ROUTES.THEME_OF_THE_MONTH}/${match.params.themeOfTheMonthId}/new`
              )
            }}
          >
            Create new Theme Deal
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Tablist
          history={history}
          selectedTab={selectedTab}
          tabs={tabs}
          rootUrl={`${ROUTES.THEME_OF_THE_MONTH}/${match.params.themeOfTheMonthId}`}
        />
        <Pane marginTop="1em" flex={1}>
          {tabs.map(
            ({ tab, component: Comp }) =>
              tab === selectedTab && <Comp key={tab} />
          )}
        </Pane>
      </SideNavPage.Content>
    </React.Fragment>
  )
}

Management.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
      themeOfTheMonthId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Management)
