import React from 'react'
import PropTypes from 'prop-types'
import SideNavPage from 'sharedComponents/SideNavPage'
import { BackButton } from 'evergreen-ui'
import ProductVariantForm from '../../components/ProductVariantForm'

const NewProductVariant = ({ match, history }) => (
  <React.Fragment>
    <SideNavPage.Header>
      <SideNavPage.Title>Create Product Variant</SideNavPage.Title>
      <BackButton onClick={() => history.goBack()} />
    </SideNavPage.Header>
    <SideNavPage.Content>
      <ProductVariantForm data={{ productId: match.params.productId }} />
    </SideNavPage.Content>
  </React.Fragment>
)

NewProductVariant.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}
export default NewProductVariant
