import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, TextInputField } from 'evergreen-ui'
import { useMutation } from 'urql'
import urql from 'utils/urql'
import CountrySelect from 'sharedComponents/CountrySelect'

const createInputHandler = (setState) => (e) => setState(e.target.value)

const UpdateShippingAddressDialog = ({
  id,
  close,
  shippingFirstName,
  shippingLastName,
  shippingStreet,
  shippingCity,
  shippingCountryIsoCode,
  shippingHouseNumber,
  shippingZipCode,
  shippingComment,
  shippingPhoneNumber,
}) => {
  const [firstName, setFirstName] = useState(shippingFirstName)
  const [lastName, setLastName] = useState(shippingLastName)
  const [street, setStreet] = useState(shippingStreet)
  const [city, setCity] = useState(shippingCity)
  const [countryIsoCode, setCountryIsoCode] = useState(shippingCountryIsoCode)
  const [houseNumber, setHouseNumber] = useState(shippingHouseNumber)
  const [zipCode, setZipCode] = useState(shippingZipCode)
  const [comment, setComment] = useState(shippingComment)
  const [phoneNumber, setPhoneNumber] = useState(shippingPhoneNumber)
  const [updateOrderMutation, executeUpdateOrderMutation] = useMutation(`
    mutation updateOrder(
      $input: UpdateOrderInput!
    ) {
      updateOrder(input: $input) {
        id
      }
    }
  `)
  return (
    <Dialog
      isShown
      isConfirmLoading={updateOrderMutation.fetching}
      title="Update shipping address"
      onCloseComplete={close}
      onConfirm={async () => {
        const { error } = await executeUpdateOrderMutation({
          input: {
            id,
            shippingFirstName: firstName,
            shippingLastName: lastName,
            shippingStreet: street,
            shippingCity: city,
            shippingCountryIsoCode: countryIsoCode,
            shippingHouseNumber: houseNumber,
            shippingZipCode: zipCode,
            shippingComment: comment,
            shippingPhoneNumber: phoneNumber,
          },
        })
        if (error) {
          urql.handleError(error)
        } else {
          close()
        }
      }}
    >
      <TextInputField
        onChange={createInputHandler(setFirstName)}
        value={firstName}
        label="First name"
        required
      />
      <TextInputField
        onChange={createInputHandler(setLastName)}
        value={lastName}
        label="Last name"
        required
      />
      <TextInputField
        onChange={createInputHandler(setStreet)}
        value={street}
        label="Street"
        required
      />
      <TextInputField
        onChange={createInputHandler(setCity)}
        value={city}
        label="City"
        required
      />
      <CountrySelect
        value={countryIsoCode}
        handleChange={setCountryIsoCode}
        isShippingCountry
        isRequired
      />
      <TextInputField
        onChange={createInputHandler(setHouseNumber)}
        value={houseNumber}
        label="House number"
        required
      />
      <TextInputField
        onChange={createInputHandler(setZipCode)}
        value={zipCode}
        label="Zip code"
        required
      />
      <TextInputField
        onChange={createInputHandler(setComment)}
        value={comment}
        label="Additional information"
        required
      />
      <TextInputField
        onChange={createInputHandler(setPhoneNumber)}
        value={phoneNumber}
        label="Phone number"
        required
      />
    </Dialog>
  )
}

UpdateShippingAddressDialog.propTypes = {
  id: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  shippingFirstName: PropTypes.string,
  shippingLastName: PropTypes.string,
  shippingStreet: PropTypes.string,
  shippingCity: PropTypes.string,
  shippingCountryIsoCode: PropTypes.string,
  shippingHouseNumber: PropTypes.string,
  shippingZipCode: PropTypes.string,
  shippingComment: PropTypes.string,
  shippingPhoneNumber: PropTypes.string,
}

export default UpdateShippingAddressDialog
