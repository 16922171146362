import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useMutation } from 'urql'
import { Pane, TextInputField, Button, Text, majorScale } from 'evergreen-ui'
import urql from 'utils/urql'
import FullCenterPage from 'components/FullCenterPage'
import ROUTES from 'constants/routes'

const ForgottenPassword = () => {
  const [email, setEmail] = useState('')
  const [isSuccess, setIsSuccess] = useState('')
  const [userForgottenPasswordMutation, executeUserForgottenPasswordMutation] =
    useMutation(`
    mutation($email: String!) {
      userForgottenPassword(email: $email)
    }
  `)
  useEffect(() => {
    const { error, data } = userForgottenPasswordMutation
    if (error) {
      urql.handleError(error)
    }
    if (data && data.userForgottenPassword) {
      setIsSuccess(true)
    }
  }, [userForgottenPasswordMutation])
  return (
    <FullCenterPage>
      {isSuccess && (
        <Pane>
          {`We've just sent you an email with instructions how to reset your password.`}
        </Pane>
      )}
      {!isSuccess && (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            executeUserForgottenPasswordMutation({
              email,
            })
          }}
        >
          <Pane display="flex" flexDirection="column" alignItems="center">
            <TextInputField
              width="100%"
              label="Email"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <Button
              disabled={userForgottenPasswordMutation.fetching}
              marginTop={majorScale(1)}
            >
              Submit
            </Button>
          </Pane>
        </form>
      )}
      <Pane marginTop={majorScale(5)} textAlign="center">
        <NavLink to={`/${ROUTES.SIGN_IN}`}>
          <Text>Back to Sign-In</Text>
        </NavLink>
      </Pane>
    </FullCenterPage>
  )
}

export default ForgottenPassword
