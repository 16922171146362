import React from 'react'
import PropTypes from 'prop-types'
import { BackButton } from 'evergreen-ui'
import { useQuery } from 'urql'
import { parseInteger } from 'utils/numbers'
import SideNavPage from 'sharedComponents/SideNavPage'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import Form from '../../components/Form'

const EditOffer = ({ match, history }) => {
  const id = parseInteger(match.params.offerId)
  const [userWishOfferQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query($id: Int!) {
        userWishOffer(id: $id) {
          id
          product {
            id
            title
            shortDescription
            longDescription
            translations {
              language {
                isoCode
                title
              }
              languageIsoCode
              title
              shortDescription
              longDescription
            }
            reviewScore
            size
            warehouseDeliveryTimeInDays
            productCondition {
              id
              title
            }
            mainImage {
              id
              url
              name
            }
            additionalImages {
              id
              url
              name
            }
            seller {
              id
              accountName
            }
            externalPid
          }
          price
          expiresAtUtc
          comment
        }
      }
    `,
    variables: {
      id,
    },
  })
  const wishOffer = userWishOfferQuery.data
    ? userWishOfferQuery.data.userWishOffer
    : null
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Edit Wish offer</SideNavPage.Title>
        <BackButton onClick={() => history.goBack()} />
      </SideNavPage.Header>
      <SideNavPage.Content>
        <FullScreenLoading isVisible={userWishOfferQuery.fetching} />
        {wishOffer && <Form data={wishOffer} />}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

EditOffer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      offerId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default EditOffer
