import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import { compose } from 'recompose'
import { useMutation, useQuery } from 'urql'
import { withRouter } from 'react-router-dom'
import sortBy from 'lodash.sortby'
import { withUser } from 'UserProvider'
import { getSpaceInPx } from 'utils/styled-system'
import { DEAL_STATUSES } from 'constants/constants'
import ACTIONS from 'constants/actions'
import NoData from 'sharedComponents/NoData'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import { parseInteger } from 'utils/numbers'
import {
  extractDataFromThemeDealsQuery,
  getThemeDealsQuery,
  pauseThemeDealMutation,
  resumeThemeDealMutation,
} from '../queries'
import { getPathToThemeDealDetail } from '../../../utils'
import ThemeDealItem from '../components/ThemeDealItem'
import DeleteThemeDealDialog from '../../../components/DeleteThemeDealDialog'
import { THEME_DEAL_ITEM_Y_MARGIN } from '../constants'
import withApproveDeclineMutations, {
  toastResultOfApproval,
  toastResultOfPublish,
} from '../../../components/withAprroveDeclineMutations'

const { APPROVAL_NEEDED, APPROVED, FINISHED, LIVE } = DEAL_STATUSES

const getIsNoData = (query, items) => !query.fetching && items.length === 0

const Approved = ({
  history,
  user,
  executeApproveThemeDealMutation,
  showDeclineThemeDealDialog,
  executePublishThemeDealMutation,
  match,
}) => {
  const themeOfTheMonthId = parseInteger(match.params.themeOfTheMonthId)
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [isPublishButtonDisabled, setIsPublishButtonDisabled] = useState(false)
  const [themeDealsQuery, executeThemeDealsQuery] = useQuery(
    getThemeDealsQuery({
      status: [APPROVED],
      pagination: { page: 1, limit: 100 },
      themeOfTheMonthId,
    })
  )
  const [finishedThemeDealsQuery] = useQuery(
    getThemeDealsQuery({
      status: [FINISHED],
      pagination: { page: 1, limit: 20 },
      themeOfTheMonthId,
    })
  )
  const [liveThemeDealsQuery, executeLiveThemeDealsQuery] = useQuery(
    getThemeDealsQuery({
      status: [LIVE],
      pagination: { page: 1, limit: 1 },
      themeOfTheMonthId,
    })
  )
  const [, executePauseThemeDealMutation] = useMutation(pauseThemeDealMutation)
  const [, executeResumeThemeDealMutation] = useMutation(
    resumeThemeDealMutation
  )
  const { themeDeals: nextThemeDeals } =
    extractDataFromThemeDealsQuery(themeDealsQuery)
  const finishedThemeDeals = finishedThemeDealsQuery.data
    ? finishedThemeDealsQuery.data.themeDeals.data
    : []
  const { themeDeals: liveThemeDeals } =
    extractDataFromThemeDealsQuery(liveThemeDealsQuery)
  const isFetching =
    themeDealsQuery.fetching ||
    finishedThemeDealsQuery.fetching ||
    liveThemeDealsQuery.fetching
  const liveThemeDeal = liveThemeDeals[0]
  const isWithEditButton =
    user.hasAction(ACTIONS.THEME_DEAL.UPDATE) ||
    user.hasAction(ACTIONS.THEME_DEAL.UPDATE_TRANSLATIONS) ||
    user.hasAction(ACTIONS.THEME_DEAL.ADD_TRANSLATIONS) ||
    user.hasAction(ACTIONS.THEME_DEAL.ADD_COMPETITOR)
  return (
    <Pane>
      <FullScreenLoading isVisible={isFetching} />
      {!isFetching && liveThemeDeal && (
        <ThemeDealItem
          {...liveThemeDeal}
          my={getSpaceInPx(THEME_DEAL_ITEM_Y_MARGIN)}
          onDetailClick={() => {
            history.push(
              getPathToThemeDealDetail({
                themeDealId: liveThemeDeal.id,
                themeOfTheMonthId: match.params.themeOfTheMonthId,
              })
            )
          }}
          isWithPauseButton={
            !liveThemeDeal.isPaused && user.hasAction(ACTIONS.THEME_DEAL.PAUSE)
          }
          onPauseButtonClicked={async () => {
            await executePauseThemeDealMutation({
              id: liveThemeDeal.id,
            })
          }}
          isWithResumeButton={
            liveThemeDeal.isPaused && user.hasAction(ACTIONS.THEME_DEAL.RESUME)
          }
          isWithDeleteButton={user.hasAction(ACTIONS.THEME_DEAL.DELETE)}
          onResumeButtonClicked={async () => {
            await executeResumeThemeDealMutation({
              id: liveThemeDeal.id,
            })
          }}
          isWithEditButton={isWithEditButton}
        />
      )}
      {!isFetching &&
        sortBy(finishedThemeDeals, ['updatedAtUtc']).map((item) => (
          <ThemeDealItem
            key={item.id}
            {...item}
            my={getSpaceInPx(THEME_DEAL_ITEM_Y_MARGIN)}
            onDetailClick={() => {
              history.push(
                getPathToThemeDealDetail({
                  themeDealId: item.id,
                  themeOfTheMonthId: match.params.themeOfTheMonthId,
                })
              )
            }}
            isWithDeleteButton={user.hasAction(ACTIONS.THEME_DEAL.DELETE)}
            isWithEditButton={isWithEditButton}
            isWithPublishButton={
              [DEAL_STATUSES.APPROVED, DEAL_STATUSES.FINISHED].includes(
                item.status
              ) && user.hasAction(ACTIONS.THEME_DEAL.PUBLISH)
            }
            onPublishButtonClicked={async () => {
              setIsPublishButtonDisabled(true)
              const result = await executePublishThemeDealMutation({
                id: item.id,
              })
              toastResultOfPublish(result)
              executeLiveThemeDealsQuery()
              setIsPublishButtonDisabled(false)
            }}
            isPublishButtonDisabled={isPublishButtonDisabled}
          />
        ))}
      <>
        {itemToSoftDelete && (
          <DeleteThemeDealDialog
            onSuccess={() => {
              executeThemeDealsQuery({ requestPolicy: 'network-only' })
            }}
            onClose={() => {
              setItemToSoftDelete(null)
            }}
            itemToDelete={itemToSoftDelete}
          />
        )}
        {nextThemeDeals.map((item) => (
          <div key={item.id}>
            <ThemeDealItem
              {...item}
              isWithApproveButton={
                item.status === APPROVAL_NEEDED &&
                user.hasAction(ACTIONS.THEME_DEAL.APPROVE)
              }
              isWithDeclineButton={
                item.status === APPROVAL_NEEDED &&
                user.hasAction(ACTIONS.THEME_DEAL.DECLINE)
              }
              onDetailClick={() =>
                history.push(
                  getPathToThemeDealDetail({
                    themeDealId: item.id,
                    themeOfTheMonthId: match.params.themeOfTheMonthId,
                  })
                )
              }
              onDeleteButtonClicked={() => setItemToSoftDelete(item)}
              onApproveButtonClicked={async () => {
                const result = await executeApproveThemeDealMutation({
                  id: item.id,
                })
                toastResultOfApproval(result)
              }}
              onDeclineButtonClicked={() => {
                showDeclineThemeDealDialog({
                  themeDealId: item.id,
                })
              }}
              isWithDeleteButton={user.hasAction(ACTIONS.THEME_DEAL.DELETE)}
              isWithEditButton={
                user.hasAction(ACTIONS.THEME_DEAL.UPDATE) ||
                user.hasAction(ACTIONS.THEME_DEAL.UPDATE_TRANSLATIONS) ||
                user.hasAction(ACTIONS.THEME_DEAL.ADD_TRANSLATIONS) ||
                user.hasAction(ACTIONS.THEME_DEAL.ADD_COMPETITOR)
              }
              isWithPublishButton={
                [DEAL_STATUSES.APPROVED, DEAL_STATUSES.FINISHED].includes(
                  item.status
                ) && user.hasAction(ACTIONS.THEME_DEAL.PUBLISH)
              }
              onPublishButtonClicked={async () => {
                setIsPublishButtonDisabled(true)
                const result = await executePublishThemeDealMutation({
                  id: item.id,
                })
                toastResultOfPublish(result)
                executeLiveThemeDealsQuery()
                setIsPublishButtonDisabled(false)
              }}
              isPublishButtonDisabled={isPublishButtonDisabled}
            />
          </div>
        ))}
      </>
      {getIsNoData(themeDealsQuery, nextThemeDeals) &&
        getIsNoData(finishedThemeDealsQuery, finishedThemeDeals) &&
        getIsNoData(liveThemeDealsQuery, liveThemeDeals) && (
          <NoData padding={60} />
        )}
    </Pane>
  )
}

Approved.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveThemeDealMutation: PropTypes.func.isRequired,
  showDeclineThemeDealDialog: PropTypes.func.isRequired,
  executePublishThemeDealMutation: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      themeOfTheMonthId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default compose(
  withRouter,
  withUser,
  withApproveDeclineMutations
)(Approved)
