import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Spinner,
  BackButton,
  Link,
  Switch,
  Button,
  Pane,
  majorScale,
  Dialog,
  Textarea,
  Text,
} from 'evergreen-ui'
import { useQuery, useMutation } from 'urql'
import { withUser } from 'UserProvider'
import format from 'date-fns/format'
import urql from 'utils/urql'
import { TIME_FORMAT, WISH_STATUSES } from 'constants/constants'
import ROUTES from 'constants/routes'
import ACTIONS from 'constants/actions'
import SideNavPage from 'sharedComponents/SideNavPage'
import WishBadge from 'components/WishBadge'
import { parseInteger } from 'utils/numbers'
import { getCountryCodeFromLanguageCode } from 'utils/flag'
import FlagIcon from 'components/FlagIcon'
import {
  acceptWishMutation,
  declineWishMutation,
  decideLaterWishMutation,
} from 'routes/Wishes/queries'
import CustomerOrders from 'components/CustomerOrders'
import { useThreads } from 'ThreadsProvider'
import WishOffers from './components/WishOffers'

const TABLE_MIN_HEIGHT = 400

const WISH_DECISION_POINT_LABELS = {
  AS_SOON_AS_POSSIBLE: 'As soon as possible',
  WEEK: 'In a week',
  MONTH: 'In a month',
  THREE_MONTHS: 'In a three months',
}

const WISH_DELETE_REASON_LABELS = {
  NO_LONGER_WANT_THE_ITEM: 'No longer want the item',
  GOT_ITEM_FROM_SOMEWHERE_ELSE: 'Got item from somewhere else',
  OTHER_REASON: 'Other reason',
}

const WishDetail = ({ match, history, user }) => {
  const [createWishThreadMutation, executeCreateWishThreadMutation] =
    useMutation(`
    mutation($input: CreateWishThreadInput!) {
      createWishThread(input: $input) {
        id
      }
    }
  `)
  const { refetch } = useThreads()
  const [wishQuery, executeWishQuery] = useQuery({
    query: `
      query($id: Int!) {
        userWish(id: $id) {
          id
          category {
            id
            name
          }
          customer {
            id
            email
            language {
              title
              isoCode
            }
          }
          currency {
            isoCode
          }
          description
          category {
            name
          }
          status
          createdAtUtc
          updatedAtUtc
          isExactProductRequested
          similarProductUrl
          similarProductUrlScreenshot
          decisionPoint
          deleteReason
          customReasonAnswer
          lastSeenAtUtc
          thread {
            id
            isSeenByUser
          }
          offers {
            id
            product {
              title
            }
            price
            createdAtUtc
            passedAtUtc
            expiresAtUtc
            passReason
            customReasonAnswer
          }
        }
      }
    `,
    variables: {
      id: parseInteger(match.params.id),
    },
  })
  const [isRequestDetailsModalOpen, setIsRequestDetailsModalOpen] =
    useState(false)
  const [isOpenAreYouSureModal, setIsOpenAreYouSureModal] = useState(false)
  const [messageToCustomer, setMessageToCustomer] = useState('')
  const [, executeAcceptWishMutation] = useMutation(acceptWishMutation)
  const [, executeDeclineWishMutation] = useMutation(declineWishMutation)
  const [, executeDecideLaterWishMutation] = useMutation(
    decideLaterWishMutation
  )
  const isInitialMount = useRef(true)
  useEffect(() => {
    const { error } = wishQuery || createWishThreadMutation
    if (error) {
      urql.handleError(wishQuery.error)
    }
  }, [
    wishQuery.error,
    createWishThreadMutation.error,
    wishQuery,
    createWishThreadMutation,
  ])
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      executeWishQuery()
    }
  }, [createWishThreadMutation, executeWishQuery])
  const wish = wishQuery.data && wishQuery.data.userWish
  useEffect(() => {
    if (wishQuery.data && wishQuery.data.userWish) {
      refetch()
    }
  }, [wishQuery.data, refetch])
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>
          {`Wish detail: ${match.params.id}`}
        </SideNavPage.Title>
        <Pane display="flex">
          {wish && (
            <>
              {user.hasAction(ACTIONS.THREAD.DETAIL) &&
                wish.thread &&
                wish.thread.id && (
                  <Button
                    marginRight={majorScale(1)}
                    onClick={() => {
                      history.push(`/${ROUTES.MESSAGES}/${wish.thread.id}`)
                    }}
                    iconBefore={
                      !wish.thread.isSeenByUser ? 'warning-sign' : 'none'
                    }
                  >
                    Go to messages
                  </Button>
                )}
              {user.hasAction(ACTIONS.WISH.CREATE_WISH_THREAD) &&
                WISH_STATUSES.ACCEPTED === wish.status &&
                !wish.thread &&
                wish.customer && (
                  <Button
                    marginRight={majorScale(1)}
                    iconBefore="chat"
                    appearance="primary"
                    intent="warning"
                    onClick={() => {
                      setIsRequestDetailsModalOpen(true)
                    }}
                  >
                    Request details
                  </Button>
                )}
              {[WISH_STATUSES.ACCEPTED, WISH_STATUSES.OFFER_CREATED].includes(
                wish.status
              ) &&
                user.hasAction(ACTIONS.WISH_OFFER.CREATE) &&
                wish.customer && (
                  <Button
                    marginRight={majorScale(1)}
                    iconBefore="add"
                    appearance="primary"
                    intent="success"
                    onClick={() => {
                      history.push(`/${ROUTES.WISHES}/${wish.id}/offers/`)
                    }}
                  >
                    Make an offer
                  </Button>
                )}
              {user.hasAction(ACTIONS.WISH.ACCEPT) &&
                [
                  WISH_STATUSES.NEW,
                  WISH_STATUSES.DECIDE_LATER,
                  WISH_STATUSES.DECLINED,
                  WISH_STATUSES.OFFER_NOT_ACCEPTED,
                ].includes(wish.status) && (
                  <Button
                    marginRight={majorScale(1)}
                    iconBefore="tick"
                    intent="success"
                    onClick={async () => {
                      const { error } = await executeAcceptWishMutation({
                        id: wish.id,
                      })
                      if (error) {
                        urql.handleError(error)
                      }
                    }}
                  >
                    Accept
                  </Button>
                )}
              {user.hasAction(ACTIONS.WISH.DECIDE_LATER) &&
                [
                  WISH_STATUSES.NEW,
                  WISH_STATUSES.DECLINED,
                  WISH_STATUSES.OFFER_NOT_ACCEPTED,
                ].includes(wish.status) && (
                  <Button
                    marginRight={majorScale(1)}
                    iconBefore="time"
                    intent="warning"
                    onClick={async () => {
                      const { error } = await executeDecideLaterWishMutation({
                        id: wish.id,
                      })
                      if (error) {
                        urql.handleError(error)
                      }
                    }}
                  >
                    Decide later
                  </Button>
                )}
              {user.hasAction(ACTIONS.WISH.DECLINE) &&
                [
                  WISH_STATUSES.NEW,
                  WISH_STATUSES.DECIDE_LATER,
                  WISH_STATUSES.ACCEPTED,
                ].includes(wish.status) && (
                  <Button
                    marginRight={majorScale(1)}
                    iconBefore="trash"
                    intent="danger"
                    onClick={async () => {
                      const { error } = await executeDeclineWishMutation({
                        id: wish.id,
                      })
                      if (error) {
                        urql.handleError(error)
                      }
                    }}
                  >
                    Decline
                  </Button>
                )}
            </>
          )}
          <BackButton onClick={() => history.goBack()} />
        </Pane>
      </SideNavPage.Header>
      <SideNavPage.Content>
        {wishQuery.fetching && (
          <Spinner
            marginX="auto"
            marginY={TABLE_MIN_HEIGHT / 2}
            delay={200}
            size={80}
          />
        )}
        {wish && (
          <>
            <Table border>
              <Table.Body>
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>Id</Table.TextHeaderCell>
                  <Table.TextCell>{wish.id}</Table.TextCell>
                </Table.Row>
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                  <Table.Cell>
                    <WishBadge status={wish.status} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>Category</Table.TextHeaderCell>
                  <Table.TextCell>{wish.category.name}</Table.TextCell>
                </Table.Row>
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>Customer</Table.TextHeaderCell>
                  <Table.Cell>
                    {wish.customer &&
                      (user.hasAction(ACTIONS.CUSTOMER.DETAIL) ? (
                        <Link
                          onClick={(e) => {
                            e.stopPropagation()
                            history.push(
                              `/${ROUTES.CUSTOMERS}/${wish.customer.id}`
                            )
                          }}
                        >
                          {wish.customer.email}
                        </Link>
                      ) : (
                        wish.customer.email
                      ))}
                  </Table.Cell>
                </Table.Row>
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>Customer language</Table.TextHeaderCell>
                  <Table.Cell>
                    {wish.customer && (
                      <>
                        {wish.customer.language.title}
                        <Pane paddingLeft="10px">
                          <FlagIcon
                            code={getCountryCodeFromLanguageCode(
                              wish.customer.language.isoCode
                            )}
                          />
                        </Pane>
                      </>
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>
                    Product Brand and Model
                  </Table.TextHeaderCell>
                  <Table.TextCell>{wish.description}</Table.TextCell>
                </Table.Row>
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>User Description</Table.TextHeaderCell>
                  <Table.Cell>{wish.similarProductUrl}</Table.Cell>
                </Table.Row>
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>
                    Exact Product Requested
                  </Table.TextHeaderCell>
                  <Table.Cell>
                    <Switch disabled checked={wish.isExactProductRequested} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>Decision Point</Table.TextHeaderCell>
                  <Table.TextCell>
                    {WISH_DECISION_POINT_LABELS[wish.decisionPoint]}
                  </Table.TextCell>
                </Table.Row>
                {wish.deleteReason && (
                  <Table.Row
                    minHeight="48px"
                    height="auto"
                    paddingY={majorScale(1)}
                  >
                    <Table.TextHeaderCell>Delete Reason</Table.TextHeaderCell>
                    <Table.TextCell>
                      <span style={{ whiteSpace: 'normal' }}>
                        {wish.customReasonAnswer ||
                          WISH_DELETE_REASON_LABELS[wish.deleteReason]}
                      </span>
                    </Table.TextCell>
                  </Table.Row>
                )}
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
                  <Table.TextCell>
                    {format(wish.createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                  </Table.TextCell>
                </Table.Row>
                <Table.Row style={{ height: 'auto', minHeight: 50 }}>
                  <Table.TextHeaderCell>Updated at</Table.TextHeaderCell>
                  <Table.TextCell>
                    {format(wish.updatedAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                  </Table.TextCell>
                </Table.Row>
              </Table.Body>
            </Table>
            <WishOffers
              offers={wish.offers}
              wishId={wish.id}
              lastSeenAtUtc={wish.lastSeenAtUtc}
            />
            {wish.customer && user.hasAction(ACTIONS.ORDER.LIST) && (
              <CustomerOrders customerId={wish.customer.id} />
            )}
          </>
        )}
        {wish && (
          <Dialog
            isShown={isRequestDetailsModalOpen}
            title="Request missing details from customer"
            intent="success"
            onCloseComplete={() => setIsRequestDetailsModalOpen(false)}
            onConfirm={() => {
              setIsOpenAreYouSureModal(true)
            }}
            confirmLabel="Send"
          >
            <Pane
              borderBottom="1px solid #EDF0F2"
              paddingBottom="10px"
              marginBottom="10px"
            >
              <Pane marginBottom="10px">
                <Text fontWeight={600}>Provided wish details:</Text>
              </Pane>
              <Pane marginBottom="5px" display="flex">
                <Pane width="180px" flexShrink={0}>
                  <Text>Category:</Text>
                </Pane>
                <Text fontWeight={600}>{wish.category.name}</Text>
              </Pane>
              <Pane marginBottom="5px" display="flex">
                <Pane width="180px" flexShrink={0}>
                  <Text>Product Brand and Model:</Text>
                </Pane>
                <Text fontWeight={600}>{wish.description}</Text>
              </Pane>
              <Pane marginBottom="5px" display="flex">
                <Pane width="180px" flexShrink={0}>
                  <Text>User Description:</Text>
                </Pane>
                <Text fontWeight={600}>
                  {wish.similarProductUrl || '-'}
                  sdfdsf
                </Text>
              </Pane>
              <Pane marginBottom="5px" display="flex">
                <Pane width="180px" flexShrink={0}>
                  <Text>Exact Product Requested:</Text>
                </Pane>
                <Switch disabled checked={wish.isExactProductRequested} />
              </Pane>
              <Pane marginBottom="5px" display="flex">
                <Pane width="180px" flexShrink={0}>
                  <Text>Decision Point:</Text>
                </Pane>
                <Text fontWeight={600}>
                  {WISH_DECISION_POINT_LABELS[wish.decisionPoint]}
                </Text>
              </Pane>
              <Pane marginBottom="5px" display="flex">
                <Pane width="180px" flexShrink={0}>
                  <Text>Created at:</Text>
                </Pane>
                <Text fontWeight={600}>
                  {format(wish.createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                </Text>
              </Pane>
            </Pane>
            <Pane>
              <Pane marginBottom="10px">
                <Text fontWeight={600}>
                  Please request missing information from a client
                </Text>
              </Pane>
              <Pane>
                <Textarea
                  onChange={(e) => setMessageToCustomer(e.target.value)}
                  value={messageToCustomer}
                />
              </Pane>
            </Pane>
            <Dialog
              isShown={isOpenAreYouSureModal}
              title="Request missing details from customer"
              intent="success"
              onCloseComplete={() => setIsOpenAreYouSureModal(false)}
              onConfirm={(close) => {
                executeCreateWishThreadMutation({
                  input: {
                    text: messageToCustomer,
                    wishId: wish.id,
                  },
                })
                executeWishQuery()
                setMessageToCustomer('')
                setIsRequestDetailsModalOpen(false)
                close()
              }}
              confirmLabel="Send"
            >
              <Text fontWeight={600}>
                Do you really want to send message to customer?
              </Text>
            </Dialog>
          </Dialog>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

WishDetail.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(WishDetail)
