import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useQuery, useMutation } from 'urql'
import {
  Table,
  Spinner,
  Button,
  Dialog,
  TextInputField,
  Link,
  Text,
  toaster,
  majorScale,
} from 'evergreen-ui'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import UploadImageDialog from 'sharedComponents/UploadImageDialog'
import Pagination from 'components/Pagination'
import Dropzone from 'components/Dropzone'
import uploadRequest, { getIsFileSizeExceeded } from 'utils/uploadRequest'
import urql from 'utils/urql'
import ACTIONS from 'constants/actions'

const PreviewImage = styled.div`
  width: 200px;
  height: 90%;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  padding: ${majorScale(1)}px;
`

const TABLE_MIN_HEIGHT = 500

let isInitialMount = true

const ProductImages = ({ user }) => {
  const [isCreateProductImageModalOpen, setIsCreateProductImageModalOpen] =
    useState(false)
  const [editProductImageId, setEditProductImageId] = useState(null)
  const [name, setName] = useState('')
  const [isUpdateProductImageLoading, setIsUpdateProductImageLoading] =
    useState(false)
  const [deleteProdutImageId, setDeleteProductImageId] = useState(null)
  const [image, setImage] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [productImagesQuery, executeProductImagesQuery] = useQuery({
    query: `
      query($page: Int!) {
        productImages(pagination: { page: $page }) {
          paginationInfo {
            totalCount
            firstPage
            lastPage
            currentPage
            nextPage
            previousPage
          }
          data {
            id
            name
            url
            updatedAtUtc
          }
        }
      }
    `,
    variables: {
      page: currentPage,
    },
  })
  const paginationInfo =
    productImagesQuery.data &&
    productImagesQuery.data.productImages.paginationInfo
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    if (getIsFileSizeExceeded({ file })) {
      setImage(file)
      setImagePreview(URL.createObjectURL(file))
    }
  }, [])
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: Int!) {
      deleteProductImage(id: $id) {
        id
      }
    }
  `)
  const lastDeletedId =
    deleteMutation.data && deleteMutation.data.deleteProductImage.id
  useEffect(() => {
    if (!isInitialMount) {
      executeProductImagesQuery({ requestPolicy: 'network-only' })
    } else {
      isInitialMount = false
    }
  }, [lastDeletedId, executeProductImagesQuery])
  useEffect(() => {
    if (deleteMutation.error) {
      urql.handleError(deleteMutation.error)
    }
  }, [deleteMutation.error])
  const editedProducImage =
    productImagesQuery.data &&
    productImagesQuery.data.productImages.data.find(
      ({ id }) => id === editProductImageId
    )
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Product images</SideNavPage.Title>
        {user.hasAction(ACTIONS.PRODUCT_IMAGE.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setName('')
              setIsCreateProductImageModalOpen(true)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head height="auto" paddingY={12}>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>URL</Table.TextHeaderCell>
            <Table.TextHeaderCell />
          </Table.Head>
          <Table.Body minHeight={TABLE_MIN_HEIGHT}>
            {productImagesQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                delay={200}
                size={80}
              />
            )}
            {productImagesQuery.data &&
              productImagesQuery.data.productImages.data.map(
                ({ id, name: productImageName, url, updatedAtUtc }) => (
                  <Table.Row key={id + updatedAtUtc} height="100px">
                    <Table.Cell>
                      <PreviewImage src={`${url}?updated=${updatedAtUtc}`} />
                      <Text ml={majorScale(1)}>{productImageName}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Link
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {user.hasAction(ACTIONS.PRODUCT_IMAGE.UPDATE) && (
                        <Button
                          marginRight={12}
                          iconBefore="edit"
                          onClick={() => {
                            setName(productImageName)
                            setImage(null)
                            setImagePreview(null)
                            setEditProductImageId(id)
                          }}
                        >
                          Edit
                        </Button>
                      )}
                      {user.hasAction(ACTIONS.PRODUCT_IMAGE.DELETE) && (
                        <Button
                          marginRight={12}
                          iconBefore="trash"
                          intent="danger"
                          onClick={() => {
                            setDeleteProductImageId(id)
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
          </Table.Body>
        </Table>
        {paginationInfo && (
          <Pagination
            {...paginationInfo}
            onPageChange={(page) => {
              setCurrentPage(page)
            }}
          />
        )}
      </SideNavPage.Content>
      <UploadImageDialog
        title="Create product image"
        onClose={() => {
          setIsCreateProductImageModalOpen(false)
        }}
        isShown={isCreateProductImageModalOpen}
        onSuccess={(createdImage) => {
          executeProductImagesQuery({ requestPolicy: 'network-only' })
          toaster.success(
            `Product image "${createdImage.name}" has been created.`
          )
        }}
      />
      <Dialog
        isShown={editProductImageId !== null}
        title="Edit product image"
        hasCancel={false}
        shouldCloseOnEscapePress={false}
        confirmLabel="Edit"
        isConfirmLoading={isUpdateProductImageLoading}
        onCloseComplete={() => {
          setEditProductImageId(null)
        }}
        onConfirm={async (close) => {
          try {
            setIsUpdateProductImageLoading(true)
            await uploadRequest({
              accessToken: user.accessToken,
              body: {
                query: `
                  mutation($input: UpdateProductImageInput!) {
                    updateProductImage(input: $input) {
                      id
                      name
                      url
                    }
                  }
                `,
                variables: {
                  input: { id: editProductImageId, name, file: image },
                },
              },
            })
            setImage(null)
            setImagePreview(null)
            executeProductImagesQuery({ requestPolicy: 'network-only' })
            close()
            toaster.success(
              `Product image ${name && `"${name}"`} has been updated.`
            )
          } catch (error) {
            // do nothing
          } finally {
            setIsUpdateProductImageLoading(false)
          }
        }}
      >
        <TextInputField
          label="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value)
          }}
        />
        <Dropzone onDrop={onDrop} />
        <Image
          alt={name}
          src={imagePreview || (editedProducImage && editedProducImage.url)}
        />
      </Dialog>
      <Dialog
        isShown={deleteProdutImageId}
        title="Confirm delete product image"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setDeleteProductImageId(null)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id: deleteProdutImageId,
          })
          if (!error) {
            close()
          }
        }}
      >
        Are you sure you want to delete product image with id:{' '}
        {deleteProdutImageId}?
      </Dialog>
    </React.Fragment>
  )
}

ProductImages.propTypes = {
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
  }).isRequired,
}

export default withUser(ProductImages)
