import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Spinner } from 'evergreen-ui'
import { useQuery } from 'urql'
import Pagination from 'components/Pagination'
import ImagesGrid from '../ImagesGrid'
import { productImagesQueryString } from '../queries'

const imagesIncludesImage = (images, image) =>
  !!images.find(({ id }) => id === image.id)

const SelectImagesDialog = ({ onClose, isShown, images, onSuccess }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [productImagesQuery, executeProductImagesQuery] = useQuery({
    query: productImagesQueryString,
    variables: {
      page: currentPage,
    },
  })
  const [selectedImages, setSelectedImages] = useState(images || [])
  const paginationInfo =
    productImagesQuery.data &&
    productImagesQuery.data.productImages.paginationInfo
  const allImages =
    (productImagesQuery.data && productImagesQuery.data.productImages.data) ||
    []
  return (
    <Dialog
      isShown={isShown}
      title="Select Additional product images"
      onCloseComplete={onClose}
      confirmLabel="Select"
      onOpenComplete={() => {
        executeProductImagesQuery({ requestPolicy: 'network-only' })
      }}
      onConfirm={async (close) => {
        close()
        onSuccess(selectedImages)
      }}
    >
      {productImagesQuery.fetching && (
        <Spinner marginX="auto" marginY={250} delay={200} size={80} />
      )}
      {allImages.length && (
        <ImagesGrid>
          {allImages.map((image) => (
            <ImagesGrid.Item
              key={image.id}
              isSelected={
                selectedImages && imagesIncludesImage(selectedImages, image)
              }
              alt={image.name}
              src={image.url}
              onClick={() => {
                const updatedSelectedImages = imagesIncludesImage(
                  selectedImages,
                  image
                )
                  ? selectedImages.filter(({ id }) => id !== image.id)
                  : [...selectedImages, image]
                setSelectedImages(updatedSelectedImages)
              }}
            />
          ))}
        </ImagesGrid>
      )}
      {paginationInfo && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </Dialog>
  )
}

SelectImagesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
}

export default SelectImagesDialog
