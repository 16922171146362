import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DEAL_STATUSES, SORT_DIRECTION_ENUM } from 'constants/constants'
import { useMutation, useQuery } from 'urql'
import { compose } from 'recompose'
import { toaster } from 'evergreen-ui'
import { withUser } from 'UserProvider'
import ACTIONS from 'constants/actions'
import NoData from 'sharedComponents/NoData'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import ActionsRow from 'components/ActionsRow'
import EditModeButton from 'components/EditModeButton'
import EditableList, {
  rearangeItemsBasedOnNewLayout,
  moveItemInItemsToTheTop,
  getIdsOrderedByPositionIndex,
} from 'components/EditableList'
import { withRouter } from 'react-router-dom'
import {
  getDealOfTheDaysQuery,
  extractDataFromDealOfTheDaysQuery,
  updateDealOfTheDayPublishPositionsMutation,
} from '../queries'
import DeleteDoDDialog from '../../../components/DeleteDoDDialog'
import { DOD_ITEM_Y_MARGIN, DOD_ITEM_HEIGHT } from '../constants'
import DoDItem from '../components/DoDItem'
import withApproveDeclineMutations, {
  toastResultOfApproval,
} from '../../../components/withAprroveDeclineMutations'
import { getPathToDealOfTheDayDetail } from '../../../utils'

const { APPROVAL_NEEDED, APPROVED, DECLINED, DRAFT } = DEAL_STATUSES

const Next = ({
  user,
  history,
  executeApproveDealOfTheDayMutation,
  showDeclineDealOfTheDayDialog,
}) => {
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [newDealOfTheDays, setNewDealOfTheDays] = useState([])
  const [dealOfTheDaysQuery, executeDealOfTheDaysQuery] = useQuery(
    getDealOfTheDaysQuery(
      {
        status: [APPROVED, APPROVAL_NEEDED, DECLINED, DRAFT],
        pagination: { page: 0, limit: 200 },
        sorter: {
          field: 'positionIndexWeight',
          direction: SORT_DIRECTION_ENUM.ASC,
        },
      },
      `
        estimatedStartAtUtc
        positionIndexWeight
      `
    )
  )
  const [, executeUpdateDealOfTheDayPublishPositionsMutation] = useMutation(
    updateDealOfTheDayPublishPositionsMutation
  )
  const { dealOfTheDays } =
    extractDataFromDealOfTheDaysQuery(dealOfTheDaysQuery)
  const currentDealOfTheDays = isEditMode ? newDealOfTheDays : dealOfTheDays
  return (
    <div>
      <FullScreenLoading isVisible={dealOfTheDaysQuery.fetching} />
      <ActionsRow>
        {user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE_PUBLISH_POSITION) && (
          <EditModeButton
            isEditMode={isEditMode}
            onClick={async () => {
              if (isEditMode) {
                const { error } =
                  await executeUpdateDealOfTheDayPublishPositionsMutation({
                    ids: getIdsOrderedByPositionIndex(newDealOfTheDays),
                  })
                if (error) {
                  toaster.danger('Rearrangement failed.')
                } else {
                  executeDealOfTheDaysQuery({ requestPolicy: 'network-only' })
                  toaster.success('Rearrangement succeeded.')
                }
              } else {
                setNewDealOfTheDays(dealOfTheDays)
              }
              setIsEditMode(!isEditMode)
            }}
          />
        )}
      </ActionsRow>
      {dealOfTheDays && (
        <>
          <DeleteDoDDialog
            onSuccess={() => {
              executeDealOfTheDaysQuery({ requestPolicy: 'network-only' })
            }}
            onClose={() => {
              setItemToSoftDelete(null)
            }}
            itemToDelete={itemToSoftDelete}
          />
          <EditableList
            items={currentDealOfTheDays}
            isEditMode={isEditMode}
            onDragStop={(newLayout) => {
              setNewDealOfTheDays(
                rearangeItemsBasedOnNewLayout({
                  newLayout,
                  items: newDealOfTheDays,
                })
              )
            }}
            margin={[0, DOD_ITEM_Y_MARGIN]}
            rowHeight={DOD_ITEM_HEIGHT}
          >
            {(item) => (
              <div key={item.id}>
                <DoDItem
                  {...item}
                  isEditMode={isEditMode}
                  isToTheTopButtonVisible={user.hasAction(
                    ACTIONS.DEAL_OF_THE_DAY.UPDATE_PUBLISH_POSITION
                  )}
                  isWithApproveButton={
                    item.status === APPROVAL_NEEDED &&
                    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.APPROVE)
                  }
                  isWithDeclineButton={
                    item.status === APPROVAL_NEEDED &&
                    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.DECLINE)
                  }
                  onMoveItemToTopClick={async () => {
                    const { error } =
                      await executeUpdateDealOfTheDayPublishPositionsMutation({
                        ids: getIdsOrderedByPositionIndex(
                          moveItemInItemsToTheTop({
                            items: dealOfTheDays,
                            idOfItemToTop: item.id,
                          })
                        ),
                      })
                    if (error) {
                      toaster.danger('Rearrangement failed.')
                    } else {
                      toaster.success('Rearrangement succeeded.')
                      executeDealOfTheDaysQuery({
                        requestPolicy: 'network-only',
                      })
                    }
                  }}
                  onDetailClick={() =>
                    history.push(getPathToDealOfTheDayDetail(item.id))
                  }
                  onDeleteButtonClicked={() => setItemToSoftDelete(item)}
                  onApproveButtonClicked={async () => {
                    const result = await executeApproveDealOfTheDayMutation({
                      id: item.id,
                    })
                    toastResultOfApproval(result)
                  }}
                  onDeclineButtonClicked={() => {
                    showDeclineDealOfTheDayDialog({
                      dealOfTheDayId: item.id,
                    })
                  }}
                  isWithDeleteButton={user.hasAction(
                    ACTIONS.DEAL_OF_THE_DAY.DELETE
                  )}
                  isWithEditButton={
                    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE) ||
                    user.hasAction(
                      ACTIONS.DEAL_OF_THE_DAY.UPDATE_TRANSLATIONS
                    ) ||
                    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_TRANSLATIONS) ||
                    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_COMPETITOR)
                  }
                />
              </div>
            )}
          </EditableList>
        </>
      )}
      {!dealOfTheDaysQuery.fetching && dealOfTheDays.length === 0 && (
        <NoData padding={60} />
      )}
    </div>
  )
}

Next.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveDealOfTheDayMutation: PropTypes.func.isRequired,
  showDeclineDealOfTheDayDialog: PropTypes.func.isRequired,
}

export default compose(withUser, withRouter, withApproveDeclineMutations)(Next)
