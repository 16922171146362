import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import {
  Dialog,
  Button,
  Pane,
  Table,
  Spinner,
  TextInputField,
  Heading,
  Checkbox,
  majorScale,
} from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import find from 'lodash.find'
import ActionsRow from 'components/ActionsRow'
import ACTIONS from 'constants/actions'
import urql from 'utils/urql'

const TABLE_MIN_HEIGHT = 500

const brandFields = `{
  id
  name
  personalisationsProductCategoriesBrands {
    id
    name
  }
}`

// eslint-disable-next-line no-unused-vars
const Brands = ({ match, history, user }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)

  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [productCategories, setProductCategories] = useState([])

  const [brandsQuery] = useQuery({
    query: `
      query {
        personalisationsBrandsList ${brandFields}
      }
    `,
  })

  const [productCategoriesQuery] = useQuery({
    query: `
      query {
        personalisationsProductCategoriesList {
          id
          name
        }
      }
    `,
  })

  const [createMutation, executeCreateMutation] = useMutation(`
    mutation($input: CreatePersonalisationsBrandsInput!) {
      createPersonalisationsBrands(input: $input) {
        id
        name
        personalisationsProductCategoriesBrands {
          id
          name
        }
      }
    }
  `)

  const [updateMutation, executeUpdateMutation] = useMutation(`
    mutation($input: EditPersonalisationsBrandsInput!) {
      editPersonalisationsBrands(input: $input) {
        id
        name
        personalisationsProductCategoriesBrands {
          id
          name
        }
      }
    }
  `)

  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: Int!) {
      deletePersonalisationsBrands(id: $id) {
        id
        name
      }
    }
  `)

  useEffect(() => {
    const error =
      productCategoriesQuery.error ||
      createMutation.error ||
      updateMutation.error ||
      deleteMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [
    productCategoriesQuery.error,
    createMutation.error,
    updateMutation.error,
    deleteMutation.error,
  ])

  return (
    <Pane>
      <ActionsRow>
        {user.hasAction(ACTIONS.PERSONALISATION.CREATE) && (
          <Button
            marginLeft="0.5em"
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setTitle('')
              setIsCreateModalOpen(true)
            }}
          >
            Create New Brands
          </Button>
        )}
      </ActionsRow>
      <Table border style={{ marginTop: '10px' }}>
        <Table.Head>
          <Table.TextHeaderCell>Brands</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body minHeight={TABLE_MIN_HEIGHT}>
          {brandsQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {brandsQuery.data &&
            brandsQuery.data.personalisationsBrandsList.map((item) => (
              <Table.Row key={item.id}>
                <Table.TextCell>{item.name}</Table.TextCell>
                <Table.Cell>
                  {user.hasAction(ACTIONS.PERSONALISATION.UPDATE) && (
                    <Button
                      marginRight={12}
                      iconBefore="edit"
                      onClick={(e) => {
                        e.stopPropagation()
                        setId(item.id)
                        setTitle(item.name)
                        setProductCategories(
                          item.personalisationsProductCategoriesBrands
                        )
                        setIsUpdateModalOpen(true)
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {user.hasAction(ACTIONS.PERSONALISATION.DELETE) && (
                    <Button
                      marginRight={12}
                      iconBefore="trash"
                      intent="danger"
                      onClick={(e) => {
                        e.stopPropagation()
                        setId(item.id)
                        setIsConfirmDeleteModalOpen(true)
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Dialog
        isShown={isCreateModalOpen || isUpdateModalOpen}
        title={isCreateModalOpen ? 'Create Brand' : `Edit Brand: ${title}`}
        hasCancel={false}
        confirmLabel={isCreateModalOpen ? 'Create' : 'Edit'}
        isConfirmLoading={createMutation.fetching || updateMutation.fetching}
        onCloseComplete={() => {
          setIsCreateModalOpen(false)
          setIsUpdateModalOpen(false)
        }}
        onConfirm={async (close) => {
          if (isCreateModalOpen) {
            const input = {
              name: title,
              personalisationsProductCategories: productCategories.map(
                ({ id: personalisationsProductCategoriesId }) => ({
                  personalisationsProductCategoriesId,
                })
              ),
            }
            const { error } = await executeCreateMutation({
              input,
            })
            if (!error) {
              close()
            }
          }
          if (isUpdateModalOpen) {
            const input = {
              id,
              name: title,
              personalisationsProductCategories: productCategories.map(
                ({ id: personalisationsProductCategoriesId }) => ({
                  personalisationsProductCategoriesId,
                })
              ),
            }
            const { error } = await executeUpdateMutation({
              input,
            })
            if (!error) {
              close()
            }
          }
        }}
      >
        <TextInputField
          label="Name"
          required
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Product Categories
          </Heading>
          {productCategoriesQuery.data &&
            productCategoriesQuery.data.personalisationsProductCategoriesList.map(
              (category) => (
                <Checkbox
                  label={category.name}
                  checked={find(productCategories, { id: category.id })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setProductCategories((prevStateRoles) => [
                        ...prevStateRoles,
                        category,
                      ])
                    } else {
                      setProductCategories((prevStateRoles) =>
                        prevStateRoles.filter(
                          (prevStateRole) => prevStateRole.id !== category.id
                        )
                      )
                    }
                  }}
                />
              )
            )}
        </Pane>
      </Dialog>

      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete brand"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id,
          })
          if (!error) {
            setId('')
            close()
          }
        }}
      >
        Are you sure you want to delete Brand: {title} ??
      </Dialog>
    </Pane>
  )
}

Brands.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withRouter, withUser)(Brands)
