import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Pane } from 'evergreen-ui'
import Picker from 'components/Datepicker'

import { requestFileDownload } from 'utils/request'
import { getAccessToken } from 'UserProvider'

const initialDatetime = ''

const createBody = ({ from, to }) => {
  const body = {}
  if (from !== initialDatetime) {
    body.from = new Date(from).toISOString()
  }
  if (to !== initialDatetime) {
    body.to = new Date(to).toISOString()
  }
  return body
}

const ExportCsvDialogOpen = ({ isShown, onCloseComplete }) => {
  const [from, setFrom] = useState(initialDatetime)
  const [to, setTo] = useState(initialDatetime)
  const [isExportFetching, setIsExportFetching] = useState(false)

  return (
    <Dialog
      isShown={isShown}
      title="Filter and export to csv"
      onCloseComplete={() => {
        setFrom(initialDatetime)
        setTo(initialDatetime)
        onCloseComplete()
      }}
      onConfirm={async (close) => {
        setIsExportFetching(true)
        try {
          await requestFileDownload({
            url: `${process.env.REACT_APP_API_URL}/exports/customers`,
            accessToken: getAccessToken(),
            filename: `customers${Date.now()}.csv`,
            body: createBody({
              from,
              to,
            }),
          })
          close()
        } catch (e) {
          // do nothing
        } finally {
          setIsExportFetching(false)
        }
      }}
      isConfirmLoading={isExportFetching}
      confirmLabel={isExportFetching ? 'Exporting...' : 'Export'}
    >
      <Pane>
        <Picker.DateTime
          onChangeDate={(date) => setFrom(date)}
          date={from}
          label="From Local Datetime - Customer Register"
        />
        <Picker.DateTime
          onChangeDate={(date) => setTo(date)}
          date={to}
          label="To Local Datetime - Customer Register"
        />
      </Pane>
    </Dialog>
  )
}

ExportCsvDialogOpen.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onCloseComplete: PropTypes.func.isRequired,
}

export default ExportCsvDialogOpen
