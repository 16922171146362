import React from 'react'
import PropTypes from 'prop-types'
import { SelectField } from 'evergreen-ui'
import { useQuery } from 'urql'

const AttributeSelect = ({
  value,
  handleChange,
  hasDeselectOption,
  label,
  isRequired,
  isDisabled,
  isInvalid,
}) => {
  const [attributesQuery] = useQuery({
    query: `
      query($pagination: PaginationInput)  {
        attributes(pagination: $pagination) {
          data {
            id
            title
          }
        }
      }
    `,
    variables: {
      pagination: {
        limit: 1000,
      },
    },
  })
  return (
    <SelectField
      isInvalid={isInvalid}
      disabled={isDisabled || attributesQuery.fetching}
      required={isRequired}
      label={label}
      value={value}
      onChange={(e) => {
        handleChange(e.target.value)
      }}
      marginBottom={0}
    >
      {hasDeselectOption && (
        <option key="-" value="">
          ---Select Attribute---
        </option>
      )}
      {attributesQuery.data &&
        attributesQuery.data.attributes.data.map((attribute) => (
          <option key={attribute.id} value={attribute.id}>
            {attribute.title}
          </option>
        ))}
    </SelectField>
  )
}

AttributeSelect.defaultProps = {
  label: 'Attribute',
}

AttributeSelect.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  hasDeselectOption: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
}

export default AttributeSelect
