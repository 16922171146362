import React from 'react'

export const OptimizeImageInformation = () => (
  <div>
    <p>
      Always compress image before uploading it here:&nbsp;
      <a rel="noopener noreferrer" target="_blank" href="https://tinypng.com/">
        https://tinypng.com
      </a>
      <br />
      Remember - product photo should have maximally about ~500KB
    </p>
  </div>
)
