import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, toaster } from 'evergreen-ui'
import { useMutation } from 'urql'
import urql from 'utils/urql'

const productDeleteTexts = {
  title: 'Delete Product',
  successToast: 'Product successfully deleted.',
  getBodyText: ({ id, title }) =>
    `Do you really want to delete Product: "${title}" with id: "${id}"?`,
}

const productVariantDeleteTexts = {
  title: 'Delete Product Variant',
  successToast: 'Product Variant successfully deleted.',
  getBodyText: ({ id }) =>
    `Do you really want to delete Product Variant with id: "${id}"?`,
}

const DeleteDialog = ({
  onClose,
  itemToDelete,
  onSuccess,
  isProductVariantDialog,
}) => {
  const [, executeDeleteProductMutation] = useMutation(`
    mutation($id: Int!) {
      deleteProduct(id: $id) {
        id
      }
    }
  `)
  const [, executeDeleteProductVariantMutation] = useMutation(`
    mutation($id: Int!) {
      deleteProductVariant(id: $id) {
        id
      }
    }
  `)
  const texts = isProductVariantDialog
    ? productVariantDeleteTexts
    : productDeleteTexts
  return (
    <Dialog
      isShown
      title={texts.title}
      intent="danger"
      confirmLabel="Delete"
      onCloseComplete={onClose}
      onConfirm={async (close) => {
        let response
        if (isProductVariantDialog) {
          response = await executeDeleteProductVariantMutation({
            id: itemToDelete.id,
          })
        } else {
          response = await executeDeleteProductMutation({
            id: itemToDelete.id,
          })
        }
        if (response.error) {
          urql.handleError(response.error)
        } else {
          toaster.success(texts.successToast)
          if (onSuccess) {
            onSuccess()
          }
        }
        close()
      }}
    >
      {texts.getBodyText(itemToDelete)}
    </Dialog>
  )
}

DeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  itemToDelete: PropTypes.shape({
    product: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
  }),
  onSuccess: PropTypes.func,
  isProductVariantDialog: PropTypes.bool,
}
export default DeleteDialog
