import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { useMutation, useQuery } from 'urql'
import styled from 'styled-components/macro'
import { BackButton, Button, majorScale, toaster } from 'evergreen-ui'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import ROUTES from 'constants/routes'
import { DEAL_STATUSES } from 'constants/constants'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import { getPathToDealOfTheWeekDetail } from '../../utils'
import DeleteDoWDialog from '../../components/DeleteDoWDialog'
import EarlyEndDialog from '../../components/EarlyEndDialog'
import Form, { formatDataIntoInput } from '../../components/Form'
import withApproveDeclineMutations, {
  toastResultOfApproval,
} from '../../components/withAprroveDeclineMutations'
import Statistics from './Statistics'
import {
  pauseDealOfTheWeekMutation,
  resumeDealOfTheWeekMutation,
} from '../Management/queries'

const { DRAFT, APPROVAL_NEEDED, APPROVED, DECLINED, DELETED, LIVE, FINISHED } =
  DEAL_STATUSES

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const BUTTON_LEFT_MARGIN = `${majorScale(1)}px`
const StyledButton = styled(Button)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const StyledBackButton = styled(BackButton)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const getIsApprovalButtonDisplayed = (dealOfTheWeek, user) =>
  dealOfTheWeek.status === APPROVAL_NEEDED &&
  user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.APPROVE)

const getIsDeleteButtonDisplayed = (dealOfTheWeek, user) =>
  ([DRAFT, APPROVAL_NEEDED, APPROVED, DECLINED].includes(
    dealOfTheWeek.status
  ) &&
    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.DELETE)) ||
  (dealOfTheWeek.status === DELETED &&
    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.DESTROY))

const EditDoW = ({
  match,
  history,
  user,
  executeApproveDealOfTheWeekMutation,
  showDeclineDealOfTheWeekDialog,
}) => {
  const id = parseInteger(match.params.id)
  const [dealOfTheWeekQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query($id: Int!) {
        dealOfTheWeek(id: $id) {
          isPaused
          publishedAtUtc
          id
          product {
            id
            title
            shortDescription
            longDescription
            translations {
              language {
                isoCode
                title
              }
              languageIsoCode
              title
              shortDescription
              longDescription
            }
            reviewScore
            size
            warehouseDeliveryTimeInDays
            productCondition {
              id
              title
            }
            mainImage {
              id
              url
              name
            }
            additionalImages {
              id
              url
              name
            }
            seller {
              id
              accountName
            }
            externalPid
            modelName
          }
          stockQuantity
          startPrice
          endPrice
          priceIncreaseIntervalInMinutes
          status
          competitors {
            id
            title
            price
            country {
              isoCode
              title
            }
          }
          defaultCompetitorId
          changelog {
            createdByUser {
              name
            }
            oldStatus
            newStatus
            message
            createdAtUtc
          }
          comment
          earlyEndUtc
        }
      }
    `,
    variables: {
      id,
    },
  })
  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateDealOfTheWeekInput!) {
      createDealOfTheWeek(input: $input) {
        id
      }
    }
  `)
  const [, executeResumeMutation] = useMutation(resumeDealOfTheWeekMutation)
  const [, executePauseMutation] = useMutation(pauseDealOfTheWeekMutation)
  const dealOfTheWeek = dealOfTheWeekQuery.data
    ? dealOfTheWeekQuery.data.dealOfTheWeek
    : null
  const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false)
  const [isEarlyEndDialogOpened, setEarlyEndDialogOpened] = useState(false)
  const isDowStatusEditable =
    dealOfTheWeek &&
    (![LIVE, FINISHED].includes(dealOfTheWeek.status) || dealOfTheWeek.isPaused)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Edit Deal of the week</SideNavPage.Title>
        {dealOfTheWeek && (
          <div>
            {LIVE === dealOfTheWeek.status &&
              user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE_END) && (
                <StyledButton
                  onClick={() => {
                    setEarlyEndDialogOpened(true)
                  }}
                >
                  Set early end
                </StyledButton>
              )}
            {LIVE === dealOfTheWeek.status &&
              dealOfTheWeek.isPaused &&
              user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.RESUME) && (
                <StyledButton
                  intent="success"
                  appearance="primary"
                  iconBefore="play"
                  onClick={async () => {
                    const { error } = await executeResumeMutation({
                      id: dealOfTheWeek.id,
                    })
                    displayToast(error, 'Resumed')
                  }}
                >
                  RESUME
                </StyledButton>
              )}
            {LIVE === dealOfTheWeek.status &&
              !dealOfTheWeek.isPaused &&
              user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.PAUSE) && (
                <StyledButton
                  intent="warning"
                  appearance="primary"
                  iconBefore="pause"
                  onClick={async () => {
                    const { error } = await executePauseMutation({
                      id: dealOfTheWeek.id,
                    })
                    displayToast(error, 'Paused')
                  }}
                >
                  PAUSE
                </StyledButton>
              )}
            {getIsDeleteButtonDisplayed(dealOfTheWeek, user) && (
              <StyledButton
                intent="danger"
                appearance="primary"
                onClick={() => {
                  setDeleteDialogOpened(true)
                }}
              >
                {dealOfTheWeek.status === DELETED ? 'Destroy' : 'Delete'}
              </StyledButton>
            )}
            {getIsApprovalButtonDisplayed(dealOfTheWeek, user) && (
              <StyledButton
                intent="danger"
                onClick={async () => {
                  showDeclineDealOfTheWeekDialog({
                    dealOfTheWeekId: id,
                  })
                }}
              >
                Decline
              </StyledButton>
            )}
            {user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.COPY) && (
              <StyledButton
                onClick={async () => {
                  const { product, ...rest } = dealOfTheWeek
                  const createResult = await executeCreateMutation({
                    input: formatDataIntoInput({
                      ...rest,
                      ...product,
                      title: product.title,
                      productConditionId:
                        product.productCondition && product.productCondition.id,
                      sellerId: product.seller && product.seller.id,
                      isDraft: true,
                      isCopy: true,
                      comment: dealOfTheWeek.comment || undefined,
                    }),
                  })
                  if (createResult.error) {
                    urql.handleError(createResult.error)
                  } else {
                    toaster.success('Deal of the week created')
                    history.push(
                      getPathToDealOfTheWeekDetail(
                        createResult.data.createDealOfTheWeek.id
                      )
                    )
                  }
                }}
              >
                Copy
              </StyledButton>
            )}
            {getIsApprovalButtonDisplayed(dealOfTheWeek, user) && (
              <StyledButton
                intent="success"
                appearance="primary"
                onClick={async () => {
                  const result = await executeApproveDealOfTheWeekMutation({
                    id,
                  })
                  toastResultOfApproval(result)
                }}
              >
                Approve
              </StyledButton>
            )}
            <StyledBackButton onClick={() => history.goBack()} pl="0.5rem" />
          </div>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <FullScreenLoading isVisible={dealOfTheWeekQuery.fetching} />
        {dealOfTheWeek && (
          <React.Fragment>
            <DeleteDoWDialog
              itemToDelete={
                isDeleteDialogOpened
                  ? { id, product: dealOfTheWeek.product }
                  : null
              }
              isDestroyDialog={dealOfTheWeek.status === DELETED}
              onClose={() => {
                setDeleteDialogOpened(false)
              }}
              onSuccess={() => {
                if (
                  dealOfTheWeek.status === DELETED ||
                  !user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.DESTROY)
                ) {
                  history.replace(`/${ROUTES.DEAL_OF_THE_WEEKS}`)
                }
              }}
            />
            {isEarlyEndDialogOpened && (
              <EarlyEndDialog
                deal={dealOfTheWeek}
                onClose={() => {
                  setEarlyEndDialogOpened(false)
                }}
              />
            )}
            <Form
              key={dealOfTheWeek.id}
              data={dealOfTheWeek}
              isReadOnly={
                !isDowStatusEditable ||
                !user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE)
              }
              isCompetitorReadOnly={
                !isDowStatusEditable ||
                !user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_COMPETITOR)
              }
              isTranslationEditReadOnly={
                !isDowStatusEditable ||
                !user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE_TRANSLATIONS)
              }
              isTranslationAddReadOnly={
                !isDowStatusEditable ||
                !user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_TRANSLATIONS)
              }
            />
            {[LIVE, FINISHED].includes(dealOfTheWeek.status) && (
              <Statistics
                id={String(dealOfTheWeek.id)}
                publishedAtUtc={dealOfTheWeek.publishedAtUtc}
              />
            )}
          </React.Fragment>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

EditDoW.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveDealOfTheWeekMutation: PropTypes.func.isRequired,
  showDeclineDealOfTheWeekDialog: PropTypes.func.isRequired,
}

export default compose(withApproveDeclineMutations, withUser)(EditDoW)
