import React from 'react'
import PropTypes from 'prop-types'
import { Pane, majorScale } from 'evergreen-ui'

const Header = ({ children, ...rest }) => <Pane {...rest}>{children}</Pane>

Header.defaultProps = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: majorScale(5),
}

Header.propTypes = {
  display: PropTypes.string,
  justifyContent: PropTypes.string,
  margin: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default Header
