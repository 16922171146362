import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
import {
  Table,
  Spinner,
  Link,
  IconButton,
  Pane,
  FormField,
  Icon,
  majorScale,
} from 'evergreen-ui'
import styled from 'styled-components/macro'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import format from 'date-fns/format'
import Pagination from 'components/Pagination'
import WishBadge from 'components/WishBadge'
import { TIME_FORMAT, WISH_STATUSES } from 'constants/constants'
import ROUTES from 'constants/routes'
import ACTIONS from 'constants/actions'
import urql from 'utils/urql'
import { getPathToWishDetail } from 'routes/Wishes/utils'
import {
  acceptWishMutation,
  declineWishMutation,
  decideLaterWishMutation,
} from 'routes/Wishes/queries'
import { useThreads } from 'ThreadsProvider'
import { WISH_SORTER_FIELD_ENUM } from '../constants'
import TableSortableHeaderCell from './TableSortableHeaderCell'
import WishCategoriesSelectMenu from './WishCategoriesSelectMenu'
import { extractDataFromWishesQuery } from '../queries'

const TABLE_MIN_HEIGHT = 500

const StyledRow = styled(Table.Row)`
  ${({ isUnseen }) => isUnseen && 'background-color:  #F9F9FB'};
`

const WishList = ({
  history,
  user,
  wishesQuery,
  setCurrentPage,
  handleSortChange,
  currentSort,
  selectedCategoryIds,
  onCategorySelectChange,
  onChange,
}) => {
  const { wishes, paginationInfo } = extractDataFromWishesQuery(wishesQuery)
  const [, executeAcceptWishMutation] = useMutation(acceptWishMutation)
  const [, executeDeclineWishMutation] = useMutation(declineWishMutation)
  const [, executeDecideLaterWishMutation] = useMutation(
    decideLaterWishMutation
  )
  const { refetch } = useThreads()
  const handleButtonClick = async (e, mutation, id, isUnseen) => {
    e.stopPropagation()
    const { error } = await mutation({
      id,
    })
    if (onChange) {
      onChange()
    }
    if (isUnseen && refetch) {
      refetch()
    }
    if (error) {
      urql.handleError(error)
    }
  }
  return (
    <React.Fragment>
      <Pane
        display="flex"
        justifyContent="flex-end"
        marginBottom={majorScale(2)}
      >
        <FormField label="Filter by wish categories">
          <WishCategoriesSelectMenu
            onChange={onCategorySelectChange}
            selectedCategoryIds={selectedCategoryIds}
          />
        </FormField>
      </Pane>
      <Table border>
        <Table.Head>
          <Table.TextHeaderCell flexBasis={75} flexShrink={0} flexGrow={0}>
            ID
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>Customer</Table.TextHeaderCell>
          <Table.TextHeaderCell>Category</Table.TextHeaderCell>
          <Table.TextHeaderCell flexGrow="2">Status</Table.TextHeaderCell>
          <TableSortableHeaderCell
            currentSort={currentSort}
            sortField={WISH_SORTER_FIELD_ENUM.CREATED_AT_UTC}
            onSortChange={handleSortChange}
          >
            Created At
          </TableSortableHeaderCell>
          <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body minHeight={TABLE_MIN_HEIGHT}>
          {wishesQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {wishes.map(
            ({
              id,
              customer,
              category,
              status,
              createdAtUtc,
              lastSeenAtUtc,
              thread,
              hasUnseenOffers,
            }) => (
              <StyledRow
                key={id}
                isSelectable={user.hasAction(ACTIONS.WISH.DETAIL)}
                onSelect={() => history.push(getPathToWishDetail(id))}
                isUnseen={!lastSeenAtUtc}
              >
                <Table.TextCell flexBasis={75} flexShrink={0} flexGrow={0}>
                  <Pane display="flex" justifyContent="space-between">
                    <Pane>
                      {id}
                      {hasUnseenOffers && (
                        <Icon
                          marginLeft={majorScale(1)}
                          icon="symbol-circle"
                          color="purple"
                        />
                      )}
                    </Pane>
                    {thread && !thread.isSeenByUser ? (
                      <Icon icon="chat" color="danger" />
                    ) : null}
                  </Pane>
                </Table.TextCell>
                <Table.TextCell>
                  {customer &&
                    (user.hasAction(ACTIONS.CUSTOMER.DETAIL) ? (
                      <Link
                        onClick={(e) => {
                          e.stopPropagation()
                          history.push(`/${ROUTES.CUSTOMERS}/${customer.id}`)
                        }}
                      >
                        {customer.email}
                      </Link>
                    ) : (
                      customer.email
                    ))}
                  {!customer && `[DELETED]`}
                </Table.TextCell>
                <Table.TextCell>{category.name}</Table.TextCell>
                <Table.Cell flexGrow="2">
                  <WishBadge status={status} />
                </Table.Cell>
                <Table.TextCell>
                  {format(createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                </Table.TextCell>
                <Table.Cell>
                  {user.hasAction(ACTIONS.WISH.ACCEPT) &&
                    [
                      WISH_STATUSES.NEW,
                      WISH_STATUSES.DECIDE_LATER,
                      WISH_STATUSES.DECLINED,
                      WISH_STATUSES.OFFER_NOT_ACCEPTED,
                    ].includes(status) && (
                      <IconButton
                        icon="tick"
                        intent="success"
                        height={32}
                        title="Move to accepted"
                        appearance="minimal"
                        onClick={(e) => {
                          handleButtonClick(
                            e,
                            executeAcceptWishMutation,
                            id,
                            !lastSeenAtUtc
                          )
                        }}
                      />
                    )}
                  {user.hasAction(ACTIONS.WISH.DECIDE_LATER) &&
                    [
                      WISH_STATUSES.NEW,
                      WISH_STATUSES.DECLINED,
                      WISH_STATUSES.OFFER_NOT_ACCEPTED,
                    ].includes(status) && (
                      <IconButton
                        icon="time"
                        intent="warning"
                        height={32}
                        title="Move to decide later"
                        appearance="minimal"
                        onClick={(e) => {
                          handleButtonClick(
                            e,
                            executeDecideLaterWishMutation,
                            id,
                            !lastSeenAtUtc
                          )
                        }}
                      />
                    )}
                  {user.hasAction(ACTIONS.WISH.DECLINE) &&
                    [
                      WISH_STATUSES.NEW,
                      WISH_STATUSES.DECIDE_LATER,
                      WISH_STATUSES.ACCEPTED,
                      WISH_STATUSES.OFFER_CREATED,
                    ].includes(status) && (
                      <IconButton
                        icon="trash"
                        intent="danger"
                        height={32}
                        title="Move to trash"
                        appearance="minimal"
                        onClick={async (e) => {
                          handleButtonClick(
                            e,
                            executeDeclineWishMutation,
                            id,
                            !lastSeenAtUtc
                          )
                        }}
                      />
                    )}
                </Table.Cell>
              </StyledRow>
            )
          )}
        </Table.Body>
      </Table>
      {paginationInfo && wishes.length > 0 && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </React.Fragment>
  )
}

WishList.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  wishesQuery: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      userWishes: PropTypes.shape({
        data: PropTypes.array.isRequired,
        paginationInfo: PropTypes.object.isRequired,
      }),
    }),
  }),
  setCurrentPage: PropTypes.func.isRequired,
  currentSort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
  handleSortChange: PropTypes.func.isRequired,
  selectedCategoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onCategorySelectChange: PropTypes.func.isRequired,
}

export default compose(withRouter, withUser)(WishList)
