import * as Sentry from '@sentry/browser'

const init = () => {
  if (process.env.REACT_APP_BACKOFFICE_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_BACKOFFICE_SENTRY_DSN,
      environment: process.env.REACT_APP_BACKOFFICE_SENTRY_ENVIRONMENT,
    })
  }
}

const setUser = (user) => Sentry.setUser(user)
const captureException = (error) => Sentry.captureException(error)

export default { init, setUser, captureException }
