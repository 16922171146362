import React from 'react'
import PropTypes from 'prop-types'
import { SelectField } from 'evergreen-ui'
import { useQuery } from 'urql'

const CountrySelect = ({
  value,
  handleChange,
  hasDeselectOption,
  label,
  isShippingCountry,
  isRequired,
}) => {
  const [countriesQuery] = useQuery({
    query: `
      query {
        userCountries {
          isoCode
          title
          isShippingEnabled
        }
      }
    `,
  })
  return (
    <SelectField
      required={isRequired}
      label={label}
      value={value}
      onChange={(e) => {
        handleChange(e.target.value)
      }}
    >
      {hasDeselectOption && (
        <option key="-" value="">
          ---Select Country---
        </option>
      )}
      {countriesQuery.data &&
        countriesQuery.data.userCountries
          .filter(
            ({ isShippingEnabled }) => !isShippingCountry || isShippingEnabled
          )
          .map((country) => (
            <option key={country.isoCode} value={country.isoCode}>
              {`${country.title} (${country.isoCode})`}
            </option>
          ))}
    </SelectField>
  )
}

CountrySelect.defaultProps = {
  label: 'Country',
  isShippingCountry: false,
}

CountrySelect.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  hasDeselectOption: PropTypes.bool,
  isShippingCountry: PropTypes.bool,
  isRequired: PropTypes.bool,
}

export default CountrySelect
