import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Dialog, majorScale, Pane, TextInputField } from 'evergreen-ui'
import uploadRequest, { getIsFileSizeExceeded } from 'utils/uploadRequest'
import { withUser } from 'UserProvider'
import Dropzone from 'components/Dropzone'
import { createProductImageMutationString } from 'sharedComponents/queries'
import { OptimizeImageInformation } from 'components/OptimizeImageInformation'

const Image = styled.img`
  width: 100%;
  height: auto;
  padding: ${majorScale(1)}px;
`

const defaultNewImage = { objectUrl: '', name: '' }

const UploadImageDialog = ({
  onClose,
  isShown,
  mainImage,
  title,
  user,
  onSuccess,
}) => {
  const [newImage, setNewImage] = useState(
    mainImage
      ? { objectUrl: mainImage.url, name: mainImage.name }
      : defaultNewImage
  )
  const [isCreatingImage, setIsCreatingImage] = useState(false)
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    if (getIsFileSizeExceeded({ file })) {
      setNewImage({
        file,
        name: file.name,
        objectUrl: URL.createObjectURL(file),
      })
    }
  }, [])
  return (
    <Dialog
      isShown={isShown}
      title={title}
      onCloseComplete={() => {
        onClose()
        setNewImage(defaultNewImage)
      }}
      confirmLabel="Upload"
      isConfirmLoading={isCreatingImage}
      onConfirm={async (close) => {
        try {
          setIsCreatingImage(true)
          const request = await uploadRequest({
            accessToken: user.accessToken,
            body: {
              query: createProductImageMutationString,
              variables: {
                input: { name: newImage.name, file: newImage.file },
              },
            },
          })
          close()
          onSuccess(request.createProductImage)
        } catch (e) {
          // do nothing
        } finally {
          setIsCreatingImage(false)
        }
      }}
    >
      <Pane paddingBottom="16px">
        <OptimizeImageInformation />
        <TextInputField
          label="Name"
          onChange={(e) => {
            e.persist()
            setNewImage((currentNewImage) => ({
              ...currentNewImage,
              name: e.target ? e.target.value : '',
            }))
          }}
          value={newImage.name}
        />
        <Dropzone onDrop={onDrop} />
      </Pane>
      {newImage.objectUrl && <Image alt="preview" src={newImage.objectUrl} />}
    </Dialog>
  )
}

UploadImageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  mainImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
  }).isRequired,
}

export default withUser(UploadImageDialog)
