import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import EditFeed from './routes/EditFeed'
import NewFeed from './routes/NewFeed'
import FeedsList from './routes/FeedsList'

const Feeds = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/new`} component={NewFeed} />
    <Route path={`${match.url}/edit/:feedId`} component={EditFeed} />
    <Route component={FeedsList} path={`${match.url}`} />
  </Switch>
)

Feeds.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default Feeds
