import SideNavPage from './SideNavPage'
import Title from './Title'
import Header from './Header'
import Content from './Content'
import TitleWithBackButton from './TitleWithBackButton'

SideNavPage.Title = Title
SideNavPage.Header = Header
SideNavPage.Content = Content
SideNavPage.TitleWithBackButton = TitleWithBackButton

export default SideNavPage
