import PropTypes from 'prop-types'
import React from 'react'
import { Icon, Pane } from 'evergreen-ui'
import styled from 'styled-components/macro'
import { borderRadius } from 'Theme'

const MediaIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const Thumb = styled.img`
  position: relative;
  border-radius: ${borderRadius.SMALL}px;
  max-width: 100%;
  max-height: 100%;
`

const ItemImage = ({ image }) => (
  <Pane
    height={100}
    width={133}
    minWidth={133}
    position="relative"
    display="block"
    borderRadius={borderRadius.SMALL}
  >
    {image ? (
      <Thumb src={image.url} alt={image.name} />
    ) : (
      <MediaIcon icon="media" size={50} />
    )}
  </Pane>
)

ItemImage.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

export default ItemImage
