import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import styled from 'styled-components/macro'
import {
  Table,
  Label,
  Pane,
  majorScale,
  Button,
  Tooltip,
  Icon,
  Text,
} from 'evergreen-ui'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import find from 'lodash.find'
import { getPathToProductVariant } from '../utils'
import DeleteDialog from './DeleteDialog'

const PreviewImage = styled.div`
  width: 120px;
  height: 90%;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

const VariantList = ({
  user,
  history,
  productVariants,
  productId,
  productAttributeValues,
  onVariantDeleteSuccess,
}) => {
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false)
  const [productVariantToDelete, setProductVariantToDelete] = useState({})
  const variantCreatorAttributes = productAttributeValues.filter(
    ({ isVariantCreator }) => isVariantCreator
  )
  return (
    <Pane marginTop={majorScale(2)}>
      <Label marginBottom={majorScale(1)} display="block">
        Product Variants
        <Tooltip content="Every product needs to have at least one product variant even if thare are no variant creator attributes">
          <Icon marginLeft={majorScale(1)} icon="info-sign" color="info" />
        </Tooltip>
      </Label>
      <Button
        marginBottom={majorScale(1)}
        onClick={() =>
          history.push(getPathToProductVariant({ id: '', productId }))
        }
      >
        Add Variant
      </Button>
      <Table border marginTop={majorScale(1)}>
        <Table.Head>
          <Table.TextHeaderCell flexBasis={50} flexGrow={0}>
            ID
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={100} flexGrow={0}>
            Image
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={150} flexGrow={0}>
            EAN
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>Attributes</Table.TextHeaderCell>
          <Table.TextHeaderCell align="center">Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {productVariants.map(
            ({ id, mainImage, ean, productVariantAttributeValues }) => (
              <Table.Row
                minHeight="60px"
                height="auto"
                key={id}
                isSelectable={user.hasAction(ACTIONS.PRODUCT_VARIANT.DETAIL)}
                onSelect={() =>
                  history.push(getPathToProductVariant({ id, productId }))
                }
              >
                <Table.TextCell flexBasis={50} flexGrow={0}>
                  {id}
                </Table.TextCell>
                <Table.Cell flexBasis={100} flexGrow={0}>
                  {mainImage && <PreviewImage src={mainImage.url} />}
                </Table.Cell>
                <Table.TextCell flexBasis={150} flexGrow={0}>
                  {ean}
                </Table.TextCell>
                <Table.TextCell paddingY={majorScale(1)}>
                  {variantCreatorAttributes.map(({ attribute }) => {
                    const attributeValue = find(productVariantAttributeValues, {
                      attributeValue: { attributeId: attribute.id },
                    })
                    return (
                      <Pane key={attribute.id}>
                        {attribute.title}:{' '}
                        {attributeValue ? (
                          attributeValue.attributeValue.title
                        ) : (
                          <Text size={300} color="red">
                            missing value
                          </Text>
                        )}
                      </Pane>
                    )
                  })}
                </Table.TextCell>
                <Table.Cell justifyContent="center">
                  {user.hasAction(ACTIONS.PRODUCT_VARIANT.DELETE) && (
                    <Button
                      intent="danger"
                      onClick={(e) => {
                        e.stopPropagation()
                        setProductVariantToDelete({ id })
                        setIsDeleteDialogOpened(true)
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
      {isDeleteDialogOpened && productVariantToDelete.id && (
        <DeleteDialog
          itemToDelete={productVariantToDelete}
          onSuccess={() =>
            onVariantDeleteSuccess(
              productVariants.filter(
                (productVariant) =>
                  productVariant.id !== productVariantToDelete.id
              )
            )
          }
          isProductVariantDialog
          onClose={() => {
            setIsDeleteDialogOpened(false)
          }}
        />
      )}
    </Pane>
  )
}

VariantList.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  productVariants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      mainImage: PropTypes.object,
      ean: PropTypes.string,
      productVariantAttributeValues: PropTypes.array,
    })
  ),
  productId: PropTypes.number.isRequired,
  productAttributeValues: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.object,
      isVariantCreator: PropTypes.bool,
    })
  ),
  onVariantDeleteSuccess: PropTypes.func,
}

export default compose(withRouter, withUser)(VariantList)
