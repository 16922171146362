import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import styled from 'styled-components/macro'
import { Button, BackButton, majorScale, toaster } from 'evergreen-ui'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import ROUTES from 'constants/routes'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import DeleteDialog from '../../components/DeleteDialog'
import Form, { formatDataIntoInput } from '../../components/ProductForm'
import { getPathToProduct } from '../../utils'

const BUTTON_LEFT_MARGIN = `${majorScale(1)}px`

const StyledButton = styled(Button)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`
const StyledBackButton = styled(BackButton)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const EditProduct = ({ match, history, user }) => {
  const id = parseInteger(match.params.id)
  const [productQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query($id: Int!) {
        product(id: $id) {
          id
          title
          shortDescription
          longDescription
          shortDescriptionHtml
          longDescriptionHtml
          pictureSpatial
          translations {
            language {
              isoCode
              title
            }
            languageIsoCode
            title
            shortDescription
            longDescription
          }
          reviewScore
          size
          warehouseDeliveryTimeInDays
          productCondition {
            id
            title
          }
          mainImage {
            id
            url
            name
          }
          additionalImages {
            id
            url
            name
          }
          seller {
            id
            accountName
          }
          externalPid
          category {
            id
          }
          isPhoneServiceEnabled
          isDraft
          productAttributeValues {
            isVariantCreator
            attribute {
              id
              title
            }
            attributeValue {
              id
              title
            }
          }
          productVariants {
            id
            mainImage {
              id
              url
            }
            additionalImages {
              id
            }
            ean
            productVariantAttributeValues {
              productId
              attributeValue {
                id
                attributeId
                title
              }
            }
          }
        }
      }
    `,
    variables: {
      id,
    },
  })
  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateProductInput!) {
      createProduct(input: $input) {
        id
      }
    }
  `)
  const product = productQuery.data ? productQuery.data.product : null
  const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Edit Product</SideNavPage.Title>
        {product && (
          <div>
            {user.hasAction(ACTIONS.PRODUCT.DELETE) && (
              <StyledButton
                intent="danger"
                appearance="primary"
                onClick={() => {
                  setDeleteDialogOpened(true)
                }}
              >
                Delete
              </StyledButton>
            )}
            {user.hasAction(ACTIONS.PRODUCT.CREATE) && (
              <StyledButton
                onClick={async () => {
                  const createResult = await executeCreateMutation({
                    input: formatDataIntoInput({
                      ...product,
                      title: product.title,
                      productConditionId:
                        product.productCondition && product.productCondition.id,
                      isCopy: true,
                      isDraft: true,
                    }),
                  })
                  if (createResult.error) {
                    urql.handleError(createResult.error)
                  } else {
                    toaster.success('Product was created')
                    history.push(
                      getPathToProduct({
                        productId: createResult.data.createProduct.id,
                      })
                    )
                  }
                }}
              >
                Copy
              </StyledButton>
            )}
            <StyledBackButton onClick={() => history.goBack()} pl="0.5rem" />
          </div>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <FullScreenLoading isVisible={productQuery.fetching} />
        {product && (
          <React.Fragment>
            {isDeleteDialogOpened && (
              <DeleteDialog
                itemToDelete={{ id, title: product.title }}
                onClose={() => {
                  setDeleteDialogOpened(false)
                }}
                onSuccess={() => history.replace(`/${ROUTES.PRODUCTS}`)}
              />
            )}
            <Form key={product.id} data={product} />
          </React.Fragment>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

EditProduct.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(EditProduct)
