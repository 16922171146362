import React from 'react'
import PropTypes from 'prop-types'
import SideNavPage from 'sharedComponents/SideNavPage'
import { BackButton } from 'evergreen-ui'
import Form from './Form'

const NewBestSeller = ({ history }) => (
  <React.Fragment>
    <SideNavPage.Header>
      <SideNavPage.Title>Create BestSeller</SideNavPage.Title>
      <BackButton onClick={() => history.goBack()} />
    </SideNavPage.Header>
    <SideNavPage.Content>
      <Form />
    </SideNavPage.Content>
  </React.Fragment>
)

NewBestSeller.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
}
export default NewBestSeller
