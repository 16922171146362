import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Spinner,
  BackButton,
  Link,
  Button,
  Pane,
  majorScale,
  Label,
  Textarea,
  toaster,
  Text,
} from 'evergreen-ui'
import { useQuery, useMutation } from 'urql'
import { withUser } from 'UserProvider'
import format from 'date-fns/format'
import urql from 'utils/urql'
import { TIME_FORMAT } from 'constants/constants'
import ROUTES from 'constants/routes'
import ACTIONS from 'constants/actions'
import SideNavPage from 'sharedComponents/SideNavPage'
import FeedbackBadge from 'components/FeedbackBadge'
import { parseInteger } from 'utils/numbers'
import MoveFeedbackMenu from 'routes/Feedback/components/MoveFeedbackMenu'
import { moveFeedbackToArchiveMutation } from '../Management/queries'

const TABLE_MIN_HEIGHT = 400

const FeedbackDetail = ({ match, history, user }) => {
  const [feedbackQuery] = useQuery({
    query: `
      query($id: Int!) {
        feedback(id: $id) {
          id
          customer {
            id
            email
          }
          isPositive
          type
          text
          createdAtUtc
          comment
          isArchived
          thread {
            id
          }
        }
      }
    `,
    variables: {
      id: parseInteger(match.params.id),
    },
  })
  const [, executeUpdateCommentMutation] = useMutation(`
    mutation updateFeedbackComment($input: UpdateFeedbackCommentInput!) {
      updateFeedbackComment(input: $input) {
        id
      }
    }
  `)
  const [, executeCreateFeedbackThreadMutation] = useMutation(`
    mutation createFeedbackThread($feedbackId: Int!) {
      createFeedbackThread(feedbackId: $feedbackId) {
        id
      }
    }
  `)
  const [, executeMoveFeedbackToArchiveMutation] = useMutation(
    moveFeedbackToArchiveMutation
  )
  const [comment, setComment] = useState('')
  useEffect(() => {
    if (feedbackQuery.data && feedbackQuery.data.feedback) {
      setComment(feedbackQuery.data.feedback.comment || '')
    }
  }, [feedbackQuery.data])
  useEffect(() => {
    if (feedbackQuery.error) {
      urql.handleError(feedbackQuery.error)
    }
  }, [feedbackQuery.error])
  const feedback = feedbackQuery.data && feedbackQuery.data.feedback
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>
          {`Feedback detail: ${match.params.id}`}
        </SideNavPage.Title>
        <Pane display="flex">
          {feedback && (
            <>
              {!feedback.thread &&
                user.hasAction(ACTIONS.THREAD.CREATE_FOR_FEEDBACK) && (
                  <Button
                    marginRight={majorScale(1)}
                    onClick={async () => {
                      const { data, error } =
                        await executeCreateFeedbackThreadMutation({
                          feedbackId: feedback.id,
                        })
                      if (error) {
                        urql.handleError(error)
                      } else if (
                        data.createFeedbackThread.id &&
                        user.hasAction(ACTIONS.THREAD.DETAIL)
                      ) {
                        history.push(
                          `/${ROUTES.MESSAGES}/${data.createFeedbackThread.id}`
                        )
                      } else {
                        toaster.success('Thread created')
                      }
                    }}
                  >
                    Create message thread
                  </Button>
                )}
              {feedback.thread && user.hasAction(ACTIONS.THREAD.DETAIL) && (
                <Button
                  marginRight={majorScale(1)}
                  onClick={() => {
                    history.push(`/${ROUTES.MESSAGES}/${feedback.thread.id}`)
                  }}
                >
                  Go to feedback messages
                </Button>
              )}
              {(user.hasAction(ACTIONS.FEEDBACK.MOVE_TO_CUSTOMER_SUPPORT) ||
                user.hasAction(ACTIONS.FEEDBACK.MOVE_TO_PRODUCT_FEEDBACK)) && (
                <MoveFeedbackMenu id={feedback.id} type={feedback.type}>
                  <Button marginRight={majorScale(1)}>Type</Button>
                </MoveFeedbackMenu>
              )}
              {!feedback.isArchived &&
                user.hasAction(ACTIONS.FEEDBACK.MOVE_TO_ARCHIVE) && (
                  <Button
                    onClick={async (e) => {
                      e.stopPropagation()
                      const { error } =
                        await executeMoveFeedbackToArchiveMutation({
                          id: feedback.id,
                        })
                      if (error) {
                        urql.handleError(error)
                      }
                    }}
                    intent="danger"
                    marginRight={majorScale(1)}
                  >
                    Archive
                  </Button>
                )}
            </>
          )}
          <BackButton onClick={() => history.goBack()} />
        </Pane>
      </SideNavPage.Header>
      <SideNavPage.Content>
        {feedbackQuery.fetching && (
          <Spinner
            marginX="auto"
            marginY={TABLE_MIN_HEIGHT / 2}
            delay={200}
            size={80}
          />
        )}
        {feedback && (
          <>
            <Table border>
              <Table.Body>
                <Table.Row>
                  <Table.TextHeaderCell>ID</Table.TextHeaderCell>
                  <Table.TextCell>{feedback.id}</Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Type</Table.TextHeaderCell>
                  <Table.Cell>
                    <FeedbackBadge type={feedback.type} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Customer</Table.TextHeaderCell>
                  <Table.Cell>
                    {feedback.customer &&
                      (user.hasAction(ACTIONS.CUSTOMER.DETAIL) ? (
                        <Link
                          onClick={(e) => {
                            e.stopPropagation()
                            history.push(
                              `/${ROUTES.CUSTOMERS}/${feedback.customer.id}`
                            )
                          }}
                        >
                          {feedback.customer.email}
                        </Link>
                      ) : (
                        feedback.customer.email
                      ))}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Positive</Table.TextHeaderCell>
                  {feedback.isPositive === null ? (
                    <Table.TextCell> </Table.TextCell>
                  ) : (
                    <Table.TextCell>
                      {feedback.isPositive ? 'YES' : 'NO'}
                    </Table.TextCell>
                  )}
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Archived</Table.TextHeaderCell>
                  <Table.TextCell>
                    {feedback.isArchived ? 'YES' : 'NO'}
                  </Table.TextCell>
                </Table.Row>
                <Table.Row height="auto" paddingY={majorScale(2)}>
                  <Table.TextHeaderCell>Text</Table.TextHeaderCell>
                  <Table.Cell>
                    <Text>{feedback.text}</Text>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
                  <Table.TextCell>
                    {format(feedback.createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                  </Table.TextCell>
                </Table.Row>
                <Table.Row height="auto">
                  <Table.HeaderCell>
                    <Label htmlFor="textarea-comment">Comment</Label>
                  </Table.HeaderCell>
                  <Table.Cell>
                    <Textarea
                      id="textarea-comment"
                      marginY={majorScale(1)}
                      disabled={
                        !user.hasAction(ACTIONS.FEEDBACK.UPDATE_COMMENT)
                      }
                      value={comment || ''}
                      onChange={({ target }) => {
                        setComment(target.value)
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                {user.hasAction(ACTIONS.FEEDBACK.UPDATE_COMMENT) && (
                  <Table.Row>
                    <Table.Cell justifyContent="center">
                      <Button
                        intent="success"
                        marginRight={majorScale(2)}
                        onClick={async () => {
                          const { error } = await executeUpdateCommentMutation({
                            input: { id: feedback.id, comment },
                          })
                          if (error) {
                            urql.handleError(error)
                          } else {
                            toaster.success('Saved')
                          }
                        }}
                      >
                        Save
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

FeedbackDetail.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(FeedbackDetail)
