import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { SelectField } from 'evergreen-ui'

const SellersSelect = ({ setSellerId, sellerId, isReadOnly, required }) => {
  const [sellersQuery] = useQuery({
    query: `
      query {
        sellers {
          id
          accountName
        }
      }
    `,
  })
  return (
    <SelectField
      disabled={isReadOnly}
      required={required}
      label="Seller"
      value={sellerId || ''}
      onChange={({ target }) => {
        setSellerId(target.value === '' ? undefined : target.value)
      }}
    >
      <option value="">---Select Product Seller---</option>
      {sellersQuery.data &&
        sellersQuery.data.sellers.map(({ id, accountName }) => (
          <option key={id} value={id}>
            {accountName}
          </option>
        ))}
    </SelectField>
  )
}

SellersSelect.propTypes = {
  required: PropTypes.bool,
  setSellerId: PropTypes.func.isRequired,
  sellerId: PropTypes.string,
  isReadOnly: PropTypes.bool,
}

export default SellersSelect
