import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { IconButton, Pane } from 'evergreen-ui'
import Title from './Title'

const TitleWithBackButton = ({ history, children }) => (
  <Pane alignItems="center" display="flex">
    <IconButton
      display="inline-block"
      icon="chevron-left"
      height={28}
      iconSize={24}
      marginRight="8px"
      appearance="minimal"
      title="Back"
      onClick={() => {
        history.goBack()
      }}
    />
    <Title>{children}</Title>
  </Pane>
)

TitleWithBackButton.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
}

export default withRouter(TitleWithBackButton)
