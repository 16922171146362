import React from 'react'
import PropTypes from 'prop-types'
import MultiSelectMenu from 'components/MultiSelectMenu'
import { ORDER_STATUSES } from 'constants/constants'
import { getOrderLabelByStatus } from 'components/OrderStatus'

const OrderStatusesSelectMenu = ({ selectedStatuses, onChange }) => (
  <MultiSelectMenu
    selectedValues={selectedStatuses}
    onChange={onChange}
    title="Select multiple statuses:"
    placeholder="Select multiple statuses..."
    options={Object.values(ORDER_STATUSES).map((status) => ({
      value: status,
      label: getOrderLabelByStatus(status),
    }))}
  />
)

OrderStatusesSelectMenu.propTypes = {
  selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default OrderStatusesSelectMenu
