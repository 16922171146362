import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'evergreen-ui'
import { PUBLIC_ORDER_STATUSES } from 'constants/constants'

const getStatusColor = (status) => {
  switch (status) {
    case PUBLIC_ORDER_STATUSES.WAITING_PAYMENT:
      return 'yellow'
    case PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION:
      return 'yellow'
    case PUBLIC_ORDER_STATUSES.PROCESSING:
      return 'blue'
    case PUBLIC_ORDER_STATUSES.SHIPPING:
      return 'blue'
    case PUBLIC_ORDER_STATUSES.COMPLETED:
      return 'green'
    case PUBLIC_ORDER_STATUSES.ON_HOLD:
      return 'neutral'
    case PUBLIC_ORDER_STATUSES.REFUNDED:
      return 'purple'
    case PUBLIC_ORDER_STATUSES.CANCELLED_BY_USER:
    case PUBLIC_ORDER_STATUSES.CANCELLED:
      return 'red'
    default:
      return status
  }
}

const getOrderLabelByStatus = (status) => {
  switch (status) {
    case PUBLIC_ORDER_STATUSES.WAITING_PAYMENT:
      return 'Waiting Payment'
    case PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION:
      return 'Waiting Verification'
    case PUBLIC_ORDER_STATUSES.PROCESSING:
      return 'Processing'
    case PUBLIC_ORDER_STATUSES.SHIPPING:
      return 'Shipping'
    case PUBLIC_ORDER_STATUSES.COMPLETED:
      return 'Completed'
    case PUBLIC_ORDER_STATUSES.ON_HOLD:
      return 'On Hold'
    case PUBLIC_ORDER_STATUSES.REFUNDED:
      return 'Refunded'
    case PUBLIC_ORDER_STATUSES.CANCELLED_BY_USER:
      return 'Canceled By User'
    case PUBLIC_ORDER_STATUSES.CANCELLED:
      return 'Canceled'
    default:
      return status
  }
}

const PublicOrderStatus = ({ status, onClick }) => (
  <Badge
    color={getStatusColor(status)}
    marginBottom={5}
    isSolid
    isInteractive={!!onClick}
    onClick={onClick}
  >
    {getOrderLabelByStatus(status)}
  </Badge>
)

PublicOrderStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(PUBLIC_ORDER_STATUSES)).isRequired,
  onClick: PropTypes.func,
}

export default PublicOrderStatus
