import styled from 'styled-components/macro'
import {
  space,
  minWidth,
  width,
  maxWidth,
  minHeight,
  height,
  maxHeight,
  flex,
  order,
  color,
} from 'styled-system'

const Box = styled.div`
  ${space}
  ${minWidth}
  ${width}
  ${maxWidth}
  ${minHeight}
  ${height}
  ${maxHeight}
  ${flex}
  ${order}
  ${color}
`

Box.propTypes = {
  ...space.propTypes,
  ...minWidth.propTypes,
  ...width.propTypes,
  ...maxWidth.propTypes,
  ...minHeight.propTypes,
  ...height.propTypes,
  ...maxHeight.propTypes,
  ...flex.propTypes,
  ...order.propTypes,
  ...color.propTypes,
}

export default Box
