import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import format from 'date-fns/format'
import { IconButton, Pane, Table } from 'evergreen-ui'
import { useQuery } from 'urql'
import urql from 'utils/urql'
import { withUser } from 'UserProvider'
import NoData from 'sharedComponents/NoData'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import SearchInput from 'sharedComponents/SearchInput'
import ActionsRow from 'components/ActionsRow'
import {
  DEAL_STATUSES,
  SORT_DIRECTION_ENUM,
  TIME_FORMAT,
} from 'constants/constants'
import ACTIONS from 'constants/actions'
import Pagination from 'components/Pagination'
import DealBadge from 'components/DealBadge'
import SortableTableHeaderCell from 'components/SortableTableHeaderCell'
import ExportDoDOrdersButton from '../components/ExportDodOrdersButton'
import { getPathToDealOfTheDayDetail } from '../../../utils'

const { FINISHED } = DEAL_STATUSES

export const extractData = (dealOfTheDaysQuery) => {
  const dealOfTheDays = dealOfTheDaysQuery.data
    ? dealOfTheDaysQuery.data.dealOfTheDays.data
    : []
  const paginationInfo =
    dealOfTheDaysQuery.data &&
    dealOfTheDaysQuery.data.dealOfTheDays.paginationInfo
  return { dealOfTheDays, paginationInfo }
}

const Archived = ({ history, user }) => {
  const [searchString, setSearchString] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [sorter, setSorter] = useState({
    field: 'publishedAtUtc',
    direction: SORT_DIRECTION_ENUM.DESC,
  })
  const [dealOfTheDaysQuery] = useQuery({
    query: `
      query dealOfTheDays($status: [DealOfTheDayStatus!], $pagination: PaginationInput, $search: String, $sorter: DealOfTheDaySorterInput ) {
        dealOfTheDays(status: $status, pagination: $pagination, search: $search, sorter: $sorter) {
          paginationInfo {
            totalCount
            firstPage
            currentPage
            nextPage
            previousPage
            lastPage
          }
          data {
            id
            product {
              title
              shortDescription
              productCondition {
                title
              }
            }
            status
            stockQuantity
            publishedAtUtc
            createdAtUtc
          }
        }
      }
    `,
    variables: {
      status: [FINISHED],
      search: searchString,
      pagination: { page: currentPage, limit: 20 },
      sorter,
    },
    requestPolicy: 'network-only',
  })
  useEffect(() => {
    const { error } = dealOfTheDaysQuery
    if (error) {
      urql.handleError(error)
    }
  }, [dealOfTheDaysQuery, dealOfTheDaysQuery.error])
  const { dealOfTheDays, paginationInfo } = extractData(dealOfTheDaysQuery)

  return (
    <Pane>
      <FullScreenLoading isVisible={dealOfTheDaysQuery.fetching} />
      <ActionsRow>
        <SearchInput onChange={setSearchString} />
      </ActionsRow>
      <Table border marginTop="1em">
        <Table.Head>
          <SortableTableHeaderCell
            sorter={sorter}
            sortField="productTitle"
            onClick={setSorter}
          >
            Title
          </SortableTableHeaderCell>
          <Table.TextHeaderCell>Short Description</Table.TextHeaderCell>
          <Table.TextHeaderCell>Stock Quantity</Table.TextHeaderCell>
          <Table.TextHeaderCell>Status</Table.TextHeaderCell>
          <Table.TextHeaderCell>Product Condition</Table.TextHeaderCell>
          <SortableTableHeaderCell
            sorter={sorter}
            sortField="publishedAtUtc"
            onClick={setSorter}
          >
            Published at
          </SortableTableHeaderCell>
          <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {dealOfTheDays.map((dealOfTheDay) => (
            <Table.Row height="auto" minHeight="45px" key={dealOfTheDay.id}>
              <Table.TextCell>{dealOfTheDay.product.title}</Table.TextCell>
              <Table.TextCell>
                {dealOfTheDay.product.shortDescription}
              </Table.TextCell>
              <Table.TextCell>{dealOfTheDay.stockQuantity}</Table.TextCell>
              <Table.TextCell>
                <DealBadge status={dealOfTheDay.status} />
              </Table.TextCell>
              <Table.TextCell>
                {dealOfTheDay.product.productCondition &&
                  dealOfTheDay.product.productCondition.title}
              </Table.TextCell>
              <Table.TextCell>
                {format(
                  dealOfTheDay.publishedAtUtc,
                  TIME_FORMAT.LOCAL_DATETIME
                )}
              </Table.TextCell>
              <Table.TextCell>
                {format(dealOfTheDay.createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
              </Table.TextCell>
              <Table.Cell>
                {user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE) && (
                  <IconButton
                    marginRight={12}
                    icon="edit"
                    title="Edit dod item"
                    appearance="minimal"
                    onClick={(e) => {
                      e.stopPropagation()
                      history.push(getPathToDealOfTheDayDetail(dealOfTheDay.id))
                    }}
                  />
                )}
                {user.hasAction(ACTIONS.DEAL_OF_THE_DAY.EXPORT_CSV) && (
                  <ExportDoDOrdersButton dealOfTheDayId={dealOfTheDay.id} />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {!dealOfTheDaysQuery.fetching && dealOfTheDays.length === 0 && (
          <NoData padding={60} />
        )}
        {paginationInfo && (
          <Pagination
            {...paginationInfo}
            onPageChange={(page) => {
              setCurrentPage(page)
            }}
          />
        )}
      </Table>
    </Pane>
  )
}

Archived.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withRouter, withUser)(Archived)
