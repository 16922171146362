import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'evergreen-ui'
import { ORDER_STATUSES } from 'constants/constants'

const {
  PROCESSING,
  ON_HOLD,
  COMPLETED,
  IN_CARRIER_HANDS,
  CANCELLED,
  CANCELLED_BY_USER,
  REFUNDED,
  PENDING,
} = ORDER_STATUSES

const getStatusColor = (status) => {
  switch (status) {
    case ON_HOLD:
      return 'yellow'
    case COMPLETED:
      return 'green'
    case CANCELLED_BY_USER:
    case CANCELLED:
      return 'red'
    case IN_CARRIER_HANDS:
      return 'teal'
    case PROCESSING:
      return 'blue'
    case REFUNDED:
      return 'neutral'
    case PENDING:
      return 'orange'
    default:
      return 'neutral'
  }
}

export const getOrderLabelByStatus = (status) => {
  switch (status) {
    case PROCESSING:
      return 'PROCESSING'
    case ON_HOLD:
      return 'ON HOLD'
    case COMPLETED:
      return 'COMPLETED'
    case IN_CARRIER_HANDS:
      return 'IN CARRIER HANDS'
    case CANCELLED_BY_USER:
      return 'CANCELLED BY USER'
    case CANCELLED:
      return 'CANCELLED'
    case REFUNDED:
      return 'REFUNDED'
    case PENDING:
      return 'PENDING'
    default:
      return status
  }
}

const OrderStatus = ({ status, onClick }) => (
  <Badge
    color={getStatusColor(status)}
    marginBottom={5}
    isSolid
    isInteractive={!!onClick}
    onClick={onClick}
  >
    {getOrderLabelByStatus(status)}
  </Badge>
)

OrderStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(ORDER_STATUSES)).isRequired,
  onClick: PropTypes.func,
}

export default OrderStatus
