import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'evergreen-ui'
import { RETURN_ORDER_STATUSES } from 'constants/constants'

const getStatusColor = (status) => {
  switch (status) {
    case RETURN_ORDER_STATUSES.REQUESTED:
      return 'yellow'
    case RETURN_ORDER_STATUSES.REJECTED:
      return 'red'
    case RETURN_ORDER_STATUSES.ELIGIBLE:
      return 'orange'
    case RETURN_ORDER_STATUSES.SENT:
      return 'teal'
    case RETURN_ORDER_STATUSES.RECEIVED:
      return 'blue'
    case RETURN_ORDER_STATUSES.APPROVED:
      return 'green'
    case RETURN_ORDER_STATUSES.REFUNDED:
      return 'purple'
    default:
      return 'neutral'
  }
}

export const getOrderLabelByStatus = (status) => {
  switch (status) {
    case RETURN_ORDER_STATUSES.REQUESTED:
      return 'Request Being Evaluated'
    case RETURN_ORDER_STATUSES.REJECTED:
      return 'Request Rejected'
    case RETURN_ORDER_STATUSES.ELIGIBLE:
      return 'Eligible for Return'
    case RETURN_ORDER_STATUSES.SENT:
      return 'Package Sent'
    case RETURN_ORDER_STATUSES.RECEIVED:
      return 'Package Investigated by Beneko'
    case RETURN_ORDER_STATUSES.APPROVED:
      return 'Return is Approved, Process Refund'
    case RETURN_ORDER_STATUSES.REFUNDED:
      return 'Refund Success'
    default:
      return '-'
  }
}

const ReturnOrderStatus = ({ status, onClick }) =>
  status !== RETURN_ORDER_STATUSES.NOT_RETURN ? (
    <Badge
      color={getStatusColor(status)}
      marginBottom={5}
      isSolid
      isInteractive={!!onClick}
      onClick={onClick}
    >
      {getOrderLabelByStatus(status)}
    </Badge>
  ) : (
    ''
  )

ReturnOrderStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(RETURN_ORDER_STATUSES)).isRequired,
  onClick: PropTypes.func,
}

export default ReturnOrderStatus
