export const getCountryCodeFromLanguageCode = (langugageIsoCode) => {
  switch (langugageIsoCode) {
    case 'en':
      return 'gb'
    case 'cs':
      return 'cz'
    case 'da':
      return 'dk'
    case 'el':
      return 'gr'
    case 'et':
      return 'ee'
    case 'sl':
      return 'si'
    case 'ga':
      return 'ie'
    case 'sv':
      return 'se'
    default:
      return langugageIsoCode
  }
}
