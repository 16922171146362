import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Spinner,
  BackButton,
  Switch,
  Pane,
  Button,
  Heading,
  majorScale,
} from 'evergreen-ui'
import { useQuery, useMutation } from 'urql'
import format from 'date-fns/format'
import urql from 'utils/urql'
import { withUser } from 'UserProvider'
import { TIME_FORMAT } from 'constants/constants'
import ACTIONS from 'constants/actions'
import SideNavPage from 'sharedComponents/SideNavPage'
import CustomerOrders from 'components/CustomerOrders'

const TABLE_MIN_HEIGHT = 500
const FALLBACK = 'unknown'

const CustomerDetail = ({ match, history, user }) => {
  const [customerQuery] = useQuery({
    query: `
      query($id: String!){
        customer(id: $id) {
          id
          email
          firstName
          lastName
          createdAtUtc
          facebookDisplayName
          facebookPhotoUrl
          googleDisplayName
          googlePhotoUrl
          isEmailNotificationEnabled
          country {
            title
          }
          isTester
          areOrdersSynchronized
          language {
            title
          }
          devices {
            id
            appVersion
            brand
            model
            systemName
            systemVersion
          }
        }
      }
    `,
    variables: {
      id: match.params.id,
    },
  })
  const [, executeSetCustomerTesterStatus] = useMutation(`
    mutation($input: CustomerTesterStatus!) {
      updateCustomerTesterStatus(input: $input) {
        id
        isTester
      }
    }
  `)
  const [, updateCustomerOldOrderSynchronizationStatus] = useMutation(`
    mutation($input: CustomerOrdersSynchronizationStatus!) {
      updateCustomerOldOrderSynchronizationStatus(input: $input) {
        id
        areOrdersSynchronized
      }
    }
  `)
  useEffect(() => {
    if (customerQuery.error) {
      urql.handleError(customerQuery.error)
    }
  }, [customerQuery.error])
  const customer = (customerQuery.data && customerQuery.data.customer) || false
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>
          Customer detail: {customer.id || ''}
        </SideNavPage.Title>
        <Pane>
          <Button
            marginRight="10px"
            appearance="primary"
            intent="success"
            onClick={async () => {
              await updateCustomerOldOrderSynchronizationStatus({
                input: {
                  customerId: customer.id,
                  areOrdersSynchronized: !customer.areOrdersSynchronized,
                },
              })
              window.location.reload()
            }}
          >
            {customer.areOrdersSynchronized
              ? 'Set orders as not synchronized'
              : 'Set orders as synchronized'}
          </Button>
          {user.hasAction(ACTIONS.CUSTOMER.CHANGE_TESTER_FLAG) && (
            <Button
              marginRight="10px"
              appearance="primary"
              intent="success"
              onClick={() =>
                executeSetCustomerTesterStatus({
                  input: {
                    customerId: customer.id,
                    isTester: !customer.isTester,
                  },
                })
              }
            >
              {customer.isTester
                ? 'Remove tester flag'
                : 'Set customer as tester'}
            </Button>
          )}
          <BackButton onClick={() => history.goBack()} />
        </Pane>
      </SideNavPage.Header>
      <SideNavPage.Content>
        {customerQuery.fetching && (
          <Spinner
            marginX="auto"
            marginY={TABLE_MIN_HEIGHT / 2}
            delay={200}
            size={80}
          />
        )}
        {customer && (
          <>
            <Table border>
              <Table.Body>
                <Table.Row>
                  <Table.TextHeaderCell>Id</Table.TextHeaderCell>
                  <Table.TextCell>{customer.id}</Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>First Name</Table.TextHeaderCell>
                  <Table.TextCell>{customer.firstName}</Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Last Name</Table.TextHeaderCell>
                  <Table.TextCell>{customer.lastName}</Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Email</Table.TextHeaderCell>
                  <Table.TextCell>{customer.email}</Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>
                    Facebook display name
                  </Table.TextHeaderCell>
                  <Table.TextCell>
                    {customer.facebookDisplayName}
                  </Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>
                    Facebook Photo url
                  </Table.TextHeaderCell>
                  <Table.TextCell>{customer.facebookPhotoUrl}</Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>
                    Google display name
                  </Table.TextHeaderCell>
                  <Table.TextCell>{customer.googleDisplayName}</Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Google Photo url</Table.TextHeaderCell>
                  <Table.TextCell>{customer.googlePhotoUrl}</Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>
                    Email notifications
                  </Table.TextHeaderCell>
                  <Table.TextCell>
                    <Switch
                      disabled
                      checked={customer.isEmailNotificationEnabled}
                    />
                  </Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Is tester</Table.TextHeaderCell>
                  <Table.TextCell>
                    <Switch disabled checked={customer.isTester} />
                  </Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>
                    Are orders synchronized
                  </Table.TextHeaderCell>
                  <Table.TextCell>
                    <Switch disabled checked={customer.areOrdersSynchronized} />
                  </Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Country</Table.TextHeaderCell>
                  <Table.TextCell>{customer.country.title}</Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Language</Table.TextHeaderCell>
                  <Table.TextCell>{customer.language.title}</Table.TextCell>
                </Table.Row>
                <Table.Row>
                  <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
                  <Table.TextCell>
                    {format(customer.createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                  </Table.TextCell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Pane marginTop={majorScale(3)}>
              <Heading is="h3" marginBottom={majorScale(2)}>
                Customer Devices
              </Heading>
              <Table border>
                <Table.Head>
                  <Table.TextHeaderCell>ID</Table.TextHeaderCell>
                  <Table.TextHeaderCell>System Name</Table.TextHeaderCell>
                  <Table.TextHeaderCell>System Version</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Brand</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Model</Table.TextHeaderCell>
                  <Table.TextHeaderCell>App Version</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                  {customerQuery.fetching && (
                    <Spinner
                      marginX="auto"
                      marginY={TABLE_MIN_HEIGHT / 2}
                      delay={200}
                      size={80}
                    />
                  )}
                  {customer.devices &&
                    customer.devices.map(
                      ({
                        id,
                        appVersion,
                        brand,
                        model,
                        systemName,
                        systemVersion,
                      }) => (
                        <Table.Row key={id}>
                          <Table.TextCell>{id}</Table.TextCell>
                          <Table.TextCell>
                            {systemName || FALLBACK}
                          </Table.TextCell>
                          <Table.TextCell>
                            {systemVersion || FALLBACK}
                          </Table.TextCell>
                          <Table.TextCell>{brand || FALLBACK}</Table.TextCell>
                          <Table.TextCell>{model || FALLBACK}</Table.TextCell>
                          <Table.TextCell>
                            {appVersion || FALLBACK}
                          </Table.TextCell>
                        </Table.Row>
                      )
                    )}
                </Table.Body>
              </Table>
            </Pane>
            {user.hasAction(ACTIONS.ORDER.LIST) && (
              <CustomerOrders customerId={customer.id} />
            )}
          </>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

CustomerDetail.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(CustomerDetail)
