import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Label, Pane, withTheme } from 'evergreen-ui'
import styled from 'styled-components/macro'
import { borderRadius } from 'Theme'
import UploadImageDialog from 'sharedComponents/UploadImageDialog'
import { OptimizeImageInformation } from 'components/OptimizeImageInformation'
import SelectImageDialog from './SelectImageDialog'

const MediaIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const StyledImage = styled.img`
  position: relative;
  border-radius: ${borderRadius.SMALL}px;
  overflow: hidden;
`

const MainImage = ({ mainImage, theme, setMainImage, isDisabled }) => {
  const [isUploadDialogShown, setIsUploadDialogShown] = useState(false)
  const [isSelectDialogShown, setIsSelectDialogShown] = useState(false)
  return (
    <div>
      <UploadImageDialog
        title="Upload main product image"
        onClose={() => setIsUploadDialogShown(false)}
        isShown={isUploadDialogShown}
        mainImage={mainImage}
        onSuccess={(image) => {
          setIsUploadDialogShown(false)
          setMainImage(image)
        }}
      />
      <SelectImageDialog
        onClose={() => setIsSelectDialogShown(false)}
        isShown={isSelectDialogShown}
        mainImage={mainImage}
        onSuccess={(image) => {
          setIsSelectDialogShown(false)
          setMainImage(image)
        }}
      />
      <Label marginBottom="8px">Main Product Image</Label>
      <OptimizeImageInformation />
      {!isDisabled && (
        <Pane marginBottom="8px">
          <Button
            marginRight="8px"
            onClick={() => {
              setIsUploadDialogShown(true)
            }}
          >
            Upload image
          </Button>
          <Button
            onClick={() => {
              setIsSelectDialogShown(true)
            }}
          >
            Select image
          </Button>
        </Pane>
      )}
      <Pane
        width="50%"
        position="relative"
        display="block"
        borderRadius={borderRadius.SMALL}
        background={theme.palette.neutral.light}
        minHeight={150}
      >
        <MediaIcon icon="media" size={50} />
        {mainImage && (
          <StyledImage src={mainImage.url} alt="main product" width="100%" />
        )}
      </Pane>
    </div>
  )
}

MainImage.propTypes = {
  mainImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  theme: PropTypes.object.isRequired,
  setMainImage: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default withTheme(MainImage)
