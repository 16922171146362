import React, { useState } from 'react'
import SideNavPage from 'sharedComponents/SideNavPage'
import { Button } from 'evergreen-ui'
import ThemeDealItem from 'routes/ThemeDeal/routes/Management/components/ThemeDealItem'
import { useQuery } from 'urql'
import ROUTES, { BEST_SELLER_ROUTES } from 'constants/routes'
import { DEAL_STATUSES } from 'constants/constants'
import { allBestSellersQueryGraphQL } from './graphql'
import { getPathToSingleBestSeller } from './NewBestSeller/Form'
import withApproveDeclineMutations, {
  toastResultOfPublish,
} from './EditBestSeller/withAprroveDeclineMutations'
import DeleteDialog from './EditBestSeller/DeleteDialog'

const BestSellers = ({ history, ...rest }) => {
  const { executePublishBestSellerMutation } = rest
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [allBestSellersQuery, executeAllBestSellersQuery] = useQuery({
    query: allBestSellersQueryGraphQL,
  })

  const renderAllBestSellers = () => {
    if (allBestSellersQuery.data && allBestSellersQuery.data.allBestSellers) {
      return allBestSellersQuery.data.allBestSellers.map((item) => (
        <div>
          <ThemeDealItem
            {...item}
            my={10}
            isWithPublishButton={[
              DEAL_STATUSES.APPROVED,
              DEAL_STATUSES.FINISHED,
            ].includes(item.status)}
            onDetailClick={() => {
              history.push(getPathToSingleBestSeller({ bestSellerId: item.id }))
            }}
            onPublishButtonClicked={async () => {
              const result = await executePublishBestSellerMutation({
                id: item.id,
              })
              toastResultOfPublish(result)
              executeAllBestSellersQuery({ requestPolicy: 'network-only' })
            }}
            isWithEditButton
            isWithDeleteButton
            onDeleteButtonClicked={() => setItemToSoftDelete(item)}
          />
        </div>
      ))
    }

    return null
  }

  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>BestSellers</SideNavPage.Title>

        <Button
          marginLeft="0.5em"
          iconBefore="add"
          appearance="primary"
          intent="success"
          onClick={() => {
            history.push(`/${ROUTES.BEST_SELLERS}/${BEST_SELLER_ROUTES.NEW}`)
          }}
        >
          Create new BestSeller
        </Button>
      </SideNavPage.Header>
      <SideNavPage.Content>{renderAllBestSellers()}</SideNavPage.Content>
      {itemToSoftDelete && (
        <DeleteDialog
          onSuccess={() => {
            executeAllBestSellersQuery({ requestPolicy: 'network-only' })
          }}
          onClose={() => {
            setItemToSoftDelete(null)
          }}
          itemToDelete={itemToSoftDelete}
        />
      )}
    </React.Fragment>
  )
}

export default withApproveDeclineMutations(BestSellers)
