import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import {
  Table,
  Spinner,
  Button,
  Pane,
  Dialog,
  TextInputField,
  Heading,
  majorScale,
} from 'evergreen-ui'
import find from 'lodash.find'
import { withUser } from 'UserProvider'
import ACTIONS from 'constants/actions'
import SideNavPage from 'sharedComponents/SideNavPage'
import urql from 'utils/urql'

const TABLE_MIN_HEIGHT = 500

let isInitialMount = true

const productConditionFields = `{
  id
  title
  translations {
    language {
      isoCode
      title
    }
    value
  }
}`

const ProductConditions = ({ user }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [translations, setTranslations] = useState([])
  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })
  const [productConditionsQuery, executeProductConditionsQuery] = useQuery({
    query: `
      query {
        productConditions ${productConditionFields}
      }
    `,
  })
  const [createMutation, executeCreateMutation] = useMutation(`
    mutation($input: CreateProductConditionInput!) {
      createProductCondition(input: $input) ${productConditionFields}
    }
  `)
  const lastCreatedId =
    createMutation.data && createMutation.data.createProductCondition.id

  const [updateMutation, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateProductConditionInput!) {
      updateProductCondition(input: $input) ${productConditionFields}
    }
  `)
  const lastUpdatedId =
    updateMutation.data && updateMutation.data.updateProductCondition.id
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: ID!) {
      deleteProductCondition(id: $id) {
        id
      }
    }
  `)
  const lastDeletedId =
    deleteMutation.data && deleteMutation.data.deleteProductCondition.id

  const updateTranslationValue = (languageIsoCode, value) => {
    setTranslations([
      ...translations.filter(
        (translation) => translation.languageIsoCode !== languageIsoCode
      ),
      { languageIsoCode, value },
    ])
  }
  const getTranslationValue = (languageIsoCode) => {
    const translation = find(translations, {
      languageIsoCode,
    })
    return translation ? translation.value : ''
  }
  useEffect(() => {
    if (!isInitialMount) {
      if (lastCreatedId || lastUpdatedId || lastDeletedId) {
        executeProductConditionsQuery({ requestPolicy: 'network-only' })
      }
    } else {
      isInitialMount = false
    }
  }, [
    lastCreatedId,
    lastUpdatedId,
    lastDeletedId,
    executeProductConditionsQuery,
  ])
  useEffect(() => {
    const error =
      productConditionsQuery.error ||
      createMutation.error ||
      updateMutation.error ||
      deleteMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [
    productConditionsQuery.error,
    createMutation.error,
    updateMutation.error,
    deleteMutation.error,
  ])
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Product Conditions</SideNavPage.Title>
        {user.hasAction(ACTIONS.PRODUCT_CONDITION.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setId('')
              setTitle('')
              setTranslations([])
              setIsCreateModalOpen(true)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Id</Table.TextHeaderCell>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body minHeight={TABLE_MIN_HEIGHT}>
            {productConditionsQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                delay={200}
                size={80}
              />
            )}
            {productConditionsQuery.data &&
              productConditionsQuery.data.productConditions.map(
                (productCondition) => (
                  <Table.Row key={productCondition.id}>
                    <Table.TextCell>{productCondition.id}</Table.TextCell>
                    <Table.TextCell>{productCondition.title}</Table.TextCell>
                    <Table.Cell>
                      {user.hasAction(ACTIONS.PRODUCT_CONDITION.UPDATE) && (
                        <Button
                          marginRight={12}
                          iconBefore="edit"
                          onClick={(e) => {
                            e.stopPropagation()
                            setId(productCondition.id)
                            setTitle(productCondition.title)
                            setTranslations(
                              productCondition.translations.map(
                                ({ language, value }) => ({
                                  languageIsoCode: language.isoCode,
                                  value,
                                })
                              )
                            )
                            setIsUpdateModalOpen(true)
                          }}
                        >
                          Edit
                        </Button>
                      )}
                      {user.hasAction(ACTIONS.PRODUCT_CONDITION.DELETE) && (
                        <Button
                          marginRight={12}
                          iconBefore="trash"
                          intent="danger"
                          onClick={(e) => {
                            e.stopPropagation()
                            setId(productCondition.id)
                            setTitle(productCondition.title)
                            setIsConfirmDeleteModalOpen(true)
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
          </Table.Body>
        </Table>
      </SideNavPage.Content>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete product condition"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id,
          })
          if (!error) {
            setId('')
            setTitle('')
            close()
          }
        }}
      >
        Are you sure you want to delete Product condition: {title} ??
      </Dialog>
      <Dialog
        isShown={isCreateModalOpen || isUpdateModalOpen}
        title={
          isCreateModalOpen
            ? 'Create product condition'
            : `Edit product condition: ${id}`
        }
        hasCancel={false}
        confirmLabel={isCreateModalOpen ? 'Create' : 'Edit'}
        isConfirmLoading={createMutation.fetching || updateMutation.fetching}
        onCloseComplete={() => {
          setIsCreateModalOpen(false)
          setIsUpdateModalOpen(false)
        }}
        onConfirm={async (close) => {
          const input = {
            title,
            translations: translations.filter(({ value }) => value !== ''),
          }
          if (isCreateModalOpen) {
            const { error } = await executeCreateMutation({
              input,
            })
            if (!error) {
              close()
            }
          }
          if (isUpdateModalOpen) {
            const { error } = await executeUpdateMutation({
              input: {
                ...input,
                id,
              },
            })
            if (!error) {
              close()
            }
          }
        }}
      >
        {isUpdateModalOpen && (
          <TextInputField
            label="Id"
            disabled
            value={id}
            onChange={(e) => {
              setId(e.target.value)
            }}
          />
        )}
        <TextInputField
          label="Title"
          required
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Translations
          </Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Language</Table.TextHeaderCell>
              <Table.TextHeaderCell>Value</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {languagesQuery.data &&
                languagesQuery.data.languages.map((language) => (
                  <Table.Row key={language.isoCode}>
                    <Table.TextCell>{language.title}</Table.TextCell>
                    <Table.Cell>
                      <TextInputField
                        label="Translation"
                        value={getTranslationValue(language.isoCode)}
                        onChange={(e) => {
                          updateTranslationValue(
                            language.isoCode,
                            e.target.value
                          )
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Pane>
      </Dialog>
    </React.Fragment>
  )
}

ProductConditions.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(ProductConditions)
