import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Table, withTheme } from 'evergreen-ui'
import styled from 'styled-components/macro'
import { SORT_DIRECTION_ENUM } from 'constants/constants'

const { DESC, ASC } = SORT_DIRECTION_ENUM

const ColoredSpan = styled.span`
  color: ${({ color }) => color};
`
const StyledHeaderCell = styled(Table.TextHeaderCell)`
  cursor: pointer;
`

const getColor = ({ isActive, theme }) =>
  isActive ? theme.palette.blue.base : theme.palette.neutral.base

const SortableTableHeaderCell = ({
  children,
  sorter,
  sortField,
  onClick,
  theme,
}) => {
  const isActive = sorter.field === sortField
  return (
    <StyledHeaderCell
      onClick={() => {
        onClick(
          isActive
            ? {
                field: sorter.field,
                direction: sorter.direction === DESC ? ASC : DESC,
              }
            : {
                field: sortField,
                direction: DESC,
              }
        )
      }}
    >
      <ColoredSpan color={getColor({ isActive, theme })}>
        {children}
      </ColoredSpan>
      <Icon
        marginLeft="2px"
        marginBottom="2px"
        icon={sorter.direction === ASC || !isActive ? 'caret-down' : 'caret-up'}
        color={getColor({ isActive, theme })}
        size={16}
      />
    </StyledHeaderCell>
  )
}

SortableTableHeaderCell.propTypes = {
  children: PropTypes.string.isRequired,
  sorter: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(Object.values(SORT_DIRECTION_ENUM)).isRequired,
  }).isRequired,
  sortField: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(SortableTableHeaderCell)
