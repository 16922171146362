import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import ThemesList from './ThemesList'
import ThemeDeal from '../ThemeDeal'

const ThemesOfTheMonth = ({ match }) => (
  <Switch>
    <Route component={ThemeDeal} path={`${match.url}/:themeOfTheMonthId`} />
    <Route path={match.url} component={ThemesList} />
  </Switch>
)

ThemesOfTheMonth.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default ThemesOfTheMonth
