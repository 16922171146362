export const MESSAGE_DETAIL_QUERY = `
  id
  createdAtUtc
  text
  isMine
  userId
  customerId
  attachmentName
  attachmentUrl
  messageType
`
export const THREAD_QUERY = `
  id
  createdAtUtc
  firstMessage {
    id
  }
  order {
    id
  }
  ticket {
    id
  }
  feedback {
    id
  }
  wish {
    id
  }
  user {
    id
    name
  }
  threadStatus {
    id
  }
  threadCategory {
    id
  }
  customer {
    id
    country {
      isoCode
      title
    }
    language {
      isoCode
      title
    }
    fullName
    email
  }
  messages {
    data {
      ${MESSAGE_DETAIL_QUERY}
    }
    paginationInfo {
      nextPage
    }
  }
`
