import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'

import { Button, TextInputField, Pane, majorScale, toaster } from 'evergreen-ui'

import { withRouter } from 'react-router-dom'
import urql from 'utils/urql'

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const Form = ({ history }) => {
  const [title, setTitle] = useState('')

  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateHeroBannerInput!) {
      createHeroBanner(input: $input) {
        id
      }
    }
  `)

  const createNewHeroBanner = async () => {
    if (!title) {
      toaster.danger('Internal title should be set')
    } else {
      const dataToSave = { internalTitle: title }

      const { error, data } = await executeCreateMutation({ input: dataToSave })

      displayToastOnSave(error, 'Saved')
      history.push(`/hero-banners/${data.createHeroBanner.id}/edit`)
    }
  }
  return (
    <Pane>
      <Pane maxWidth={780}>
        <TextInputField
          label="Internal Title"
          required
          value={title || ''}
          onChange={({ target }) => {
            setTitle(target.value === '' ? undefined : target.value)
          }}
        />

        <Pane marginTop={majorScale(2)} justifyContent="center" display="flex">
          <Pane>
            <Button
              intent="success"
              marginRight={majorScale(2)}
              onClick={createNewHeroBanner}
            >
              Save and go to add single slides
            </Button>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

Form.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(Form)
