import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import ProductList from './routes/ProductList'
import NewProduct from './routes/NewProduct'
import EditProduct from './routes/EditProduct'
import NewProductVariant from './routes/NewProductVariant'
import EditProductVariant from './routes/EditProductVariant'

const Product = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/:productId/variants/:id`}
      component={EditProductVariant}
    />
    <Route
      path={`${match.url}/:productId/variants/`}
      component={NewProductVariant}
    />
    <Route path={`${match.url}/new`} component={NewProduct} />
    <Route path={`${match.url}/edit/:id`} component={EditProduct} />
    <Route path={`${match.url}/:pageNumber?`} component={ProductList} />
  </Switch>
)

Product.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default Product
