export const acceptWishMutation = `
  mutation acceptWish($id: Int!) {
    acceptWish(id: $id) {
      id
    }
  }
`

export const declineWishMutation = `
  mutation declineWish($id: Int!) {
    declineWish(id: $id) {
      id
    }
  }
`

export const decideLaterWishMutation = `
  mutation decideLaterWish($id: Int!) {
    decideLaterWish(id: $id) {
      id
    }
  }
`
