import React from 'react'
import PropTypes from 'prop-types'
import { Pane, majorScale } from 'evergreen-ui'

const Content = ({ children }) => <Pane margin={majorScale(5)}>{children}</Pane>

Content.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Content
