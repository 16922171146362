export const pauseBestSellerMutation = `
  mutation pauseBestSeller($id: Int!) {
    pauseBestSeller(id: $id) {
      id
    }
  }
`
export const resumeBestSellerMutation = `
  mutation resumeBestSeller($id: Int!) {
    resumeBestSeller(id: $id) {
      id
    }
  }
`
