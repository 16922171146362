import React from 'react'
import PropTypes from 'prop-types'
import request from 'utils/request'
import monitoring from 'utils/monitoring'

const UserContext = React.createContext({})
const ACCESS_TOKEN = 'accessToken'

const userFields = `
  id
  email
  name
  actionIds
  roles {
    id
  }
  countries {
    isoCode
  }
`

export const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN)

export class UserProvider extends React.Component {
  state = {
    accessToken: getAccessToken(),
    user: null,
    isUserChecked: false,
    isSigningIn: false,
  }

  componentDidMount() {
    const { accessToken } = this.state
    this.fetchUser({ accessToken })
  }

  fetchUser = async ({ accessToken }) => {
    try {
      const response = await request({
        accessToken,
        isIgnoreErrorsEnabled: true,
        body: {
          query: `query userMe {
            userMe {
              ${userFields}
            }
          }`,
        },
      })
      this.setState({ user: response.userMe })
      monitoring.setUser({ id: response.userMe.id })
    } catch (error) {
      this.signOut()
    } finally {
      this.setState({ isUserChecked: true })
    }
  }

  signIn = async ({ email, password }) => {
    try {
      this.setState({ isSigningIn: true })
      const response = await request({
        body: {
          query: `mutation userSignIn($input: UserSignInInput!) {
            userSignIn(input: $input) {
              accessToken
              user {
                ${userFields}
              }
            }
          }`,
          variables: { input: { email, password } },
        },
      })
      const { accessToken, user } = response.userSignIn
      window.localStorage.setItem(ACCESS_TOKEN, accessToken)
      this.setState({ accessToken, user })
      monitoring.setUser({ id: user.id })
    } catch (error) {
      // do nothing
    } finally {
      this.setState({ isSigningIn: false })
    }
  }

  signOut = async () => {
    window.localStorage.removeItem(ACCESS_TOKEN)
    this.setState({ user: null, accessToken: null })
    monitoring.setUser(null)
  }

  hasAction = (userActions) => (action) => {
    if (Array.isArray(action)) {
      return userActions.some((userAction) => action.includes(userAction))
    }
    return userActions.includes(action)
  }

  render() {
    const { accessToken, user, isUserChecked, isSigningIn } = this.state
    return (
      <UserContext.Provider
        value={{
          accessToken,
          isSignedIn: !!accessToken,
          isUserChecked,
          data: user,
          signIn: this.signIn,
          signOut: this.signOut,
          hasAction: this.hasAction(user ? user.actionIds : []),
          isSigningIn,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const UserConsumer = UserContext.Consumer

export const withUser = (WrappedComponent) => {
  const Temp = (props) => (
    <UserConsumer>
      {(userProps) => <WrappedComponent {...props} user={userProps} />}
    </UserConsumer>
  )
  Temp.displayName = `${
    WrappedComponent.displayName || WrappedComponent.name || ''
  }WithUser`
  return Temp
}
