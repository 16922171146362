import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { FEEDBACK_MANAGEMENT_ROUTES } from 'constants/routes'
import Management from './routes/Management'
import FeedbackDetail from './routes/FeedbackDetail'

const Feedback = ({ match }) => (
  <Switch>
    <Route
      component={Management}
      exact
      path={`${match.url}/:tab(${Object.values(FEEDBACK_MANAGEMENT_ROUTES).join(
        '|'
      )})?`}
    />
    <Route path={`${match.url}/:id`} component={FeedbackDetail} />
  </Switch>
)

Feedback.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default Feedback
