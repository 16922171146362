import React from 'react'
import PropTypes from 'prop-types'
import RGL, { WidthProvider } from 'react-grid-layout'
import { compose } from 'recompose'
import sortBy from 'lodash.sortby'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'
import { withUser } from 'UserProvider'
import { parseInteger } from 'utils/numbers'

const COLS = 1
const layoutElementBase = {
  x: 0,
  w: COLS,
  h: 1,
}
const ReactGridLayout = WidthProvider(RGL)

const RGLBox = styled.div`
  position: relative;
`

const mapDataToLayout = ({ id, positionIndexWeight }) => ({
  ...layoutElementBase,
  i: String(id),
  y: positionIndexWeight,
})

export const getIdsOrderedByPositionIndex = (items) =>
  sortBy(items, ['positionIndexWeight']).map(({ id }) => id)

export const moveItemInItemsToTheTop = ({ items, idOfItemToTop }) => {
  const firstItem = sortBy(items, ['positionIndexWeight'])[0]
  if (idOfItemToTop === firstItem.id) {
    return items
  }
  const itemToMove = items.find(({ id }) => id === idOfItemToTop)
  return items.map((item) => {
    if (item.id === itemToMove.id) {
      return {
        ...itemToMove,
        positionIndexWeight: firstItem.positionIndexWeight,
      }
    }
    if (item.positionIndexWeight < itemToMove.positionIndexWeight) {
      return {
        ...item,
        positionIndexWeight: item.positionIndexWeight + 1,
      }
    }
    return item
  })
}

export const rearangeItemsBasedOnNewLayout = ({ newLayout, items }) =>
  newLayout.map(({ i, y: positionInNewLayout }) => ({
    id: parseInteger(i),
    ...items.find(({ id }) => String(id) === i),
    positionIndexWeight: positionInNewLayout,
  }))

const EditableList = ({
  isEditMode,
  items,
  onDragStop,
  children,
  rowHeight,
  margin,
}) => (
  <React.Fragment>
    <RGLBox>
      <ReactGridLayout
        layout={items.map(mapDataToLayout)}
        items={items.length}
        cols={COLS}
        margin={margin}
        rowHeight={rowHeight}
        onDragStop={onDragStop}
        useCSSTransforms
        isResizable={false}
        isDraggable={isEditMode}
      >
        {items.map((item) => children(item))}
      </ReactGridLayout>
    </RGLBox>
  </React.Fragment>
)

EditableList.defaultProps = {
  rowHeight: 30,
  margin: [30, 30],
}

EditableList.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      positionIndexWeight: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  onDragStop: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  rowHeight: PropTypes.number,
  margin: PropTypes.arrayOf(PropTypes.number),
}

export default compose(withRouter, withUser)(EditableList)
