import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import { withUser } from 'UserProvider'
import styled from 'styled-components/macro'
import {
  Table,
  Spinner,
  Button,
  Dialog,
  TextInputField,
  Pane,
  Heading,
  majorScale,
  toaster,
} from 'evergreen-ui'
import find from 'lodash.find'
import urql from 'utils/urql'
import uploadRequest, { getIsFileSizeExceeded } from 'utils/uploadRequest'
import SideNavPage from 'sharedComponents/SideNavPage'
import ACTIONS from 'constants/actions'
import ActionsRow from 'components/ActionsRow'
import EditModeButton from 'components/EditModeButton'
import EditableList, {
  rearangeItemsBasedOnNewLayout,
  getIdsOrderedByPositionIndex,
} from 'components/EditableList'
import Dropzone from 'components/Dropzone'

const Image = styled.img`
  width: 100%;
  height: auto;
  padding: ${majorScale(1)}px;
`

const StyledRow = styled(Table.Row)`
  border: ${({ isReorderMode }) =>
    isReorderMode ? '1px dashed' : '1px solid #EDF0F2'};
`

const StyledBody = styled(Table.Body)`
  overflow-y: 'initial';
`

let isInitialMount = true
const TABLE_MIN_HEIGHT = 500
const wishCategoryFields = `{
  id
  name
  activeIconUrl
  inactiveIconUrl
  positionIndexWeight
  translations {
    language {
      isoCode
      title
    }
    value
  }
}`

const defaultFile = { objectUrl: '', name: '' }

const WishCategories = ({ user }) => {
  const [wishCategoriesQuery, executeWishCategoriesQuery] = useQuery({
    query: `
      query {
        userWishCategories ${wishCategoryFields}
      }
    `,
  })
  const [isReorderMode, setIsReorderMode] = useState(false)
  const [newOrderedWishCategories, setNewOrderedWishCategories] = useState([])
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [name, setName] = useState('')
  const [id, setId] = useState('')
  const [inactiveIcon, setInactiveIcon] = useState(defaultFile)
  const [activeIcon, setActiveIcon] = useState(defaultFile)
  const [translations, setTranslations] = useState([])
  const [isSending, setIsSending] = useState(false)
  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })
  const [, executeChangeOrderMutation] = useMutation(`
  mutation($ids: [Int]!) {
    updateWishCategoriesPositions(ids: $ids)
  }
`)
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: Int!) {
      deleteWishCategory(id: $id) {
        id
      }
    }
  `)
  const lastDeletedWishCategoryId =
    deleteMutation.data && deleteMutation.data.deleteWishCategory.id
  const updateTranslationValue = (languageIsoCode, value) => {
    setTranslations([
      ...translations.filter(
        (translation) => translation.languageIsoCode !== languageIsoCode
      ),
      { languageIsoCode, value },
    ])
  }
  const getTranslationValue = (languageIsoCode) => {
    const translation = find(translations, {
      languageIsoCode,
    })
    return translation ? translation.value : ''
  }
  useEffect(() => {
    if (!isInitialMount) {
      if (lastDeletedWishCategoryId) {
        executeWishCategoriesQuery({ requestPolicy: 'network-only' })
      }
    } else {
      isInitialMount = false
    }
  }, [lastDeletedWishCategoryId, executeWishCategoriesQuery])
  useEffect(() => {
    const error =
      deleteMutation.error || wishCategoriesQuery.error || languagesQuery.error
    if (error) {
      urql.handleError(error)
    }
  }, [wishCategoriesQuery.error, deleteMutation.error, languagesQuery.error])
  const isUpdateDisabled =
    isUpdateModalOpen && !user.hasAction(ACTIONS.WISH_CATEGORY.UPDATE)
  const items =
    (wishCategoriesQuery.data && wishCategoriesQuery.data.userWishCategories) ||
    []
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Wish Categories</SideNavPage.Title>
        {user.hasAction(ACTIONS.WISH_CATEGORY.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setName('')
              setActiveIcon(defaultFile)
              setInactiveIcon(defaultFile)
              setTranslations([])
              setIsCreateModalOpen(true)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Pane marginBottom={10}>
          <ActionsRow>
            {user.hasAction(ACTIONS.WISH_CATEGORY.UPDATE_POSITION) && (
              <EditModeButton
                isEditMode={isReorderMode}
                onClick={async () => {
                  if (isReorderMode) {
                    const { error } = executeChangeOrderMutation({
                      ids: getIdsOrderedByPositionIndex(
                        newOrderedWishCategories
                      ),
                    })
                    if (error) {
                      toaster.danger('Rearrangement failed.')
                    } else {
                      toaster.success('Rearrangement succeeded.')
                    }
                  }
                  setIsReorderMode(!isReorderMode)
                }}
              />
            )}
          </ActionsRow>
        </Pane>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>Id</Table.TextHeaderCell>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell minWidth="250px" align="center">
              Actions
            </Table.TextHeaderCell>
          </Table.Head>
        </Table>
        <StyledBody minHeight={TABLE_MIN_HEIGHT} overflowY="none">
          {wishCategoriesQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          <EditableList
            rowHeight={48}
            margin={[2, 10]}
            items={items}
            isEditMode={isReorderMode}
            onDragStop={(newLayout) => {
              setNewOrderedWishCategories(
                rearangeItemsBasedOnNewLayout({
                  newLayout,
                  items,
                })
              )
            }}
          >
            {(item) => (
              <StyledRow key={item.id} isReorderMode={isReorderMode}>
                <Table.TextCell>{item.id}</Table.TextCell>
                <Table.TextCell>{item.name}</Table.TextCell>
                <Table.Cell minWidth="250px" justifyContent="center">
                  {user.hasAction(ACTIONS.WISH_CATEGORY.UPDATE) && (
                    <Button
                      disabled={isReorderMode}
                      marginRight={12}
                      iconBefore="edit"
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsUpdateModalOpen(true)
                        setId(item.id)
                        setName(item.name)
                        setActiveIcon({
                          objectUrl: item.activeIconUrl,
                          name: 'activeIcon',
                        })
                        setInactiveIcon({
                          objectUrl: item.inactiveIconUrl,
                          name: 'inactiveIcon',
                        })
                        setTranslations(
                          item.translations.map(({ language, value }) => ({
                            languageIsoCode: language.isoCode,
                            value,
                          }))
                        )
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {user.hasAction(ACTIONS.WISH_CATEGORY.DELETE) && (
                    <Button
                      disabled={isReorderMode}
                      marginRight={12}
                      iconBefore="trash"
                      intent="danger"
                      onClick={(e) => {
                        e.stopPropagation()
                        setId(item.id)
                        setIsConfirmDeleteModalOpen(true)
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Table.Cell>
              </StyledRow>
            )}
          </EditableList>
        </StyledBody>
      </SideNavPage.Content>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete country"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id,
          })
          if (!error) {
            setId('')
            close()
          }
        }}
      >
        {`Are you sure you want to delete wish category with name: "${name}"?`}
      </Dialog>
      <Dialog
        isShown={isCreateModalOpen || isUpdateModalOpen}
        title={
          isCreateModalOpen
            ? 'Create wish category'
            : `Edit wish category: ${name}`
        }
        hasCancel={false}
        confirmLabel={isCreateModalOpen ? 'Create' : 'Edit'}
        isConfirmLoading={isSending}
        onCloseComplete={() => {
          setIsCreateModalOpen(false)
          setIsUpdateModalOpen(false)
        }}
        onConfirm={async (close) => {
          setIsSending(true)
          try {
            if (isCreateModalOpen) {
              await uploadRequest({
                accessToken: user.accessToken,
                body: {
                  query: `mutation($input: CreateWishCategoryInput!) {
                    createWishCategory(input: $input) ${wishCategoryFields}
                  }`,
                  variables: {
                    input: {
                      name,
                      activeIconFile: activeIcon.file,
                      inactiveIconFile: inactiveIcon.file,
                      translations: translations.filter(
                        ({ value }) => value !== ''
                      ),
                    },
                  },
                },
              })
            }
            if (isUpdateModalOpen) {
              await uploadRequest({
                accessToken: user.accessToken,
                body: {
                  query: `mutation($input: EditWishCategoryInput!) {
                    editWishCategory(input: $input) ${wishCategoryFields}
                  }`,
                  variables: {
                    input: {
                      id,
                      name,
                      activeIconFile: activeIcon.file,
                      inactiveIconFile: inactiveIcon.file,
                      translations: translations.filter(
                        ({ value }) => value !== ''
                      ),
                    },
                  },
                },
              })
            }
            await executeWishCategoriesQuery({ requestPolicy: 'network-only' })
            close()
          } catch (error) {
            // do nothing
          } finally {
            setIsSending(false)
          }
        }}
      >
        <TextInputField
          label="Name"
          required
          value={name}
          disabled={isUpdateDisabled}
          onChange={(e) => {
            setName(e.target.value)
          }}
        />
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Icon (SVG)
          </Heading>
          <Dropzone
            onDrop={(acceptedFiles) => {
              const file = acceptedFiles[0]
              if (getIsFileSizeExceeded({ file })) {
                setInactiveIcon({
                  file,
                  name: file.name,
                  objectUrl: URL.createObjectURL(file),
                })
              }
            }}
          />
          {inactiveIcon.objectUrl && (
            <Image alt="preview" src={inactiveIcon.objectUrl} />
          )}
        </Pane>
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Active icon (SVG)
          </Heading>
          <Dropzone
            onDrop={(acceptedFiles) => {
              const file = acceptedFiles[0]
              if (getIsFileSizeExceeded({ file })) {
                setActiveIcon({
                  file,
                  name: file.name,
                  objectUrl: URL.createObjectURL(file),
                })
              }
            }}
          />
          {activeIcon.objectUrl && (
            <Image alt="preview" src={activeIcon.objectUrl} />
          )}
        </Pane>
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Translations
          </Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Language</Table.TextHeaderCell>
              <Table.TextHeaderCell>Value</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {languagesQuery.data &&
                languagesQuery.data.languages.map((language) => (
                  <Table.Row key={language.isoCode}>
                    <Table.TextCell>{language.title}</Table.TextCell>
                    <Table.Cell>
                      <TextInputField
                        label="Translation"
                        value={getTranslationValue(language.isoCode)}
                        disabled={isUpdateDisabled}
                        onChange={(e) => {
                          updateTranslationValue(
                            language.isoCode,
                            e.target.value
                          )
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Pane>
      </Dialog>
    </React.Fragment>
  )
}

WishCategories.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(WishCategories)
