import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { MANAGEMENT_ROUTES, DOD_ROUTES } from 'constants/routes'
import NewDoD from './routes/NewDoD'
import EditDoD from './routes/EditDoD'
import Management from './routes/Management'

const { NEW, EDIT } = DOD_ROUTES

const DealOfTheDays = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/${NEW}`} component={NewDoD} />
    <Route path={`${match.url}/${EDIT}/:id`} component={EditDoD} />
    <Route
      component={Management}
      path={`${match.url}/:tab(${Object.values(MANAGEMENT_ROUTES).join('|')})?`}
    />
  </Switch>
)

DealOfTheDays.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default DealOfTheDays
