import React from 'react'
import PropTypes from 'prop-types'
import SideNavPage from 'sharedComponents/SideNavPage'
import { BackButton } from 'evergreen-ui'
import Form from '../../components/Form'

const NewThemeDeal = ({ history }) => (
  <React.Fragment>
    <SideNavPage.Header>
      <SideNavPage.Title>Create Theme Deal</SideNavPage.Title>
      <BackButton onClick={() => history.goBack()} />
    </SideNavPage.Header>
    <SideNavPage.Content>
      <Form />
    </SideNavPage.Content>
  </React.Fragment>
)

NewThemeDeal.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
}
export default NewThemeDeal
