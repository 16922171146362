import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { TIME_FORMAT } from 'constants/constants'
import format from 'date-fns/format'
import { useMutation } from 'urql'
import urql from 'utils/urql'
import {
  TextInputField,
  Pane,
  Button,
  toaster,
  RadioGroup,
  SegmentedControl,
  Label,
} from 'evergreen-ui'
import Picker from 'components/Datepicker'
import SellersSelect from 'components/SellersSelect'
import CurrencySelect from 'components/CurrencySelect'
import { parseInteger } from 'utils/numbers'
import ROUTES from 'constants/routes'

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}
const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const FEED_TYPES = {
  XML: 'XML',
  CSV: 'CSV',
  JSON: 'JSON',
}

const Form = ({ feed, isDisabled, history }) => {
  const [sellerId, setSellerId] = useState(feed ? feed.seller.id : '')
  const [url, setUrl] = useState(feed ? feed.url : '')
  const [isEnabled, setIsEnabled] = useState(feed ? feed.isEnabled : true)
  const [type, setType] = useState(feed ? feed.type : FEED_TYPES.XML)

  const [intervalInMinutes, setIntervalInMinutes] = useState(
    feed ? feed.intervalInMinutes : null
  )
  const [defaultCurrencyIsoCode, setDefaultCurrencyIsoCode] = useState(
    feed ? feed.defaultCurrencyIsoCode : ''
  )
  const [productFieldName, setProductFieldName] = useState(
    feed ? feed.productFieldName : ''
  )
  const [sellerExternalNote, setSellerExternalNote] = useState(
    feed ? feed.sellerExternalNote : ''
  )

  const [productIdFieldName, setProductIdFieldName] = useState(
    feed ? feed.productIdFieldName : ''
  )
  const [productPriceFieldName, setProductPriceFieldName] = useState(
    feed ? feed.productPriceFieldName : ''
  )
  const [productCurrencyFieldName, setProductCurrencyFieldName] = useState(
    feed ? feed.productCurrencyFieldName : ''
  )
  const [productQuantityFieldName, setProductQuantityFieldName] = useState(
    feed ? feed.productQuantityFieldName : ''
  )
  const [firstSyncAtUtc, setFirstSyncAtUtc] = useState(
    feed ? feed.firstSyncAtUtc : null
  )
  const dataToSave = {
    url,
    type,
    productFieldName,
    productIdFieldName,
    sellerExternalNote,
    productPriceFieldName,
    productQuantityFieldName,
    productCurrencyFieldName,
    firstSyncAtUtc,
    isEnabled,
    defaultCurrencyIsoCode,
    intervalInMinutes: parseInteger(intervalInMinutes),
    sellerId: parseInteger(sellerId),
  }
  const [, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateFeedInput!) {
      updateFeed(input: $input) {
        id
      }
    }
  `)
  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateFeedInput!) {
      createFeed(input: $input) {
        id
      }
    }
  `)
  return (
    <div>
      <TextInputField
        label="URL"
        required
        value={url}
        disabled={isDisabled}
        onChange={(e) => {
          setUrl(e.target.value)
        }}
      />
      <SellersSelect required sellerId={sellerId} setSellerId={setSellerId} />
      <RadioGroup
        size={16}
        marginBottom="10px"
        label="Feed type *"
        value={type}
        options={[
          { label: FEED_TYPES.XML, value: FEED_TYPES.XML },
          { label: FEED_TYPES.JSON, value: FEED_TYPES.JSON },
          { label: FEED_TYPES.CSV, value: FEED_TYPES.CSV },
        ]}
        onChange={(value) => setType(value)}
      />
      <Label>Is feed enabled for sync</Label>
      <Pane marginY="10px">
        <SegmentedControl
          name="switch"
          width={240}
          options={[
            {
              label: 'YES',
              value: true,
            },
            {
              label: 'NO',
              value: false,
            },
          ]}
          value={isEnabled}
          onChange={(value) => setIsEnabled(value)}
        />
      </Pane>
      <TextInputField
        label="Product field name"
        description="Name of the property, where the products are stored"
        required
        value={productFieldName}
        disabled={isDisabled}
        onChange={(e) => {
          setProductFieldName(e.target.value)
        }}
      />
      <TextInputField
        label="Product id field name"
        required
        value={productIdFieldName}
        disabled={isDisabled}
        onChange={(e) => {
          setProductIdFieldName(e.target.value)
        }}
      />
      <TextInputField
        label="Product price field name"
        required
        value={productPriceFieldName}
        disabled={isDisabled}
        onChange={(e) => {
          setProductPriceFieldName(e.target.value)
        }}
      />
      <TextInputField
        label="Product currency field name"
        description="We will look for this field in the product entity. If nothing is found, the default currency is used."
        value={productCurrencyFieldName}
        disabled={isDisabled}
        onChange={(e) => {
          setProductCurrencyFieldName(e.target.value)
        }}
      />
      <CurrencySelect
        label="Default currency"
        setCurrencyIsoCode={setDefaultCurrencyIsoCode}
        selectedIsoCode={defaultCurrencyIsoCode}
        disabled={isDisabled}
        required
      />
      <TextInputField
        label="Product quantity field name"
        required
        value={productQuantityFieldName}
        disabled={isDisabled}
        onChange={(e) => {
          setProductQuantityFieldName(e.target.value)
        }}
      />
      <Pane marginBottom="20px">
        <Picker.DateTime
          onChangeDate={setFirstSyncAtUtc}
          date={firstSyncAtUtc}
          minDate={new Date()}
          label="First sync at *"
          isDisabled={feed && feed.lastSyncAtUtc > feed.firstSyncAtUtc}
        />
      </Pane>
      <TextInputField
        label="Interval of syncs"
        type="number"
        required
        value={intervalInMinutes}
        disabled={isDisabled}
        onChange={(e) => {
          setIntervalInMinutes(e.target.value)
        }}
      />
      <TextInputField
        label="Seller PID"
        required
        value={sellerExternalNote}
        onChange={(e) => {
          setSellerExternalNote(e.target.value)
        }}
      />

      {feed && (
        <TextInputField
          label="Last sync"
          value={
            feed &&
            feed.lastSyncAtUtc &&
            format(feed.lastSyncAtUtc, TIME_FORMAT.LOCAL_DATETIME)
          }
          disabled
        />
      )}
      <Pane justifyContent="center" display="flex">
        <Button
          appearance="primary"
          intent="success"
          onClick={async () => {
            if (feed && feed.id) {
              const { error } = await executeUpdateMutation({
                input: {
                  id: parseInteger(feed.id),
                  ...dataToSave,
                },
              })
              displayToastOnSave(error)
            } else {
              const result = await executeCreateMutation({
                input: dataToSave,
              })
              displayToastOnSave(result.error)
              if (!result.error) {
                history.replace(
                  `/${ROUTES.FEEDS}/edit/${result.data.createFeed.id}`
                )
              }
            }
          }}
        >
          {feed && feed.id ? 'Save' : 'Create new feed'}
        </Button>
      </Pane>
    </div>
  )
}

Form.defaultProps = {
  isDisabled: false,
}

Form.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
  isDisabled: PropTypes.bool,
  feed: PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    seller: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    productFieldName: PropTypes.string.isRequired,
    productIdFieldName: PropTypes.string.isRequired,
    productPriceFieldName: PropTypes.string.isRequired,
    productQuantityFieldName: PropTypes.string.isRequired,
    intervalInMinutes: PropTypes.number,
    lastSyncAtUtc: PropTypes.string,
  }),
}

export default withRouter(Form)
