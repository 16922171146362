import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SideNavPage from 'sharedComponents/SideNavPage'
import { useQuery, useMutation } from 'urql'
import urql from 'utils/urql'
import {
  Heading,
  Pane,
  Table,
  Spinner,
  Button,
  Dialog,
  TextInputField,
} from 'evergreen-ui'
// import SettingsForm from './components/SettingsForm'
import { withUser } from 'UserProvider'
import ACTIONS from 'constants/actions'

const TABLE_MIN_HEIGHT = 500

const currencyFields = `{
  isoCode
  title
  iban
  accountBankName
  bankCode
  accountHolder
  accountNumber
  basePriceToCalculationBenefit
  bic
  sortCode
}`

const settingFields = `{
  value
}`

const Settings = ({ user }) => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)

  const [isoCode, setIsoCode] = useState('')
  const [title, setTitle] = useState('')
  const [iban, setIban] = useState('')
  const [accountBankName, setAccountBankName] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [accountHolder, setAccountHolder] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [bic, setBic] = useState('')
  const [sortCode, setSortCode] = useState('')
  const [basePriceToCalculationBenefit, setBasePriceToCalculationBenefit] =
    useState(1000)

  const [currenciesQuery] = useQuery({
    query: `
      query {
        currencies ${currencyFields}
      }
    `,
  })

  const [updateMutation, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateCurrencyInput!) {
      updateCurrency(input: $input) ${currencyFields}
    }
  `)

  const [defaultMPFeeQuery] = useQuery({
    query: `
      query {
        getDefaultMPFee ${settingFields}
      }
    `,
  })

  // const dataDefaultMPFee = defaultMPFeeQuery.data ? defaultMPFeeQuery.data.getDefaultMPFee : null

  useEffect(() => {
    const error =
      defaultMPFeeQuery.error || currenciesQuery.error || updateMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [defaultMPFeeQuery.error, currenciesQuery.error, updateMutation.error])

  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Settings</SideNavPage.Title>
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Pane paddingBottom={15}>
          <Heading>IBAN</Heading>
        </Pane>
        <Pane paddingBottom={15}>
          <Table border>
            <Table.Head>
              <Table.TextHeaderCell>ISO code</Table.TextHeaderCell>
              <Table.TextHeaderCell>Title</Table.TextHeaderCell>
              <Table.TextHeaderCell>Bank Information</Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Base price to calculation benefit
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body minHeight={TABLE_MIN_HEIGHT}>
              {currenciesQuery.fetching && (
                <Spinner
                  marginX="auto"
                  marginY={TABLE_MIN_HEIGHT / 2}
                  delay={200}
                  size={80}
                />
              )}
              {currenciesQuery.data &&
                currenciesQuery.data.currencies.map((currency) => (
                  <Table.Row key={currency.isoCode}>
                    <Table.TextCell>{currency.isoCode}</Table.TextCell>
                    <Table.TextCell>{currency.title}</Table.TextCell>
                    <Table.TextCell>
                      {currency.accountBankName && (
                        <Pane>{`Bank Name: ${currency.accountBankName}`}</Pane>
                      )}
                      {currency.bankCode && (
                        <Pane>{`Bank Code: ${currency.bankCode}`}</Pane>
                      )}
                      {currency.accountHolder && (
                        <Pane>
                          {`Account Holder: ${currency.accountHolder}`}
                        </Pane>
                      )}
                      {currency.accountNumber && (
                        <Pane>
                          {`Account Number: ${currency.accountNumber}`}
                        </Pane>
                      )}
                      {currency.iban && <Pane>{`IBAN: ${currency.iban}`}</Pane>}
                      {currency.bic && <Pane>{`BIC: ${currency.bic}`}</Pane>}
                      {currency.sortCode && (
                        <Pane>{`Sort Code: ${currency.sortCode}`}</Pane>
                      )}
                    </Table.TextCell>
                    <Table.TextCell>
                      {currency.basePriceToCalculationBenefit}
                    </Table.TextCell>
                    <Table.Cell>
                      {user.hasAction(ACTIONS.CURRENCY.UPDATE) && (
                        <Button
                          marginRight={12}
                          iconBefore="edit"
                          onClick={(e) => {
                            e.stopPropagation()
                            setIsoCode(currency.isoCode)
                            setTitle(currency.title)
                            setIban(currency.iban)
                            setAccountBankName(currency.accountBankName)
                            setIsUpdateModalOpen(true)
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Pane>
        {/* <Pane paddingBottom={15}>
          <Heading>Others</Heading>
        </Pane> */}
        {/* <SettingsForm data={dataDefaultMPFee} /> */}
      </SideNavPage.Content>

      <Dialog
        isShown={isUpdateModalOpen}
        title={`Setting Bank (${isoCode})`}
        hasCancel={false}
        confirmLabel="Edit"
        isConfirmLoading={updateMutation.fetching}
        onCloseComplete={() => {
          setIsUpdateModalOpen(false)
        }}
        onConfirm={async (close) => {
          const input = {
            isoCode,
            iban,
            accountBankName,
            bankCode,
            accountHolder,
            accountNumber,
            basePriceToCalculationBenefit,
            bic,
            sortCode,
          }
          if (isUpdateModalOpen) {
            const { error } = await executeUpdateMutation({
              input,
            })
            if (!error) {
              close()
            }
          }
        }}
      >
        <TextInputField
          label="ISO code"
          disabled
          value={isoCode}
          onChange={(e) => {
            setIsoCode(e.target.value)
          }}
        />
        <TextInputField
          label="Title"
          disabled
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <TextInputField
          label="Bank Name"
          value={accountBankName}
          onChange={(e) => {
            setAccountBankName(e.target.value)
          }}
        />
        <TextInputField
          label="Bank Code"
          value={bankCode}
          onChange={(e) => {
            setBankCode(e.target.value)
          }}
        />
        <TextInputField
          label="Account Holder"
          value={accountHolder}
          onChange={(e) => {
            setAccountHolder(e.target.value)
          }}
        />
        <TextInputField
          label="Account Number"
          value={accountNumber}
          onChange={(e) => {
            setAccountNumber(e.target.value)
          }}
        />
        <TextInputField
          label="BIC"
          value={bic}
          onChange={(e) => {
            setBic(e.target.value)
          }}
        />
        <TextInputField
          label="Sort Code"
          value={sortCode}
          onChange={(e) => {
            setSortCode(e.target.value)
          }}
        />
        <TextInputField
          label="IBAN"
          value={iban}
          onChange={(e) => {
            setIban(e.target.value)
          }}
        />
        <TextInputField
          label="Base price to calculation benefit"
          value={basePriceToCalculationBenefit}
          type="number"
          onChange={(e) => {
            setBasePriceToCalculationBenefit(e.target.value)
          }}
        />
      </Dialog>
    </React.Fragment>
  )
}

Settings.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Settings)
