import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'

import { Button, TextInputField, Pane, majorScale, toaster } from 'evergreen-ui'

import { withRouter } from 'react-router-dom'
import urql from 'utils/urql'
import ROUTES from 'constants/routes'
import { parseInteger } from 'utils/numbers'

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const Form = ({ history }) => {
  const [customerId, setCustomerId] = useState('')
  const [orderTotal, setOrderTotal] = useState('')
  const [orderCurrencyIsoCode, setOrderCurrencyIsoCode] = useState('')
  const [productName, setProductName] = useState('')
  const [productSource, setProductSource] = useState('')
  const [orderDate, setOrderDate] = useState('')

  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateOldOrderInput!) {
      createOldOrder(input: $input) {
        id
      }
    }
  `)

  const importOrder = async () => {
    const dataToSave = {
      customerId: parseInteger(customerId),
      orderTotal: parseFloat(orderTotal),
      orderCurrencyIsoCode,
      productName,
      productSource,
      orderDate,
    }
    const { error } = await executeCreateMutation({ input: dataToSave })
    displayToastOnSave(error, 'Saved')
    if (!error) {
      history.push(ROUTES.OLD_ORDERS)
    }
  }
  return (
    <Pane>
      <Pane maxWidth={780}>
        <TextInputField
          label="customer id (for example: 4401)"
          required
          value={customerId || ''}
          onChange={({ target }) => {
            setCustomerId(target.value === '' ? undefined : target.value)
          }}
        />
        <TextInputField
          label="order total price (for example: 1250.55)"
          required
          value={orderTotal || ''}
          onChange={({ target }) => {
            setOrderTotal(target.value === '' ? undefined : target.value)
          }}
        />
        <TextInputField
          label="order currency (for example: EUR)"
          required
          value={orderCurrencyIsoCode || ''}
          onChange={({ target }) => {
            setOrderCurrencyIsoCode(
              target.value === '' ? undefined : target.value
            )
          }}
        />
        <TextInputField
          label="Product Name (for example: iPhone Red 256GB)"
          required
          value={productName || ''}
          onChange={({ target }) => {
            setProductName(target.value === '' ? undefined : target.value)
          }}
        />
        <TextInputField
          label="Product Source (for example: yukatel)"
          required
          value={productSource || ''}
          onChange={({ target }) => {
            setProductSource(target.value === '' ? undefined : target.value)
          }}
        />
        <TextInputField
          label="Order date (for example: 2019-01-31 13:55)"
          required
          value={orderDate || ''}
          onChange={({ target }) => {
            setOrderDate(target.value === '' ? undefined : target.value)
          }}
        />

        <Pane marginTop={majorScale(2)} justifyContent="center" display="flex">
          <Pane>
            <Button
              intent="success"
              marginRight={majorScale(2)}
              onClick={importOrder}
            >
              Import order
            </Button>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

Form.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(Form)
