import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Pane, toaster } from 'evergreen-ui'
import { compose } from 'recompose'
import { useMutation, useQuery } from 'urql'
import { withRouter } from 'react-router-dom'
import sortBy from 'lodash.sortby'
import { withUser } from 'UserProvider'
import { getSpaceInPx } from 'utils/styled-system'
import { DEAL_STATUSES, SORT_DIRECTION_ENUM } from 'constants/constants'
import ACTIONS from 'constants/actions'
import ROUTES, { MANAGEMENT_ROUTES } from 'constants/routes'
import NoData from 'sharedComponents/NoData'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import ActionsRow from 'components/ActionsRow'
import EditModeButton from 'components/EditModeButton'
import EditableList, {
  rearangeItemsBasedOnNewLayout,
  moveItemInItemsToTheTop,
  getIdsOrderedByPositionIndex,
} from 'components/EditableList'
import {
  extractDataFromDealOfTheDaysQuery,
  getDealOfTheDaysQuery,
  updateDealOfTheDayPublishPositionsMutation,
  pauseDealOfTheDayMutation,
  resumeDealOfTheDayMutation,
} from '../queries'
import { getPathToDealOfTheDayDetail } from '../../../utils'
import DoDItem from '../components/DoDItem'
import DeleteDoDDialog from '../../../components/DeleteDoDDialog'
import { DOD_ITEM_Y_MARGIN, DOD_ITEM_HEIGHT } from '../constants'
import withApproveDeclineMutations, {
  toastResultOfApproval,
  toastResultOfPublish,
} from '../../../components/withAprroveDeclineMutations'

const { APPROVAL_NEEDED, APPROVED, DECLINED, DRAFT, FINISHED, LIVE } =
  DEAL_STATUSES

const MAX_MOVABLE_DODS = 10
const PUBLISHED_AT_UTC = 'publishedAtUtc'

const getIsNoData = (query, items) => !query.fetching && items.length === 0

const General = ({
  history,
  user,
  executeApproveDealOfTheDayMutation,
  showDeclineDealOfTheDayDialog,
  executePublishDealOfTheDayMutation,
}) => {
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [rearrangedDealOfTheDays, setRearrangedDealOfTheDays] = useState([])
  const [dealOfTheDaysQuery, executeDealOfTheDaysQuery] = useQuery(
    getDealOfTheDaysQuery(
      {
        status: [APPROVED, APPROVAL_NEEDED, DECLINED, DRAFT],
        sorter: {
          field: 'positionIndexWeight',
          direction: SORT_DIRECTION_ENUM.ASC,
        },
      },
      `
        positionIndexWeight
        estimatedStartAtUtc
      `
    )
  )
  const [finishedDealOfTheDaysQuery] = useQuery(
    getDealOfTheDaysQuery(
      {
        status: [FINISHED],
        pagination: { page: 1, limit: 3 },
        sorter: {
          field: 'publishedAtUtc',
          direction: SORT_DIRECTION_ENUM.DESC,
        },
      },
      PUBLISHED_AT_UTC
    )
  )
  const [liveDealOfTheDaysQuery, executeLiveDealOfTheDaysQuery] = useQuery(
    getDealOfTheDaysQuery(
      {
        status: [LIVE],
        pagination: { page: 1, limit: 1 },
      },
      PUBLISHED_AT_UTC
    )
  )
  const [, executeUpdateDealOfTheDayPublishPositionsMutation] = useMutation(
    updateDealOfTheDayPublishPositionsMutation
  )
  const [, executePauseDealOfTheDayMutation] = useMutation(
    pauseDealOfTheDayMutation
  )
  const [, executeResumeDealOfTheDayMutation] = useMutation(
    resumeDealOfTheDayMutation
  )
  const { dealOfTheDays: nextDealOfTheDays } =
    extractDataFromDealOfTheDaysQuery(dealOfTheDaysQuery)
  const finishedDealOfTheDays = finishedDealOfTheDaysQuery.data
    ? finishedDealOfTheDaysQuery.data.dealOfTheDays.data
    : []
  const { dealOfTheDays: liveDealOfTheDays } =
    extractDataFromDealOfTheDaysQuery(liveDealOfTheDaysQuery)
  const movableNextDealOfTheDays = nextDealOfTheDays.slice(0, MAX_MOVABLE_DODS)
  const hiddenNextDealOfTheDays = nextDealOfTheDays.slice(
    MAX_MOVABLE_DODS,
    nextDealOfTheDays.length
  )
  const currentlyDisplayedNextDoD = isEditMode
    ? rearrangedDealOfTheDays
    : movableNextDealOfTheDays
  const isFetching =
    dealOfTheDaysQuery.fetching ||
    finishedDealOfTheDaysQuery.fetching ||
    liveDealOfTheDaysQuery.fetching
  const liveDealOfTheDay = liveDealOfTheDays[0]
  const isWithEditButton =
    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE) ||
    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE_TRANSLATIONS) ||
    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_TRANSLATIONS) ||
    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_COMPETITOR)
  return (
    <Pane>
      <FullScreenLoading isVisible={isFetching} />
      <ActionsRow>
        {user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE_PUBLISH_POSITION) && (
          <EditModeButton
            isEditMode={isEditMode}
            onClick={async () => {
              if (isEditMode) {
                const { error } =
                  await executeUpdateDealOfTheDayPublishPositionsMutation({
                    ids: [
                      ...getIdsOrderedByPositionIndex(rearrangedDealOfTheDays),
                      ...hiddenNextDealOfTheDays.map(({ id }) => id),
                    ],
                  })
                if (error) {
                  toaster.danger('Rearrangement failed.')
                } else {
                  executeDealOfTheDaysQuery({ requestPolicy: 'network-only' })
                  toaster.success('Rearrangement succeeded.')
                }
              } else {
                setRearrangedDealOfTheDays(movableNextDealOfTheDays)
              }
              setIsEditMode(!isEditMode)
            }}
          />
        )}
      </ActionsRow>
      {!isFetching &&
        sortBy(finishedDealOfTheDays, [PUBLISHED_AT_UTC]).map((item) => (
          <DoDItem
            key={item.id}
            {...item}
            my={getSpaceInPx(DOD_ITEM_Y_MARGIN)}
            onDetailClick={() => {
              history.push(getPathToDealOfTheDayDetail(item.id))
            }}
            isWithDeleteButton={user.hasAction(ACTIONS.DEAL_OF_THE_DAY.DELETE)}
            isWithEditButton={isWithEditButton}
          />
        ))}
      {!isFetching && liveDealOfTheDay && (
        <DoDItem
          {...liveDealOfTheDay}
          my={getSpaceInPx(DOD_ITEM_Y_MARGIN)}
          onDetailClick={() => {
            history.push(getPathToDealOfTheDayDetail(liveDealOfTheDay.id))
          }}
          isWithPauseButton={
            !liveDealOfTheDay.isPaused &&
            user.hasAction(ACTIONS.DEAL_OF_THE_DAY.PAUSE)
          }
          onPauseButtonClicked={async () => {
            await executePauseDealOfTheDayMutation({ id: liveDealOfTheDay.id })
          }}
          isWithResumeButton={
            liveDealOfTheDay.isPaused &&
            user.hasAction(ACTIONS.DEAL_OF_THE_DAY.RESUME)
          }
          isWithDeleteButton={user.hasAction(ACTIONS.DEAL_OF_THE_DAY.DELETE)}
          onResumeButtonClicked={async () => {
            await executeResumeDealOfTheDayMutation({ id: liveDealOfTheDay.id })
          }}
          isWithEditButton={isWithEditButton}
        />
      )}
      <>
        <DeleteDoDDialog
          onSuccess={() => {
            executeDealOfTheDaysQuery({ requestPolicy: 'network-only' })
          }}
          onClose={() => {
            setItemToSoftDelete(null)
          }}
          itemToDelete={itemToSoftDelete}
        />
        <EditableList
          items={currentlyDisplayedNextDoD}
          isEditMode={isEditMode}
          onDragStop={(newLayout) => {
            setRearrangedDealOfTheDays((currentNewDealOfTheDays) =>
              rearangeItemsBasedOnNewLayout({
                newLayout,
                items: currentNewDealOfTheDays,
              })
            )
          }}
          margin={[0, DOD_ITEM_Y_MARGIN]}
          rowHeight={DOD_ITEM_HEIGHT}
        >
          {(item) => (
            <div key={item.id}>
              <DoDItem
                {...item}
                isEditMode={isEditMode}
                isToTheTopButtonVisible={user.hasAction(
                  ACTIONS.DEAL_OF_THE_DAY.UPDATE_PUBLISH_POSITION
                )}
                isWithApproveButton={
                  item.status === APPROVAL_NEEDED &&
                  user.hasAction(ACTIONS.DEAL_OF_THE_DAY.APPROVE)
                }
                isWithDeclineButton={
                  item.status === APPROVAL_NEEDED &&
                  user.hasAction(ACTIONS.DEAL_OF_THE_DAY.DECLINE)
                }
                onMoveItemToTopClick={async () => {
                  const { error } =
                    await executeUpdateDealOfTheDayPublishPositionsMutation({
                      ids: getIdsOrderedByPositionIndex(
                        moveItemInItemsToTheTop({
                          items: nextDealOfTheDays,
                          idOfItemToTop: item.id,
                        })
                      ),
                    })
                  if (error) {
                    toaster.danger('Rearrangement failed.')
                  } else {
                    toaster.success('Rearrangement succeeded.')
                    executeDealOfTheDaysQuery({
                      requestPolicy: 'network-only',
                    })
                  }
                }}
                onDetailClick={() =>
                  history.push(getPathToDealOfTheDayDetail(item.id))
                }
                onDeleteButtonClicked={() => setItemToSoftDelete(item)}
                onApproveButtonClicked={async () => {
                  const result = await executeApproveDealOfTheDayMutation({
                    id: item.id,
                  })
                  toastResultOfApproval(result)
                }}
                onDeclineButtonClicked={() => {
                  showDeclineDealOfTheDayDialog({
                    dealOfTheDayId: item.id,
                  })
                }}
                isWithDeleteButton={user.hasAction(
                  ACTIONS.DEAL_OF_THE_DAY.DELETE
                )}
                isWithEditButton={
                  user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE) ||
                  user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE_TRANSLATIONS) ||
                  user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_TRANSLATIONS) ||
                  user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_COMPETITOR)
                }
                isWithPublishButton={
                  !liveDealOfTheDay &&
                  item.status === DEAL_STATUSES.APPROVED &&
                  user.hasAction(ACTIONS.DEAL_OF_THE_DAY.PUBLISH)
                }
                onPublishButtonClicked={async () => {
                  const result = await executePublishDealOfTheDayMutation({
                    id: item.id,
                  })
                  toastResultOfPublish(result)
                  executeLiveDealOfTheDaysQuery()
                }}
              />
            </div>
          )}
        </EditableList>
      </>
      {getIsNoData(dealOfTheDaysQuery, nextDealOfTheDays) &&
        getIsNoData(finishedDealOfTheDaysQuery, finishedDealOfTheDays) &&
        getIsNoData(liveDealOfTheDaysQuery, liveDealOfTheDays) && (
          <NoData padding={60} />
        )}
      <ActionsRow>
        <Button
          appearance="minimal"
          onClick={() => {
            history.push(
              `/${ROUTES.DEAL_OF_THE_DAYS}/${MANAGEMENT_ROUTES.NEXT}`
            )
          }}
        >
          Go to the `Next` tab to see all DoDs
        </Button>
      </ActionsRow>
    </Pane>
  )
}

General.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveDealOfTheDayMutation: PropTypes.func.isRequired,
  showDeclineDealOfTheDayDialog: PropTypes.func.isRequired,
  executePublishDealOfTheDayMutation: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  withUser,
  withApproveDeclineMutations
)(General)
