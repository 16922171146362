import format from 'date-fns/format'

export const TIME_FORMAT = {
  BACKEND_DATE: 'YYYY-MM-DD',
  LOCAL_DATE: 'DD.MM.YYYY',
  LOCAL_DATETIME: 'DD.MM.YYYY HH:mm:ss',
}
export const TODAY = format(new Date(), TIME_FORMAT.BACKEND_DATE)

export const PLATFORMS = {
  IOS: 'iOS',
  ANDROID: 'Android',
}

export const DEAL_STATUSES = {
  DRAFT: 'DRAFT',
  APPROVAL_NEEDED: 'APPROVAL_NEEDED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  FINISHED: 'FINISHED',
  LIVE: 'LIVE',
  DELETED: 'DELETED',
  IN_CARRIER_HANDS: 'IN_CARRIER_HANDS',
}

export const SIZES = {
  FREE: 'FREE',
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
  XXL: 'XXL',
}

const PROCESSING = 'PROCESSING'
const ON_HOLD = 'ON_HOLD'
const COMPLETED = 'COMPLETED'
const IN_CARRIER_HANDS = 'IN_CARRIER_HANDS'
const CANCELLED = 'CANCELLED'
const CANCELLED_BY_USER = 'CANCELLED_BY_USER'
const REFUNDED = 'REFUNDED'
const PENDING = 'PENDING'

export const QUERY_TYPES = {
  DEAL_OF_THE_DAY: 'OrderPublicDealOfTheDay',
  DEAL_OF_THE_WEEK: 'OrderPublicDealOfTheWeek',
  WISH_OFFER: 'OrderWishOffer',
  THEME_DEAL: 'OrderPublicThemeDeal',
  BEST_SELLER: 'OrderPublicBestSeller',
  PHONE_SERVICE: 'OrderPublicPhoneService',
}

export const ORDER_STATUSES = {
  PENDING,
  PROCESSING,
  ON_HOLD,
  IN_CARRIER_HANDS,
  COMPLETED,
  CANCELLED,
  CANCELLED_BY_USER,
  REFUNDED,
}

export const THREAD_CATEGORIES = {
  WARRANTY: 'warranty',
  DELIVERY: 'delivery',
  RETURN: 'return',
}

export const THREAD_STATUSES = {
  PROCESSING: PROCESSING.toLowerCase(),
  CLOSED: 'closed',
  REOPENED: 'reopened',
  URGENT: 'urgent',
  OPEN: 'open',
}

export const PUBLIC_ORDER_STATUSES = {
  WAITING_PAYMENT: 'WAITING_PAYMENT',
  WAITING_VERIFICATION: 'WAITING_VERIFICATION',
  PROCESSING: 'PROCESSING',
  SHIPPING: 'SHIPPING',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  CANCELLED_BY_USER: 'CANCELLED_BY_USER',
  REFUNDED: 'REFUNDED',
  ON_HOLD: 'ON_HOLD',
}

export const RETURN_ORDER_STATUSES = {
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
  ELIGIBLE: 'ELIGIBLE',
  SENT: 'SENT',
  RECEIVED: 'RECEIVED',
  APPROVED: 'APPROVED',
  REFUNDED: 'REFUNDED',
  NOT_RETURN: 'NOT_RETURN',
}

export const PAYMENT_INTENT_STATUSES = {
  SUCCEEDED: 'succeeded',
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
  REQUIRES_ACTION: 'requires_action',
}

export const SORT_DIRECTION_ENUM = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const WISH_STATUSES = {
  NEW: 'NEW',
  DECIDE_LATER: 'DECIDE_LATER',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  CUSTOMER_DELETED: 'CUSTOMER_DELETED',
  OFFER_CREATED: 'OFFER_CREATED',
  ORDERED: 'ORDERED',
  OFFER_NOT_ACCEPTED: 'OFFER_NOT_ACCEPTED',
}

export const FEEDBACK_TYPES = {
  UNCATEGORIZED: 'UNCATEGORIZED',
  PRODUCT_FEEDBACK: 'PRODUCT_FEEDBACK',
  CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT',
}

export const DEALS_DURATIONS = {
  DEAL_OF_THE_DAY_DURATION_IN_DAYS: 1,
  DEAL_OF_THE_WEEK_DURATION_IN_WEEKS: 1,
}

export const ENVIRONMENTS = {
  PRODUCTION: 'production',
}

export const STRIPE_PRODUCTION_URL = 'https://dashboard.stripe.com/'

export const STRIPE_URL = process.env.REACT_APP_API_URL.includes('production')
  ? STRIPE_PRODUCTION_URL
  : `${STRIPE_PRODUCTION_URL}test/`

export const MAX_UPLOAD_IMAGE_SIZE_IN_BYTES = 10000000
export const MAX_UPLOAD_FILE_SIZE_IN_BYTES = 10000000
