import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'urql'
import { withUser } from 'UserProvider'
import { getSpaceInPx } from 'utils/styled-system'
import { DEAL_STATUSES } from 'constants/constants'
import ActionsRow from 'components/ActionsRow'
import ACTIONS from 'constants/actions'
import DoWList from '../components/DoWList'
import DoWItem from '../components/DoWItem'
import { DOW_ITEM_Y_MARGIN } from '../constants'
import { getPathToDealOfTheWeekDetail } from '../../../utils'
import DeleteDoWDialog from '../../../components/DeleteDoWDialog'
import { getDealOfTheWeeksQuery } from '../queries'

const { DECLINED, DRAFT } = DEAL_STATUSES

const WorkInProgress = ({ history, user }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [dealOfTheWeeksQuery, executeDealOfTheWeeksQuery] = useQuery(
    getDealOfTheWeeksQuery(
      {
        status: [DRAFT, DECLINED],
        pagination: { page: currentPage, limit: 20 },
      },
      `
      positionIndexWeight
    `
    )
  )
  return (
    <Pane>
      <DeleteDoWDialog
        itemToDelete={itemToSoftDelete}
        onSuccess={() => {
          executeDealOfTheWeeksQuery({ requestPolicy: 'network-only' })
        }}
        onClose={() => {
          setItemToSoftDelete(null)
        }}
      />
      <ActionsRow />
      <DoWList
        dealOfTheWeeksQuery={dealOfTheWeeksQuery}
        setCurrentPage={setCurrentPage}
      >
        {({ dealOfTheWeeks }) =>
          dealOfTheWeeks.map((item) => (
            <DoWItem
              key={item.id}
              {...item}
              my={getSpaceInPx(DOW_ITEM_Y_MARGIN)}
              onDetailClick={() => {
                history.push(getPathToDealOfTheWeekDetail(item.id))
              }}
              onDeleteButtonClicked={() => {
                setItemToSoftDelete(item)
              }}
              isWithDeleteButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_WEEK.DELETE
              )}
              isWithEditButton={
                user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE_TRANSLATIONS) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_TRANSLATIONS) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_COMPETITOR)
              }
            />
          ))
        }
      </DoWList>
    </Pane>
  )
}

WorkInProgress.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withRouter, withUser)(WorkInProgress)
