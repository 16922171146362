import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'evergreen-ui'
import { useMutation } from 'urql'
import urql from 'utils/urql'

const UserStatusButton = ({ isActive, userId, onStatusChanged }) => {
  const [deactivateUserQuery, executeDeactivateUser] = useMutation(`
    mutation($id: ID!) {
      deactivateUser(id: $id) {
        id
        isActive
      }
    }
  `)
  const [activateUserQuery, executeActivateUser] = useMutation(`
    mutation($id: ID!) {
      activateUser(id: $id) {
        id
        isActive
      }
    }
  `)
  const updatedUser =
    (deactivateUserQuery.data && deactivateUserQuery.data.deactivateUser) ||
    (activateUserQuery.data && activateUserQuery.data.activateUser) ||
    {}
  useEffect(() => {
    if (updatedUser.id && isActive !== updatedUser.isActive) {
      onStatusChanged(updatedUser.id)
    }
  }, [isActive, onStatusChanged, updatedUser.id, updatedUser.isActive])
  useEffect(() => {
    const { error } = deactivateUserQuery || activateUserQuery
    if (error) {
      urql.handleError(error)
    }
  }, [deactivateUserQuery, activateUserQuery])
  return isActive ? (
    <Button
      appearance="primary"
      intent="danger"
      onClick={() => executeDeactivateUser({ id: userId })}
    >
      Deactivate
    </Button>
  ) : (
    <Button
      appearance="primary"
      intent="success"
      onClick={() => executeActivateUser({ id: userId })}
    >
      Activate
    </Button>
  )
}

UserStatusButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  onStatusChanged: PropTypes.func,
}

export default UserStatusButton
