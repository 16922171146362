import sortBy from 'lodash.sortby'
import { parseInteger } from 'utils/numbers'

export const getDealOfTheWeeksQuery = (
  { status, pagination = { page: 1 }, search, sorter },
  additionalFields = ''
) => ({
  query: `
  fragment dealOfTheWeek on DealOfTheWeek {
    id
    product {
      title
      shortDescription
      mainImage {
        url
      }
    }
    status
    currentStockQuantity
    stockQuantity
    isPaused
  }
  query dealOfTheWeeks($status: [DealOfTheWeekStatus!], $pagination: PaginationInput, $search: String, $sorter: DealOfTheWeekSorterInput) {
    dealOfTheWeeks(status: $status, pagination: $pagination, search: $search, sorter: $sorter) {
      paginationInfo {
        totalCount
        firstPage
        currentPage
        nextPage
        previousPage
        lastPage
      }
      data {
        ...dealOfTheWeek
        ${additionalFields}
      }
    }
  }
`,
  variables: {
    status,
    pagination,
    search,
    sorter,
  },
  requestPolicy: 'network-only',
})

export const extractDataFromDealOfTheWeeksQuery = (dealOfTheWeeksQuery) => {
  const dealOfTheWeeks = dealOfTheWeeksQuery.data
    ? sortBy(
        dealOfTheWeeksQuery.data.dealOfTheWeeks.data.map((dow) => ({
          ...dow,
          id: parseInteger(dow.id),
        })),
        ['positionIndexWeight']
      )
    : []
  const paginationInfo =
    dealOfTheWeeksQuery.data &&
    dealOfTheWeeksQuery.data.dealOfTheWeeks.paginationInfo
  return { dealOfTheWeeks, paginationInfo }
}

export const updateDealOfTheWeekPublishPositionsMutation = `
    mutation updateDealOfTheWeekPublishPositions($ids: [Int!]!) {
    updateDealOfTheWeekPublishPositions(ids: $ids)
  }
`

export const restoreDealOfTheWeekMutation = `
  mutation restoreDealOfTheWeek($id: Int!) {
    restoreDealOfTheWeek(id: $id) {
      id
      status
    }
  }
`

export const pauseDealOfTheWeekMutation = `
  mutation pauseDealOfTheWeek($id: Int!) {
    pauseDealOfTheWeek(id: $id) {
      id
    }
  }
`
export const resumeDealOfTheWeekMutation = `
  mutation resumeDealOfTheWeek($id: Int!) {
    resumeDealOfTheWeek(id: $id) {
      id
    }
  }
`
