import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import SellersList from './routes/SellersList'
import SellerDetail from './routes/SellerDetail'
import ReturnAddresses from './routes/ReturnAddresses'

const Sellers = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/return-address/:id`}
      component={ReturnAddresses}
    />
    <Route path={`${match.url}/:id`} component={SellerDetail} />
    <Route path={`${match.url}`} component={SellersList} />
  </Switch>
)

Sellers.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default Sellers
