export const allBestSellersQueryGraphQL = `
  query allBestSellers {
    allBestSellers {
     
      id
    product {
      title
      shortDescription
      mainImage {
        url
      }
    }
    status
    currentStockQuantity
    stockQuantity
    isPaused
    }
  }
`
