import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { SearchInput as EvergreenSearchInput } from 'evergreen-ui'

const SearchInput = ({ onChange, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const debouncedOnSearchHandler = debounce((e) => {
    setSearchTerm(e.target.value)
  }, 300)

  useEffect(() => {
    onChange(searchTerm)
  }, [searchTerm, onChange])

  return (
    <EvergreenSearchInput
      width="100%"
      onChange={(e) => {
        e.persist()
        debouncedOnSearchHandler(e)
      }}
      {...rest}
    />
  )
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default SearchInput
