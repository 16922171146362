import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { FormField, Spinner, Table, majorScale } from 'evergreen-ui'

const SellerInfo = ({ sellerId }) => {
  const [sellerQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query sellerQuery($id: ID!) {
        seller(id: $id) {
          id
          accountName
          companyName
          vatGroup
          country {
            title
          }
          phoneNumber
          email
          web
          responsiblePersonFirstName
          responsiblePersonLastName
          isActive
        }
      }
    `,
    variables: {
      id: sellerId,
    },
  })
  const seller = sellerQuery.data ? sellerQuery.data.seller : { country: {} }
  return (
    <FormField disabled label="Seller" paddingBottom={majorScale(2)}>
      <Table border>
        <Table.Head>
          <Table.TextHeaderCell>Account Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Company Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>VAT group</Table.TextHeaderCell>
          <Table.TextHeaderCell>Country</Table.TextHeaderCell>
          <Table.TextHeaderCell>Email</Table.TextHeaderCell>
          <Table.TextHeaderCell>Phone number</Table.TextHeaderCell>
          <Table.TextHeaderCell>Responsible Person</Table.TextHeaderCell>
          <Table.TextHeaderCell>Active</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {sellerQuery.fetching ? (
            <Spinner marginX="auto" marginY={160} delay={200} size={80} />
          ) : (
            <Table.Row>
              <Table.TextCell>{seller.accountName}</Table.TextCell>
              <Table.TextCell>{seller.companyName}</Table.TextCell>
              <Table.TextCell>{seller.vatGroup}</Table.TextCell>
              <Table.TextCell>{seller.country.title}</Table.TextCell>
              <Table.TextCell>{seller.email}</Table.TextCell>
              <Table.TextCell>{seller.phoneNumber}</Table.TextCell>
              <Table.TextCell>
                {`${seller.responsiblePersonFirstName} ${seller.responsiblePersonLastName}`}
              </Table.TextCell>
              <Table.TextCell>{seller.isActive ? 'Yes' : 'No'}</Table.TextCell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </FormField>
  )
}

SellerInfo.propTypes = {
  sellerId: PropTypes.string.isRequired,
}

export default SellerInfo
