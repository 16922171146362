import React from 'react'
import PropTypes from 'prop-types'
import { Heading, IconButton, Pane } from 'evergreen-ui'

const UpdateAddressHeader = ({ onClick, title, isWithButton }) => (
  <Pane paddingBottom={15} display="flex" justifyContent="space-between">
    <Heading>{title}</Heading>
    {isWithButton && (
      <IconButton icon="edit" height={32} title="edit" onClick={onClick}>
        {title}
      </IconButton>
    )}
  </Pane>
)

UpdateAddressHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isWithButton: PropTypes.bool.isRequired,
}

export default UpdateAddressHeader
