import React from 'react'
import PropTypes from 'prop-types'
import { BackButton } from 'evergreen-ui'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import ACTIONS from 'constants/actions'
import From from '../components/Form'

const NewFeed = ({ user, history }) => (
  <React.Fragment>
    <SideNavPage.Header>
      <SideNavPage.Title>Edit feed</SideNavPage.Title>
      <BackButton onClick={() => history.goBack()} />
    </SideNavPage.Header>
    <SideNavPage.Content>
      <From isDisabled={!user.hasAction(ACTIONS.FEED.CREATE)} />
    </SideNavPage.Content>
  </React.Fragment>
)

NewFeed.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      feedId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default withUser(NewFeed)
