import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import MultiSelectMenu from 'components/MultiSelectMenu'

const LanguagesSelectMenu = ({ selectedIsoCodes, onChange }) => {
  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })
  return (
    <MultiSelectMenu
      title="Select multiple languages:"
      placeholder="Select multiple languages..."
      options={
        languagesQuery.data
          ? languagesQuery.data.languages.map((language) => ({
              label: language.title,
              value: language.isoCode,
            }))
          : []
      }
      onChange={onChange}
      selectedValues={selectedIsoCodes}
    />
  )
}

LanguagesSelectMenu.propTypes = {
  selectedIsoCodes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default LanguagesSelectMenu
