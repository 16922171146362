import React from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'

const ActionsRow = ({ children }) => (
  <Pane
    display="flex"
    justifyContent="flex-end"
    alignItems="center"
    height={32}
  >
    {children}
  </Pane>
)

ActionsRow.propTypes = {
  children: PropTypes.node,
}

export default ActionsRow
