import React from 'react'
import { Pane, Heading, Text, Link } from 'evergreen-ui'
import PropTypes from 'prop-types'

const StripeInformation = ({ paymentIntentStatusLabel, paymentIntentUrl }) => (
  <>
    <Pane paddingBottom={15}>
      <Heading>Stripe</Heading>
    </Pane>
    <Text>
      Payment URL:{' '}
      <Link href={paymentIntentUrl} target="_blank" rel="noopener noreferrer">
        {paymentIntentUrl}
      </Link>
    </Text>
    <Text>{`Payment Status: ${paymentIntentStatusLabel}`}</Text>
  </>
)

StripeInformation.propTypes = {
  paymentIntentStatusLabel: PropTypes.string.isRequired,
  paymentIntentUrl: PropTypes.string.isRequired,
}

export default StripeInformation
