/* eslint no-plusplus: "off" */
import { toaster } from 'evergreen-ui'
import { extractFiles } from 'extract-files'
import { MAX_UPLOAD_IMAGE_SIZE_IN_BYTES } from 'constants/constants'

export const getIsFileSizeExceeded = ({
  file,
  fileMaxSize = MAX_UPLOAD_IMAGE_SIZE_IN_BYTES,
}) => {
  if (file.size > fileMaxSize) {
    toaster.danger(
      `File ${file.name} is too large. Maximum allowed size is ${
        fileMaxSize / 1000000
      }MB`
    )
    return false
  }
  return true
}

const uploadRequest = async ({ accessToken, body, isIgnoreErrorsEnabled }) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      Accept: 'application/json',
    },
  }
  const { clone, files } = extractFiles(body)
  const operationJSON = JSON.stringify(clone)
  if (files.size) {
    const form = new FormData()
    form.append('operations', operationJSON)
    const map = {}
    let i = 0
    files.forEach((paths) => {
      map[++i] = paths
    })
    form.append('map', JSON.stringify(map))
    i = 0
    files.forEach((paths, file) => {
      form.append(`${++i}`, file, file.name)
    })
    fetchOptions.body = form
  } else {
    fetchOptions.headers['Content-Type'] = 'application/json'
    fetchOptions.body = operationJSON
  }
  const res = await fetch(process.env.REACT_APP_API_URL, fetchOptions)
  const { data, errors } = await res.json()
  if (errors && errors.length > 0) {
    const { message } = errors[0]
    if (!isIgnoreErrorsEnabled) {
      toaster.danger(message)
    }
    throw new Error(message)
  }
  return data
}

export default uploadRequest
