import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'urql'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import { getSpaceInPx } from 'utils/styled-system'
import { DEAL_STATUSES } from 'constants/constants'
import { parseInteger } from 'utils/numbers'
import DeleteThemeDealDialog from '../../../components/DeleteThemeDealDialog'
import { getThemeDealsQuery } from '../queries'
import ThemeDealList from '../components/ThemeDealList'
import ThemeDealItem from '../components/ThemeDealItem'
import { THEME_DEAL_ITEM_Y_MARGIN } from '../constants'
import { getPathToThemeDealDetail } from '../../../utils'
import withApproveDeclineMutations, {
  toastResultOfApproval,
} from '../../../components/withAprroveDeclineMutations'

const { APPROVAL_NEEDED } = DEAL_STATUSES

const ApprovalNeeded = ({
  user,
  history,
  executeApproveThemeDealMutation,
  showDeclineThemeDealDialog,
  match,
}) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [themeDealsQuery, executeThemeDealssQuery] = useQuery(
    getThemeDealsQuery({
      status: [APPROVAL_NEEDED],
      pagination: { page: currentPage, limit: 20 },
      themeOfTheMonthId: parseInteger(match.params.themeOfTheMonthId),
    })
  )
  return (
    <Pane>
      {itemToSoftDelete && (
        <DeleteThemeDealDialog
          itemToDelete={itemToSoftDelete}
          onClose={() => {
            setItemToSoftDelete(null)
          }}
          onSuccess={() => {
            executeThemeDealssQuery({ requestPolicy: 'network-only' })
          }}
        />
      )}
      <ThemeDealList
        themeDealsQuery={themeDealsQuery}
        setCurrentPage={setCurrentPage}
      >
        {({ themeDeals }) =>
          themeDeals.map((item) => (
            <ThemeDealItem
              key={item.id}
              {...item}
              my={getSpaceInPx(THEME_DEAL_ITEM_Y_MARGIN)}
              onDetailClick={() => {
                history.push(
                  getPathToThemeDealDetail({
                    themeDealId: item.id,
                    themeOfTheMonthId: match.params.themeOfTheMonthId,
                  })
                )
              }}
              isWithApproveButton={user.hasAction(ACTIONS.THEME_DEAL.APPROVE)}
              isWithDeclineButton={user.hasAction(ACTIONS.THEME_DEAL.DECLINE)}
              onDeleteButtonClicked={() => {
                setItemToSoftDelete(item)
              }}
              onApproveButtonClicked={() => {
                const result = executeApproveThemeDealMutation({
                  id: item.id,
                })
                toastResultOfApproval(result)
              }}
              onDeclineButtonClicked={() => {
                showDeclineThemeDealDialog({
                  themeDealId: item.id,
                })
              }}
              isWithDeleteButton={user.hasAction(ACTIONS.THEME_DEAL.DELETE)}
              isWithEditButton={
                user.hasAction(ACTIONS.THEME_DEAL.UPDATE) ||
                user.hasAction(ACTIONS.THEME_DEAL.UPDATE_TRANSLATIONS) ||
                user.hasAction(ACTIONS.THEME_DEAL.ADD_TRANSLATIONS) ||
                user.hasAction(ACTIONS.THEME_DEAL.ADD_COMPETITOR)
              }
            />
          ))
        }
      </ThemeDealList>
    </Pane>
  )
}

ApprovalNeeded.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveThemeDealMutation: PropTypes.func.isRequired,
  showDeclineThemeDealDialog: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      themeOfTheMonthId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default compose(
  withRouter,
  withUser,
  withApproveDeclineMutations
)(ApprovalNeeded)
