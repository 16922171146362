import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import {
  Pane,
  SelectMenuContent,
  Position,
  Popover,
  SearchInput,
  TextInputField,
} from 'evergreen-ui'
import NoData from 'sharedComponents/NoData'

let isInitialMount = true

const AttributesFilter = ({ onChange, values }) => {
  const [selected, setSelected] = useState(values)
  const [inputValue, setInputValue] = useState('')
  const [attributesQuery, executeAttributesQuery] = useQuery({
    query: `
      query {
        attributes(pagination:{limit: 10000}) {
          data {
            id
            title
            values {
              id
              title
            }
          }
        }
      }
    `,
  })
  useEffect(() => {
    if (onChange) {
      onChange(selected)
    }
  }, [onChange, selected])

  useEffect(() => {
    if (!isInitialMount) {
      executeAttributesQuery({ requestPolicy: 'network-only' })
    } else {
      isInitialMount = false
    }
  }, [executeAttributesQuery])
  if (!attributesQuery.data) {
    return null
  }
  const attributes =
    attributesQuery.data.attributes &&
    attributesQuery.data.attributes.data.filter(({ title: attributeTitle }) =>
      attributeTitle.toLowerCase().includes(inputValue.toLowerCase())
    )
  return (
    <Popover
      bringFocusInside
      minWidth={240}
      position={Position.BOTTOM_LEFT}
      minHeight={248}
      content={() => (
        <Pane width="100%" maxHeight={500} overflow="auto">
          <Pane display="flex" width="100%" justifyContent="space-between">
            <SearchInput
              autoFocus
              placeholder="Search for attribute"
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
            />
          </Pane>
          {attributes.length !== 0 ? (
            attributes.map(({ values: attributeGroupValues, title, id }) => {
              const formatedId = id.toString()
              let selectedValues = []
              const selectedForAttributes = selected.find(
                ({ id: attributeGroupId }) => attributeGroupId === formatedId
              )
              if (selectedForAttributes) {
                selectedValues = selectedForAttributes.values.map(
                  ({ value }) => value
                )
              }
              if (attributeGroupValues.length !== 0) {
                return (
                  <SelectMenuContent
                    titleView={() => (
                      <Pane
                        width="100%"
                        padding="10px"
                        backgroundColor="#E4E7EB"
                        fontSize="14px"
                      >
                        {title}
                      </Pane>
                    )}
                    key={id}
                    width="100%"
                    isMultiSelect
                    hasFilter={false}
                    title="Select multiple values"
                    placeholder="Select multiple value"
                    options={
                      attributeGroupValues
                        ? attributeGroupValues.map(
                            ({ id: valueId, title: valueTitle }) => ({
                              label: valueTitle,
                              value: `${valueId}`,
                            })
                          )
                        : []
                    }
                    listProps={{
                      height: attributeGroupValues.length * 33,
                      selected: selectedValues,
                      onSelect: (item) => {
                        let newSelected = []
                        const selectedAttributeGroupIndex = selected.findIndex(
                          ({ id: attributeGroupId }) =>
                            attributeGroupId === formatedId
                        )
                        if (selectedAttributeGroupIndex === -1) {
                          newSelected = [
                            ...selected,
                            {
                              id: formatedId,
                              label: title,
                              values: [item],
                            },
                          ]
                        } else {
                          newSelected = [...selected]
                          newSelected[selectedAttributeGroupIndex] = {
                            ...newSelected[selectedAttributeGroupIndex],
                            values: [
                              ...newSelected[selectedAttributeGroupIndex]
                                .values,
                              item,
                            ],
                          }
                        }
                        setSelected(newSelected)
                      },
                      onDeselect: (itemToDeselect) => {
                        const selectedAttributeGroupIndex = selected.findIndex(
                          ({ id: attributeGroupId }) =>
                            attributeGroupId === formatedId
                        )
                        if (selectedAttributeGroupIndex !== -1) {
                          const selectedAttribute =
                            selected[selectedAttributeGroupIndex]
                          const indexOfElement =
                            selectedAttribute.values.findIndex(
                              ({ value }) => value === itemToDeselect.value
                            )
                          const newValues = selectedAttribute.values
                          newValues.splice(indexOfElement, 1)
                          const newSelected = [...selected]
                          newSelected[selectedAttributeGroupIndex] = {
                            ...selected[selectedAttributeGroupIndex],
                            values: newValues,
                          }
                          if (newValues.length === 0) {
                            newSelected.splice(selectedAttributeGroupIndex, 1)
                          }
                          setSelected(newSelected)
                        }
                      },
                    }}
                  />
                )
              }
              return null
            })
          ) : (
            <Pane paddingTop="50px">
              <NoData />
            </Pane>
          )}
        </Pane>
      )}
    >
      <Pane width="100%" marginBottom="24px">
        <TextInputField
          readOnly
          marginBottom="0"
          label="Filter"
          value={selected
            .map(({ label: attributeTitle, values: selectedValues }) =>
              selectedValues.length !== 0
                ? `${attributeTitle}: ${selectedValues
                    .map(({ label }) => label)
                    .join(', ')}`
                : ''
            )
            .join(', ')}
        />
      </Pane>
    </Popover>
  )
}

AttributesFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          title: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
}

export default AttributesFilter
