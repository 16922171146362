import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import { useQuery, useMutation } from 'urql'
import { getSpaceInPx } from 'utils/styled-system'
import urql from 'utils/urql'
import { DEAL_STATUSES } from 'constants/constants'
import ACTIONS from 'constants/actions'
import { parseInteger } from 'utils/numbers'
import DeleteThemeDealDialog from '../../../components/DeleteThemeDealDialog'
import ThemeDealItem from '../components/ThemeDealItem'
import { THEME_DEAL_ITEM_Y_MARGIN } from '../constants'
import { getPathToThemeDealDetail } from '../../../utils'
import { getThemeDealsQuery, restoreThemeDealMutation } from '../queries'
import ThemeDealList from '../components/ThemeDealList'

const { DELETED } = DEAL_STATUSES

const SoftDeleted = ({ history, user, match }) => {
  const [itemToDelete, setItemToDelete] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [themeDealsQuery] = useQuery(
    getThemeDealsQuery({
      status: [DELETED],
      pagination: { page: currentPage, limit: 20 },
      themeOfTheMonthId: parseInteger(match.params.themeOfTheMonthId),
    })
  )
  const [restoreMutation, executeRestoreMutation] = useMutation(
    restoreThemeDealMutation
  )
  useEffect(() => {
    const error = themeDealsQuery.error || restoreMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [themeDealsQuery, themeDealsQuery.error, restoreMutation.error])
  return (
    <Pane>
      {itemToDelete && (
        <DeleteThemeDealDialog
          itemToDelete={itemToDelete}
          onClose={() => {
            setItemToDelete(null)
          }}
          isDestroyDialog
        />
      )}
      <ThemeDealList
        themeDealsQuery={themeDealsQuery}
        setCurrentPage={setCurrentPage}
      >
        {({ themeDeals }) =>
          themeDeals.map((item) => (
            <ThemeDealItem
              key={item.id}
              {...item}
              my={getSpaceInPx(THEME_DEAL_ITEM_Y_MARGIN)}
              onDetailClick={() => {
                history.push(getPathToThemeDealDetail(item.id))
              }}
              onDeleteButtonClicked={() => {
                setItemToDelete(item)
              }}
              deleteIcon="delete"
              deleteIconDescription="Destroy theme deal"
              onRevertButtonClicked={() => {
                executeRestoreMutation({
                  id: item.id,
                })
              }}
              isWithDeleteButton={user.hasAction(ACTIONS.THEME_DEAL.DESTROY)}
              isWithRestoreButton={user.hasAction(ACTIONS.THEME_DEAL.RESTORE)}
            />
          ))
        }
      </ThemeDealList>
    </Pane>
  )
}

SoftDeleted.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      themeOfTheMonthId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default compose(withRouter, withUser)(SoftDeleted)
