import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import MultiSelectMenu from 'components/MultiSelectMenu'

const ThreadCategoriesSelectMenu = ({
  selectedThreadCategories = [],
  onChange,
}) => {
  const [threadCategoriesQuery] = useQuery({
    query: `
      query {
        threadCategories {
          name
          code
        }
      }
    `,
  })
  if (!threadCategoriesQuery.data) {
    return null
  }
  return (
    <MultiSelectMenu
      title="All ticket category:"
      placeholder="All ticket category"
      options={
        threadCategoriesQuery.data
          ? threadCategoriesQuery.data.threadCategories.map((category) => ({
              label: category.name,
              value: category.code,
            }))
          : []
      }
      onChange={onChange}
      selectedValues={selectedThreadCategories}
    />
  )
}

ThreadCategoriesSelectMenu.propTypes = {
  selectedThreadCategories: PropTypes.arrayOf(PropTypes.string.isRequired)
    .isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ThreadCategoriesSelectMenu
