import React from 'react'
import PropTypes from 'prop-types'
import { Table, Spinner, Link, Button, majorScale } from 'evergreen-ui'
import { compose } from 'recompose'
import { useMutation } from 'urql'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import format from 'date-fns/format'
import Pagination from 'components/Pagination'
import FeedbackBadge from 'components/FeedbackBadge'
import { TIME_FORMAT } from 'constants/constants'
import ROUTES from 'constants/routes'
import ACTIONS from 'constants/actions'
import { getPathToFeedbackDetail } from 'routes/Feedback/utils'
import urql from 'utils/urql'
import MoveFeedbackMenu from 'routes/Feedback/components/MoveFeedbackMenu'
import {
  extractDataFromFeedbacksQuery,
  moveFeedbackToArchiveMutation,
} from '../queries'

const TABLE_MIN_HEIGHT = 500

const FeedbackList = ({ history, user, feedbacksQuery, setCurrentPage }) => {
  const { feedbacks, paginationInfo } =
    extractDataFromFeedbacksQuery(feedbacksQuery)
  const [, executeMoveFeedbackToArchiveMutation] = useMutation(
    moveFeedbackToArchiveMutation
  )
  return (
    <React.Fragment>
      <Table border>
        <Table.Head height="auto" paddingY={12}>
          <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
            ID
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={180} flexShrink={0} flexGrow={0}>
            Customer
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>Feedback text</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
            Positive
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={200} flexShrink={0} flexGrow={0}>
            Type
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
            Created at
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={175} flexShrink={0} flexGrow={0}>
            Action
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body minHeight={TABLE_MIN_HEIGHT}>
          {feedbacksQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {feedbacks.map(
            ({
              id,
              customer,
              text,
              isPositive,
              type,
              createdAtUtc,
              isArchived,
            }) => (
              <Table.Row
                key={id}
                height="auto"
                paddingY={12}
                isSelectable={user.hasAction(ACTIONS.FEEDBACK.DETAIL)}
                onSelect={() => history.push(getPathToFeedbackDetail(id))}
              >
                <Table.TextCell flexBasis={50} flexShrink={0} flexGrow={0}>
                  {id}
                </Table.TextCell>
                <Table.Cell flexBasis={180} flexShrink={0} flexGrow={0}>
                  {customer &&
                    (user.hasAction(ACTIONS.CUSTOMER.DETAIL) ? (
                      <Link
                        onClick={(e) => {
                          e.stopPropagation()
                          history.push(`/${ROUTES.CUSTOMERS}/${customer.id}`)
                        }}
                      >
                        {customer.email}
                      </Link>
                    ) : (
                      customer.email
                    ))}
                </Table.Cell>
                <Table.TextCell>{text}</Table.TextCell>
                {isPositive === null ? (
                  <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} />
                ) : (
                  <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
                    {isPositive ? 'YES' : 'NO'}
                  </Table.TextCell>
                )}
                <Table.Cell flexBasis={200} flexShrink={0} flexGrow={0}>
                  <FeedbackBadge type={type} />
                </Table.Cell>
                <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0}>
                  {format(createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                </Table.TextCell>
                <Table.Cell
                  flexBasis={175}
                  flexShrink={0}
                  flexGrow={0}
                  onClick={(e) => e.stopPropagation()}
                >
                  {(user.hasAction(ACTIONS.FEEDBACK.MOVE_TO_CUSTOMER_SUPPORT) ||
                    user.hasAction(
                      ACTIONS.FEEDBACK.MOVE_TO_PRODUCT_FEEDBACK
                    )) && (
                    <MoveFeedbackMenu id={id} type={type}>
                      <Button marginRight={majorScale(1)}>Type</Button>
                    </MoveFeedbackMenu>
                  )}
                  {!isArchived &&
                    user.hasAction(ACTIONS.FEEDBACK.MOVE_TO_ARCHIVE) && (
                      <Button
                        intent="danger"
                        onClick={async (e) => {
                          e.stopPropagation()
                          const { error } =
                            await executeMoveFeedbackToArchiveMutation({
                              id,
                            })
                          if (error) {
                            urql.handleError(error)
                          }
                        }}
                      >
                        Archive
                      </Button>
                    )}
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
      {paginationInfo && feedbacks.length > 0 && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </React.Fragment>
  )
}

FeedbackList.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  feedbacksQuery: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      feedbacks: PropTypes.shape({
        data: PropTypes.array.isRequired,
        paginationInfo: PropTypes.object.isRequired,
      }),
    }),
  }),
  setCurrentPage: PropTypes.func.isRequired,
}

export default compose(withRouter, withUser)(FeedbackList)
