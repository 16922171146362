export const formatPrice = ({
  value,
  currencyIsoCode = 'EUR',
  languageIsoCode = 'en',
}) =>
  new Intl.NumberFormat(`${languageIsoCode}-${languageIsoCode.toUpperCase()}`, {
    style: 'currency',
    currency: currencyIsoCode,
    maximumFractionDigits: 2,
  }).format(value)
