import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'evergreen-ui'
import { useMutation } from 'urql'
import urql from 'utils/urql'

const PMEmailNotificationsButton = ({
  isProductManagerEmailEnabled,
  userId,
  onStatusChanged,
}) => {
  const [
    deactivatePMEmailNotificationQuery,
    executeDeactivatePMEmailNotification,
  ] = useMutation(`
    mutation($id: ID!) {
      deactivateUserProductManagerEmailNotifications(id: $id) {
        id
        isProductManagerEmailEnabled
      }
    }
  `)
  const [activatePMEmailNotificationQuery, executeActivatePMEmailNotification] =
    useMutation(`
    mutation($id: ID!) {
      activateUserProductManagerEmailNotifications(id: $id) {
        id
        isProductManagerEmailEnabled
      }
    }
  `)
  const updatedUser =
    (deactivatePMEmailNotificationQuery.data &&
      deactivatePMEmailNotificationQuery.data
        .deactivateUserProductManagerEmailNotifications) ||
    (activatePMEmailNotificationQuery.data &&
      activatePMEmailNotificationQuery.data
        .activateUserProductManagerEmailNotifications) ||
    {}
  useEffect(() => {
    if (
      updatedUser.id &&
      isProductManagerEmailEnabled !== updatedUser.isProductManagerEmailEnabled
    ) {
      onStatusChanged(updatedUser.id)
    }
  }, [
    isProductManagerEmailEnabled,
    onStatusChanged,
    updatedUser.id,
    updatedUser.isProductManagerEmailEnabled,
  ])
  useEffect(() => {
    const { error } =
      activatePMEmailNotificationQuery || deactivatePMEmailNotificationQuery
    if (error) {
      urql.handleError(error)
    }
  }, [deactivatePMEmailNotificationQuery, activatePMEmailNotificationQuery])

  return isProductManagerEmailEnabled ? (
    <Button
      appearance="primary"
      intent="danger"
      onClick={() => executeDeactivatePMEmailNotification({ id: userId })}
    >
      Disable Product Manager Email Notifications
    </Button>
  ) : (
    <Button
      appearance="primary"
      intent="success"
      onClick={() => executeActivatePMEmailNotification({ id: userId })}
    >
      Enable Product Manager Email Notifications
    </Button>
  )
}

PMEmailNotificationsButton.propTypes = {
  isProductManagerEmailEnabled: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  onStatusChanged: PropTypes.func,
}

export default PMEmailNotificationsButton
