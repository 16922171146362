import React from 'react'
import PropTypes from 'prop-types'
import isRequiredIf from 'react-proptype-conditional-require'
import { Button, IconButton } from 'evergreen-ui'

const PageButton = ({ onClick, page, disabled }) => (
  <Button onClick={onClick} disabled={disabled}>
    {page}
  </Button>
)

PageButton.propTypes = {
  onClick: isRequiredIf(PropTypes.func, (props) => !props.disabled),
  page: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

const PreviousPageButton = ({ disabled, onClick }) => (
  <IconButton
    icon="double-chevron-left"
    disabled={disabled}
    onClick={onClick}
  />
)

PreviousPageButton.propTypes = {
  onClick: isRequiredIf(PropTypes.func, (props) => !props.disabled),
  disabled: PropTypes.bool,
}

const NextPageButton = ({ disabled, onClick }) => (
  <IconButton
    icon="double-chevron-right"
    disabled={disabled}
    onClick={onClick}
  />
)

NextPageButton.propTypes = {
  onClick: isRequiredIf(PropTypes.func, (props) => !props.disabled),
  disabled: PropTypes.bool,
}

const MoreButton = ({ disabled, onClick }) => (
  <IconButton icon="more" disabled={disabled} onClick={onClick} />
)

MoreButton.propTypes = {
  onClick: isRequiredIf(PropTypes.func, (props) => !props.disabled),
  disabled: PropTypes.bool,
}

export default {
  Page: PageButton,
  More: MoreButton,
  PreviousPage: PreviousPageButton,
  NextPage: NextPageButton,
}
