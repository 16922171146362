import React from 'react'
import PropTypes from 'prop-types'
import Linkify from 'react-linkify'

const LinkParser = ({ children }) => (
  <Linkify
    properties={{
      target: '_blank',
      style: { fontWeight: 'bold' },
    }}
  >
    {children}
  </Linkify>
)

LinkParser.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default LinkParser
