import React from 'react'
import PropTypes from 'prop-types'
import {
  FormField,
  majorScale,
  Pane,
  Spinner,
  Table,
  TextInputField,
} from 'evergreen-ui'
import { useQuery } from 'urql'
import { format } from 'date-fns'
import { TIME_FORMAT } from 'constants/constants'
import NoData from 'sharedComponents/NoData'

const getCreatedAtUtc = (data) =>
  (data &&
    data.currencyRatesForDatetime &&
    data.currencyRatesForDatetime.length &&
    data.currencyRatesForDatetime[0].createdAtUtc) ||
  null

const Statistics = ({ id, publishedAtUtc }) => {
  const [dealOfTheDayStatistics] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query dealOfTheDayStatistics( $id: ID!) {
        dealOfTheDayStatistics(dealOfTheDayId: $id) {
          soldItemsCount
          averagePrice
          averagePriceCurrencyIsoCode
          ordersByCountry {
            ordersCount
            countryIsoCode
          }
        }
      }
    `,
    variables: {
      id,
    },
  })
  const [currencyRates] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query currencyRatesForDatetime($datetimeUtc: String!){
        currencyRatesForDatetime(
          datetimeUtc: $datetimeUtc
        ) {
          value
          createdAtUtc
          currencyIsoCode
        }
      }
    `,
    variables: {
      datetimeUtc: publishedAtUtc,
    },
  })
  const { data } = dealOfTheDayStatistics
  const fetching = currencyRates.fetching || dealOfTheDayStatistics.fetching
  const createdAtUtc = getCreatedAtUtc(currencyRates.data)
  return (
    <FormField label="Statistics" paddingBottom={majorScale(2)}>
      <Pane display="flex" alignItems="center" justifyContent="center">
        <Pane width={780}>
          {fetching ? (
            <Spinner marginX="auto" marginY={160} delay={200} size={80} />
          ) : (
            <React.Fragment>
              <TextInputField
                value={data.dealOfTheDayStatistics.soldItemsCount}
                disabled
                label="Items sold"
              />
              <TextInputField
                value={
                  data.dealOfTheDayStatistics.averagePrice
                    ? `${data.dealOfTheDayStatistics.averagePrice} ${data.dealOfTheDayStatistics.averagePriceCurrencyIsoCode}`
                    : 'Not available'
                }
                disabled
                label="Average price"
              />
              <FormField
                label="Number of orders by country"
                paddingBottom={majorScale(2)}
              >
                <Table border>
                  <Table.Head>
                    <Table.TextHeaderCell>
                      Country ISO code
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell>Orders count</Table.TextHeaderCell>
                  </Table.Head>
                  <Table.Body>
                    {data.dealOfTheDayStatistics.ordersByCountry.map(
                      ({ ordersCount, countryIsoCode }) => (
                        <Table.Row key={countryIsoCode}>
                          <Table.TextCell>{countryIsoCode}</Table.TextCell>
                          <Table.TextCell>{ordersCount}</Table.TextCell>
                        </Table.Row>
                      )
                    )}
                    {data.dealOfTheDayStatistics.ordersByCountry.length ===
                      0 && <NoData paddingY={majorScale(2)} />}
                  </Table.Body>
                </Table>
              </FormField>
              <FormField
                label={
                  createdAtUtc
                    ? `Currency rates at ${format(
                        createdAtUtc,
                        TIME_FORMAT.LOCAL_DATETIME
                      )}`
                    : 'Currency rates'
                }
                paddingBottom={majorScale(2)}
              >
                <Table border>
                  <Table.Head>
                    <Table.TextHeaderCell>ISO code</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Value</Table.TextHeaderCell>
                  </Table.Head>
                  <Table.Body>
                    {currencyRates.data.currencyRatesForDatetime.map(
                      ({ value, currencyIsoCode }) => (
                        <Table.Row key={currencyIsoCode}>
                          <Table.TextCell>{currencyIsoCode}</Table.TextCell>
                          <Table.TextCell>{value}</Table.TextCell>
                        </Table.Row>
                      )
                    )}
                    {currencyRates.data.currencyRatesForDatetime.length ===
                      0 && <NoData paddingY={majorScale(2)} />}
                  </Table.Body>
                </Table>
              </FormField>
            </React.Fragment>
          )}
        </Pane>
      </Pane>
    </FormField>
  )
}

Statistics.propTypes = {
  id: PropTypes.string.isRequired,
  publishedAtUtc: PropTypes.string.isRequired,
}

export default Statistics
