import React from 'react'
import PropTypes from 'prop-types'
import { Spinner, withTheme } from 'evergreen-ui'
import styled from 'styled-components/macro'

const StyledWrapper = withTheme(styled.div`
  background: ${({ theme }) => theme.scales.neutral.N3A};
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`)

const FullScreenLoading = ({ isVisible }) => (
  <StyledWrapper isVisible={isVisible}>
    <Spinner delay={200} size={80} />
  </StyledWrapper>
)

FullScreenLoading.propTypes = {
  isVisible: PropTypes.bool.isRequired,
}

export default FullScreenLoading
