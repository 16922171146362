import React from 'react'
import PropTypes from 'prop-types'
import NoData from 'sharedComponents/NoData'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import Pagination from 'components/Pagination'
import { extractDataFromDealOfTheDaysQuery } from '../queries'

const DoDList = ({ dealOfTheDaysQuery, children, setCurrentPage }) => {
  const { dealOfTheDays, paginationInfo } =
    extractDataFromDealOfTheDaysQuery(dealOfTheDaysQuery)
  return (
    <React.Fragment>
      <FullScreenLoading isVisible={dealOfTheDaysQuery.fetching} />
      {children({ dealOfTheDays })}
      {!dealOfTheDaysQuery.fetching && dealOfTheDays.length === 0 && (
        <NoData padding={60} />
      )}
      {paginationInfo && dealOfTheDays.length > 1 && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </React.Fragment>
  )
}

DoDList.propTypes = {
  dealOfTheDaysQuery: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      dealOfTheDays: PropTypes.shape({
        data: PropTypes.array.isRequired,
        paginationInfo: PropTypes.object.isRequired,
      }),
    }),
  }),
  children: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}

export default DoDList
