import React from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'

const Panel = ({ children }) => (
  <Pane
    elevation={1}
    backgroundColor="white"
    width="calc(50% - 15px)"
    margin={5}
    display="flex"
    flexDirection="column"
    padding={10}
  >
    {children}
  </Pane>
)

Panel.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Panel
