import React from 'react'
import PropTypes from 'prop-types'
import NoData from 'sharedComponents/NoData'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import Pagination from 'components/Pagination'
import { extractDataFromThemeDealsQuery } from '../queries'

const ThemeDealList = ({ themeDealsQuery, children, setCurrentPage }) => {
  const { themeDeals, paginationInfo } =
    extractDataFromThemeDealsQuery(themeDealsQuery)
  return (
    <React.Fragment>
      <FullScreenLoading isVisible={themeDealsQuery.fetching} />
      {children({ themeDeals })}
      {!themeDealsQuery.fetching && themeDeals.length === 0 && (
        <NoData padding={60} />
      )}
      {paginationInfo && themeDeals.length > 1 && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </React.Fragment>
  )
}

ThemeDealList.propTypes = {
  themeDealsQuery: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      themeDeals: PropTypes.shape({
        data: PropTypes.array.isRequired,
        paginationInfo: PropTypes.object.isRequired,
      }),
    }),
  }),
  children: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}

export default ThemeDealList
