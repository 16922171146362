import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, toaster } from 'evergreen-ui'
import { useMutation } from 'urql'
import urql from 'utils/urql'
import addWeeks from 'date-fns/add_weeks'
import Picker from 'components/Datepicker'
import { DEALS_DURATIONS } from 'constants/constants'

const EarlyEndDialog = ({ onClose, deal }) => {
  const [earlyEndUtc, setEarlyEndUtc] = useState(deal.earlyEndUtc || '')
  const [, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateDealOfTheWeekEndInput!) {
      updateDealOfTheWeekEnd(input: $input) {
        id
      }
    }
  `)
  return (
    <Dialog
      isShown
      title="Set early end of DoW"
      confirmLabel="Save"
      onCloseComplete={onClose}
      onConfirm={async (close) => {
        const { error } = await executeUpdateMutation({
          input: {
            id: deal.id,
            earlyEndUtc,
          },
        })
        if (error) {
          urql.handleError(error)
        } else {
          toaster.success('Successfully updated')
        }
        close()
      }}
    >
      <Picker.DateTime
        onChangeDate={setEarlyEndUtc}
        date={earlyEndUtc}
        minDate={new Date()}
        maxDate={addWeeks(
          new Date(deal.publishedAtUtc),
          DEALS_DURATIONS.DEAL_OF_THE_WEEK_DURATION_IN_WEEKS
        )}
        label="Early end at"
      />
    </Dialog>
  )
}

EarlyEndDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    earlyEndUtc: PropTypes.string,
  }),
}
export default EarlyEndDialog
