const ROUTES = {
  USERS: 'users',
  ROLES: 'roles',
  CUSTOMERS: 'customers',
  PERSONALISATION: 'personalisation',
  PRODUCTS: 'products',
  ORDERS: 'orders',
  OLD_ORDERS: 'old-orders',
  SETTINGS: 'settings',
  LANGUAGES: 'languages',
  SIGN_IN: 'sign-in',
  FORGOTTEN_PASSWORD: 'forgoten-password',
  RESET_PASSWORD: 'reset-password',
  COUNTRIES: 'countries',
  SET_PASSWORD: 'set-password',
  PRODUCT_IMAGES: 'product-images',
  DEAL_OF_THE_DAYS: 'deal-of-the-days',
  SELLERS: 'sellers',
  PRODUCT_CONDITIONS: 'product-conditions',
  CURRENCIES: 'currencies',
  CURRENCIES_RATES: 'currencies-rates',
  MESSAGES: 'messages',
  TICKETS: 'tickets',
  CATEGORIES: 'categories',
  DOCUMENTS: 'documents',
  WISHES: 'wishes',
  LOGS: 'logs',
  WISH_CATEGORIES: 'wish-categories',
  DEAL_OF_THE_WEEKS: 'deal-of-the-weeks',
  FEEDBACKS: 'feedbacks',
  THEME_OF_THE_MONTH: 'themes-of-the-month',
  THEME_DEAL: 'theme-deals',
  ADDITIONAL_SERVICES: 'additional-services',
  ATTRIBUTES: 'attributes',
  ATTRIBUTE_VALUES: 'attribute-values',
  FEEDS: 'feeds',
  PHONE_SERVICE: 'phone-service',
  HERO_BANNERS: 'hero-banners',
  BEST_SELLERS: 'best-sellers',
}

export const DOD_ROUTES = {
  NEW: 'new',
  EDIT: 'edit',
}

export const BEST_SELLER_ROUTES = {
  NEW: 'new',
  EDIT: 'edit',
}

export const DOW_ROUTES = {
  NEW: 'new',
  EDIT: 'edit',
}

export const THEME_DEAL_ROUTES = {
  NEW: 'new',
  EDIT: 'edit',
}

export const MANAGEMENT_ROUTES = {
  GENERAL: 'general',
  NEXT: 'next',
  APPROVAL_NEEDED: 'approval-needed',
  ARCHIVED: 'archived',
  WORK_IN_PROGRESS: 'work-in-progress',
  DELETED: 'deleted',
}

export const MANAGEMENT_PERSONALISATION_ROUTES = {
  STATISTICS: 'statistics',
  CATEGORIES: 'categories',
  BRANDS: 'brands',
}

export const WISH_MANAGEMENT_ROUTES = {
  INBOX: 'inbox',
  TRASH: 'trash',
  DECIDE_LATER: 'decide-later',
  ACCEPTED: 'accepted',
  OFFER_CREATED: 'offer-created',
  ORDERED: 'ordered',
}

export const THEME_DEAL_MANAGEMENT_ROUTES = {
  APPROVED: 'approved',
  APPROVAL_NEEDED: 'approval-needed',
  ARCHIVED: 'archived',
  WORK_IN_PROGRESS: 'work-in-progress',
  DELETED: 'deleted',
}

export const FEEDBACK_MANAGEMENT_ROUTES = {
  INBOX: 'inbox',
  PRODUCT_FEEDBACK: 'product-feedback',
  CUSTOMER_SUPPORT: 'customer-support',
  ARCHIVE: 'archive',
}

export default ROUTES
