import React, { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import { Table, Text, Spinner } from 'evergreen-ui'
import urql from 'utils/urql'
import format from 'date-fns/format'
import { TIME_FORMAT } from 'constants/constants'
import SideNavPage from 'sharedComponents/SideNavPage'
import Pagination from 'components/Pagination'

const TABLE_MIN_HEIGHT = 500

const CurrenciesRates = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [currencyRatesQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query currencyRates($page: Int!) {
        currencyRates(pagination: {page: $page}) {
          paginationInfo {
            totalCount
            firstPage
            lastPage
            currentPage
            nextPage
            previousPage
          }
          data {
            currencyIsoCode
            value
            id
            createdAtUtc
          }
        }
      }
    `,
    variables: {
      page: currentPage,
    },
  })
  useEffect(() => {
    if (currencyRatesQuery.error) {
      urql.handleError(currencyRatesQuery.error)
    }
  }, [currencyRatesQuery.error])
  const paginationInfo =
    currencyRatesQuery.data &&
    currencyRatesQuery.data.currencyRates.paginationInfo
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Currencies Rates</SideNavPage.Title>
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>ISO code</Table.TextHeaderCell>
            <Table.TextHeaderCell>Value</Table.TextHeaderCell>
            <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body minHeight={TABLE_MIN_HEIGHT}>
            {currencyRatesQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                size={80}
              />
            )}
            {!currencyRatesQuery.fetching &&
              currencyRatesQuery.data &&
              currencyRatesQuery.data.currencyRates.data.map(
                ({ id, currencyIsoCode, value, createdAtUtc }) => (
                  <Table.Row key={id}>
                    <Table.Cell>
                      <Text marginLeft={8} size={300} fontWeight={500}>
                        {currencyIsoCode}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text marginLeft={8} size={300} fontWeight={500}>
                        {value}
                      </Text>
                    </Table.Cell>
                    <Table.TextCell>
                      {format(createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                    </Table.TextCell>
                  </Table.Row>
                )
              )}
          </Table.Body>
        </Table>
        {paginationInfo && (
          <Pagination
            {...paginationInfo}
            onPageChange={(page) => {
              setCurrentPage(page)
            }}
          />
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

export default CurrenciesRates
