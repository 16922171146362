import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'urql'
import { withUser } from 'UserProvider'
import { getSpaceInPx } from 'utils/styled-system'
import { DEAL_STATUSES } from 'constants/constants'
import ActionsRow from 'components/ActionsRow'
import ACTIONS from 'constants/actions'
import DoDList from '../components/DoDList'
import DoDItem from '../components/DoDItem'
import { DOD_ITEM_Y_MARGIN } from '../constants'
import { getPathToDealOfTheDayDetail } from '../../../utils'
import DeleteDoDDialog from '../../../components/DeleteDoDDialog'
import { getDealOfTheDaysQuery } from '../queries'

const { DECLINED, DRAFT } = DEAL_STATUSES

const WorkInProgress = ({ history, user }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [dealOfTheDaysQuery, executeDealOfTheDaysQuery] = useQuery(
    getDealOfTheDaysQuery(
      {
        status: [DRAFT, DECLINED],
        pagination: { page: currentPage, limit: 20 },
      },
      `
      positionIndexWeight
    `
    )
  )
  return (
    <Pane>
      <DeleteDoDDialog
        itemToDelete={itemToSoftDelete}
        onSuccess={() => {
          executeDealOfTheDaysQuery({ requestPolicy: 'network-only' })
        }}
        onClose={() => {
          setItemToSoftDelete(null)
        }}
      />
      <ActionsRow />
      <DoDList
        dealOfTheDaysQuery={dealOfTheDaysQuery}
        setCurrentPage={setCurrentPage}
      >
        {({ dealOfTheDays }) =>
          dealOfTheDays.map((item) => (
            <DoDItem
              key={item.id}
              {...item}
              my={getSpaceInPx(DOD_ITEM_Y_MARGIN)}
              onDetailClick={() => {
                history.push(getPathToDealOfTheDayDetail(item.id))
              }}
              onDeleteButtonClicked={() => {
                setItemToSoftDelete(item)
              }}
              isWithDeleteButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_DAY.DELETE
              )}
              isWithEditButton={
                user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE_TRANSLATIONS) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_TRANSLATIONS) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_COMPETITOR)
              }
            />
          ))
        }
      </DoDList>
    </Pane>
  )
}

WorkInProgress.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withRouter, withUser)(WorkInProgress)
