import sortBy from 'lodash.sortby'
import { parseInteger } from 'utils/numbers'

export const getDealOfTheDaysQuery = (
  { status, pagination = { page: 1 }, search, sorter },
  additionalFields = ''
) => ({
  query: `
  fragment dealOfTheDay on DealOfTheDay {
    id
    product {
      title
      shortDescription
      mainImage {
        url
      }
    }
    status
    currentStockQuantity
    stockQuantity
    isPaused
  }
  query dealOfTheDays($status: [DealOfTheDayStatus!], $pagination: PaginationInput, $search: String, $sorter: DealOfTheDaySorterInput) {
    dealOfTheDays(status: $status, pagination: $pagination, search: $search, sorter: $sorter) {
      paginationInfo {
        totalCount
        firstPage
        currentPage
        nextPage
        previousPage
        lastPage
      }
      data {
        ...dealOfTheDay
        ${additionalFields}
      }
    }
  }
`,
  variables: {
    status,
    pagination,
    search,
    sorter,
  },
  requestPolicy: 'network-only',
})

export const extractDataFromDealOfTheDaysQuery = (dealOfTheDaysQuery) => {
  const dealOfTheDays = dealOfTheDaysQuery.data
    ? sortBy(
        dealOfTheDaysQuery.data.dealOfTheDays.data.map((dod) => ({
          ...dod,
          id: parseInteger(dod.id),
        })),
        ['positionIndexWeight']
      )
    : []
  const paginationInfo =
    dealOfTheDaysQuery.data &&
    dealOfTheDaysQuery.data.dealOfTheDays.paginationInfo
  return { dealOfTheDays, paginationInfo }
}

export const updateDealOfTheDayPublishPositionsMutation = `
    mutation updateDealOfTheDayPublishPositions($ids: [Int!]!) {
    updateDealOfTheDayPublishPositions(ids: $ids)
  }
`

export const restoreDealOfTheDayMutation = `
  mutation restoreDealOfTheDay($id: Int!) {
    restoreDealOfTheDay(id: $id) {
      id
      status
    }
  }
`

export const pauseDealOfTheDayMutation = `
  mutation pauseDealOfTheDay($id: Int!) {
    pauseDealOfTheDay(id: $id) {
      id
    }
  }
`
export const resumeDealOfTheDayMutation = `
  mutation resumeDealOfTheDay($id: Int!) {
    resumeDealOfTheDay(id: $id) {
      id
    }
  }
`
