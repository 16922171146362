import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SideNavPage from 'sharedComponents/SideNavPage'
import AttributeSelect from 'sharedComponents/AttributeSelect'
import { Table, Pane, Button, toaster } from 'evergreen-ui'
import { useQuery, useMutation } from 'urql'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const PhoneService = ({ user }) => {
  const [filterAttributes, setFilterAttributes] = useState([])
  const [phoneServiceFilterAttributesQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
     query phoneServiceFilterAttributes{
      phoneServiceFilterAttributes{
        filterAttributeType
        attribute{
          id
          values{
            title
          }
        }
      }
    }
    `,
  })
  const data =
    phoneServiceFilterAttributesQuery.data &&
    phoneServiceFilterAttributesQuery.data.phoneServiceFilterAttributes &&
    phoneServiceFilterAttributesQuery.data.phoneServiceFilterAttributes
  useEffect(() => {
    if (data) {
      setFilterAttributes(
        data.map(({ filterAttributeType, attribute }) => ({
          filterAttributeType,
          attributeId: attribute ? attribute.id : null,
        }))
      )
    }
  }, [data, phoneServiceFilterAttributesQuery])
  const [
    updatePhoneService,
    executeUpdatePhoneServiceFilterAttributesMutation,
  ] = useMutation(`
    mutation($input: PhoneServiceFilterAttributesInput!) {
      setPhoneServiceFilterAttributes(input: $input)
    }
  `)

  useEffect(() => {
    if (updatePhoneService.error) {
      urql.handleError(updatePhoneService.error)
    }
  }, [updatePhoneService.error])

  const setFilterAttributesHelper = ({
    filterAttributeType: attributeType,
    attributeId,
  }) => {
    const indexOfAtribute = filterAttributes.findIndex(
      ({ filterAttributeType }) => filterAttributeType === attributeType
    )
    const newFilterAttributes = [...filterAttributes]
    newFilterAttributes[indexOfAtribute] = {
      attributeId,
      filterAttributeType: attributeType,
    }
    setFilterAttributes(newFilterAttributes)
  }
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Phone Service</SideNavPage.Title>
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border width={750}>
          <Table.Head>
            <Table.TextHeaderCell>Filter Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Attribute</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {filterAttributes &&
              filterAttributes.map(({ filterAttributeType, attributeId }) => (
                <Table.Row height="auto" key={filterAttributeType}>
                  <Table.TextCell>{filterAttributeType}</Table.TextCell>
                  <Table.Cell>
                    <AttributeSelect
                      label={null}
                      isInvalid={!attributeId}
                      isDisabled={phoneServiceFilterAttributesQuery.fetching}
                      handleChange={(newAttributeId) =>
                        setFilterAttributesHelper({
                          filterAttributeType,
                          attributeId: parseInteger(newAttributeId),
                        })
                      }
                      value={attributeId}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        {user.hasAction(ACTIONS.PHONE_SERVICE.EDIT_ATTRIBUTES_MAPPING) && (
          <Pane marginTop={20}>
            <Button
              intent="success"
              appearance="primary"
              onClick={async () => {
                const { error } =
                  await executeUpdatePhoneServiceFilterAttributesMutation({
                    input: {
                      filterAttributes,
                    },
                  })
                displayToastOnSave(error)
              }}
            >
              Save
            </Button>
          </Pane>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

PhoneService.propTypes = {
  user: PropTypes.shape({
    data: PropTypes.shape({
      countries: PropTypes.arrayOf(
        PropTypes.shape({
          isoCode: PropTypes.string.isRequired,
        })
      ),
    }),
    hasAction: PropTypes.func.isRequired,
  }),
}

export default withUser(PhoneService)
