import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'evergreen-ui'
import { useMutation } from 'urql'
import urql from 'utils/urql'

const CSEmailNotificationsButton = ({
  isCustomerSupportEmailEnabled,
  userId,
  onStatusChanged,
}) => {
  const [
    deactivateCSEmailNotificationQuery,
    executeDeactivateCSEmailNotification,
  ] = useMutation(`
    mutation($id: ID!) {
      deactivateUserCustomerSupportEmailNotifications(id: $id) {
        id
        isCustomerSupportEmailEnabled
      }
    }
  `)
  const [activateCSEmailNotificationQuery, executeActivateCSEmailNotification] =
    useMutation(`
    mutation($id: ID!) {
      activateUserCustomerSupportEmailNotifications(id: $id) {
        id
        isCustomerSupportEmailEnabled
      }
    }
  `)
  const updatedUser =
    (deactivateCSEmailNotificationQuery.data &&
      deactivateCSEmailNotificationQuery.data
        .deactivateUserCustomerSupportEmailNotifications) ||
    (activateCSEmailNotificationQuery.data &&
      activateCSEmailNotificationQuery.data
        .activateUserCustomerSupportEmailNotifications) ||
    {}
  useEffect(() => {
    if (
      updatedUser.id &&
      isCustomerSupportEmailEnabled !==
        updatedUser.isCustomerSupportEmailEnabled
    ) {
      onStatusChanged(updatedUser.id)
    }
  }, [
    isCustomerSupportEmailEnabled,
    onStatusChanged,
    updatedUser.id,
    updatedUser.isCustomerSupportEmailEnabled,
  ])
  useEffect(() => {
    if (activateCSEmailNotificationQuery.error) {
      urql.handleError(activateCSEmailNotificationQuery.error)
    }
    if (deactivateCSEmailNotificationQuery.error) {
      urql.handleError(deactivateCSEmailNotificationQuery.error)
    }
  }, [deactivateCSEmailNotificationQuery, activateCSEmailNotificationQuery])

  return isCustomerSupportEmailEnabled ? (
    <Button
      appearance="primary"
      intent="danger"
      onClick={() => executeDeactivateCSEmailNotification({ id: userId })}
    >
      Disable Customer Support Email Notifications
    </Button>
  ) : (
    <Button
      appearance="primary"
      intent="success"
      onClick={() => executeActivateCSEmailNotification({ id: userId })}
    >
      Enable Customer Support Email Notifications
    </Button>
  )
}

CSEmailNotificationsButton.propTypes = {
  isCustomerSupportEmailEnabled: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  onStatusChanged: PropTypes.func,
}

export default CSEmailNotificationsButton
