import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withTheme, Pane, Tab, Tablist as DefaultTablist } from 'evergreen-ui'
import ROUTES, {
  MANAGEMENT_ROUTES,
  WISH_MANAGEMENT_ROUTES,
  FEEDBACK_MANAGEMENT_ROUTES,
  THEME_DEAL_MANAGEMENT_ROUTES,
  MANAGEMENT_PERSONALISATION_ROUTES,
} from 'constants/routes'

class Tablist extends PureComponent {
  createTabSelectHandler = (tab) => () => {
    const { history, rootUrl } = this.props
    history.replace(`/${rootUrl}/${tab}`)
  }

  render() {
    const { selectedTab, tabs, theme } = this.props
    return (
      <Pane background={theme.colors.background.tint1} paddingY="0.5em">
        <DefaultTablist>
          {tabs.map(({ tab, name }) => (
            <Tab
              key={tab}
              id={tab}
              onSelect={this.createTabSelectHandler(tab)}
              isSelected={selectedTab === tab}
              aria-controls={`panel-${tab}`}
            >
              {name}
            </Tab>
          ))}
        </DefaultTablist>
      </Pane>
    )
  }
}

Tablist.propTypes = {
  rootUrl: PropTypes.oneOf([
    ROUTES.DEAL_OF_THE_DAYS,
    ROUTES.DEAL_OF_THE_WEEKS,
    ROUTES.DEAL_OF_THE_MONTHS,
    ROUTES.PERSONALISATION,
    ROUTES.WISHES,
    ROUTES.FEEDBACKS,
    ROUTES.THEME_OF_THE_MONTH,
    ...Object.values(MANAGEMENT_ROUTES),
  ]),
  selectedTab: PropTypes.oneOf([
    ...Object.values(MANAGEMENT_ROUTES),
    ...Object.values(WISH_MANAGEMENT_ROUTES),
    ...Object.values(FEEDBACK_MANAGEMENT_ROUTES),
    ...Object.values(THEME_DEAL_MANAGEMENT_ROUTES),
    ...Object.values(MANAGEMENT_PERSONALISATION_ROUTES),
  ]),
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.oneOf([
        ...Object.values(MANAGEMENT_ROUTES),
        ...Object.values(WISH_MANAGEMENT_ROUTES),
        ...Object.values(FEEDBACK_MANAGEMENT_ROUTES),
        ...Object.values(THEME_DEAL_MANAGEMENT_ROUTES),
        ...Object.values(MANAGEMENT_PERSONALISATION_ROUTES),
      ]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  theme: PropTypes.object.isRequired,
}

export default withTheme(Tablist)
