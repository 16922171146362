import React from 'react'
import PropTypes from 'prop-types'
import SideNavPage from 'sharedComponents/SideNavPage'
import { BackButton } from 'evergreen-ui'
import Form from './Form'

const NewOldOrder = ({ history }) => (
  <React.Fragment>
    <SideNavPage.Header>
      <SideNavPage.Title>Import new old order</SideNavPage.Title>
      <BackButton onClick={() => history.goBack()} />
    </SideNavPage.Header>
    <SideNavPage.Content>
      <Form />
    </SideNavPage.Content>
  </React.Fragment>
)

NewOldOrder.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}
export default NewOldOrder
