import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Flex } from 'components/Layout'
import Button from './Button'

const StyledFlex = styled(Flex)`
  margin: 25px 0;
`

class Pagination extends React.Component {
  createOnClickHandler = (page) => () => this.props.onPageChange(page)

  handlePrevClick = () => {
    const { onPageChange, previousPage } = this.props
    onPageChange(previousPage)
  }

  handleNextClick = () => {
    const { onPageChange, nextPage } = this.props
    onPageChange(nextPage)
  }

  render() {
    const { firstPage, lastPage, currentPage, previousPage, nextPage } =
      this.props
    return (
      <StyledFlex width="100%" alignItems="center" justifyContent="center">
        <Button.PreviousPage
          onClick={this.handlePrevClick}
          disabled={previousPage === null}
        />
        {firstPage && firstPage !== currentPage && (
          <Button.Page
            onClick={this.createOnClickHandler(firstPage)}
            page={firstPage}
          />
        )}
        {previousPage && previousPage - 3 > firstPage && (
          <Button.More disabled />
        )}
        {previousPage && previousPage - 2 > firstPage && (
          <Button.Page
            onClick={this.createOnClickHandler(previousPage - 2)}
            page={previousPage - 2}
          />
        )}
        {previousPage && previousPage - 1 > firstPage && (
          <Button.Page
            onClick={this.createOnClickHandler(previousPage - 1)}
            page={previousPage - 1}
          />
        )}
        {previousPage && (
          <Button.Page
            onClick={this.createOnClickHandler(previousPage)}
            page={previousPage}
          />
        )}
        {currentPage && <Button.Page disabled page={currentPage} />}
        {nextPage && (
          <Button.Page
            onClick={this.createOnClickHandler(nextPage)}
            page={nextPage}
          />
        )}
        {nextPage && nextPage + 1 < lastPage && (
          <Button.Page
            onClick={this.createOnClickHandler(nextPage + 1)}
            page={nextPage + 1}
          />
        )}
        {nextPage && nextPage + 2 < lastPage && (
          <Button.Page
            onClick={this.createOnClickHandler(nextPage + 2)}
            page={nextPage + 2}
          />
        )}
        {nextPage && nextPage + 3 < lastPage && <Button.More disabled />}
        {lastPage && lastPage > currentPage + 1 ? (
          <Button.Page
            onClick={this.createOnClickHandler(lastPage)}
            page={lastPage}
          />
        ) : null}
        <Button.NextPage
          onClick={this.handleNextClick}
          disabled={nextPage === null}
        />
      </StyledFlex>
    )
  }
}

Pagination.propTypes = {
  firstPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  previousPage: PropTypes.number,
  nextPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
}

export default Pagination
