import React from 'react'
import PropTypes from 'prop-types'
import isRequiredIf from 'react-proptype-conditional-require'
import {
  Table,
  Popover,
  Position,
  Menu,
  TextDropdownButton,
} from 'evergreen-ui'
import { SORT_DIRECTION_ENUM } from 'constants/constants'

const getIconForDirection = (direction) => {
  switch (direction) {
    case SORT_DIRECTION_ENUM.ASC:
      return 'arrow-up'
    case SORT_DIRECTION_ENUM.DESC:
      return 'arrow-down'
    default:
      return 'caret-down'
  }
}

const TableSortableHeaderCell = ({
  children,
  currentSort,
  sortField,
  onSortChange,
}) => {
  const isSelected = currentSort.field === sortField
  return (
    <Table.TextHeaderCell>
      <Popover
        position={Position.BOTTOM_LEFT}
        content={({ close }) => (
          <Menu>
            <Menu.OptionsGroup
              title="Order"
              options={[
                { label: 'Ascending', value: SORT_DIRECTION_ENUM.ASC },
                { label: 'Descending', value: SORT_DIRECTION_ENUM.DESC },
              ]}
              selected={isSelected ? currentSort.direction : null}
              onChange={(direction) => {
                onSortChange({ field: sortField, direction })
                close()
              }}
            />
          </Menu>
        )}
      >
        <TextDropdownButton
          icon={
            isSelected
              ? getIconForDirection(currentSort.direction)
              : 'caret-down'
          }
        >
          {children}
        </TextDropdownButton>
      </Popover>
    </Table.TextHeaderCell>
  )
}

TableSortableHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
  currentSort: isRequiredIf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(Object.values(SORT_DIRECTION_ENUM)).isRequired,
    }),
    ({ isSortable }) => isSortable
  ),
  sortField: isRequiredIf(PropTypes.string, ({ isSortable }) => isSortable),
  onSortChange: isRequiredIf(PropTypes.func, ({ isSortable }) => isSortable),
}

export default TableSortableHeaderCell
