import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, Table } from 'evergreen-ui'

const UPDATE_TRACKING_NUMBER_STATUS = {
  UPDATED: 'UPDATED',
  INVALID_ORDER_STATUS: 'INVALID_ORDER_STATUS',
  INVALID_TRACKING_NUMBER: 'INVALID_TRACKING_NUMBER',
  NO_ORDER_FOUND: 'NO_ORDER_FOUND',
  UNKNOWN: 'UNKNOWN',
}

const getRowIntent = (status) => {
  if (status === UPDATE_TRACKING_NUMBER_STATUS.UPDATED) {
    return 'success'
  }
  if (status === UPDATE_TRACKING_NUMBER_STATUS.INVALID_ORDER_STATUS) {
    return 'warning'
  }
  return 'danger'
}

const ImportResultDialog = ({ csvUploadData, close }) => (
  <Dialog
    width={700}
    onCloseComplete={close}
    confirmLabel="Ok"
    isShown={!!csvUploadData}
    hasCancel={false}
    preventBodyScrolling
    title="Result of Zasilkovna CSV upload"
  >
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Row #</Table.TextHeaderCell>
        <Table.TextHeaderCell flexGrow={3}>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell flexGrow={2}>
          Provided order id
        </Table.TextHeaderCell>
        <Table.TextHeaderCell flexGrow={3}>
          Provided tracking number
        </Table.TextHeaderCell>
        <Table.TextHeaderCell flexGrow={3}>
          Provided serial number
        </Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {csvUploadData.map(
          (
            {
              status,
              providedOrderId,
              providedTrackingNumber,
              providedSerialNumber,
            },
            index
          ) => (
            <Table.Row key={providedOrderId} intent={getRowIntent(status)}>
              <Table.TextCell>{index + 1}</Table.TextCell>
              <Table.TextCell flexGrow={3}>{status}</Table.TextCell>
              <Table.TextCell flexGrow={2}>{providedOrderId}</Table.TextCell>
              <Table.TextCell flexGrow={3}>
                {providedTrackingNumber}
              </Table.TextCell>
              <Table.TextCell flexGrow={3}>
                {providedSerialNumber}
              </Table.TextCell>
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  </Dialog>
)

ImportResultDialog.propTypes = {
  close: PropTypes.func.isRequired,
  csvUploadData: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(Object.values(UPDATE_TRACKING_NUMBER_STATUS))
        .isRequired,
      providedOrderId: PropTypes.string.isRequired,
      providedTrackingNumber: PropTypes.string.isRequired,
      providedSerialNumber: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default ImportResultDialog
