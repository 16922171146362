import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'evergreen-ui'

const EditModeButton = ({
  isEditMode,
  onClick,
  editModeLabel,
  noEditModeLabel,
  ...rest
}) => (
  <Button onClick={onClick} width="10em" justifyContent="center" {...rest}>
    {isEditMode ? editModeLabel : noEditModeLabel}
  </Button>
)

EditModeButton.defaultProps = {
  noEditModeLabel: 'Rearrange',
  editModeLabel: 'Save',
}
EditModeButton.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  noEditModeLabel: PropTypes.string,
  editModeLabel: PropTypes.string,
}

export default EditModeButton
