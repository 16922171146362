import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import { Table, Spinner, Button, Dialog } from 'evergreen-ui'
import urql from 'utils/urql'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES from 'constants/routes'
import { withUser } from 'UserProvider'
import ACTIONS from 'constants/actions'
import { formatPrice } from 'utils/money'

const TABLE_MIN_HEIGHT = 500

let isInitialMount = true

const AdditionalServicesList = ({ history, user }) => {
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [additionalServicesQuery, executeAdditionalServicesQuery] = useQuery({
    query: `
      query {
        userAdditionalServices {
          id
          title
          price
        }
      }
    `,
  })
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: ID!) {
      deleteAdditionalService(id: $id) {
        id
      }
    }
  `)
  const lastDeletedId =
    deleteMutation.data && deleteMutation.data.deleteAdditionalService.id
  useEffect(() => {
    if (!isInitialMount) {
      executeAdditionalServicesQuery({ requestPolicy: 'network-only' })
    } else {
      isInitialMount = false
    }
  }, [lastDeletedId, executeAdditionalServicesQuery])
  useEffect(() => {
    const error = additionalServicesQuery.error || deleteMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [additionalServicesQuery.error, deleteMutation.error])
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Additional services</SideNavPage.Title>
        {user.hasAction(ACTIONS.ADDITIONAL_SERVICE.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => history.push(`${ROUTES.ADDITIONAL_SERVICES}/new`)}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell>Price</Table.TextHeaderCell>
            <Table.TextHeaderCell align="center">Action</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {additionalServicesQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                delay={200}
                size={80}
              />
            )}
            {additionalServicesQuery.data &&
              additionalServicesQuery.data.userAdditionalServices.map(
                (additionalService) => (
                  <Table.Row
                    height="auto"
                    minHeight="45px"
                    key={additionalService.id}
                    isSelectable={user.hasAction(
                      ACTIONS.ADDITIONAL_SERVICE.DETAIL
                    )}
                    onSelect={() =>
                      history.push(
                        `${ROUTES.ADDITIONAL_SERVICES}/edit/${additionalService.id}`
                      )
                    }
                  >
                    <Table.TextCell>{additionalService.id}</Table.TextCell>
                    <Table.TextCell>{additionalService.title}</Table.TextCell>
                    <Table.TextCell>
                      {formatPrice({ value: additionalService.price })}
                    </Table.TextCell>
                    <Table.Cell align="center">
                      {user.hasAction(ACTIONS.ADDITIONAL_SERVICE.DELETE) && (
                        <Button
                          marginX="auto"
                          iconBefore="trash"
                          intent="danger"
                          onClick={(e) => {
                            e.stopPropagation()
                            setId(additionalService.id)
                            setTitle(additionalService.title)
                            setIsConfirmDeleteModalOpen(true)
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
          </Table.Body>
        </Table>
      </SideNavPage.Content>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete additional service"
        confirmLabel="Delete"
        intent="danger"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          await executeDeleteMutation({
            id,
          })
          close()
        }}
      >
        Are you sure you want to delete additional service: {title} (#{id}) ?
      </Dialog>
    </React.Fragment>
  )
}

AdditionalServicesList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(AdditionalServicesList)
