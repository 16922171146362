import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { requestFileDownload } from 'utils/request'
import { IconButton, Spinner, Pane } from 'evergreen-ui'
import { getAccessToken } from 'UserProvider'

const ExportDoWOrdersButton = ({ dealOfTheWeekId }) => {
  const [isLoading, setIsLoading] = useState(false)
  if (isLoading) {
    return (
      <Pane marginRight={12}>
        <Spinner size={16} />
      </Pane>
    )
  }
  return (
    <IconButton
      disabled={isLoading}
      marginRight={12}
      icon="export"
      title="Export orders"
      appearance="minimal"
      onClick={async (e) => {
        setIsLoading(true)
        e.stopPropagation()
        try {
          await requestFileDownload({
            url: `${process.env.REACT_APP_API_URL}/exports/dow-orders`,
            accessToken: getAccessToken(),
            filename: `orders_for_dow-${dealOfTheWeekId}.csv`,
            body: {
              dealOfTheWeekId,
            },
          })
        } catch (error) {
          // do nothing
        }
        setIsLoading(false)
      }}
    />
  )
}

ExportDoWOrdersButton.propTypes = {
  dealOfTheWeekId: PropTypes.number.isRequired,
}

export default ExportDoWOrdersButton
