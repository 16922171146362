import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DEAL_STATUSES, SORT_DIRECTION_ENUM } from 'constants/constants'
import { useMutation, useQuery } from 'urql'
import { compose } from 'recompose'
import { toaster } from 'evergreen-ui'
import { withUser } from 'UserProvider'
import ACTIONS from 'constants/actions'
import NoData from 'sharedComponents/NoData'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import ActionsRow from 'components/ActionsRow'
import EditModeButton from 'components/EditModeButton'
import EditableList, {
  rearangeItemsBasedOnNewLayout,
  moveItemInItemsToTheTop,
  getIdsOrderedByPositionIndex,
} from 'components/EditableList'
import { withRouter } from 'react-router-dom'
import {
  getDealOfTheWeeksQuery,
  extractDataFromDealOfTheWeeksQuery,
  updateDealOfTheWeekPublishPositionsMutation,
} from '../queries'
import DeleteDoWDialog from '../../../components/DeleteDoWDialog'
import { DOW_ITEM_Y_MARGIN, DOW_ITEM_HEIGHT } from '../constants'
import DoWItem from '../components/DoWItem'
import withApproveDeclineMutations, {
  toastResultOfApproval,
} from '../../../components/withAprroveDeclineMutations'
import { getPathToDealOfTheWeekDetail } from '../../../utils'

const { APPROVAL_NEEDED, APPROVED, DECLINED, DRAFT } = DEAL_STATUSES

const Next = ({
  user,
  history,
  executeApproveDealOfTheWeekMutation,
  showDeclineDealOfTheWeekDialog,
}) => {
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [newDealOfTheWeeks, setNewDealOfTheWeeks] = useState([])
  const [dealOfTheWeeksQuery, executeDealOfTheWeeksQuery] = useQuery(
    getDealOfTheWeeksQuery(
      {
        status: [APPROVED, APPROVAL_NEEDED, DECLINED, DRAFT],
        pagination: { page: 0, limit: 200 },
        sorter: {
          field: 'positionIndexWeight',
          direction: SORT_DIRECTION_ENUM.ASC,
        },
      },
      `
        estimatedStartAtUtc
        positionIndexWeight
      `
    )
  )
  const [, executeUpdateDealOfTheWeekPublishPositionsMutation] = useMutation(
    updateDealOfTheWeekPublishPositionsMutation
  )
  const { dealOfTheWeeks } =
    extractDataFromDealOfTheWeeksQuery(dealOfTheWeeksQuery)
  const currentDealOfTheWeeks = isEditMode ? newDealOfTheWeeks : dealOfTheWeeks
  return (
    <div>
      <FullScreenLoading isVisible={dealOfTheWeeksQuery.fetching} />
      <ActionsRow>
        {user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE_PUBLISH_POSITION) && (
          <EditModeButton
            isEditMode={isEditMode}
            onClick={async () => {
              if (isEditMode) {
                const { error } =
                  await executeUpdateDealOfTheWeekPublishPositionsMutation({
                    ids: getIdsOrderedByPositionIndex(newDealOfTheWeeks),
                  })
                if (error) {
                  toaster.danger('Rearrangement failed.')
                } else {
                  executeDealOfTheWeeksQuery({ requestPolicy: 'network-only' })
                  toaster.success('Rearrangement succeeded.')
                }
              } else {
                setNewDealOfTheWeeks(dealOfTheWeeks)
              }
              setIsEditMode(!isEditMode)
            }}
          />
        )}
      </ActionsRow>
      {dealOfTheWeeks && (
        <>
          <DeleteDoWDialog
            onSuccess={() => {
              executeDealOfTheWeeksQuery({ requestPolicy: 'network-only' })
            }}
            onClose={() => {
              setItemToSoftDelete(null)
            }}
            itemToDelete={itemToSoftDelete}
          />
          <EditableList
            items={currentDealOfTheWeeks}
            isEditMode={isEditMode}
            onDragStop={(newLayout) => {
              setNewDealOfTheWeeks(
                rearangeItemsBasedOnNewLayout({
                  newLayout,
                  items: newDealOfTheWeeks,
                })
              )
            }}
            margin={[0, DOW_ITEM_Y_MARGIN]}
            rowHeight={DOW_ITEM_HEIGHT}
          >
            {(item) => (
              <div key={item.id}>
                <DoWItem
                  {...item}
                  isEditMode={isEditMode}
                  isToTheTopButtonVisible={user.hasAction(
                    ACTIONS.DEAL_OF_THE_WEEK.UPDATE_PUBLISH_POSITION
                  )}
                  isWithApproveButton={
                    item.status === APPROVAL_NEEDED &&
                    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.APPROVE)
                  }
                  isWithDeclineButton={
                    item.status === APPROVAL_NEEDED &&
                    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.DECLINE)
                  }
                  onMoveItemToTopClick={async () => {
                    const { error } =
                      await executeUpdateDealOfTheWeekPublishPositionsMutation({
                        ids: getIdsOrderedByPositionIndex(
                          moveItemInItemsToTheTop({
                            items: dealOfTheWeeks,
                            idOfItemToTop: item.id,
                          })
                        ),
                      })
                    if (error) {
                      toaster.danger('Rearrangement failed.')
                    } else {
                      toaster.success('Rearrangement succeeded.')
                      executeDealOfTheWeeksQuery({
                        requestPolicy: 'network-only',
                      })
                    }
                  }}
                  onDetailClick={() =>
                    history.push(getPathToDealOfTheWeekDetail(item.id))
                  }
                  onDeleteButtonClicked={() => setItemToSoftDelete(item)}
                  onApproveButtonClicked={async () => {
                    const result = await executeApproveDealOfTheWeekMutation({
                      id: item.id,
                    })
                    toastResultOfApproval(result)
                  }}
                  onDeclineButtonClicked={() => {
                    showDeclineDealOfTheWeekDialog({
                      dealOfTheWeekId: item.id,
                    })
                  }}
                  isWithDeleteButton={user.hasAction(
                    ACTIONS.DEAL_OF_THE_WEEK.DELETE
                  )}
                  isWithEditButton={
                    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE) ||
                    user.hasAction(
                      ACTIONS.DEAL_OF_THE_WEEK.UPDATE_TRANSLATIONS
                    ) ||
                    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_TRANSLATIONS) ||
                    user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_COMPETITOR)
                  }
                />
              </div>
            )}
          </EditableList>
        </>
      )}
      {!dealOfTheWeeksQuery.fetching && dealOfTheWeeks.length === 0 && (
        <NoData padding={60} />
      )}
    </div>
  )
}

Next.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveDealOfTheWeekMutation: PropTypes.func.isRequired,
  showDeclineDealOfTheWeekDialog: PropTypes.func.isRequired,
}

export default compose(withUser, withRouter, withApproveDeclineMutations)(Next)
