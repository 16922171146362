import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import { withUser } from 'UserProvider'
import {
  Table,
  Spinner,
  Button,
  Dialog,
  TextInputField,
  Switch,
  Label,
  Pane,
  Heading,
  SelectField,
  majorScale,
  Autocomplete,
} from 'evergreen-ui'
import find from 'lodash.find'
import urql from 'utils/urql'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES from 'constants/routes'
import { SIZES_OPTIONS } from 'constants/product'
import carriers from 'carriers.json'
import { parseInteger } from 'utils/numbers'
import ACTIONS from 'constants/actions'

const TABLE_MIN_HEIGHT = 500

let isInitialMount = true

const countryFields = `{
  isoCode
  title
  translations {
    language {
      isoCode
      title
    }
    value
  }
  vat0
  vat1
  vat2
  deliveryOptions {
    size
    price
    timeInDays
    carrierId
  }
  isShippingEnabled
  defaultCurrency {
    isoCode
    title
  }
}`

const Countries = ({ history, user }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [isoCode, setIsoCode] = useState('')
  const [title, setTitle] = useState('')
  const [isShippingEnabled, setIsShippingEnabled] = useState(false)
  const [translations, setTranslations] = useState([])
  const [vat0, setVat0] = useState('')
  const [vat1, setVat1] = useState('')
  const [vat2, setVat2] = useState('')
  const [deliveryOptions, setDeliveryOptions] = useState([])
  const [defaultCurrencyIsoCode, setDefaultCurrencyIsoCode] = useState('')
  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })
  const [currenciesQuery] = useQuery({
    query: `
      query {
        currencies {
          isoCode
          title
        }
      }
    `,
  })
  const [countriesQuery, executeCountriesQuery] = useQuery({
    query: `
      query {
        userCountries ${countryFields}
      }
    `,
  })
  const [createMutation, executeCreateMutation] = useMutation(`
    mutation($input: CreateCountryInput!) {
      createCountry(input: $input) ${countryFields}
    }
  `)
  const lastCreatedIsoCode =
    createMutation.data && createMutation.data.createCountry.isoCode
  const [updateMutation, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateCountryInput!) {
      updateCountry(input: $input) ${countryFields}
    }
  `)
  const lastUpdatedIsoCode =
    updateMutation.data && updateMutation.data.updateCountry.isoCode
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($isoCode: String!) {
      deleteCountry(isoCode: $isoCode) {
        isoCode
      }
    }
  `)
  const lastDeletedIsoCode =
    deleteMutation.data && deleteMutation.data.deleteCountry.isoCode
  const updateTranslationValue = (languageIsoCode, value) => {
    setTranslations([
      ...translations.filter(
        (translation) => translation.languageIsoCode !== languageIsoCode
      ),
      { languageIsoCode, value },
    ])
  }
  const getTranslationValue = (languageIsoCode) => {
    const translation = find(translations, {
      languageIsoCode,
    })
    return translation ? translation.value : ''
  }
  useEffect(() => {
    if (!isInitialMount) {
      if (lastCreatedIsoCode || lastUpdatedIsoCode || lastDeletedIsoCode) {
        executeCountriesQuery({ requestPolicy: 'network-only' })
      }
    } else {
      isInitialMount = false
    }
  }, [
    lastCreatedIsoCode,
    lastDeletedIsoCode,
    lastUpdatedIsoCode,
    executeCountriesQuery,
  ])
  useEffect(() => {
    const error =
      countriesQuery.error ||
      deleteMutation.error ||
      updateMutation.error ||
      createMutation.error ||
      languagesQuery.error
    if (error) {
      urql.handleError(error)
    }
  }, [
    countriesQuery.error,
    deleteMutation.error,
    updateMutation.error,
    createMutation.error,
    languagesQuery.error,
  ])
  const isWithEditButton =
    user.hasAction(ACTIONS.COUNTRY.UPDATE) ||
    user.hasAction(ACTIONS.COUNTRY.EDIT_DEFAULT_CURRENCY) ||
    user.hasAction(ACTIONS.COUNTRY.EDIT_SHIPPING) ||
    user.hasAction(ACTIONS.COUNTRY.EDIT_VAT)
  const isBasicEditDisabled =
    isUpdateModalOpen && !user.hasAction(ACTIONS.COUNTRY.UPDATE)
  const isVatEditDisabled =
    isUpdateModalOpen && !user.hasAction(ACTIONS.COUNTRY.EDIT_VAT)
  const isShippingEditDisabled =
    isUpdateModalOpen && !user.hasAction(ACTIONS.COUNTRY.EDIT_SHIPPING)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Countries</SideNavPage.Title>
        {user.hasAction(ACTIONS.COUNTRY.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setIsoCode('')
              setTitle('')
              setIsShippingEnabled(false)
              setTranslations([])
              setVat0('')
              setVat1('')
              setVat2('')
              const defaultDeliveryOptions = SIZES_OPTIONS.map(
                (SIZES_OPTION) => ({
                  size: SIZES_OPTION,
                  price: 0,
                  timeInDays: 0,
                })
              )
              setDeliveryOptions(defaultDeliveryOptions)
              setDefaultCurrencyIsoCode('')
              setIsCreateModalOpen(true)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head height="auto" paddingY={12}>
            <Table.TextHeaderCell>ISO code</Table.TextHeaderCell>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell>VAT_0</Table.TextHeaderCell>
            <Table.TextHeaderCell>VAT_1</Table.TextHeaderCell>
            <Table.TextHeaderCell>VAT_2</Table.TextHeaderCell>
            <Table.TextHeaderCell>Is shipping country</Table.TextHeaderCell>
            <Table.TextHeaderCell>Default currency</Table.TextHeaderCell>
            <Table.TextHeaderCell minWidth="250px" align="center">
              Actions
            </Table.TextHeaderCell>
          </Table.Head>
        </Table>
        <Table.Body minHeight={TABLE_MIN_HEIGHT}>
          {countriesQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {countriesQuery.data &&
            countriesQuery.data.userCountries.map((country) => (
              <Table.Row
                key={country.isoCode}
                isSelectable
                onSelect={() =>
                  history.push(`${ROUTES.COUNTRIES}/${country.isoCode}`)
                }
              >
                <Table.TextCell>{country.isoCode}</Table.TextCell>
                <Table.TextCell>{country.title}</Table.TextCell>
                <Table.TextCell isNumber>{country.vat0}</Table.TextCell>
                <Table.TextCell isNumber>{country.vat1}</Table.TextCell>
                <Table.TextCell isNumber>{country.vat2}</Table.TextCell>
                <Table.Cell>
                  <Switch disabled checked={country.isShippingEnabled} />
                </Table.Cell>
                <Table.TextCell>{country.defaultCurrency.title}</Table.TextCell>
                <Table.Cell minWidth="250px" justifyContent="center">
                  {isWithEditButton && (
                    <Button
                      marginRight={12}
                      iconBefore="edit"
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsoCode(country.isoCode)
                        setTitle(country.title)
                        setIsShippingEnabled(country.isShippingEnabled)
                        setTranslations(
                          country.translations.map(({ language, value }) => ({
                            languageIsoCode: language.isoCode,
                            value,
                          }))
                        )
                        setVat0(country.vat0)
                        setVat1(country.vat1)
                        setVat2(country.vat2)
                        setDeliveryOptions(
                          country.deliveryOptions.map(
                            ({ __typename, ...rest }) => rest
                          )
                        )
                        setDefaultCurrencyIsoCode(
                          country.defaultCurrency.isoCode
                        )
                        setIsUpdateModalOpen(true)
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {user.hasAction(ACTIONS.COUNTRY.DELETE) && (
                    <Button
                      marginRight={12}
                      iconBefore="trash"
                      intent="danger"
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsoCode(country.isoCode)
                        setIsConfirmDeleteModalOpen(true)
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </SideNavPage.Content>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete country"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            isoCode,
          })
          if (!error) {
            setIsoCode('')
            executeCountriesQuery({ requestPolicy: 'network-only' })
            close()
          }
        }}
      >
        Are you sure you want to delete Country with isoCode: {isoCode} ??
      </Dialog>
      <Dialog
        isShown={isCreateModalOpen || isUpdateModalOpen}
        title={
          isCreateModalOpen ? 'Create country' : `Edit country: ${isoCode}`
        }
        hasCancel={false}
        confirmLabel={isCreateModalOpen ? 'Create' : 'Edit'}
        isConfirmLoading={createMutation.fetching || updateMutation.fetching}
        onCloseComplete={() => {
          setIsCreateModalOpen(false)
          setIsUpdateModalOpen(false)
        }}
        onConfirm={async (close) => {
          const input = {
            isoCode,
            title,
            isShippingEnabled,
            translations: translations.filter(({ value }) => value !== ''),
            vat0,
            vat1,
            vat2,
            deliveryOptions,
            defaultCurrencyIsoCode,
          }
          if (isCreateModalOpen) {
            const { error } = await executeCreateMutation({
              input,
            })
            if (!error) {
              executeCountriesQuery({ requestPolicy: 'network-only' })
              close()
            }
          }
          if (isUpdateModalOpen) {
            const { error } = await executeUpdateMutation({
              input,
            })
            if (!error) {
              executeCountriesQuery({ requestPolicy: 'network-only' })
              close()
            }
          }
        }}
      >
        {isCreateModalOpen && (
          <TextInputField
            label="ISO code"
            required
            value={isoCode}
            onChange={(e) => {
              setIsoCode(e.target.value)
            }}
          />
        )}
        <TextInputField
          label="Title"
          required
          value={title}
          disabled={isBasicEditDisabled}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <TextInputField
          type="number"
          step={0.1}
          label="VAT_0"
          disabled={isVatEditDisabled}
          required
          value={vat0}
          onChange={(e) => {
            setVat0(Number.parseFloat(e.target.value))
          }}
        />
        <TextInputField
          type="number"
          step={0.1}
          label="VAT_1"
          disabled={isVatEditDisabled}
          required
          value={vat1}
          onChange={(e) => {
            setVat1(Number.parseFloat(e.target.value))
          }}
        />
        <TextInputField
          type="number"
          step={0.1}
          label="VAT_2"
          disabled={isVatEditDisabled}
          required
          value={vat2}
          onChange={(e) => {
            setVat2(Number.parseFloat(e.target.value))
          }}
        />
        <Pane>
          <Label>Is shipping enabled</Label>
          <Switch
            checked={isShippingEnabled}
            disabled={isBasicEditDisabled}
            onChange={(e) => {
              setIsShippingEnabled(e.target.checked)
            }}
          />
        </Pane>
        <SelectField
          label="Default currency"
          disabled={
            isUpdateModalOpen &&
            !user.hasAction(ACTIONS.COUNTRY.EDIT_DEFAULT_CURRENCY)
          }
          value={defaultCurrencyIsoCode}
          onChange={(e) => {
            setDefaultCurrencyIsoCode(e.target.value)
          }}
        >
          <option value="">---Select currency---</option>
          {currenciesQuery.data &&
            currenciesQuery.data.currencies.map((currency) => (
              <option key={currency.isoCode} value={currency.isoCode}>
                {currency.title}
              </option>
            ))}
        </SelectField>
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Delivery options
          </Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Size</Table.TextHeaderCell>
              <Table.TextHeaderCell>Price</Table.TextHeaderCell>
              <Table.TextHeaderCell>Time in days</Table.TextHeaderCell>
              <Table.TextHeaderCell>Carrier</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {deliveryOptions.map(
                ({ size, price, timeInDays, carrierId }, index) => (
                  <Table.Row key={size}>
                    <Table.TextCell>{size}</Table.TextCell>
                    <Table.Cell>
                      <TextInputField
                        label="Price"
                        type="number"
                        step="0.01"
                        value={price}
                        disabled={isShippingEditDisabled}
                        onChange={(e) => {
                          const { value } = e.target
                          setDeliveryOptions((prevDeliveryOptions) => [
                            ...prevDeliveryOptions.slice(0, index),
                            {
                              ...prevDeliveryOptions[index],
                              price: Number.parseFloat(value),
                            },
                            ...prevDeliveryOptions.slice(index + 1),
                          ])
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextInputField
                        label="Time in days"
                        type="number"
                        step="1"
                        value={timeInDays}
                        disabled={isShippingEditDisabled}
                        onChange={(e) => {
                          const { value } = e.target
                          setDeliveryOptions((prevDeliveryOptions) => [
                            ...prevDeliveryOptions.slice(0, index),
                            {
                              ...prevDeliveryOptions[index],
                              timeInDays: Number.parseInt(value, 10),
                            },
                            ...prevDeliveryOptions.slice(index + 1),
                          ])
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Autocomplete
                        title="Carrier"
                        defaultSelectedItem={
                          isoCode &&
                          carriers[isoCode] &&
                          carriers[isoCode][carrierId]
                        }
                        onChange={(changedItem) => {
                          setDeliveryOptions((prevDeliveryOptions) => [
                            ...prevDeliveryOptions.slice(0, index),
                            {
                              ...prevDeliveryOptions[index],
                              carrierId: parseInteger(changedItem.id),
                            },
                            ...prevDeliveryOptions.slice(index + 1),
                          ])
                        }}
                        items={
                          isoCode && isoCode.length === 2 && carriers[isoCode]
                            ? Object.values(carriers[isoCode])
                            : []
                        }
                        itemToString={(item) =>
                          (item && `${item.name} (${item.id})`) || ''
                        }
                      >
                        {({ getInputProps, getRef, openMenu, inputValue }) => (
                          <TextInputField
                            label="Carrier"
                            innerRef={getRef}
                            disabled={isShippingEditDisabled}
                            {...getInputProps({
                              onFocus: () => {
                                openMenu()
                              },
                            })}
                            value={inputValue}
                          />
                        )}
                      </Autocomplete>
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </Pane>
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Translations
          </Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Language</Table.TextHeaderCell>
              <Table.TextHeaderCell>Value</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {languagesQuery.data &&
                languagesQuery.data.languages.map((language) => (
                  <Table.Row key={language.isoCode}>
                    <Table.TextCell>{language.title}</Table.TextCell>
                    <Table.Cell>
                      <TextInputField
                        label="Translation"
                        value={getTranslationValue(language.isoCode)}
                        disabled={isBasicEditDisabled}
                        onChange={(e) => {
                          updateTranslationValue(
                            language.isoCode,
                            e.target.value
                          )
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Pane>
      </Dialog>
    </React.Fragment>
  )
}

Countries.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Countries)
