import React, { useState } from 'react'
import addDays from 'date-fns/add_days'
import format from 'date-fns/format'
import PropTypes from 'prop-types'
import { Dialog, Pane } from 'evergreen-ui'
import Picker from 'components/Datepicker'

import { requestFileDownload } from 'utils/request'
import { getAccessToken } from 'UserProvider'

const initialDatetime = addDays(new Date(), -1)

const ExportOrdersToPacketaDialog = ({ isShown, onCloseComplete }) => {
  const [date, setDate] = useState(initialDatetime)
  const [isExportFetching, setIsExportFetching] = useState(false)

  return (
    <Dialog
      isShown={isShown}
      title="Filter and export to csv"
      onCloseComplete={() => {
        setDate(initialDatetime)
        onCloseComplete()
      }}
      onConfirm={async (close) => {
        setIsExportFetching(true)
        try {
          const formatedDate = format(date, 'YYYY-MM-DD')
          await requestFileDownload({
            url: `${process.env.REACT_APP_API_URL}/exports/daily-orders`,
            accessToken: getAccessToken(),
            filename: `orders_${formatedDate}.csv`,
            body: {
              date: formatedDate,
            },
          })
          close()
        } catch (e) {
          // do nothing
        } finally {
          setIsExportFetching(false)
        }
      }}
      isConfirmLoading={isExportFetching}
      confirmLabel={isExportFetching ? 'Exporting...' : 'Export'}
    >
      <Pane>
        <Picker.Date
          onChangeDate={(newDate) => setDate(newDate)}
          date={date}
          label="Date"
          maxDate={new Date()}
        />
      </Pane>
    </Dialog>
  )
}

ExportOrdersToPacketaDialog.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onCloseComplete: PropTypes.func.isRequired,
}

export default ExportOrdersToPacketaDialog
