import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import {
  Dialog,
  Button,
  Pane,
  Table,
  Spinner,
  TextInputField,
  Heading,
  Checkbox,
  majorScale,
} from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withUser } from 'UserProvider'
import find from 'lodash.find'
import ActionsRow from 'components/ActionsRow'
import ACTIONS from 'constants/actions'
import urql from 'utils/urql'

const TABLE_MIN_HEIGHT = 500

const categoryFields = `{
  id
  name
  translations {
    value
    languageIsoCode
    language {
      isoCode
      title
    }
  }
  brands {
    id
    name
  }
}`

const Categories = ({ user }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)

  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [translations, setTranslations] = useState([])
  const [brands, setBrands] = useState([])

  const [productCategoriesQuery] = useQuery({
    query: `
      query {
        personalisationsProductCategoriesList ${categoryFields}
      }
    `,
  })

  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })

  const [brandsQuery] = useQuery({
    query: `
      query {
        personalisationsBrandsList {
          id
          name
        }
      }
    `,
  })

  const [createMutation, executeCreateMutation] = useMutation(`
    mutation($input: CreatePersonalisationsProductCategoryInput!) {
      createPersonalisationsProductCategory(input: $input) {
        name
        translations {
          languageIsoCode
          value
        }
        brands {
          id
          name
        }
      }
    }
  `)

  const [updateMutation, executeUpdateMutation] = useMutation(`
    mutation($input: EditPersonalisationsProductCategoryInput!) {
      editPersonalisationsProductCategory(input: $input) {
        id
        name
        translations {
          languageIsoCode
          value
        }
        brands {
          id
          name
        }
      }
    }
  `)

  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: Int!) {
      deletePersonalisationsProductCategory(id: $id) {
        name
        translations {
          value
          language {
            title
          }
          languageIsoCode
        }
      }
    }
  `)

  const updateTranslationValue = (languageIsoCode, value) => {
    setTranslations([
      ...translations.filter(
        (translation) => translation.languageIsoCode !== languageIsoCode
      ),
      { languageIsoCode, value },
    ])
  }

  const getTranslationValue = (languageIsoCode) => {
    const translation = find(translations, {
      languageIsoCode,
    })
    return translation ? translation.value : ''
  }

  useEffect(() => {
    const error =
      productCategoriesQuery.error ||
      createMutation.error ||
      updateMutation.error ||
      deleteMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [
    productCategoriesQuery.error,
    createMutation.error,
    updateMutation.error,
    deleteMutation.error,
  ])

  return (
    <Pane>
      <ActionsRow>
        {user.hasAction(ACTIONS.PERSONALISATION.CREATE) && (
          <Button
            marginLeft="0.5em"
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setTitle('')
              setTranslations([])
              setIsCreateModalOpen(true)
            }}
          >
            Create New Category
          </Button>
        )}
      </ActionsRow>
      <Table border style={{ marginTop: '10px' }}>
        <Table.Head>
          <Table.TextHeaderCell>Product Category</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body minHeight={TABLE_MIN_HEIGHT}>
          {productCategoriesQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {productCategoriesQuery.data &&
            productCategoriesQuery.data.personalisationsProductCategoriesList.map(
              (item) => (
                <Table.Row key={item.id}>
                  <Table.TextCell>{item.name}</Table.TextCell>
                  <Table.Cell>
                    {user.hasAction(ACTIONS.PERSONALISATION.UPDATE) && (
                      <Button
                        marginRight={12}
                        iconBefore="edit"
                        onClick={(e) => {
                          e.stopPropagation()
                          setId(item.id)
                          setTitle(item.name)
                          setBrands(item.brands)
                          setTranslations(
                            item.translations.map(({ language, value }) => ({
                              languageIsoCode: language.isoCode,
                              value,
                            }))
                          )
                          setIsUpdateModalOpen(true)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    {user.hasAction(ACTIONS.PERSONALISATION.DELETE) && (
                      <Button
                        marginRight={12}
                        iconBefore="trash"
                        intent="danger"
                        onClick={(e) => {
                          e.stopPropagation()
                          setId(item.id)
                          setIsConfirmDeleteModalOpen(true)
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              )
            )}
        </Table.Body>
      </Table>
      <Dialog
        isShown={isCreateModalOpen || isUpdateModalOpen}
        title={
          isCreateModalOpen
            ? 'Create Product Category'
            : `Edit Product Category: ${title}`
        }
        hasCancel={false}
        confirmLabel={isCreateModalOpen ? 'Create' : 'Edit'}
        isConfirmLoading={createMutation.fetching || updateMutation.fetching}
        onCloseComplete={() => {
          setIsCreateModalOpen(false)
          setIsUpdateModalOpen(false)
        }}
        onConfirm={async (close) => {
          if (isCreateModalOpen) {
            const input = {
              name: title,
              translations: translations.filter(({ value }) => value !== ''),
              personalisationsProductCategoriesBrands: brands.map(
                ({ id: personalisationsBrandsId }) => ({
                  personalisationsBrandsId,
                })
              ),
            }
            const { error } = await executeCreateMutation({
              input,
            })
            if (!error) {
              close()
            }
          }
          if (isUpdateModalOpen) {
            const input = {
              id,
              name: title,
              translations: translations.filter(({ value }) => value !== ''),
              personalisationsProductCategoriesBrands: brands.map(
                ({ id: personalisationsBrandsId }) => ({
                  personalisationsBrandsId,
                })
              ),
            }
            const { error } = await executeUpdateMutation({
              input,
            })
            if (!error) {
              close()
            }
          }
        }}
      >
        <TextInputField
          label="Name"
          required
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Translations
          </Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Language</Table.TextHeaderCell>
              <Table.TextHeaderCell>Value</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {languagesQuery.data &&
                languagesQuery.data.languages.map((language) => (
                  <Table.Row key={language.isoCode}>
                    <Table.TextCell>{language.title}</Table.TextCell>
                    <Table.Cell>
                      <TextInputField
                        label="Translation"
                        value={getTranslationValue(language.isoCode)}
                        onChange={(e) => {
                          updateTranslationValue(
                            language.isoCode,
                            e.target.value
                          )
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Pane>
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Brands
          </Heading>
          {brandsQuery.data &&
            brandsQuery.data.personalisationsBrandsList.map((brand) => (
              <Checkbox
                key={brand.id}
                label={brand.name}
                checked={find(brands, { id: brand.id })}
                onChange={(e) => {
                  if (e.target.checked) {
                    setBrands((prevStateRoles) => [...prevStateRoles, brand])
                  } else {
                    setBrands((prevStateRoles) =>
                      prevStateRoles.filter(
                        (prevStateRole) => prevStateRole.id !== brand.id
                      )
                    )
                  }
                }}
              />
            ))}
        </Pane>
      </Dialog>

      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm Delete Product Category"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id,
          })
          if (!error) {
            setId('')
            close()
          }
        }}
      >
        Are you sure you want to delete Product Category: {title} ??
      </Dialog>
    </Pane>
  )
}

Categories.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(withRouter, withUser)(Categories)
