import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'evergreen-ui'
import { pickSpaceProps } from 'utils/styled-system'
import { FEEDBACK_TYPES } from 'constants/constants'

const { PRODUCT_FEEDBACK, CUSTOMER_SUPPORT } = FEEDBACK_TYPES

const getBadgeColor = (type) => {
  switch (type) {
    case PRODUCT_FEEDBACK:
      return 'yellow'
    case CUSTOMER_SUPPORT:
      return 'teal'
    default:
      return 'green'
  }
}

const FeedbackBadge = ({ type, ...rest }) => (
  <Badge color={getBadgeColor(type)} isSolid {...pickSpaceProps(rest)}>
    {type.replace(/_/g, ' ')}
  </Badge>
)

FeedbackBadge.propTypes = {
  type: PropTypes.oneOf(Object.values(FEEDBACK_TYPES)).isRequired,
}

export default FeedbackBadge
