import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { Table, Link, Checkbox } from 'evergreen-ui'
import { NavLink } from 'react-router-dom'
import urql from 'utils/urql'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES from 'constants/routes'

const OldOrders = ({ history }) => {
  const [ordersQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query userOldOrders {
        userOldOrders {
          id
          productName
          orderTotal
          orderCurrencyIsoCode
          orderDate
          createdAtUtc
          productSource
          isCustomerDevice
          customer {
            id
            firstName
            lastName
            email
          }
          threadId
        }
      }
    `,
  })

  useEffect(() => {
    if (ordersQuery.error) {
      urql.handleError(ordersQuery.error)
    }
  }, [ordersQuery.error])

  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Old Orders</SideNavPage.Title>
        <NavLink to={`/${ROUTES.OLD_ORDERS}/new`}>Add new old order</NavLink>
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head height="auto" paddingY={12}>
            <Table.TextHeaderCell>Old Order Id</Table.TextHeaderCell>
            <Table.TextHeaderCell>Product Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Customer Email</Table.TextHeaderCell>
            <Table.TextHeaderCell>Customer Device</Table.TextHeaderCell>
            <Table.TextHeaderCell>Thread</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {!ordersQuery.fetching &&
              ordersQuery.data &&
              ordersQuery.data.userOldOrders.map((item) => (
                <Table.Row key={item.id} height="auto" paddingY={12}>
                  <Table.TextCell>{item.id}</Table.TextCell>
                  <Table.TextCell>{item.productName}</Table.TextCell>
                  <Table.TextCell>
                    {item.customer && (
                      <Link
                        onClick={(e) => {
                          e.stopPropagation()
                          history.push(
                            `/${ROUTES.CUSTOMERS}/${item.customer.id}`
                          )
                        }}
                      >
                        {item.customer.email}
                      </Link>
                    )}
                  </Table.TextCell>
                  <Table.TextCell>
                    <Checkbox checked={item.isCustomerDevice} />
                  </Table.TextCell>
                  <Table.TextCell>
                    <Link
                      onClick={(e) => {
                        e.stopPropagation()
                        history.push(`/${ROUTES.MESSAGES}/${item.threadId}`)
                      }}
                    >
                      Go to message
                    </Link>
                  </Table.TextCell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </SideNavPage.Content>
    </React.Fragment>
  )
}

OldOrders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default OldOrders
