import React, { useEffect } from 'react'
import { useQuery } from 'urql'
import PropTypes from 'prop-types'
import { Table, Spinner, Pane, BackButton } from 'evergreen-ui'
import urql from 'utils/urql'
import SideNavPage from 'sharedComponents/SideNavPage'

const TABLE_MIN_HEIGHT = 500

const CountryDetail = ({ match, history }) => {
  const [countryQuery] = useQuery({
    query: `
      query($isoCode: String!) {
        country(isoCode: $isoCode) {
          isoCode
          title
          isShippingEnabled
          translations {
            language {
              isoCode
              title
            }
            value
          }
        }
      }
    `,
    variables: {
      isoCode: match.params.isoCode,
    },
  })
  const country = (countryQuery.data && countryQuery.data.country) || false
  useEffect(() => {
    if (countryQuery.error) {
      urql.handleError(countryQuery.error)
    }
  }, [countryQuery.error])
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>CountryDetail</SideNavPage.Title>
        <BackButton onClick={() => history.goBack()} />
      </SideNavPage.Header>
      <SideNavPage.Content>
        {countryQuery.fetching && (
          <Spinner
            marginX="auto"
            marginY={TABLE_MIN_HEIGHT / 2}
            delay={200}
            size={80}
          />
        )}
        {country && (
          <Table border>
            <Table.Body>
              <Table.Row>
                <Table.TextHeaderCell>IsoCode</Table.TextHeaderCell>
                <Table.TextCell>{country.isoCode}</Table.TextCell>
              </Table.Row>
              <Table.Row>
                <Table.TextHeaderCell>Title</Table.TextHeaderCell>
                <Table.TextCell>{country.title}</Table.TextCell>
              </Table.Row>
              <Table.Row>
                <Table.TextHeaderCell>Is Shipping Enabled</Table.TextHeaderCell>
                <Table.TextCell>
                  {country.isShippingEnabled ? 'Yes' : 'No'}
                </Table.TextCell>
              </Table.Row>
              <Table.Row height="auto">
                <Table.TextHeaderCell>Translations</Table.TextHeaderCell>
                <Table.TextCell>
                  <Pane>
                    {country.translations.map((translation) => (
                      <Pane key={translation.language.isoCode}>
                        {translation.language.title} (
                        {translation.language.isoCode}) - {translation.value}
                      </Pane>
                    ))}
                  </Pane>
                </Table.TextCell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

CountryDetail.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      isoCode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default CountryDetail
