import React from 'react'
import find from 'lodash.find'
import {
  IconButton,
  Dialog,
  TextInputField,
  Spinner,
  Pane,
  Table,
  Heading,
  majorScale,
  Button,
  Text,
} from 'evergreen-ui'

import request from 'utils/request'
import { getAccessToken } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import SortableTree, {
  getVisibleNodeCount,
  toggleExpandedForAll,
  getTreeFromFlatData,
} from 'react-sortable-tree'
import 'react-sortable-tree/style.css'

const HEIGHT_OF_TREE_CELL = 62

class Categories extends React.Component {
  state = {
    isEditCategoryModalOpen: false,
    isCreateCategoryModalOpen: false,
    parentId: null,
    categoryId: null,
    isConfirmDeleteModalOpen: false,
    treeData: [],
    title: '',
    isLoading: true,
    isLoadingDelete: false,
    isLoadingCreate: false,
    isLoadingEdit: false,
    isLoadingMove: false,
    languages: [],
    translations: [],
  }

  async componentDidMount() {
    await this.fetchCategoryTree()
    await this.fetchLanguages()
  }

  fetchLanguages = async () => {
    try {
      const response = await request({
        isThrowErrorsDisabled: true,
        accessToken: getAccessToken(),
        body: {
          query: `
          query languages {
            languages {
              isoCode
              title
            }
          }
        `,
        },
      })
      this.setState({
        languages: response.languages || [],
      })
    } catch (error) {
      // do nothing
    }
  }

  fetchCategoryTree = async () => {
    this.setState({
      isLoading: true,
    })
    try {
      const response = await request({
        accessToken: getAccessToken(),
        body: {
          query: `
          query categories {
            categories {
              id
              title
              parentId
              translations {
                language {
                  isoCode
                  title
                }
                value
              }
            }
          }
        `,
        },
      })
      this.setState({
        isLoading: false,
        treeData: toggleExpandedForAll({
          treeData: getTreeFromFlatData({
            rootKey: null,
            flatData: response.categories || [],
          }),
          expanded: true,
        }),
      })
    } catch (error) {
      // do nothing
    }
  }

  handleEditCategory = async (close) => {
    try {
      this.setState({ isLoadingEdit: true })
      await request({
        accessToken: getAccessToken(),
        body: {
          query: `
            mutation editCategory($input: EditCategoryInput!){
              editCategory(input: $input){
                id
              }
            }`,
          variables: {
            input: {
              id: this.state.categoryId,
              title: this.state.title,
              translations: this.state.translations,
            },
          },
        },
      })
      await this.fetchCategoryTree()
      close()
    } catch (error) {
      // do nothing
    } finally {
      this.setState({ isLoadingEdit: false })
    }
  }

  moveCategory = async ({ nextParentId, id, childrenCategoryIds }) => {
    try {
      this.setState({ isLoadingMove: true })
      await request({
        accessToken: getAccessToken(),
        body: {
          query: `
            mutation moveCategoryAndReorderChildren($input: MoveCategoryAndReorderChildrenInput!){
              moveCategoryAndReorderChildren(input: $input) {
                id
              }
            }`,
          variables: {
            input: {
              id,
              parentId: nextParentId,
              childrenCategoryIds,
            },
          },
        },
      })
      await this.fetchCategoryTree()
    } catch (error) {
      // do nothing
    }
    this.setState({ isLoadingMove: false })
  }

  handleRemoveCategory = async (close) => {
    try {
      this.setState({ isLoadingDelete: true })
      await request({
        accessToken: getAccessToken(),
        body: {
          query: `
          mutation deleteCategory($id: Int!){
            deleteCategory(id: $id){
              id
            }
          }
        `,
          variables: {
            id: this.state.categoryDeleteId,
          },
        },
      })
      await this.fetchCategoryTree()
      close()
    } catch (error) {
      // do nothing
    } finally {
      this.setState({ isLoadingDelete: false })
    }
  }

  handleAddCategory = async (close) => {
    try {
      this.setState({ isLoadingCreate: true })
      await request({
        accessToken: getAccessToken(),
        body: {
          query: `
          mutation createCategory($input: CreateCategoryInput!){
            createCategory(input: $input) {
              id
            }
          }
        `,
          variables: {
            input: {
              title: this.state.title,
              parentId: this.state.parentId,
              translations: this.state.translations,
            },
          },
        },
      })
      await this.fetchCategoryTree()
      close()
    } catch (error) {
      // do nothing
    } finally {
      this.setState({ isLoadingCreate: false })
    }
  }

  handleOpenRemoveCategoryModal = (id) => {
    this.setState({
      isConfirmDeleteModalOpen: true,
      categoryDeleteId: id,
    })
  }

  getTranslationValue = (languageIsoCode) => {
    const translation = find(this.state.translations, {
      languageIsoCode,
    })
    return translation ? translation.value : ''
  }

  updateTranslationValue = (languageIsoCode, value) => {
    this.setState((state) => ({
      translations: [
        ...state.translations.filter(
          (translation) => translation.languageIsoCode !== languageIsoCode
        ),
        { languageIsoCode, value },
      ],
    }))
  }

  handleOpenCreateCategoryModal = ({ parentId = null } = {}) => {
    this.setState({
      isCreateCategoryModalOpen: true,
      parentId,
      translations: [],
    })
  }

  handleOpenEditCategoryModal = ({ categoryId, title, translations }) => {
    this.setState({
      categoryId,
      title,
      isEditCategoryModalOpen: true,
      translations,
    })
  }

  renderCreateCategoryButton = () => (
    <Button
      onClick={() => {
        this.handleOpenCreateCategoryModal()
      }}
      iconBefore="plus"
      intent="success"
    >
      Create category
    </Button>
  )

  render() {
    const { languages } = this.state
    const count = getVisibleNodeCount({ treeData: this.state.treeData })
    return (
      <React.Fragment>
        <SideNavPage.Header>
          <SideNavPage.Title>Categories</SideNavPage.Title>
          {this.renderCreateCategoryButton()}
        </SideNavPage.Header>
        <SideNavPage.Content>
          <Pane
            height={count * HEIGHT_OF_TREE_CELL}
            pointerEvents={this.state.isLoadingMove ? 'none' : 'initial'}
            backgroundColor={
              this.state.isLoadingMove ? 'rgba(0,0,0,0.05)' : '#FFF'
            }
            position="relative"
          >
            {this.state.isLoadingMove && (
              <Pane
                zIndex="99"
                top="calc(50% - 40px)"
                left="calc(50% - 40px)"
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size={80} />
              </Pane>
            )}
            <SortableTree
              style={{ zIndex: this.state.isLoadingMove ? -1 : 0 }}
              placeholderRenderer={() =>
                !this.state.isLoading && this.state.treeData.length === 0 ? (
                  <Pane width="100%" textAlign="center">
                    <Text size={500}>No categories.</Text>
                  </Pane>
                ) : (
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner size={80} />
                  </Pane>
                )
              }
              treeData={this.state.treeData}
              onChange={(treeData) => this.setState({ treeData })}
              getNodeKey={({ node }) => node.id}
              generateNodeProps={(rowInfo) => {
                const hasChildren =
                  rowInfo.node.children && rowInfo.node.children.length !== 0
                const removeButton = (
                  <IconButton
                    icon="trash"
                    intent="danger"
                    onClick={() => {
                      this.handleOpenRemoveCategoryModal(rowInfo.node.id)
                    }}
                  />
                )
                return {
                  buttons: [
                    ...(!hasChildren ? [removeButton] : []),
                    <IconButton
                      icon="edit"
                      onClick={() => {
                        this.handleOpenEditCategoryModal({
                          categoryId: rowInfo.node.id,
                          title: rowInfo.node.title,
                          translations: (rowInfo.node.translations || []).map(
                            ({ language, value }) => ({
                              languageIsoCode: language.isoCode,
                              value,
                            })
                          ),
                        })
                      }}
                    />,
                    <IconButton
                      icon="plus"
                      intent="success"
                      onClick={() => {
                        this.handleOpenCreateCategoryModal({
                          parentId: rowInfo.node.id,
                        })
                      }}
                    />,
                  ],
                }
              }}
              onMoveNode={async (data) => {
                const categoryId = data.node.id
                const parent = data.nextParentNode
                const childrensOrder = (
                  (parent && parent.children) ||
                  data.treeData
                ).map((node) => node.id)
                const nextParentId =
                  (data.nextParentNode && data.nextParentNode.id) || null
                await this.moveCategory({
                  nextParentId,
                  id: categoryId,
                  childrenCategoryIds: childrensOrder,
                })
              }}
            />
          </Pane>
          <Dialog
            isShown={this.state.isConfirmDeleteModalOpen}
            title="Confirm delete category"
            confirmLabel="Delete"
            onCloseComplete={() => {
              this.setState({
                isConfirmDeleteModalOpen: false,
                categoryDeleteId: null,
              })
            }}
            isConfirmLoading={this.state.isLoadingDelete}
            onConfirm={this.handleRemoveCategory}
          >
            Are you sure you want to delete selected category?
          </Dialog>
          <Dialog
            isShown={this.state.isCreateCategoryModalOpen}
            title="Create category"
            confirmLabel="Create"
            isConfirmLoading={this.state.isLoadingCreate}
            onCloseComplete={() => {
              this.setState({
                isCreateCategoryModalOpen: false,
                parentId: null,
                title: '',
              })
            }}
            onConfirm={this.handleAddCategory}
          >
            <TextInputField
              label="Title"
              value={this.state.title || ''}
              onChange={({ target }) => {
                this.setState({
                  title: target.value,
                })
              }}
            />
            <Pane marginTop={majorScale(3)}>
              <Heading is="h3" marginBottom={majorScale(2)}>
                Translations
              </Heading>
              <Table>
                <Table.Head>
                  <Table.TextHeaderCell>Language</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Value</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                  {languages.map((language) => (
                    <Table.Row key={language.isoCode}>
                      <Table.TextCell>{language.title}</Table.TextCell>
                      <Table.Cell>
                        <TextInputField
                          label="Translation"
                          value={this.getTranslationValue(language.isoCode)}
                          onChange={(e) => {
                            this.updateTranslationValue(
                              language.isoCode,
                              e.target.value
                            )
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Pane>
          </Dialog>
          <Dialog
            isShown={this.state.isEditCategoryModalOpen}
            title="Edit category"
            confirmLabel="Edit"
            onCloseComplete={() => {
              this.setState({
                isEditCategoryModalOpen: false,
                categoryId: null,
                title: '',
              })
            }}
            isConfirmLoading={this.state.isLoadingEdit}
            onConfirm={this.handleEditCategory}
          >
            <TextInputField
              label="Title"
              value={this.state.title || ''}
              onChange={({ target }) => {
                this.setState({
                  title: target.value,
                })
              }}
            />
            <Pane marginTop={majorScale(3)}>
              <Heading is="h3" marginBottom={majorScale(2)}>
                Translations
              </Heading>
              <Table>
                <Table.Head>
                  <Table.TextHeaderCell>Language</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Value</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                  {languages.map((language) => (
                    <Table.Row key={language.isoCode}>
                      <Table.TextCell>{language.title}</Table.TextCell>
                      <Table.Cell>
                        <TextInputField
                          label="Translation"
                          value={this.getTranslationValue(language.isoCode)}
                          onChange={(e) => {
                            this.updateTranslationValue(
                              language.isoCode,
                              e.target.value
                            )
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Pane>
          </Dialog>
        </SideNavPage.Content>
      </React.Fragment>
    )
  }
}

export default Categories
