import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, FormField, TextInputField } from 'evergreen-ui'
import { withUser } from 'UserProvider'
import uploadRequest, { getIsFileSizeExceeded } from 'utils/uploadRequest'
import urql from 'utils/urql'
import Dropzone from 'components/Dropzone'

const SetupImportDialog = ({ close, user, isShown }) => {
  const [orderIdColumnPosition, setOrderIdColumnPosition] = useState(3)
  const [trackingIdColumnPosition, setTrackingIdColumnPosition] = useState(4)
  const [serialNumberColumnPosition, setSerialNumberColumnPosition] =
    useState(5)
  const [isCsvFileUploading, setCsvFileUploading] = useState(false)
  const [file, setFile] = useState(null)
  return (
    <Dialog
      onCloseComplete={() => {
        close()
      }}
      width={700}
      confirmLabel="Import"
      isShown={isShown}
      isConfirmLoading={isCsvFileUploading}
      hasCancel={false}
      preventBodyScrolling
      title="Setup Zasilkovna CSV upload"
      isConfirmDisabled={
        !file || !trackingIdColumnPosition || !orderIdColumnPosition
      }
      onConfirm={async () => {
        setCsvFileUploading(true)
        const result = await uploadRequest({
          accessToken: user.accessToken,
          body: {
            query: `
              mutation updateOrderTrackingNumber($input: UpdateOrderTrackingNumberInput!)  {
                updateOrderTrackingNumber(input: $input) {
                  status
                  providedOrderId
                  providedTrackingNumber
                  providedSerialNumber
                }
              }
            `,
            variables: {
              input: {
                file,
                orderIdColumnIndex: orderIdColumnPosition - 1,
                trackingIdColumnIndex: trackingIdColumnPosition - 1,
                serialNumberColumnIndex: serialNumberColumnPosition - 1,
              },
            },
          },
        })
        const { error } = result
        setCsvFileUploading(false)
        if (error) {
          urql.handleError(error)
        } else {
          close(result.updateOrderTrackingNumber)
        }
      }}
    >
      <TextInputField
        label="Order id position"
        description="Column position of order id in csv file from left"
        hint="Accepts only numbers"
        type="number"
        min={1}
        required
        value={orderIdColumnPosition}
        onChange={(e) => {
          setOrderIdColumnPosition(e.target.value)
        }}
      />
      <TextInputField
        label="Tracking id position"
        description="Column position of tracking id in csv file from left"
        hint="Accepts only numbers"
        required
        type="number"
        min={1}
        value={trackingIdColumnPosition}
        onChange={(e) => {
          setTrackingIdColumnPosition(e.target.value)
        }}
      />
      <TextInputField
        label="Serial number position"
        description="Column position of serial number in csv file from left"
        hint="Accepts only numbers"
        required
        type="number"
        min={1}
        value={serialNumberColumnPosition}
        onChange={(e) => {
          setSerialNumberColumnPosition(e.target.value)
        }}
      />
      <FormField
        label="Csv file"
        description="Csv file from zasilkovna.cz"
        isRequired
      >
        <Dropzone
          onDrop={(files) => {
            const inputFile = files[0]
            if (getIsFileSizeExceeded({ file: inputFile })) {
              setFile(inputFile)
            }
          }}
          accept="text/csv"
          dragInactiveSingleFileLabel={
            file
              ? file.name
              : 'Drag&drop a csv file here, or click to select file'
          }
        />
      </FormField>
    </Dialog>
  )
}

SetupImportDialog.propTypes = {
  close: PropTypes.func.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
  }).isRequired,
  isShown: PropTypes.bool.isRequired,
}

export default withUser(SetupImportDialog)
