import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { MANAGEMENT_PERSONALISATION_ROUTES } from 'constants/routes'
import Management from './routes'

const Personalisation = ({ match }) => (
  <Switch>
    <Route
      component={Management}
      path={`${match.url}/:tab(${Object.values(
        MANAGEMENT_PERSONALISATION_ROUTES
      ).join('|')})?`}
    />
  </Switch>
)

Personalisation.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default Personalisation
