import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { useMutation, useQuery } from 'urql'
import styled from 'styled-components/macro'
import { BackButton, Button, majorScale, toaster } from 'evergreen-ui'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import ROUTES from 'constants/routes'
import { DEAL_STATUSES } from 'constants/constants'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import { getPathToDealOfTheDayDetail } from '../../utils'
import DeleteDoDDialog from '../../components/DeleteDoDDialog'
import Form, { formatDataIntoInput } from '../../components/Form'
import withApproveDeclineMutations, {
  toastResultOfApproval,
} from '../../components/withAprroveDeclineMutations'
import Statistics from './Statistics'
import {
  pauseDealOfTheDayMutation,
  resumeDealOfTheDayMutation,
} from '../Management/queries'

const { DRAFT, APPROVAL_NEEDED, APPROVED, DECLINED, DELETED, LIVE, FINISHED } =
  DEAL_STATUSES

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const BUTTON_LEFT_MARGIN = `${majorScale(1)}px`
const StyledButton = styled(Button)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const StyledBackButton = styled(BackButton)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const getIsApprovalButtonDisplayed = (dealOfTheDay, user) =>
  dealOfTheDay.status === APPROVAL_NEEDED &&
  user.hasAction(ACTIONS.DEAL_OF_THE_DAY.APPROVE)

const getIsDeleteButtonDisplayed = (dealOfTheDay, user) =>
  ([DRAFT, APPROVAL_NEEDED, APPROVED, DECLINED].includes(dealOfTheDay.status) &&
    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.DELETE)) ||
  (dealOfTheDay.status === DELETED &&
    user.hasAction(ACTIONS.DEAL_OF_THE_DAY.DESTROY))

const EditDoD = ({
  match,
  history,
  user,
  executeApproveDealOfTheDayMutation,
  showDeclineDealOfTheDayDialog,
}) => {
  const id = parseInteger(match.params.id)
  const [dealOfTheDayQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query($id: Int!) {
        dealOfTheDay(id: $id) {
          isPaused
          publishedAtUtc
          id
          product {
            id
            title
            shortDescription
            longDescription
            translations {
              language {
                isoCode
                title
              }
              languageIsoCode
              title
              shortDescription
              longDescription
            }
            reviewScore
            size
            warehouseDeliveryTimeInDays
            productCondition {
              id
              title
            }
            mainImage {
              id
              url
              name
            }
            additionalImages {
              id
              url
              name
            }
            seller {
              id
              accountName
            }
            externalPid
            modelName
          }
          stockQuantity
          startPrice
          endPrice
          priceIncreaseIntervalInMinutes
          status
          competitors {
            id
            title
            price
            country {
              isoCode
              title
            }
          }
          defaultCompetitorId
          changelog {
            createdByUser {
              name
            }
            oldStatus
            newStatus
            message
            createdAtUtc
          }
          comment
        }
      }
    `,
    variables: {
      id,
    },
  })
  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateDealOfTheDayInput!) {
      createDealOfTheDay(input: $input) {
        id
      }
    }
  `)
  const [, executeResumeMutation] = useMutation(resumeDealOfTheDayMutation)
  const [, executePauseMutation] = useMutation(pauseDealOfTheDayMutation)
  const dealOfTheDay = dealOfTheDayQuery.data
    ? dealOfTheDayQuery.data.dealOfTheDay
    : null
  const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false)
  const isDodStatusEditable =
    dealOfTheDay &&
    (![LIVE, FINISHED].includes(dealOfTheDay.status) || dealOfTheDay.isPaused)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Edit Deal of the day</SideNavPage.Title>
        {dealOfTheDay && (
          <div>
            {LIVE === dealOfTheDay.status &&
              dealOfTheDay.isPaused &&
              user.hasAction(ACTIONS.DEAL_OF_THE_DAY.RESUME) && (
                <StyledButton
                  intent="success"
                  appearance="primary"
                  iconBefore="play"
                  onClick={async () => {
                    const { error } = await executeResumeMutation({
                      id: dealOfTheDay.id,
                    })
                    displayToast(error, 'Resumed')
                  }}
                >
                  RESUME
                </StyledButton>
              )}
            {LIVE === dealOfTheDay.status &&
              !dealOfTheDay.isPaused &&
              user.hasAction(ACTIONS.DEAL_OF_THE_DAY.PAUSE) && (
                <StyledButton
                  intent="warning"
                  appearance="primary"
                  iconBefore="pause"
                  onClick={async () => {
                    const { error } = await executePauseMutation({
                      id: dealOfTheDay.id,
                    })
                    displayToast(error, 'Paused')
                  }}
                >
                  PAUSE
                </StyledButton>
              )}
            {getIsDeleteButtonDisplayed(dealOfTheDay, user) && (
              <StyledButton
                intent="danger"
                appearance="primary"
                onClick={() => {
                  setDeleteDialogOpened(true)
                }}
              >
                {dealOfTheDay.status === DELETED ? 'Destroy' : 'Delete'}
              </StyledButton>
            )}
            {getIsApprovalButtonDisplayed(dealOfTheDay, user) && (
              <StyledButton
                intent="danger"
                onClick={async () => {
                  showDeclineDealOfTheDayDialog({
                    dealOfTheDayId: id,
                  })
                }}
              >
                Decline
              </StyledButton>
            )}
            {user.hasAction(ACTIONS.DEAL_OF_THE_DAY.COPY) && (
              <StyledButton
                onClick={async () => {
                  const { product, ...rest } = dealOfTheDay
                  const createResult = await executeCreateMutation({
                    input: formatDataIntoInput({
                      ...rest,
                      ...product,
                      title: product.title,
                      productConditionId:
                        product.productCondition && product.productCondition.id,
                      sellerId: product.seller && product.seller.id,
                      isDraft: true,
                      isCopy: true,
                      comment: dealOfTheDay.comment || undefined,
                    }),
                  })
                  if (createResult.error) {
                    urql.handleError(createResult.error)
                  } else {
                    toaster.success('Deal of the day created')
                    history.push(
                      getPathToDealOfTheDayDetail(
                        createResult.data.createDealOfTheDay.id
                      )
                    )
                  }
                }}
              >
                Copy
              </StyledButton>
            )}
            {getIsApprovalButtonDisplayed(dealOfTheDay, user) && (
              <StyledButton
                intent="success"
                appearance="primary"
                onClick={async () => {
                  const result = await executeApproveDealOfTheDayMutation({
                    id,
                  })
                  toastResultOfApproval(result)
                }}
              >
                Approve
              </StyledButton>
            )}
            <StyledBackButton onClick={() => history.goBack()} pl="0.5rem" />
          </div>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <FullScreenLoading isVisible={dealOfTheDayQuery.fetching} />
        {dealOfTheDay && (
          <React.Fragment>
            <DeleteDoDDialog
              itemToDelete={
                isDeleteDialogOpened
                  ? { id, product: dealOfTheDay.product }
                  : null
              }
              isDestroyDialog={dealOfTheDay.status === DELETED}
              onClose={() => {
                setDeleteDialogOpened(false)
              }}
              onSuccess={() => {
                if (
                  dealOfTheDay.status === DELETED ||
                  !user.hasAction(ACTIONS.DEAL_OF_THE_DAY.DESTROY)
                ) {
                  history.replace(`/${ROUTES.DEAL_OF_THE_DAYS}`)
                }
              }}
            />
            <Form
              key={dealOfTheDay.id}
              data={dealOfTheDay}
              isReadOnly={
                !isDodStatusEditable ||
                !user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE)
              }
              isCompetitorReadOnly={
                !isDodStatusEditable ||
                !user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_COMPETITOR)
              }
              isTranslationEditReadOnly={
                !isDodStatusEditable ||
                !user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE_TRANSLATIONS)
              }
              isTranslationAddReadOnly={
                !isDodStatusEditable ||
                !user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_TRANSLATIONS)
              }
            />
            {[LIVE, FINISHED].includes(dealOfTheDay.status) && (
              <Statistics
                id={String(dealOfTheDay.id)}
                publishedAtUtc={dealOfTheDay.publishedAtUtc}
              />
            )}
          </React.Fragment>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

EditDoD.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveDealOfTheDayMutation: PropTypes.func.isRequired,
  showDeclineDealOfTheDayDialog: PropTypes.func.isRequired,
}

export default compose(withApproveDeclineMutations, withUser)(EditDoD)
