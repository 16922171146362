import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { WISH_MANAGEMENT_ROUTES } from 'constants/routes'
import Management from './routes/Management'
import WishDetail from './routes/WishDetail'
import NewOffer from './routes/NewOffer'
import EditOffer from './routes/EditOffer'

const Wishes = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/:wishId/offers/:offerId`}
      component={EditOffer}
    />
    <Route path={`${match.url}/:wishId/offers/`} component={NewOffer} />
    <Route
      component={Management}
      exact
      path={`${match.url}/:tab(${Object.values(WISH_MANAGEMENT_ROUTES).join(
        '|'
      )})?`}
    />
    <Route path={`${match.url}/:id`} component={WishDetail} />
  </Switch>
)

Wishes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default Wishes
