import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, SelectMenu } from 'evergreen-ui'

const createMenuLabel = ({ selectedValues, startTruncationLimit, options }) => {
  const { length } = selectedValues
  let label = ''
  if (length !== 0) {
    if (length !== 0 && length < startTruncationLimit) {
      label = selectedValues
        .map((value) => options.find((option) => option.value === value).label)
        .join(', ')
    } else if (length >= startTruncationLimit) {
      label = `${length.toString()} selected...`
    }
  }
  return label
}

const MultiSelectMenu = ({
  title,
  placeholder,
  options,
  selectedValues,
  onChange,
  startTruncationLimit,
}) => {
  const [menuLabel, setMenuLabel] = useState(
    createMenuLabel({ selectedValues, startTruncationLimit, options })
  )
  return (
    <SelectMenu
      isMultiSelect
      title={title}
      options={options}
      selected={selectedValues}
      hasFilter={false}
      onSelect={(option) => {
        const newValues = [...selectedValues, option.value]
        setMenuLabel(
          createMenuLabel({
            selectedValues: newValues,
            startTruncationLimit,
            options,
          })
        )
        onChange(newValues)
      }}
      onDeselect={(option) => {
        const deselectedOptionIndex = selectedValues.indexOf(option.value)
        const newValues = selectedValues.filter(
          (_option, i) => i !== deselectedOptionIndex
        )
        setMenuLabel(
          createMenuLabel({
            selectedValues: newValues,
            startTruncationLimit,
            options,
          })
        )
        onChange(newValues)
      }}
    >
      <Button>{menuLabel || placeholder}</Button>
    </SelectMenu>
  )
}

MultiSelectMenu.defaultProps = {
  placeholder: 'Select multiple...',
  startTruncationLimit: 4,
}
MultiSelectMenu.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  startTruncationLimit: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MultiSelectMenu
