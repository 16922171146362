import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import startOfWeek from 'date-fns/start_of_week'
import endOfWeek from 'date-fns/end_of_week'
import startOfDay from 'date-fns/start_of_day'
import endOfDay from 'date-fns/end_of_day'
import request from 'utils/request'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'

const ThreadsContext = React.createContext({
  threads: {},
})

export const ThreadsConsumer = ThreadsContext.Consumer

export const useThreads = () => {
  const context = useContext(ThreadsContext)
  if (!context) {
    throw new Error('useThreads must be used inside the ThreadsProvider')
  }
  return context
}

const POOLING_TIMEOUT = 200000
const NUMBER_OF_THREADS_PER_LOAD = 20

const USER_THREADS_QUERY = `
  query userThreads($page: Int!, $limit: Int!, $filter: UserThreadsFilter ) {
    userThreads(
        pagination: {page: $page, limit: $limit}
        filter: $filter
      ) {
      unseenCount
      unseenWishesCount
      unseenWishOffersCount
      data {
        id
        createdAtUtc
        lastMessage {
          text
        }
        lastMessageAddedAtUtc
        lastUserSeenAtUtc
        order {
          id
        }
        ticket {
          id
        }
        messages {
          data {
            id
            attachmentName
          }
        }
        country {
          isoCode
          title
        }
        feedback {
          id
        }
        wish {
          id
        }
        user {
          id
          name
        }
        threadCategory {
          id
          name
          code
        }
        threadStatus {
          id
          name
          code
        }
      }
      paginationInfo {
        nextPage
      }
    }
  }
`

class ThreadsProvider extends React.Component {
  userThreadsTimeoutId = null

  state = {
    userThreads: [],
    paginationInfo: {},
    isFetching: true,
    selectedWithoutClosed: false,
    unseenCount: 0,
    unseenWishesCount: 0,
    unseenWishOffersCount: 0,
    selectedCountryIsoCodes: [],
    selectedThreadStatuses: [],
    selectedThreadCategories: [],
    selectedDateRange: [startOfWeek(new Date()), endOfWeek(new Date())],
  }

  async componentDidMount() {
    if (this.props.user.hasAction(ACTIONS.THREAD.PAGE)) {
      await this.fetchThreads({
        limit: NUMBER_OF_THREADS_PER_LOAD,
        filter: this.getFilter(),
      })
      this.setState({
        selectedCountryIsoCodes: this.getUserCountryIsoCodes(),
      })
      this.userThreadsTimeoutId = setTimeout(() => {
        this.fetchUpdatedThreads()
      }, POOLING_TIMEOUT)
    }
  }

  componentWillUnmount() {
    if (this.userThreadsTimeoutId) {
      clearTimeout(this.userThreadsTimeoutId)
    }
  }

  getFilter = () => {
    const {
      selectedCountryIsoCodes,
      selectedThreadStatuses,
      selectedThreadCategories,
      selectedWithoutClosed,
      selectedDateRange,
    } = this.state
    const [startDate, endDate] = selectedDateRange
    return {
      ...(selectedCountryIsoCodes && selectedCountryIsoCodes.length
        ? { countryIsoCodes: selectedCountryIsoCodes }
        : {}),
      ...(selectedThreadStatuses && selectedThreadStatuses.length
        ? { threadStatusCodes: selectedThreadStatuses }
        : {}),
      ...(selectedThreadCategories && selectedThreadCategories.length
        ? { threadCategoryCodes: selectedThreadCategories }
        : {}),
      ...(selectedWithoutClosed
        ? { threadsWithoutClosed: !!selectedWithoutClosed }
        : {}),
      ...(startDate ? { threadsStartDate: startDate } : {}),
      ...(endDate ? { threadsEndDate: endDate } : {}),
    }
  }

  fetchThreads = async ({
    page = 1,
    limit = NUMBER_OF_THREADS_PER_LOAD,
    filter = {},
  }) => {
    this.setState({
      isFetching: true,
    })
    const {
      userThreads: {
        data: userThreads,
        paginationInfo,
        unseenCount,
        unseenWishesCount,
        unseenWishOffersCount,
      },
    } = await request({
      isThrowErrorsDisabled: true,
      accessToken: this.props.accessToken,
      body: {
        query: USER_THREADS_QUERY,
        variables: {
          page,
          limit,
          filter,
        },
      },
    })
    this.setState((state) => ({
      unseenCount,
      userThreads:
        page === 1 ? userThreads : [...state.userThreads, ...userThreads],
      paginationInfo,
      unseenWishesCount,
      unseenWishOffersCount,
      isFetching: false,
    }))
  }

  fetchUpdatedThreads = async () => {
    try {
      const { userThreads } = this.state
      const {
        userThreads: {
          unseenCount,
          data,
          unseenWishesCount,
          unseenWishOffersCount,
        },
      } = await request({
        isThrowErrorsDisabled: true,
        accessToken: this.props.accessToken,
        body: {
          query: USER_THREADS_QUERY,
          variables: {
            page: 1,
            limit:
              Math.ceil(userThreads.length / NUMBER_OF_THREADS_PER_LOAD) *
              NUMBER_OF_THREADS_PER_LOAD,
            filter: this.getFilter(),
          },
        },
      })
      this.setState({
        unseenCount,
        userThreads: data,
        unseenWishesCount,
        unseenWishOffersCount,
      })
      this.userThreadsTimeoutId = setTimeout(
        this.fetchUpdatedThreads,
        POOLING_TIMEOUT
      )
    } catch (error) {
      // do nothing
    }
  }

  handleLoadMore = () => {
    const {
      paginationInfo: { nextPage },
      userThreads,
      isFetching,
    } = this.state
    if (userThreads.length !== 0 && !isFetching && nextPage) {
      this.fetchThreads({
        page: nextPage,
        filter: this.getFilter(),
      })
    }
  }

  handleRefetch = async () => {
    if (this.userThreadsTimeoutId) {
      clearTimeout(this.userThreadsTimeoutId)
    }
    await this.fetchUpdatedThreads()
  }

  getUserCountryIsoCodes = () => {
    const { user } = this.props
    return user.data.countries
      ? user.data.countries.map(({ isoCode }) => isoCode)
      : []
  }

  setSelectedCountryIsoCodes = async (countryIsoCodes) => {
    this.setState({ selectedCountryIsoCodes: countryIsoCodes })
    await this.fetchThreads({
      filter: this.getFilter(),
    })
    clearTimeout(this.userThreadsTimeoutId)
    await this.fetchUpdatedThreads()
  }

  setSelectedThreadStatuses = async (threadStatusCodes) => {
    this.setState({ selectedThreadStatuses: threadStatusCodes })
    await this.fetchThreads({
      filter: this.getFilter(),
    })
    clearTimeout(this.userThreadsTimeoutId)
    await this.fetchUpdatedThreads()
  }

  setSelectedThreadCategories = async (threadCategoryCodes) => {
    this.setState({ selectedThreadCategories: threadCategoryCodes })
    await this.fetchThreads({
      filter: this.getFilter(),
    })
    clearTimeout(this.userThreadsTimeoutId)
    await this.fetchUpdatedThreads()
  }

  setSelectedWithoutClosed = async (selectedWithoutClosed) => {
    this.setState({ selectedWithoutClosed })
    await this.fetchThreads({
      filter: this.getFilter(),
    })
    clearTimeout(this.userThreadsTimeoutId)
    await this.fetchUpdatedThreads()
  }

  setSelectedDateRange = async (selectedDateRange) => {
    const [startDate, endDate] = selectedDateRange
    this.setState({ selectedDateRange })
    if (startDate && endDate) {
      const newSelectedDateRange = [startOfDay(startDate), endOfDay(endDate)]
      this.setState({ selectedDateRange: newSelectedDateRange })
      await this.fetchThreads({
        filter: this.getFilter(),
      })
      clearTimeout(this.userThreadsTimeoutId)
      await this.fetchUpdatedThreads()
    }
  }

  render() {
    const { children } = this.props
    const {
      unseenCount,
      unseenWishesCount,
      unseenWishOffersCount,
      isFetching,
      userThreads,
      paginationInfo,
      selectedCountryIsoCodes,
      selectedThreadStatuses,
      selectedWithoutClosed,
      selectedThreadCategories,
      selectedDateRange,
    } = this.state
    return (
      <ThreadsContext.Provider
        value={{
          refetch: this.handleRefetch,
          unseenCount,
          unseenWishesCount,
          unseenWishOffersCount,
          threads: {
            isFetching,
            arrayList: userThreads,
            onLoadMore: this.handleLoadMore,
            nextPage: paginationInfo && paginationInfo.nextPage,
            filter: {
              selectedCountryIsoCodes:
                selectedCountryIsoCodes || this.getUserCountryIsoCodes(),
              selectedThreadStatuses,
              selectedWithoutClosed,
              selectedThreadCategories,
              selectedDateRange,
              setSelectedCountryIsoCodes: this.setSelectedCountryIsoCodes,
              setSelectedThreadStatuses: this.setSelectedThreadStatuses,
              setSelectedThreadCategories: this.setSelectedThreadCategories,
              setSelectedWithoutClosed: this.setSelectedWithoutClosed,
              setSelectedDateRange: this.setSelectedDateRange,
            },
          },
        }}
      >
        {children}
      </ThreadsContext.Provider>
    )
  }
}

ThreadsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  accessToken: PropTypes.string.isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape({
      countries: PropTypes.arrayOf(
        PropTypes.shape({
          isoCode: PropTypes.string.isRequired,
        })
      ),
    }),
    hasAction: PropTypes.func.isRequired,
  }),
}

export default withUser(ThreadsProvider)
