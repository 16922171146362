export const productImagesQueryString = `
  query($page: Int!) {
    productImages(pagination: { page: $page }) {
      paginationInfo {
        totalCount
        firstPage
        lastPage
        currentPage
        nextPage
        previousPage
      }
      data {
        id
        name
        url
      }
    }
  }
`
