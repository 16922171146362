import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  BackButton,
  Dialog,
  TextInputField,
  TextareaField,
  SelectField,
  Spinner,
  Button,
  toaster,
} from 'evergreen-ui'
import { useQuery, useMutation } from 'urql'
import urql from 'utils/urql'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import SellerReturnAddresses from 'components/SellerReturnAddresses'
import ACTIONS from 'constants/actions'
import { parseInteger } from 'utils/numbers'

const returnAddressFields = `{
  id
  seller {
    id
    companyName
  }
  country {
    title
    isoCode
  }
  houseNumber
  street
  city
  zipCode
  phoneNumber
}`

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const ReturnAddresses = ({ history, match, user }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const [houseNumber, setHouseNumber] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryIsoCode, setCountryIsoCode] = useState('')

  const [countriesQuery] = useQuery({
    query: `
      query($sellerId: Int!) {
        countryReturnAddress(sellerId: $sellerId) {
          isoCode
          title
        }
      }
    `,
    variables: {
      sellerId: parseInteger(match.params.id),
    },
  })

  const [sellerQuery] = useQuery({
    query: `
        query($id: ID!){
            seller(id: $id) {
                id
            }
        }
    `,
    variables: {
      id: match.params.id,
    },
  })

  const [createMutation, executeCreateMutation] = useMutation(`
    mutation($input: CreateReturnAddressInput!) {
      createReturnAddress(input: $input) ${returnAddressFields}
    }
  `)

  const seller = (sellerQuery.data && sellerQuery.data.seller) || false
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>
          Return addresses of seller: {seller.id}
        </SideNavPage.Title>
        <div>
          {user.hasAction(ACTIONS.RETURN_ADDRESS.CREATE) && (
            <Button
              iconBefore="add"
              appearance="primary"
              marginRight={3}
              intent="success"
              onClick={() => {
                setHouseNumber('')
                setStreet('')
                setCity('')
                setZipCode('')
                setPhoneNumber('')
                setCountryIsoCode('')
                setIsCreateModalOpen(true)
              }}
            >
              Add new
            </Button>
          )}
          <BackButton onClick={() => history.goBack()} />
        </div>
      </SideNavPage.Header>
      <SideNavPage.Content>
        {sellerQuery.fetching && (
          <Spinner marginX="auto" delay={200} size={80} />
        )}
        {seller && <SellerReturnAddresses sellerId={match.params.id} />}
      </SideNavPage.Content>

      <Dialog
        isShown={isCreateModalOpen}
        title="Create Return Address"
        hasCancel={false}
        confirmLabel="Create"
        isConfirmLoading={createMutation.fetching}
        onCloseComplete={() => {
          setIsCreateModalOpen(false)
        }}
        onConfirm={async (close) => {
          const input = {
            sellerId: parseInteger(match.params.id),
            countryIsoCode,
            houseNumber,
            street,
            city,
            zipCode,
            phoneNumber,
          }
          if (isCreateModalOpen) {
            const { error } = await executeCreateMutation({
              input,
            })

            displayToastOnSave(error)

            if (!error) {
              close()
            }
          }
        }}
      >
        <SelectField
          label="Country"
          required
          value={countryIsoCode}
          onChange={(e) => {
            setCountryIsoCode(e.target.value)
          }}
        >
          <option value="">---Select Country---</option>
          {countriesQuery.data &&
            countriesQuery.data.countryReturnAddress.map((item) => (
              <option key={item.isoCode} value={item.isoCode}>
                {item.title}
              </option>
            ))}
        </SelectField>
        <TextInputField
          label="House Number"
          required
          value={houseNumber}
          onChange={(e) => {
            setHouseNumber(e.target.value)
          }}
        />
        <TextareaField
          label="Street"
          required
          value={street}
          onChange={(e) => {
            setStreet(e.target.value)
          }}
        />
        <TextInputField
          label="City"
          required
          value={city}
          onChange={(e) => {
            setCity(e.target.value)
          }}
        />
        <TextInputField
          label="Zip Code"
          required
          value={zipCode}
          onChange={(e) => {
            setZipCode(e.target.value)
          }}
        />
        <TextInputField
          label="Phone Number"
          required
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value)
          }}
        />
      </Dialog>
    </React.Fragment>
  )
}

ReturnAddresses.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(ReturnAddresses)
