import React, { useState } from 'react'
import { useMutation } from 'urql'
import {
  toaster,
  Pane,
  Label,
  Dialog,
  Textarea,
  majorScale,
} from 'evergreen-ui'
import urql from 'utils/urql'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'

const handleResponseError = (response) => {
  if (response.error) {
    urql.handleError(response.error)
  }
}

export const toastResultOfApproval = (result) => {
  handleResponseError(result)
  if (result.data) {
    toaster.success('Approved')
  }
}

export const toastResultOfDecline = (result) => {
  handleResponseError(result)
  if (result.data) {
    toaster.success('Declined')
  }
}

export const toastResultOfPublish = (result) => {
  handleResponseError(result)
  if (!result.error) {
    toaster.success('Published')
  }
}

const withApproveDeclineMutations = (Comp) => (props) => {
  const [id, setId] = useState('')
  const [message, setMessage] = useState('')
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false)
  const [approveRes, executeApproveDealOfTheWeekMutation] = useMutation(`
    mutation approveDealOfTheWeek($id: Int!) {
      approveDealOfTheWeek(id: $id) {
        id
      }
    }
  `)
  const [declineRes, executeDeclineDealOfTheWeekMutation] = useMutation(`
    mutation declineDealOfTheWeek($input: DeclineDealOfTheWeekInput!) {
      declineDealOfTheWeek(input: $input) {
        id
      }
    }
  `)
  const [publishRes, executePublishDealOfTheWeekMutation] = useMutation(`
    mutation publishDealOfTheWeek($id: Int!) {
      publishDealOfTheWeek(id: $id) {
        id
      }
    }
  `)
  const showDeclineDealOfTheWeekDialog = ({ dealOfTheWeekId }) => {
    setId(dealOfTheWeekId)
    setIsDeclineModalOpen(true)
  }
  return (
    <React.Fragment>
      <FullScreenLoading
        isVisible={
          approveRes.fetching || declineRes.fetching || publishRes.fetching
        }
      />
      <Dialog
        isShown={isDeclineModalOpen}
        title="Decline Deal of the Week"
        confirmLabel="Decline"
        isConfirmLoading={declineRes.fetching}
        onCloseComplete={() => {
          setIsDeclineModalOpen(false)
        }}
        isConfirmDisabled={message === '' || declineRes.fetching}
        onConfirm={async (close) => {
          const result = await executeDeclineDealOfTheWeekMutation({
            input: {
              id,
              message,
            },
          })
          toastResultOfDecline(result)
          if (!result.error) {
            setId('')
            setMessage('')
            close()
          }
        }}
      >
        <Pane>
          <Label
            htmlFor="textarea-message"
            marginBottom={majorScale(1)}
            display="block"
          >
            Please provide reason, why is DoW Declined:
          </Label>
          <Textarea
            id="textarea-message"
            value={message || ''}
            onChange={({ target }) => {
              setMessage(target.value)
            }}
          />
        </Pane>
      </Dialog>
      <Comp
        executeApproveDealOfTheWeekMutation={
          executeApproveDealOfTheWeekMutation
        }
        showDeclineDealOfTheWeekDialog={showDeclineDealOfTheWeekDialog}
        executePublishDealOfTheWeekMutation={
          executePublishDealOfTheWeekMutation
        }
        {...props}
      />
    </React.Fragment>
  )
}

export default withApproveDeclineMutations
