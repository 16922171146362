import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Button, IconButton, Label, Pane } from 'evergreen-ui'
import ImagesGrid from '../ImagesGrid'
import UploadImagesDialog from './UploadImagesDialog'
import { getArrayWithoutItemOnIndex } from '../../utils/array'

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`

const AdditionalImages = ({
  additionalImages,
  setAdditionalImages,
  isDisabled,
}) => {
  const [isUploadDialogShown, setUploadDialogShown] = useState(false)

  return (
    <div>
      <Label marginBottom="8px">Attachments</Label>
      {!isDisabled && (
        <Pane marginBottom="8px">
          <Button
            marginRight="8px"
            onClick={() => {
              setUploadDialogShown(true)
            }}
          >
            Upload attachments
          </Button>
        </Pane>
      )}
      <UploadImagesDialog
        isShown={isUploadDialogShown}
        images={additionalImages}
        onClose={() => {
          setUploadDialogShown(false)
        }}
        onSuccess={(images) => {
          setUploadDialogShown(false)
          setAdditionalImages([...additionalImages, ...images])
        }}
      />
      {additionalImages.length > 0 && (
        <ImagesGrid>
          {additionalImages.map((image, index) => (
            <Pane width="100%" position="relative" key={image.id}>
              <ImagesGrid.Item src={image.url} alt={image.name} />
              {!isDisabled && (
                <StyledIconButton
                  intent="danger"
                  icon="trash"
                  onClick={() => {
                    setAdditionalImages(
                      getArrayWithoutItemOnIndex(additionalImages, index)
                    )
                  }}
                />
              )}
            </Pane>
          ))}
        </ImagesGrid>
      )}
    </div>
  )
}

AdditionalImages.propTypes = {
  additionalImages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  setAdditionalImages: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default AdditionalImages
