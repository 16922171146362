import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Pane, Text } from 'evergreen-ui'
import { pickSpaceProps } from 'utils/styled-system'

const NoData = ({ children, size, ...rest }) => (
  <Pane
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    {...pickSpaceProps(rest)}
  >
    <Icon size={size} marginBottom={size / 3} icon="info-sign" color="info" />
    <Text>{children}</Text>
  </Pane>
)

NoData.defaultProps = {
  children: 'No data here',
  size: 60,
}
NoData.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
}

export default NoData
