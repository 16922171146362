import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'urql'
import { withUser } from 'UserProvider'
import { getSpaceInPx } from 'utils/styled-system'
import { DEAL_STATUSES } from 'constants/constants'
import ACTIONS from 'constants/actions'
import { parseInteger } from 'utils/numbers'
import ThemeDealList from '../components/ThemeDealList'
import ThemeDealItem from '../components/ThemeDealItem'
import { THEME_DEAL_ITEM_Y_MARGIN } from '../constants'
import { getPathToThemeDealDetail } from '../../../utils'
import DeleteThemeDealDialog from '../../../components/DeleteThemeDealDialog'
import { getThemeDealsQuery } from '../queries'

const { DECLINED, DRAFT } = DEAL_STATUSES

const WorkInProgress = ({ history, user, match }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [themeDealsQuery, executeThemeDealsQuery] = useQuery(
    getThemeDealsQuery({
      status: [DRAFT, DECLINED],
      pagination: { page: currentPage, limit: 20 },
      themeOfTheMonthId: parseInteger(match.params.themeOfTheMonthId),
    })
  )
  return (
    <Pane>
      {itemToSoftDelete && (
        <DeleteThemeDealDialog
          itemToDelete={itemToSoftDelete}
          onSuccess={() => {
            executeThemeDealsQuery({ requestPolicy: 'network-only' })
          }}
          onClose={() => {
            setItemToSoftDelete(null)
          }}
        />
      )}
      <ThemeDealList
        themeDealsQuery={themeDealsQuery}
        setCurrentPage={setCurrentPage}
      >
        {({ themeDeals }) =>
          themeDeals.map((item) => (
            <ThemeDealItem
              key={item.id}
              {...item}
              my={getSpaceInPx(THEME_DEAL_ITEM_Y_MARGIN)}
              onDetailClick={() => {
                history.push(
                  getPathToThemeDealDetail({
                    themeDealId: item.id,
                    themeOfTheMonthId: match.params.themeOfTheMonthId,
                  })
                )
              }}
              onDeleteButtonClicked={() => {
                setItemToSoftDelete(item)
              }}
              isWithDeleteButton={user.hasAction(ACTIONS.THEME_DEAL.DELETE)}
              isWithEditButton={
                user.hasAction(ACTIONS.THEME_DEAL.UPDATE) ||
                user.hasAction(ACTIONS.THEME_DEAL.UPDATE_TRANSLATIONS) ||
                user.hasAction(ACTIONS.THEME_DEAL.ADD_TRANSLATIONS) ||
                user.hasAction(ACTIONS.THEME_DEAL.ADD_COMPETITOR)
              }
            />
          ))
        }
      </ThemeDealList>
    </Pane>
  )
}

WorkInProgress.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      themeOfTheMonthId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default compose(withRouter, withUser)(WorkInProgress)
