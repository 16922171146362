import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'urql'
// Editor
import { EditorState, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import {
  Button,
  TextInputField,
  Label,
  Pane,
  Textarea,
  majorScale,
  toaster,
} from 'evergreen-ui'
import { withRouter } from 'react-router-dom'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import ROUTES from 'constants/routes'
import Translations from 'components/Translations'
import EditorDeleteCustomOption from 'components/EditorDeleteCustomOption'
import { convertEditorStateToRawJson } from 'utils/convertToRaw'

export const formatDataIntoInput = ({
  title,
  shortDescription,
  longDescription,
  translations,
  price,
  termsAndConditions,
}) => ({
  title,
  shortDescription,
  longDescription,
  termsAndConditions,
  translations:
    translations &&
    translations.map(({ __typename, language, ...rest }) => ({
      ...rest,
      languageIsoCode: language.isoCode,
    })),
  price: price && parseFloat(price),
})

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const Form = ({ data = {}, isReadOnly = false, history }) => {
  const [title, setTitle] = useState(data.title || undefined)
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )

  const [shortDescription, setShortDescription] = useState(
    data.shortDescription || undefined
  )
  const [longDescription, setLongDescription] = useState(
    data.longDescription || undefined
  )
  const [translations, setTranslations] = useState(data.translations || [])
  const [price, setPrice] = useState(data.price || '0')

  useEffect(() => {
    if (data.termsAndConditions) {
      setEditorState(() =>
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(data.termsAndConditions))
        )
      )
    }
  }, [data.termsAndConditions])

  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateAdditionalServiceInput!) {
      createAdditionalService(input: $input) {
        id
      }
    }
  `)
  const [, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateAdditionalServiceInput!) {
      updateAdditionalService(input: $input) {
        id
      }
    }
  `)
  const dataToSave = {
    title,
    shortDescription,
    longDescription,
    translations,
    price,
    termsAndConditions: convertEditorStateToRawJson(editorState),
  }
  return (
    <Pane>
      <Pane maxWidth={780}>
        <TextInputField
          label="Title"
          disabled={isReadOnly}
          value={title || ''}
          onChange={({ target }) => {
            setTitle(target.value === '' ? undefined : target.value)
          }}
        />
        <Pane>
          <Label
            htmlFor="textarea-short-description"
            marginBottom={majorScale(1)}
            display="block"
          >
            Short Description
          </Label>
          <Textarea
            id="textarea-short-description"
            disabled={isReadOnly}
            value={shortDescription || ''}
            onChange={({ target }) => {
              setShortDescription(
                target.value === '' ? undefined : target.value
              )
            }}
          />
        </Pane>
        <Pane>
          <Label
            htmlFor="textarea-long-description"
            marginBottom={majorScale(1)}
            display="block"
          >
            Long Description
          </Label>
          <Textarea
            id="textarea-long-description"
            disabled={isReadOnly}
            value={longDescription || ''}
            onChange={({ target }) => {
              setLongDescription(target.value === '' ? undefined : target.value)
            }}
          />
        </Pane>
        <TextInputField
          label="Price"
          type="number"
          disabled={isReadOnly}
          value={price || '0'}
          onChange={({ target }) => {
            setPrice(target.value === '' ? '0' : target.value)
          }}
        />

        <Pane marginBottom={20}>
          <p>Terms and Conditions</p>
          <Editor
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
            }}
            editorState={editorState}
            editorStyle={{
              border: '1px solid #e2e2e2',
              height: 300,
            }}
            onEditorStateChange={setEditorState}
            toolbarCustomButtons={[<EditorDeleteCustomOption />]}
          />
        </Pane>

        <Translations
          isAddDisabled={isReadOnly}
          isEditDisabled={isReadOnly}
          translations={translations}
          handleSaveTranslations={setTranslations}
        />
        <Pane marginTop={majorScale(2)} justifyContent="center" display="flex">
          <Pane>
            <Button
              intent="success"
              marginRight={majorScale(2)}
              onClick={async () => {
                if (data.id) {
                  const { error } = await executeUpdateMutation({
                    input: {
                      id: parseInteger(data.id),
                      ...formatDataIntoInput(dataToSave),
                    },
                  })
                  displayToastOnSave(error)
                } else {
                  const result = await executeCreateMutation({
                    input: formatDataIntoInput(dataToSave),
                  })
                  displayToastOnSave(result.error)
                  if (!result.error) {
                    history.replace(
                      `/${ROUTES.ADDITIONAL_SERVICES}/edit/${result.data.createAdditionalService.id}`
                    )
                  }
                }
              }}
            >
              Save
            </Button>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

Form.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    shortDescription: PropTypes.string,
    longDescription: PropTypes.string,
    translations: PropTypes.array,
    price: PropTypes.number,
  }),
  isReadOnly: PropTypes.bool,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(Form)
