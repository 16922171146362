import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import format from 'date-fns/format'
import { Table, Spinner, BackButton } from 'evergreen-ui'
import { TIME_FORMAT } from 'constants/constants'
import SideNavPage from 'sharedComponents/SideNavPage'

const TABLE_MIN_HEIGHT = 500
let isInitialMount = true

const TicketDetail = (props) => {
  const {
    history,
    match: {
      params: { ticketId },
    },
  } = props
  const [ticketQuery, executeUserTicketQuery] = useQuery({
    query: `
    query userGetTicket{
      userTicket(id: ${ticketId}){
        id
        createdAtUtc
        customer{
          fullName
          email
        }
        description
      }
    }
    `,
  })
  if (isInitialMount) {
    executeUserTicketQuery()
    isInitialMount = false
  }
  const ticket = (ticketQuery.data && ticketQuery.data.userTicket) || false
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Ticket detail: </SideNavPage.Title>
        <BackButton onClick={() => history.goBack()} />
      </SideNavPage.Header>
      <SideNavPage.Content>
        {ticketQuery.fetching && (
          <Spinner
            marginX="auto"
            marginY={TABLE_MIN_HEIGHT / 2}
            delay={200}
            size={80}
          />
        )}
        {ticket && (
          <Table border>
            <Table.Body>
              <Table.Row>
                <Table.TextHeaderCell>Id</Table.TextHeaderCell>
                <Table.TextCell>{ticket.id}</Table.TextCell>
              </Table.Row>
              <Table.Row>
                <Table.TextHeaderCell>Full Name</Table.TextHeaderCell>
                <Table.TextCell>{ticket.customer.fullName}</Table.TextCell>
              </Table.Row>
              <Table.Row>
                <Table.TextHeaderCell>Email</Table.TextHeaderCell>
                <Table.TextCell>{ticket.customer.email}</Table.TextCell>
              </Table.Row>
              <Table.Row>
                <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
                <Table.TextCell>
                  {format(ticket.createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                </Table.TextCell>
              </Table.Row>
              <Table.Row height="auto" minHeight="48px">
                <Table.TextHeaderCell>Description</Table.TextHeaderCell>
                <Table.TextCell>
                  <span>{ticket.description}</span>
                </Table.TextCell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

TicketDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      ticketId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
}

export default TicketDetail
