import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import { Table, Spinner, Pane, Button } from 'evergreen-ui'
import format from 'date-fns/format'
import urql from 'utils/urql'
import ROUTES from 'constants/routes'
import { TIME_FORMAT, PLATFORMS } from 'constants/constants'
import SearchInput from 'sharedComponents/SearchInput'
import SideNavPage from 'sharedComponents/SideNavPage'
import Pagination from 'components/Pagination'
import AndroidIcon from 'components/AndroidIcon'
import AppleIcon from 'components/AppleIcon'
import { withUser } from 'UserProvider'
import ACTIONS from 'constants/actions'
import ExportCsvDialogOpen from './ExportCsvDialogOpen'

const TABLE_MIN_HEIGHT = 500

const Customers = ({ history, user }) => {
  const [isExportCsvDialogOpen, setIsExportCsvDialogOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  let paginationInfoQuery = { page: currentPage, limit: 20 }
  if (searchValue) {
    paginationInfoQuery = { page: 1, limit: -1 }
  }
  const [customersQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query customers($pagination: PaginationInput, $filter: CustomerFilterInput) {
        customers(pagination: $pagination, filter: $filter) {
          paginationInfo {
            totalCount
            firstPage
            lastPage
            currentPage
            nextPage
            previousPage
          }
          data {
            id
            email
            fullName
            createdAtUtc
            isEmailNotificationEnabled
            devices {
              id
              systemName
            }
            country {
              title
            }
            language {
              title
            }
          }
        }
      }
    `,
    variables: {
      pagination: paginationInfoQuery,
      filter: {
        search: searchValue,
      },
    },
  })
  useEffect(() => {
    if (customersQuery.error) {
      urql.handleError(customersQuery.error)
    }
  }, [customersQuery.error])
  const paginationInfo =
    customersQuery.data && customersQuery.data.customers.paginationInfo
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Customers</SideNavPage.Title>
        <Pane>
          {user.hasAction(ACTIONS.CUSTOMER.EXPORT_CSV) && (
            <Button
              appearance="primary"
              intent="success"
              onClick={() => {
                setIsExportCsvDialogOpen(true)
              }}
            >
              Export to CSV
            </Button>
          )}
        </Pane>
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Pane display="flex" justifyContent="flex-center" marginBottom={20}>
          <SearchInput
            label="Search"
            onChange={setSearchValue}
            values={searchValue}
          />
        </Pane>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
              Id
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Email</Table.TextHeaderCell>
            <Table.TextHeaderCell>OS</Table.TextHeaderCell>
            <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body minHeight={TABLE_MIN_HEIGHT}>
            {customersQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                size={80}
              />
            )}
            {!customersQuery.fetching &&
              customersQuery.data &&
              customersQuery.data.customers.data.map(
                ({ id, email, createdAtUtc, devices }) => (
                  <Table.Row
                    key={id}
                    isSelectable={user.hasAction(ACTIONS.CUSTOMER.DETAIL)}
                    onSelect={() => history.push(`${ROUTES.CUSTOMERS}/${id}`)}
                  >
                    <Table.TextCell
                      isNumber
                      flexBasis={100}
                      flexShrink={0}
                      flexGrow={0}
                    >
                      {id}
                    </Table.TextCell>
                    <Table.TextCell>{email}</Table.TextCell>
                    <Table.Cell>
                      {devices
                        .map(({ systemName }) => systemName)
                        .includes(PLATFORMS.IOS) && (
                        <Pane width="20px" height="20px">
                          <AppleIcon />
                        </Pane>
                      )}
                      {devices
                        .map(({ systemName }) => systemName)
                        .includes(PLATFORMS.ANDROID) && (
                        <Pane width="20px" height="20px">
                          <AndroidIcon />
                        </Pane>
                      )}
                    </Table.Cell>
                    <Table.TextCell>
                      {format(createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                    </Table.TextCell>
                  </Table.Row>
                )
              )}
          </Table.Body>
        </Table>
        {!searchValue && paginationInfo && (
          <Pagination
            {...paginationInfo}
            onPageChange={(page) => {
              setCurrentPage(page)
            }}
          />
        )}
        <ExportCsvDialogOpen
          isShown={isExportCsvDialogOpen}
          onCloseComplete={() => setIsExportCsvDialogOpen(false)}
        />
      </SideNavPage.Content>
    </React.Fragment>
  )
}

Customers.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Customers)
