import React, { useState } from 'react'
import { useQuery } from 'urql'
import { FEEDBACK_TYPES } from 'constants/constants'
import { getFeedbacksQuery } from '../queries'
import FeedbackList from '../components/FeedbackList'

const ProductFeedback = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [feedbacksQuery] = useQuery(
    getFeedbacksQuery({
      pagination: { page: currentPage, limit: 20 },
      filter: {
        types: [FEEDBACK_TYPES.PRODUCT_FEEDBACK],
        isArchived: false,
      },
    })
  )
  return (
    <FeedbackList
      feedbacksQuery={feedbacksQuery}
      setCurrentPage={setCurrentPage}
    />
  )
}

export default ProductFeedback
