import React from 'react'
import PropTypes from 'prop-types'
import { Table, Spinner, Button } from 'evergreen-ui'

const TABLE_MIN_HEIGHT = 500
const ACTIONS_WIDTH = 250

const DataGrid = ({
  sellers,
  isFetching,
  handleEditSellerClick,
  handleDeleteSellerClick,
  handleReturnAddressClick,
  isWithEditButton,
  isWithDeleteButton,
  isSelectable,
  onRowClick,
}) => (
  <Table border>
    <Table.Head height="auto" paddingY={12}>
      <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
        Account Id
      </Table.TextHeaderCell>
      <Table.TextHeaderCell>Account Name</Table.TextHeaderCell>
      <Table.TextHeaderCell>Company Name</Table.TextHeaderCell>
      <Table.TextHeaderCell>VAT group</Table.TextHeaderCell>
      <Table.TextHeaderCell>Country</Table.TextHeaderCell>
      <Table.TextHeaderCell>Email</Table.TextHeaderCell>
      <Table.TextHeaderCell>Phone number</Table.TextHeaderCell>
      <Table.TextHeaderCell>Responsible Person</Table.TextHeaderCell>
      <Table.TextHeaderCell>GBC PDF</Table.TextHeaderCell>
      <Table.TextHeaderCell>Active</Table.TextHeaderCell>
      <Table.TextHeaderCell minWidth={ACTIONS_WIDTH} />
    </Table.Head>
    <Table.Body>
      {isFetching && (
        <Spinner
          marginX="auto"
          marginY={TABLE_MIN_HEIGHT / 2}
          delay={200}
          size={80}
        />
      )}
      {sellers.map((seller) => (
        <Table.Row
          height="auto"
          minHeight="45px"
          key={seller.id}
          isSelectable={isSelectable}
          onSelect={() => onRowClick(seller)}
        >
          <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
            {seller.id}
          </Table.TextCell>
          <Table.TextCell>{seller.accountName}</Table.TextCell>
          <Table.TextCell>{seller.companyName}</Table.TextCell>
          <Table.TextCell>{seller.vatGroup}</Table.TextCell>
          <Table.TextCell>{seller.country.title}</Table.TextCell>
          <Table.TextCell>{seller.email}</Table.TextCell>
          <Table.TextCell>{seller.phoneNumber}</Table.TextCell>
          <Table.TextCell>
            {`${seller.responsiblePersonFirstName} ${seller.responsiblePersonLastName}`}
          </Table.TextCell>
          <Table.TextCell>
            <Button
              is="a"
              marginRight={12}
              iconBefore="download"
              href={seller.gbcPdfUrl}
              target="_blank"
            >
              Download
            </Button>
          </Table.TextCell>
          <Table.TextCell>{seller.isActive ? 'Yes' : 'No'}</Table.TextCell>
          <Table.Cell
            minWidth={ACTIONS_WIDTH}
            justifyContent="center"
            style={{ flexWrap: 'wrap', wordWrap: 'break-word' }}
          >
            <Button
              marginRight={12}
              marginTop={3}
              marginBottom={3}
              onClick={(e) => {
                e.stopPropagation()
                handleReturnAddressClick(seller)
              }}
            >
              Return Address
            </Button>
            {isWithEditButton && (
              <Button
                marginRight={12}
                marginTop={3}
                marginBottom={3}
                iconBefore="edit"
                onClick={(e) => {
                  e.stopPropagation()
                  handleEditSellerClick(seller)
                }}
              >
                Edit
              </Button>
            )}
            {isWithDeleteButton && (
              <Button
                marginRight={12}
                marginTop={3}
                marginBottom={3}
                iconBefore="trash"
                intent="danger"
                onClick={(e) => {
                  e.stopPropagation()
                  handleDeleteSellerClick(seller)
                }}
              >
                Delete
              </Button>
            )}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

DataGrid.propTypes = {
  handleDeleteSellerClick: PropTypes.func.isRequired,
  handleEditSellerClick: PropTypes.func.isRequired,
  handleReturnAddressClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  sellers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.integer,
      accountName: PropTypes.string,
      companyName: PropTypes.string,
      country: PropTypes.shape({
        title: PropTypes.string,
      }),
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      responsiblePersonFirstName: PropTypes.string,
      responsiblePersonLastName: PropTypes.string,
      isActive: PropTypes.bool,
    })
  ),
  isWithEditButton: PropTypes.bool,
  isWithDeleteButton: PropTypes.bool,
  isSelectable: PropTypes.bool.isRequired,
  onRowClick: PropTypes.func.isRequired,
}
export default DataGrid
