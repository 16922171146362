import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import { withUser } from 'UserProvider'
import {
  Table,
  Spinner,
  Button,
  Dialog,
  TextInputField,
  Pane,
  Heading,
  majorScale,
  SelectField,
} from 'evergreen-ui'
import Pagination from 'components/Pagination'
import find from 'lodash.find'
import urql from 'utils/urql'
import SideNavPage from 'sharedComponents/SideNavPage'
import SearchInput from 'sharedComponents/SearchInput'
import ROUTES from 'constants/routes'
import { parseInteger } from 'utils/numbers'
import ACTIONS from 'constants/actions'

const TABLE_MIN_HEIGHT = 500

let isInitialMount = true

const Attributes = ({ history, user }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [id, setId] = useState('')
  const [positionIndexWeight, setPositionIndexWeight] = useState(null)
  const [title, setTitle] = useState('')
  const [valueType, setValueType] = useState('')
  const [translations, setTranslations] = useState([])
  const [languagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
        }
      }
    `,
  })
  const [searchString, setSearchString] = useState('')
  const [attributesQuery, executeAttributesQuery] = useQuery({
    query: `
      query Attributes($page: Int!, $search: String) {
        attributes (pagination: {page: $page}, search: $search) {
          paginationInfo {
            totalCount
            firstPage
            lastPage
            currentPage
            nextPage
            previousPage
          }
          data {
            id
            title
            translations {
              language {
                isoCode
                title
              }
              value
            }
            valueType
            positionIndexWeight
          }
        }
      }
    `,
    variables: {
      page: currentPage,
      search: searchString,
    },
  })
  const [attributeValueTypesQuery] = useQuery({
    query: `
      query AttributeValueTypes {
        attributeValueTypes
      }
    `,
  })
  const [createMutation, executeCreateMutation] = useMutation(`
    mutation($input: CreateAttributeInput!) {
      createAttribute(input: $input) {
        id
      }
    }
  `)
  const lastCreatedId =
    createMutation.data && createMutation.data.createAttribute.id
  const [updateMutation, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateAttributeInput!) {
      updateAttribute(input: $input) {
        id
      }
    }
  `)
  const lastUpdatedId =
    updateMutation.data && updateMutation.data.updateAttribute.id
  const [deleteMutation, executeDeleteMutation] = useMutation(`
    mutation($id: Int!) {
      deleteAttribute(id: $id) {
        id
      }
    }
  `)
  const lastDeletedId =
    deleteMutation.data && deleteMutation.data.deleteAttribute.id
  const updateTranslationValue = (languageIsoCode, value) => {
    setTranslations([
      ...translations.filter(
        (translation) => translation.languageIsoCode !== languageIsoCode
      ),
      { languageIsoCode, value },
    ])
  }
  const getTranslationValue = (languageIsoCode) => {
    const translation = find(translations, {
      languageIsoCode,
    })
    return translation ? translation.value : ''
  }
  useEffect(() => {
    if (!isInitialMount) {
      if (lastCreatedId || lastUpdatedId || lastDeletedId) {
        executeAttributesQuery({ requestPolicy: 'network-only' })
      }
    } else {
      isInitialMount = false
    }
  }, [executeAttributesQuery, lastCreatedId, lastUpdatedId, lastDeletedId])
  useEffect(() => {
    const error =
      attributesQuery.error ||
      deleteMutation.error ||
      updateMutation.error ||
      createMutation.error ||
      languagesQuery.error
    if (error) {
      urql.handleError(error)
    }
  }, [
    deleteMutation.error,
    updateMutation.error,
    createMutation.error,
    languagesQuery.error,
    attributesQuery.error,
  ])
  const isBasicEditDisabled =
    isUpdateModalOpen && !user.hasAction(ACTIONS.ATTRIBUTE.UPDATE)
  const paginationInfo =
    attributesQuery.data && attributesQuery.data.attributes.paginationInfo
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Attributes</SideNavPage.Title>
        {user.hasAction(ACTIONS.ATTRIBUTE.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setId('')
              setTitle('')
              setValueType('')
              setTranslations([])
              setIsCreateModalOpen(true)
              setPositionIndexWeight(null)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Pane marginBottom={majorScale(1)}>
          <SearchInput onChange={setSearchString} />
        </Pane>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>#</Table.TextHeaderCell>
            <Table.TextHeaderCell>Id</Table.TextHeaderCell>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell minWidth="250px" align="center">
              Actions
            </Table.TextHeaderCell>
          </Table.Head>
        </Table>
        <Table.Body minHeight={TABLE_MIN_HEIGHT}>
          {attributesQuery.fetching && (
            <Spinner
              marginX="auto"
              marginY={TABLE_MIN_HEIGHT / 2}
              delay={200}
              size={80}
            />
          )}
          {attributesQuery.data &&
            attributesQuery.data.attributes.data.map((attribute) => (
              <Table.Row
                key={attribute.id}
                isSelectable
                onSelect={() =>
                  history.push(`/${ROUTES.ATTRIBUTE_VALUES}/${attribute.id}`)
                }
              >
                <Table.TextCell>{attribute.positionIndexWeight}</Table.TextCell>
                <Table.TextCell>{attribute.id}</Table.TextCell>
                <Table.TextCell>{attribute.title}</Table.TextCell>
                <Table.TextCell>{attribute.valueType}</Table.TextCell>
                <Table.Cell minWidth="250px" justifyContent="center">
                  {user.hasAction(ACTIONS.ATTRIBUTE.UPDATE) && (
                    <Button
                      marginRight={12}
                      iconBefore="edit"
                      onClick={(e) => {
                        e.stopPropagation()
                        setId(attribute.id)
                        setTitle(attribute.title)
                        setPositionIndexWeight(attribute.positionIndexWeight)
                        setValueType(attribute.valueType)
                        setTranslations(
                          attribute.translations.map(({ language, value }) => ({
                            languageIsoCode: language.isoCode,
                            value,
                          }))
                        )
                        setIsUpdateModalOpen(true)
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {user.hasAction(ACTIONS.ATTRIBUTE.DELETE) && (
                    <Button
                      marginRight={12}
                      iconBefore="trash"
                      intent="danger"
                      onClick={(e) => {
                        e.stopPropagation()
                        setId(attribute.id)
                        setIsConfirmDeleteModalOpen(true)
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </SideNavPage.Content>
      <Dialog
        isShown={isConfirmDeleteModalOpen}
        title="Confirm delete attribute"
        confirmLabel="Delete"
        isConfirmLoading={deleteMutation.fetching}
        onCloseComplete={() => {
          setIsConfirmDeleteModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeDeleteMutation({
            id: parseInteger(id),
          })
          if (!error) {
            setId('')
            close()
          }
        }}
      >
        Are you sure you want to delete attribute {title}?
      </Dialog>
      <Dialog
        isShown={isCreateModalOpen || isUpdateModalOpen}
        title={isCreateModalOpen ? 'Create attribute' : `Edit attribute: ${id}`}
        hasCancel={false}
        confirmLabel={isCreateModalOpen ? 'Create' : 'Edit'}
        isConfirmLoading={createMutation.fetching || updateMutation.fetching}
        onCloseComplete={() => {
          setIsCreateModalOpen(false)
          setIsUpdateModalOpen(false)
        }}
        onConfirm={async (close) => {
          if (isCreateModalOpen) {
            const { error } = await executeCreateMutation({
              input: {
                title,
                valueType,
                positionIndexWeight: parseInteger(positionIndexWeight),
                translations: translations.filter(({ value }) => value !== ''),
              },
            })
            if (!error) {
              close()
            }
          }
          if (isUpdateModalOpen) {
            const { error } = await executeUpdateMutation({
              input: {
                id,
                title,
                valueType,
                positionIndexWeight: parseInteger(positionIndexWeight),
                translations: translations.filter(({ value }) => value !== ''),
              },
            })
            if (!error) {
              close()
            }
          }
        }}
      >
        <TextInputField
          label="Title"
          required
          value={title}
          disabled={isBasicEditDisabled}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        {paginationInfo && (
          <TextInputField
            label="Position Index Weight"
            type="number"
            min={0}
            max={
              isUpdateModalOpen
                ? paginationInfo.totalCount - 1
                : paginationInfo.totalCount
            }
            value={positionIndexWeight}
            disabled={isBasicEditDisabled}
            onChange={(e) => {
              setPositionIndexWeight(e.target.value)
            }}
          />
        )}
        <SelectField
          required
          label="Attribute type"
          value={valueType}
          onChange={(e) => {
            setValueType(e.target.value)
          }}
          marginBottom={0}
        >
          <option value="">---Select Type---</option>
          {attributeValueTypesQuery.data &&
            attributeValueTypesQuery.data.attributeValueTypes.map(
              (attributeValueType) => (
                <option key={attributeValueType} value={attributeValueType}>
                  {attributeValueType}
                </option>
              )
            )}
        </SelectField>
        <Pane marginTop={majorScale(3)}>
          <Heading is="h3" marginBottom={majorScale(2)}>
            Translations
          </Heading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Language</Table.TextHeaderCell>
              <Table.TextHeaderCell>Value</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {languagesQuery.data &&
                languagesQuery.data.languages.map((language) => (
                  <Table.Row key={language.isoCode}>
                    <Table.TextCell>{language.title}</Table.TextCell>
                    <Table.Cell>
                      <TextInputField
                        label="Translation"
                        value={getTranslationValue(language.isoCode)}
                        disabled={isBasicEditDisabled}
                        onChange={(e) => {
                          updateTranslationValue(
                            language.isoCode,
                            e.target.value
                          )
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Pane>
      </Dialog>
      {paginationInfo && (
        <Pagination
          {...paginationInfo}
          onPageChange={(page) => {
            setCurrentPage(page)
          }}
        />
      )}
    </React.Fragment>
  )
}

Attributes.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Attributes)
