import styled from 'styled-components/macro'
import { borderRadius } from 'Theme'

const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
`
const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${borderRadius.SMALL}px;
  box-shadow: ${({ isSelected }) =>
    isSelected ? '0px 0px 2px 4px rgba(16,112,202,1);' : 'none'};
`

ImagesGrid.Item = Image

export default ImagesGrid
