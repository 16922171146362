import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import format from 'date-fns/format'
import {
  Table,
  Link,
  Spinner,
  Button,
  Pane,
  majorScale,
  FormField,
} from 'evergreen-ui'
import compact from 'lodash/compact'
import urql from 'utils/urql'
import { formatPrice } from 'utils/money'
import { parseInteger } from 'utils/numbers'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import CountriesSelectMenu from 'sharedComponents/CountriesSelectMenu'
import LanguagesSelectMenu from 'sharedComponents/LanguagesSelectMenu'
import OrderStatusesSelectMenu from 'sharedComponents/OrderStatusesSelectMenu'
import ReturnStatusesSelectMenu from 'sharedComponents/ReturnStatusesSelectMenu'
import SearchInput from 'sharedComponents/SearchInput'
import NoData from 'sharedComponents/NoData'
import ROUTES, {
  DOD_ROUTES,
  BEST_SELLER_ROUTES,
  DOW_ROUTES,
  THEME_DEAL_ROUTES,
} from 'constants/routes'
import { TIME_FORMAT, QUERY_TYPES } from 'constants/constants'
import Pagination from 'components/Pagination'
// import OrderStatus from 'components/OrderStatus'
import PublicOrderStatus from 'components/PublicOrderStatus'
import ReturnOrderStatus from 'components/ReturnOrderStatus'
import ACTIONS from 'constants/actions'
import { getPathToWishOffer } from 'routes/Wishes/utils'
import { getPathToProductVariant } from 'routes/Product/utils'
import SetupImportDialog from './SetupImportDialog'
import ImportResultDialog from './ImportResultDialog'
import ExportCsvDialogOpen from './ExportCsvDialogOpen'
import ExportTransactionCsvDialogOpen from './ExportTransactionCsvDialogOpen'
import ExportOrdersToPacketaDialog from './ExportOrdersToPacketaDialog'

const TABLE_MIN_HEIGHT = 500
const IMPORTED_DATA_DEFAULT_STATE = null

const getFilter = ({
  selectedCustomerLanguageIsoCodes: customerLanguageIsoCodes,
  selectedShippingCountryIsoCodes: shippingCountryIsoCodes,
  searchedOrderId: orderId,
  selectedOrderStatuses: orderStatuses,
  selectedReturnOrderStatuses: returnOrderStatuses,
  searchedShoppingCartItemId: shoppingCartItemId,
}) => ({
  ...(shippingCountryIsoCodes.length ? { shippingCountryIsoCodes } : {}),
  ...(customerLanguageIsoCodes.length ? { customerLanguageIsoCodes } : {}),
  ...(orderId ? { orderId } : {}),
  ...(orderStatuses ? { orderStatuses } : {}),
  ...(returnOrderStatuses ? { returnOrderStatuses } : {}),
  ...(shoppingCartItemId ? { shoppingCartItemId } : {}),
})

const Orders = ({ history, user }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [isExportCsvDialogOpen, setIsExportCsvDialogOpen] = useState(false)
  const [
    isExportTransactionCsvDialogOpen,
    setIsExportTransactionCsvDialogOpen,
  ] = useState(false)
  const [isSetupImportDialogOpen, setIsSetupImportDialogOpen] = useState(false)
  const [isExportOrdersForPacketaOpen, setIsExportOrdersForPacketaOpen] =
    useState(false)
  const [importedData, setImportedData] = useState(IMPORTED_DATA_DEFAULT_STATE)
  const [selectedShippingCountryIsoCodes, setSelectedShippingCountryIsoCodes] =
    useState([])
  const [
    selectedCustomerLanguageIsoCodes,
    setSelectedCustomerLanguageIsoCodes,
  ] = useState([])
  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState([])
  const [selectedReturnOrderStatuses, setSelectedReturnOrderStatuses] =
    useState([])
  const [searchedOrderId, setSearchedOrderId] = useState('')
  const [searchedShoppingCartItemId, setSearchedShoppingCartItemId] =
    useState('')
  const [ordersQuery, executeOrdersQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query userOrders($page: Int!, $filter: UserOrdersFilter) {
        userOrders(
          pagination: {page: $page}
          filter: $filter
        ) {
          paginationInfo {
            totalCount
            firstPage
            lastPage
            currentPage
            nextPage
            previousPage
          }
          data {
            id
            shoppingCartItemId
            totalPriceTotal
            totalPricePaid
            currencyIsoCode
            status
            publicOrderStatus
            returnStatus
            customerEmail
            customer {
              id
              email
            }
            product {
              __typename
              ...on OrderWishOffer {
                  title
                  id
                  wishId
              }
              ...on OrderPublicDealOfTheDay {
                  title
                  id
                }
              ...on OrderPublicDealOfTheWeek {
                title
                id
              }
              ...on OrderPublicThemeDeal {
                title
                id
                themeOfTheMonthId
              }
              ...on OrderPublicBestSeller {
                title
                id
              }
              ...on OrderPublicPhoneService {
                id
                title
                productId
              }
            }
            createdAtUtc
            shippingCountry {
              title
            }
          }
        }
      }
    `,
    variables: {
      page: currentPage,
      filter: getFilter({
        selectedShippingCountryIsoCodes,
        selectedCustomerLanguageIsoCodes,
        searchedOrderId: searchedOrderId && parseInteger(searchedOrderId),
        selectedOrderStatuses,
        selectedReturnOrderStatuses,
        searchedShoppingCartItemId:
          searchedShoppingCartItemId &&
          parseInteger(searchedShoppingCartItemId),
      }),
    },
  })
  useEffect(() => {
    if (ordersQuery.error) {
      urql.handleError(ordersQuery.error)
    }
  }, [ordersQuery.error])
  const paginationInfo =
    ordersQuery.data && ordersQuery.data.userOrders.paginationInfo
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Orders</SideNavPage.Title>
        <Pane>
          {user.hasAction(ACTIONS.ORDER.UPDATE_TRACKING_NUMBER) && (
            <Button
              intent="success"
              marginRight={`${majorScale(1)}px`}
              onClick={() => {
                setIsSetupImportDialogOpen(true)
              }}
            >
              Import orders from zasilkovna...
            </Button>
          )}
          {user.hasAction(ACTIONS.ORDER.EXPORT_TO_PACKETA) && (
            <Button
              intent="success"
              marginRight={`${majorScale(1)}px`}
              onClick={() => {
                setIsExportOrdersForPacketaOpen(true)
              }}
            >
              Export orders for zasilkovna...
            </Button>
          )}
          {user.hasAction(ACTIONS.ORDER.EXPORT_CSV) && (
            <Button
              appearance="primary"
              intent="success"
              marginRight={`${majorScale(1)}px`}
              onClick={() => {
                setIsExportCsvDialogOpen(true)
              }}
            >
              Export to csv...
            </Button>
          )}
          {user.hasAction(ACTIONS.ORDER.EXPORT_CSV) && (
            <Button
              appearance="primary"
              intent="success"
              onClick={() => {
                setIsExportTransactionCsvDialogOpen(true)
              }}
            >
              Export Transactions to CSV
            </Button>
          )}
        </Pane>
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Pane
          display="flex"
          justifyContent="flex-end"
          marginBottom={majorScale(2)}
        >
          <Pane flex={1} alignItems="center" display="flex" width={250}>
            <FormField
              label="Filter by shopping cart ID"
              paddingRight={majorScale(1)}
              width="50%"
            >
              <SearchInput
                type="number"
                onChange={setSearchedShoppingCartItemId}
              />
            </FormField>
            <FormField
              label="Filter by order ID"
              paddingRight={majorScale(1)}
              width="50%"
            >
              <SearchInput type="number" onChange={setSearchedOrderId} />
            </FormField>
          </Pane>
          <Pane alignItems="center" display="flex">
            <FormField
              label="Filter by order statuses"
              paddingRight={majorScale(1)}
            >
              <OrderStatusesSelectMenu
                selectedStatuses={selectedOrderStatuses}
                onChange={setSelectedOrderStatuses}
              />
            </FormField>
            <FormField
              label="Filter by return statuses"
              paddingRight={majorScale(1)}
            >
              <ReturnStatusesSelectMenu
                selectedStatuses={selectedReturnOrderStatuses}
                onChange={setSelectedReturnOrderStatuses}
              />
            </FormField>
            <FormField
              label="Filter by customer languages"
              paddingRight={majorScale(1)}
            >
              <LanguagesSelectMenu
                selectedIsoCodes={selectedCustomerLanguageIsoCodes}
                onChange={setSelectedCustomerLanguageIsoCodes}
              />
            </FormField>
            <FormField label="Filter by shipping countries">
              <CountriesSelectMenu
                onChange={setSelectedShippingCountryIsoCodes}
                selectedIsoCodes={selectedShippingCountryIsoCodes}
              />
            </FormField>
          </Pane>
        </Pane>
        <ExportCsvDialogOpen
          isShown={isExportCsvDialogOpen}
          onCloseComplete={() => setIsExportCsvDialogOpen(false)}
        />
        <ExportTransactionCsvDialogOpen
          isShown={isExportTransactionCsvDialogOpen}
          onCloseComplete={() => setIsExportTransactionCsvDialogOpen(false)}
        />
        <ExportOrdersToPacketaDialog
          isShown={isExportOrdersForPacketaOpen}
          onCloseComplete={() => setIsExportOrdersForPacketaOpen(false)}
        />
        <Table border>
          <Table.Head height="auto" paddingY={12}>
            <Table.TextHeaderCell flexBasis={75} flexShrink={0} flexGrow={0}>
              Order Id
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
              Shopping Cart ID
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={200} flexShrink={0} flexGrow={0}>
              Item
            </Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="right">Price</Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="right">Paid</Table.TextHeaderCell>
            <Table.TextHeaderCell>Customer</Table.TextHeaderCell>
            <Table.TextHeaderCell>Created at</Table.TextHeaderCell>
            <Table.TextHeaderCell>Status</Table.TextHeaderCell>
            <Table.TextHeaderCell>Return Status</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>Order Status</Table.TextHeaderCell> */}
            <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
              Shipping country
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {ordersQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                size={80}
              />
            )}
            {!ordersQuery.fetching &&
              ordersQuery.data &&
              compact(ordersQuery.data.userOrders.data, (order) => order).map(
                ({
                  id: orderId,
                  createdAtUtc,
                  // status,
                  publicOrderStatus,
                  returnStatus,
                  product,
                  customer,
                  customerEmail,
                  totalPriceTotal,
                  totalPricePaid,
                  currencyIsoCode,
                  shippingCountry: { title },
                  shoppingCartItemId,
                }) => (
                  <Table.Row
                    key={orderId}
                    isSelectable={user.hasAction(ACTIONS.ORDER.DETAIL)}
                    onSelect={() =>
                      history.push(`/${ROUTES.ORDERS}/${orderId}`)
                    }
                    height="auto"
                    paddingY={12}
                  >
                    <Table.TextCell flexBasis={75} flexShrink={0} flexGrow={0}>
                      {orderId}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
                      {shoppingCartItemId}
                    </Table.TextCell>
                    {
                      // eslint-disable-next-line no-underscore-dangle
                      product.__typename === QUERY_TYPES.DEAL_OF_THE_DAY && (
                        <Table.Cell flexBasis={200} flexShrink={0} flexGrow={0}>
                          {user.hasAction(ACTIONS.DEAL_OF_THE_DAY.DETAIL) ||
                          user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE) ? (
                            <Link
                              onClick={(e) => {
                                e.stopPropagation()
                                history.push(
                                  `/${ROUTES.DEAL_OF_THE_DAYS}/${DOD_ROUTES.EDIT}/${product.id}`
                                )
                              }}
                            >
                              {product.title}
                            </Link>
                          ) : (
                            product.title
                          )}
                        </Table.Cell>
                      )
                    }
                    {
                      // eslint-disable-next-line no-underscore-dangle
                      product.__typename === QUERY_TYPES.DEAL_OF_THE_WEEK && (
                        <Table.Cell flexBasis={200} flexShrink={0} flexGrow={0}>
                          {user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.DETAIL) ||
                          user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE) ? (
                            <Link
                              onClick={(e) => {
                                e.stopPropagation()
                                history.push(
                                  `/${ROUTES.DEAL_OF_THE_WEEKS}/${DOW_ROUTES.EDIT}/${product.id}`
                                )
                              }}
                            >
                              {product.title}
                            </Link>
                          ) : (
                            product.title
                          )}
                        </Table.Cell>
                      )
                    }
                    {
                      // eslint-disable-next-line no-underscore-dangle
                      product.__typename === QUERY_TYPES.THEME_DEAL && (
                        <Table.Cell flexBasis={200} flexShrink={0} flexGrow={0}>
                          {user.hasAction(ACTIONS.THEME_DEAL.DETAIL) ||
                          user.hasAction(ACTIONS.THEME_DEAL.UPDATE) ? (
                            <Link
                              onClick={(e) => {
                                e.stopPropagation()
                                history.push(
                                  `/${ROUTES.THEME_OF_THE_MONTH}/${product.themeOfTheMonthId}/${THEME_DEAL_ROUTES.EDIT}/${product.id}`
                                )
                              }}
                            >
                              {product.title}
                            </Link>
                          ) : (
                            product.title
                          )}
                        </Table.Cell>
                      )
                    }
                    {
                      // eslint-disable-next-line no-underscore-dangle
                      product.__typename === QUERY_TYPES.BEST_SELLER && (
                        <Table.Cell flexBasis={200} flexShrink={0} flexGrow={0}>
                          {user.hasAction(ACTIONS.THEME_DEAL.DETAIL) ||
                          user.hasAction(ACTIONS.THEME_DEAL.UPDATE) ? (
                            <Link
                              onClick={(e) => {
                                e.stopPropagation()
                                history.push(
                                  `/${ROUTES.BEST_SELLERS}/${BEST_SELLER_ROUTES.EDIT}/${product.id}`
                                )
                              }}
                            >
                              {product.title}
                            </Link>
                          ) : (
                            product.title
                          )}
                        </Table.Cell>
                      )
                    }
                    {
                      // eslint-disable-next-line no-underscore-dangle
                      product.__typename === QUERY_TYPES.WISH_OFFER && (
                        <Table.Cell flexBasis={200} flexShrink={0} flexGrow={0}>
                          {user.hasAction(ACTIONS.WISH.DETAIL) ||
                          user.hasAction(ACTIONS.WISH.UPDATE) ? (
                            <Link
                              onClick={(e) => {
                                e.stopPropagation()
                                history.push(
                                  getPathToWishOffer({
                                    wishId: product.wishId,
                                    offerId: product.id,
                                  })
                                )
                              }}
                            >
                              {product.title}
                            </Link>
                          ) : (
                            product.title
                          )}
                        </Table.Cell>
                      )
                    }
                    {
                      // eslint-disable-next-line no-underscore-dangle
                      product.__typename === QUERY_TYPES.PHONE_SERVICE && (
                        <Table.Cell flexBasis={200} flexShrink={0} flexGrow={0}>
                          {user.hasAction(ACTIONS.PRODUCT_VARIANT.DETAIL) ? (
                            <Link
                              onClick={(e) => {
                                e.stopPropagation()
                                history.push(
                                  getPathToProductVariant({
                                    id: product.id,
                                    productId: product.productId,
                                  })
                                )
                              }}
                            >
                              {product.title}
                            </Link>
                          ) : (
                            product.title
                          )}
                        </Table.Cell>
                      )
                    }
                    <Table.TextCell textAlign="right">
                      {formatPrice({
                        value: totalPriceTotal,
                        currencyIsoCode,
                      })}
                    </Table.TextCell>
                    <Table.TextCell textAlign="right">
                      {formatPrice({
                        value: totalPricePaid,
                        currencyIsoCode,
                      })}
                    </Table.TextCell>
                    <Table.TextCell>
                      {customer ? (
                        <Link
                          onClick={(e) => {
                            e.stopPropagation()
                            history.push(`/${ROUTES.CUSTOMERS}/${customer.id}`)
                          }}
                        >
                          {customer.email}
                        </Link>
                      ) : (
                        `[DELETED] ${customerEmail}`
                      )}
                    </Table.TextCell>
                    <Table.TextCell>
                      {format(createdAtUtc, TIME_FORMAT.LOCAL_DATETIME)}
                    </Table.TextCell>
                    <Table.TextCell>
                      <PublicOrderStatus status={publicOrderStatus} />
                    </Table.TextCell>
                    <Table.TextCell>
                      <ReturnOrderStatus status={returnStatus} />
                    </Table.TextCell>
                    {/* <Table.TextCell>
                      <OrderStatus status={status} />
                    </Table.TextCell> */}
                    <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
                      {title}
                    </Table.TextCell>
                  </Table.Row>
                )
              )}
            {!ordersQuery.fetching &&
              paginationInfo &&
              paginationInfo.totalCount === 0 && <NoData padding={60} />}
          </Table.Body>
        </Table>
        {paginationInfo && paginationInfo.totalCount > 0 && (
          <Pagination
            {...paginationInfo}
            onPageChange={(page) => {
              setCurrentPage(page)
            }}
          />
        )}
        <SetupImportDialog
          isShown={isSetupImportDialogOpen}
          close={(data) => {
            setIsSetupImportDialogOpen(false)
            if (data) {
              executeOrdersQuery({ requestPolicy: 'network-only' })
              setImportedData(data)
            }
          }}
        />
        {importedData && (
          <ImportResultDialog
            csvUploadData={importedData}
            close={() => {
              setImportedData(IMPORTED_DATA_DEFAULT_STATE)
            }}
          />
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Orders)
