import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import {
  Table,
  Switch,
  Spinner,
  Button,
  Dialog,
  Pane,
  Label,
  TextInputField,
} from 'evergreen-ui'
import { withUser } from 'UserProvider'
import ACTIONS from 'constants/actions'
import SideNavPage from 'sharedComponents/SideNavPage'
import urql from 'utils/urql'

const TABLE_MIN_HEIGHT = 500

let isInitialMount = true

const Languages = ({ user }) => {
  const [isSupportedForMessages, setIsSupportedForMessages] = useState(false)
  const [isCreateLanguageModalOpen, setIsCreateLanguageModalOpen] =
    useState(false)
  const [isUpdateLanguageModalOpen, setIsUpdateLanguageModalOpen] =
    useState(false)
  const [isoCode, setIsoCode] = useState('')
  const [title, setTitle] = useState('')
  const [languagesQuery, executelanguagesQuery] = useQuery({
    query: `
      query {
        languages {
          isoCode
          title
          isSupportedForMessages
        }
      }
    `,
  })
  const [createLanguageMutation, executeCreateLanguageMutation] = useMutation(`
    mutation($input: CreateLanguageInput!) {
      createLanguage(input: $input) {
        isoCode
      }
    }
  `)
  const lastCreatedLanguageIsoCode =
    (createLanguageMutation.data &&
      createLanguageMutation.data.createLanguage.isoCode) ||
    false
  const [updateLanguageMutation, executeUpdateLanguageMutation] = useMutation(`
    mutation($input: UpdateLanguageInput!) {
      updateLanguage(input: $input) {
        isoCode
        title
      }
    }
  `)
  const lastUpdatedLanguageIsoCode =
    (updateLanguageMutation.data &&
      updateLanguageMutation.data.updateLanguage.isoCode) ||
    false
  useEffect(() => {
    if (!isInitialMount) {
      if (
        lastCreatedLanguageIsoCode !== false ||
        lastUpdatedLanguageIsoCode !== false
      ) {
        executelanguagesQuery({ requestPolicy: 'network-only' })
      }
    } else {
      isInitialMount = false
    }
  }, [
    lastCreatedLanguageIsoCode,
    lastUpdatedLanguageIsoCode,
    executelanguagesQuery,
  ])
  useEffect(() => {
    const error =
      languagesQuery.error ||
      createLanguageMutation.error ||
      updateLanguageMutation.error
    if (error) {
      urql.handleError(error)
    }
  }, [
    languagesQuery.error,
    createLanguageMutation.error,
    updateLanguageMutation.error,
  ])

  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Languages</SideNavPage.Title>
        {user.hasAction(ACTIONS.LANGUAGE.CREATE) && (
          <Button
            iconBefore="add"
            appearance="primary"
            intent="success"
            onClick={() => {
              setIsoCode('')
              setTitle('')
              setIsSupportedForMessages(false)
              setIsCreateLanguageModalOpen(true)
            }}
          >
            Add new
          </Button>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <Table border>
          <Table.Head>
            <Table.TextHeaderCell>ISO code</Table.TextHeaderCell>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell textAlign="center">
              Supported for messages
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body minHeight={TABLE_MIN_HEIGHT}>
            {languagesQuery.fetching && (
              <Spinner
                marginX="auto"
                marginY={TABLE_MIN_HEIGHT / 2}
                delay={200}
                size={80}
              />
            )}
            {languagesQuery.data &&
              languagesQuery.data.languages.map((language) => (
                <Table.Row key={language.isoCode}>
                  <Table.TextCell>{language.isoCode}</Table.TextCell>
                  <Table.TextCell>{language.title}</Table.TextCell>
                  <Table.Cell justifyContent="center">
                    <Switch
                      disabled
                      checked={language.isSupportedForMessages}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {user.hasAction(ACTIONS.LANGUAGE.UPDATE) && (
                      <Button
                        marginRight={12}
                        iconBefore="edit"
                        onClick={() => {
                          setIsoCode(language.isoCode)
                          setTitle(language.title)
                          setIsSupportedForMessages(
                            language.isSupportedForMessages
                          )
                          setIsUpdateLanguageModalOpen(true)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </SideNavPage.Content>
      <Dialog
        isShown={isCreateLanguageModalOpen}
        title="Create language"
        hasCancel={false}
        confirmLabel="Create"
        isConfirmLoading={createLanguageMutation.fetching}
        onCloseComplete={() => {
          setIsCreateLanguageModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeCreateLanguageMutation({
            input: { isoCode, title, isSupportedForMessages },
          })
          if (!error) {
            close()
          }
        }}
      >
        <TextInputField
          label="ISO code"
          required
          value={isoCode}
          onChange={(e) => {
            setIsoCode(e.target.value)
          }}
        />
        <TextInputField
          label="Title"
          required
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <Pane>
          <Label>Is supported for messages</Label>
          <Switch
            checked={isSupportedForMessages}
            onChange={(e) => {
              setIsSupportedForMessages(e.target.checked)
            }}
          />
        </Pane>
      </Dialog>
      <Dialog
        isShown={isUpdateLanguageModalOpen}
        title="Edit language"
        hasCancel={false}
        shouldCloseOnEscapePress={false}
        confirmLabel="Edit"
        isConfirmLoading={updateLanguageMutation.fetching}
        onCloseComplete={() => {
          setIsUpdateLanguageModalOpen(false)
        }}
        onConfirm={async (close) => {
          const { error } = await executeUpdateLanguageMutation({
            input: {
              isoCode,
              title,
              isSupportedForMessages,
            },
          })
          if (!error) {
            close()
          }
        }}
      >
        <TextInputField
          label="Title"
          required
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <Pane>
          <Label>Is supported for messages</Label>
          <Switch
            checked={isSupportedForMessages}
            onChange={(e) => {
              setIsSupportedForMessages(e.target.checked)
            }}
          />
        </Pane>
      </Dialog>
    </React.Fragment>
  )
}

Languages.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(Languages)
