import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { MANAGEMENT_ROUTES, DOW_ROUTES } from 'constants/routes'
import NewDoW from './routes/NewDoW'
import EditDoW from './routes/EditDoW'
import Management from './routes/Management'

const { NEW, EDIT } = DOW_ROUTES

const DealOfTheWeeks = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/${NEW}`} component={NewDoW} />
    <Route path={`${match.url}/${EDIT}/:id`} component={EditDoW} />
    <Route
      component={Management}
      path={`${match.url}/:tab(${Object.values(MANAGEMENT_ROUTES).join('|')})?`}
    />
  </Switch>
)

DealOfTheWeeks.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default DealOfTheWeeks
