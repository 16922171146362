import { format, differenceInCalendarDays, isDate } from 'date-fns'
import { TIME_FORMAT } from 'constants/constants'

export const formatToHumanReadable = ({ date, isTimeShown }) => {
  const dateObject = !isDate(date) ? date : new Date(date)
  const diffInDays = differenceInCalendarDays(new Date(), dateObject)
  const detail = isTimeShown ? ` ${format(date, 'hh:mm:ss a')}` : ''
  if (diffInDays === 0) {
    return format(dateObject, 'hh:mm:ss a')
  }
  if (diffInDays === 1) {
    return `Yesterday${detail}`
  }
  if (diffInDays > 1 && diffInDays <= 7) {
    return `${format(dateObject, 'dddd')}${detail}`
  }
  return `${format(dateObject, TIME_FORMAT.LOCAL_DATE)}${detail}`
}
