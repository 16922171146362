import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'urql'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import { getSpaceInPx } from 'utils/styled-system'
import { DEAL_STATUSES, SORT_DIRECTION_ENUM } from 'constants/constants'
import ActionsRow from 'components/ActionsRow'
import DeleteDoWDialog from '../../../components/DeleteDoWDialog'
import { getDealOfTheWeeksQuery } from '../queries'
import DoWList from '../components/DoWList'
import DoWItem from '../components/DoWItem'
import { DOW_ITEM_Y_MARGIN } from '../constants'
import { getPathToDealOfTheWeekDetail } from '../../../utils'
import withApproveDeclineMutations, {
  toastResultOfApproval,
} from '../../../components/withAprroveDeclineMutations'

const { APPROVAL_NEEDED } = DEAL_STATUSES

const ApprovalNeeded = ({
  user,
  history,
  executeApproveDealOfTheWeekMutation,
  showDeclineDealOfTheWeekDialog,
}) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [dealOfTheWeeksQuery, executeDealOfTheWeeksQuery] = useQuery(
    getDealOfTheWeeksQuery(
      {
        status: [APPROVAL_NEEDED],
        pagination: { page: currentPage, limit: 20 },
        sorter: {
          field: 'positionIndexWeight',
          direction: SORT_DIRECTION_ENUM.ASC,
        },
      },
      `
    positionIndexWeight
    `
    )
  )
  return (
    <Pane>
      <DeleteDoWDialog
        itemToDelete={itemToSoftDelete}
        onClose={() => {
          setItemToSoftDelete(null)
        }}
        onSuccess={() => {
          executeDealOfTheWeeksQuery({ requestPolicy: 'network-only' })
        }}
      />
      <ActionsRow />
      <DoWList
        dealOfTheWeeksQuery={dealOfTheWeeksQuery}
        setCurrentPage={setCurrentPage}
      >
        {({ dealOfTheWeeks }) =>
          dealOfTheWeeks.map((item) => (
            <DoWItem
              key={item.id}
              {...item}
              my={getSpaceInPx(DOW_ITEM_Y_MARGIN)}
              onDetailClick={() => {
                history.push(getPathToDealOfTheWeekDetail(item.id))
              }}
              isWithApproveButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_WEEK.APPROVE
              )}
              isWithDeclineButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_WEEK.DECLINE
              )}
              onDeleteButtonClicked={() => {
                setItemToSoftDelete(item)
              }}
              onApproveButtonClicked={() => {
                const result = executeApproveDealOfTheWeekMutation({
                  id: item.id,
                })
                toastResultOfApproval(result)
              }}
              onDeclineButtonClicked={() => {
                showDeclineDealOfTheWeekDialog({
                  dealOfTheWeekId: item.id,
                })
              }}
              isWithDeleteButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_WEEK.DELETE
              )}
              isWithEditButton={
                user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.UPDATE_TRANSLATIONS) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_TRANSLATIONS) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_WEEK.ADD_COMPETITOR)
              }
            />
          ))
        }
      </DoWList>
    </Pane>
  )
}

ApprovalNeeded.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveDealOfTheWeekMutation: PropTypes.func.isRequired,
  showDeclineDealOfTheWeekDialog: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  withUser,
  withApproveDeclineMutations
)(ApprovalNeeded)
