import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { useMutation, useQuery } from 'urql'
import styled from 'styled-components/macro'
import { BackButton, Button, majorScale, toaster } from 'evergreen-ui'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import ROUTES from 'constants/routes'
import { DEAL_STATUSES } from 'constants/constants'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import SideNavPage from 'sharedComponents/SideNavPage'
import FullScreenLoading from 'sharedComponents/FullScreenLoading'
import { getPathToThemeDealDetail } from '../../utils'
import DeleteThemeDealDialog from '../../components/DeleteThemeDealDialog'
import Form, { formatDataIntoInput } from '../../components/Form'
import withApproveDeclineMutations, {
  toastResultOfApproval,
} from '../../components/withAprroveDeclineMutations'
import {
  pauseThemeDealMutation,
  resumeThemeDealMutation,
} from '../Management/queries'

const { DRAFT, APPROVAL_NEEDED, APPROVED, DECLINED, DELETED, LIVE, FINISHED } =
  DEAL_STATUSES

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const BUTTON_LEFT_MARGIN = `${majorScale(1)}px`
const StyledButton = styled(Button)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const StyledBackButton = styled(BackButton)`
  margin-left: ${BUTTON_LEFT_MARGIN};
`

const getIsApprovalButtonDisplayed = (themeDeal, user) =>
  themeDeal.status === APPROVAL_NEEDED &&
  user.hasAction(ACTIONS.THEME_DEAL.APPROVE)

const getIsDeleteButtonDisplayed = (themeDeal, user) =>
  ([DRAFT, APPROVAL_NEEDED, APPROVED, DECLINED].includes(themeDeal.status) &&
    user.hasAction(ACTIONS.THEME_DEAL.DELETE)) ||
  (themeDeal.status === DELETED && user.hasAction(ACTIONS.THEME_DEAL.DESTROY))

const EditThemeDeal = ({
  match,
  history,
  user,
  executeApproveThemeDealMutation,
  showDeclineThemeDealDialog,
}) => {
  const id = parseInteger(match.params.id)
  const themeOfTheMonthId = parseInteger(match.params.themeOfTheMonthId)
  const [themeDealQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query($id: Int!) {
        themeDeal(id: $id) {
          id
          product {
            id
            title
            shortDescription
            longDescription
            translations {
              language {
                isoCode
                title
              }
              languageIsoCode
              title
              shortDescription
              longDescription
            }
            reviewScore
            size
            warehouseDeliveryTimeInDays
            productCondition {
              id
              title
            }
            mainImage {
              id
              url
              name
            }
            additionalImages {
              id
              url
              name
            }
            seller {
              id
              accountName
            }
            externalPid
            modelName
          }
          stockQuantity
          price
          status
          competitors {
            id
            title
            price
            country {
              isoCode
              title
            }
          }
          defaultCompetitorId
          changelog {
            createdByUser {
              name
            }
            oldStatus
            newStatus
            message
            createdAtUtc
          }
          comment
          isPaused
        }
      }
    `,
    variables: {
      id,
    },
  })
  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateThemeDealInput!) {
      createThemeDeal(input: $input) {
        id
      }
    }
  `)
  const [, executePauseMutation] = useMutation(pauseThemeDealMutation)
  const [, executeResumeMutation] = useMutation(resumeThemeDealMutation)
  const themeDeal = themeDealQuery.data ? themeDealQuery.data.themeDeal : null
  const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false)
  const isThemeDealEditable =
    themeDeal &&
    (![LIVE, FINISHED].includes(themeDeal.status) || themeDeal.isPaused)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Edit Theme Deal</SideNavPage.Title>
        {themeDeal && (
          <div>
            {LIVE === themeDeal.status &&
              themeDeal.isPaused &&
              user.hasAction(ACTIONS.THEME_DEAL.RESUME) && (
                <StyledButton
                  intent="success"
                  appearance="primary"
                  iconBefore="play"
                  onClick={async () => {
                    const { error } = await executeResumeMutation({
                      id: themeDeal.id,
                    })
                    displayToast(error, 'Resumed')
                  }}
                >
                  RESUME
                </StyledButton>
              )}
            {LIVE === themeDeal.status &&
              !themeDeal.isPaused &&
              user.hasAction(ACTIONS.THEME_DEAL.PAUSE) && (
                <StyledButton
                  intent="warning"
                  appearance="primary"
                  iconBefore="pause"
                  onClick={async () => {
                    const { error } = await executePauseMutation({
                      id: themeDeal.id,
                    })
                    displayToast(error, 'Paused')
                  }}
                >
                  PAUSE
                </StyledButton>
              )}
            {getIsDeleteButtonDisplayed(themeDeal, user) && (
              <StyledButton
                intent="danger"
                appearance="primary"
                onClick={() => {
                  setDeleteDialogOpened(true)
                }}
              >
                {themeDeal.status === DELETED ? 'Destroy' : 'Delete'}
              </StyledButton>
            )}
            {getIsApprovalButtonDisplayed(themeDeal, user) && (
              <StyledButton
                intent="danger"
                onClick={async () => {
                  showDeclineThemeDealDialog({
                    themeDealId: id,
                  })
                }}
              >
                Decline
              </StyledButton>
            )}
            {user.hasAction(ACTIONS.THEME_DEAL.COPY) && (
              <StyledButton
                onClick={async () => {
                  const { product, ...rest } = themeDeal
                  const createResult = await executeCreateMutation({
                    input: {
                      ...formatDataIntoInput({
                        ...rest,
                        ...product,
                        title: product.title,
                        productConditionId:
                          product.productCondition &&
                          product.productCondition.id,
                        sellerId: product.seller && product.seller.id,
                        isDraft: true,
                        isCopy: true,
                        comment: themeDeal.comment || undefined,
                      }),
                      themeOfTheMonthId,
                    },
                  })
                  if (createResult.error) {
                    urql.handleError(createResult.error)
                  } else {
                    toaster.success('Theme Deal created')
                    history.push(
                      getPathToThemeDealDetail({
                        themeDealId: createResult.data.createThemeDeal.id,
                        themeOfTheMonthId: match.params.themeOfTheMonthId,
                      })
                    )
                  }
                }}
              >
                Copy
              </StyledButton>
            )}
            {getIsApprovalButtonDisplayed(themeDeal, user) && (
              <StyledButton
                intent="success"
                appearance="primary"
                onClick={async () => {
                  const result = await executeApproveThemeDealMutation({
                    id,
                  })
                  toastResultOfApproval(result)
                }}
              >
                Approve
              </StyledButton>
            )}
            <StyledBackButton onClick={() => history.goBack()} pl="0.5rem" />
          </div>
        )}
      </SideNavPage.Header>
      <SideNavPage.Content>
        <FullScreenLoading isVisible={themeDealQuery.fetching} />
        {themeDeal && (
          <React.Fragment>
            {isDeleteDialogOpened && (
              <DeleteThemeDealDialog
                itemToDelete={{ id, product: themeDeal.product }}
                isDestroyDialog={themeDeal.status === DELETED}
                onClose={() => {
                  setDeleteDialogOpened(false)
                }}
                onSuccess={() => {
                  if (
                    themeDeal.status === DELETED ||
                    !user.hasAction(ACTIONS.THEME_DEAL.DESTROY)
                  ) {
                    history.replace(`/${ROUTES.THEME_DEAL}`)
                  }
                }}
              />
            )}
            <Form
              key={themeDeal.id}
              data={themeDeal}
              isReadOnly={
                !isThemeDealEditable ||
                !user.hasAction(ACTIONS.THEME_DEAL.UPDATE)
              }
              isCompetitorReadOnly={
                !isThemeDealEditable ||
                !user.hasAction(ACTIONS.THEME_DEAL.ADD_COMPETITOR)
              }
              isTranslationEditReadOnly={
                !isThemeDealEditable ||
                !user.hasAction(ACTIONS.THEME_DEAL.UPDATE_TRANSLATIONS)
              }
              isTranslationAddReadOnly={
                !isThemeDealEditable ||
                !user.hasAction(ACTIONS.THEME_DEAL.ADD_TRANSLATIONS)
              }
            />
          </React.Fragment>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

EditThemeDeal.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveThemeDealMutation: PropTypes.func.isRequired,
  showDeclineThemeDealDialog: PropTypes.func.isRequired,
}

export default compose(withApproveDeclineMutations, withUser)(EditThemeDeal)
