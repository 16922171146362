import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'
import MultiSelectMenu from 'components/MultiSelectMenu'

const CountriesSelectMenu = ({ selectedIsoCodes, onChange }) => {
  const [countriesQuery] = useQuery({
    query: `
      query {
        userCountries {
          isoCode
          title
        }
      }
    `,
  })
  if (!countriesQuery.data) {
    return null
  }
  return (
    <MultiSelectMenu
      title="All countries:"
      placeholder="All countries"
      options={
        countriesQuery.data
          ? countriesQuery.data.userCountries.map((country) => ({
              label: country.title,
              value: country.isoCode,
            }))
          : []
      }
      onChange={onChange}
      selectedValues={selectedIsoCodes}
    />
  )
}

CountriesSelectMenu.propTypes = {
  selectedIsoCodes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CountriesSelectMenu
