import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Pane } from 'evergreen-ui'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'urql'
import ACTIONS from 'constants/actions'
import { withUser } from 'UserProvider'
import { getSpaceInPx } from 'utils/styled-system'
import { DEAL_STATUSES, SORT_DIRECTION_ENUM } from 'constants/constants'
import ActionsRow from 'components/ActionsRow'
import DeleteDoDDialog from '../../../components/DeleteDoDDialog'
import { getDealOfTheDaysQuery } from '../queries'
import DoDList from '../components/DoDList'
import DoDItem from '../components/DoDItem'
import { DOD_ITEM_Y_MARGIN } from '../constants'
import { getPathToDealOfTheDayDetail } from '../../../utils'
import withApproveDeclineMutations, {
  toastResultOfApproval,
} from '../../../components/withAprroveDeclineMutations'

const { APPROVAL_NEEDED } = DEAL_STATUSES

const ApprovalNeeded = ({
  user,
  history,
  executeApproveDealOfTheDayMutation,
  showDeclineDealOfTheDayDialog,
}) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [itemToSoftDelete, setItemToSoftDelete] = useState(null)
  const [dealOfTheDaysQuery, executeDealOfTheDaysQuery] = useQuery(
    getDealOfTheDaysQuery(
      {
        status: [APPROVAL_NEEDED],
        pagination: { page: currentPage, limit: 20 },
        sorter: {
          field: 'positionIndexWeight',
          direction: SORT_DIRECTION_ENUM.ASC,
        },
      },
      `
    positionIndexWeight
    `
    )
  )
  return (
    <Pane>
      <DeleteDoDDialog
        itemToDelete={itemToSoftDelete}
        onClose={() => {
          setItemToSoftDelete(null)
        }}
        onSuccess={() => {
          executeDealOfTheDaysQuery({ requestPolicy: 'network-only' })
        }}
      />
      <ActionsRow />
      <DoDList
        dealOfTheDaysQuery={dealOfTheDaysQuery}
        setCurrentPage={setCurrentPage}
      >
        {({ dealOfTheDays }) =>
          dealOfTheDays.map((item) => (
            <DoDItem
              key={item.id}
              {...item}
              my={getSpaceInPx(DOD_ITEM_Y_MARGIN)}
              onDetailClick={() => {
                history.push(getPathToDealOfTheDayDetail(item.id))
              }}
              isWithApproveButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_DAY.APPROVE
              )}
              isWithDeclineButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_DAY.DECLINE
              )}
              onDeleteButtonClicked={() => {
                setItemToSoftDelete(item)
              }}
              onApproveButtonClicked={() => {
                const result = executeApproveDealOfTheDayMutation({
                  id: item.id,
                })
                toastResultOfApproval(result)
              }}
              onDeclineButtonClicked={() => {
                showDeclineDealOfTheDayDialog({
                  dealOfTheDayId: item.id,
                })
              }}
              isWithDeleteButton={user.hasAction(
                ACTIONS.DEAL_OF_THE_DAY.DELETE
              )}
              isWithEditButton={
                user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_DAY.UPDATE_TRANSLATIONS) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_TRANSLATIONS) ||
                user.hasAction(ACTIONS.DEAL_OF_THE_DAY.ADD_COMPETITOR)
              }
            />
          ))
        }
      </DoDList>
    </Pane>
  )
}

ApprovalNeeded.propTypes = {
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  executeApproveDealOfTheDayMutation: PropTypes.func.isRequired,
  showDeclineDealOfTheDayDialog: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  withUser,
  withApproveDeclineMutations
)(ApprovalNeeded)
