import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, TextInputField } from 'evergreen-ui'
import { useMutation } from 'urql'
import urql from 'utils/urql'
import CountrySelect from 'sharedComponents/CountrySelect'

const createInputHandler = (setState) => (e) => setState(e.target.value)

const UpdateBillingAddressDialog = ({
  id,
  close,
  billingFirstName,
  billingLastName,
  billingStreet,
  billingCity,
  billingCountryIsoCode,
  billingHouseNumber,
  billingZipCode,
  billingPhoneNumber,
}) => {
  const [firstName, setFirstName] = useState(billingFirstName)
  const [lastName, setLastName] = useState(billingLastName)
  const [street, setStreet] = useState(billingStreet)
  const [city, setCity] = useState(billingCity)
  const [countryIsoCode, setCountryIsoCode] = useState(billingCountryIsoCode)
  const [houseNumber, setHouseNumber] = useState(billingHouseNumber)
  const [zipCode, setZipCode] = useState(billingZipCode)
  const [phoneNumber, setPhoneNumber] = useState(billingPhoneNumber)
  const [updateOrderMutation, executeUpdateOrderMutation] = useMutation(`
    mutation updateOrder(
      $input: UpdateOrderInput!
    ) {
      updateOrder(input: $input) {
        id
      }
    }
  `)
  return (
    <Dialog
      isShown
      isConfirmLoading={updateOrderMutation.fetching}
      title="Update billing address"
      onCloseComplete={close}
      onConfirm={async () => {
        const { error } = await executeUpdateOrderMutation({
          input: {
            id,
            billingFirstName: firstName,
            billingLastName: lastName,
            billingStreet: street,
            billingCity: city,
            billingCountryIsoCode: countryIsoCode,
            billingHouseNumber: houseNumber,
            billingZipCode: zipCode,
            billingPhoneNumber: phoneNumber,
          },
        })
        if (error) {
          urql.handleError(error)
        } else {
          close()
        }
      }}
    >
      <TextInputField
        onChange={createInputHandler(setFirstName)}
        value={firstName}
        label="First name"
        required
      />
      <TextInputField
        onChange={createInputHandler(setLastName)}
        value={lastName}
        label="Last name"
        required
      />
      <TextInputField
        onChange={createInputHandler(setStreet)}
        value={street}
        label="Street"
        required
      />
      <TextInputField
        onChange={createInputHandler(setCity)}
        value={city}
        label="City"
        required
      />
      <CountrySelect
        value={countryIsoCode}
        handleChange={setCountryIsoCode}
        isRequired
      />
      <TextInputField
        onChange={createInputHandler(setHouseNumber)}
        value={houseNumber}
        label="House number"
        required
      />
      <TextInputField
        onChange={createInputHandler(setZipCode)}
        value={zipCode}
        label="Zip code"
        required
      />
      <TextInputField
        onChange={createInputHandler(setPhoneNumber)}
        value={phoneNumber}
        label="Phone number"
        required
      />
    </Dialog>
  )
}

UpdateBillingAddressDialog.propTypes = {
  id: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  billingFirstName: PropTypes.string,
  billingLastName: PropTypes.string,
  billingStreet: PropTypes.string,
  billingCity: PropTypes.string,
  billingCountryIsoCode: PropTypes.string,
  billingHouseNumber: PropTypes.string,
  billingZipCode: PropTypes.string,
  billingPhoneNumber: PropTypes.string,
}

export default UpdateBillingAddressDialog
