import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import {
  Button,
  TextInputField,
  Label,
  Pane,
  SelectField,
  Textarea,
  majorScale,
  toaster,
} from 'evergreen-ui'
import { withRouter } from 'react-router-dom'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import Picker from 'components/Datepicker'
import { SIZES_OPTIONS } from 'constants/product'
import AdditionalImages from 'components/AdditionalImages'
import Translations from 'components/Translations'
import MainImage from 'components/MainImage'
import SellerInfo from 'components/SellerInfo'
import SellersSelect from 'components/SellersSelect'
import { getPathToWishOffer } from '../utils'

export const formatDataIntoInput = ({
  productId,
  title,
  shortDescription,
  longDescription,
  sellerId,
  productConditionId,
  reviewScore,
  size,
  warehouseDeliveryTimeInDays,
  translations,
  mainImage,
  additionalImages,
  expiresAtUtc,
  price,
  comment,
  externalPid,
}) => ({
  product: {
    id: productId,
    title,
    shortDescription,
    longDescription,
    sellerId: sellerId && parseInteger(sellerId),
    productConditionId: productConditionId && parseInteger(productConditionId),
    reviewScore: reviewScore && parseFloat(reviewScore),
    size,
    warehouseDeliveryTimeInDays:
      warehouseDeliveryTimeInDays && parseInteger(warehouseDeliveryTimeInDays),
    translations:
      translations &&
      translations.map(({ __typename, language, ...rest }) => ({
        ...rest,
        languageIsoCode: language.isoCode,
      })),
    mainImageId: mainImage && parseInteger(mainImage.id),
    additionalImagesIds: additionalImages
      ? additionalImages.map(({ id }) => parseInteger(id))
      : [],
    externalPid,
  },
  price: price && parseFloat(price),
  expiresAtUtc,
  comment,
})

const displayToast = (error, successMessage) => {
  if (error) {
    urql.handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

const Form = ({ data = {}, isReadOnly, history }) => {
  const { product = {} } = data
  const [title, setTitle] = useState(product.title || undefined)
  const [shortDescription, setShortDescription] = useState(
    product.shortDescription || undefined
  )
  const [longDescription, setLongDescription] = useState(
    product.longDescription || undefined
  )
  const [reviewScore, setReviewScore] = useState(
    product.reviewScore || undefined
  )

  const [size, setSize] = useState(product.size || undefined)
  const [price, setPrice] = useState(data.price || undefined)
  const [warehouseDeliveryTimeInDays, setWarehouseDeliveryTimeInDays] =
    useState(product.warehouseDeliveryTimeInDays || undefined)
  const [productConditionId, setProductConditionId] = useState(
    product.productCondition ? product.productCondition.id : undefined
  )
  const [sellerId, setSellerId] = useState(
    product.seller ? product.seller.id : undefined
  )
  const [translations, setTranslations] = useState(product.translations || [])
  const [mainImage, setMainImage] = useState(product.mainImage)
  const [additionalImages, setAdditionalImages] = useState(
    product.additionalImages || []
  )
  const [expiresAtUtc, setExpiresAtUtc] = useState(
    data.expiresAtUtc || undefined
  )
  const [comment, setComment] = useState(data.comment || undefined)
  const [externalPid, setExternalPid] = useState(product.externalPid)
  const [productConditionsQuery] = useQuery({
    query: `
      query {
        productConditions {
          id
          title
        }
      }
    `,
  })
  const [, executeCreateMutation] = useMutation(`
    mutation($input: CreateWishOfferInput!) {
      createWishOffer(input: $input) {
        id
      }
    }
  `)

  const [, executeUpdateMutation] = useMutation(`
    mutation($input: UpdateWishOfferInput!) {
      updateWishOffer(input: $input) {
        id
      }
    }
  `)
  const dataToSave = {
    productId: product.id,
    title,
    shortDescription,
    longDescription,
    sellerId,
    productConditionId,
    reviewScore,
    size,
    price,
    warehouseDeliveryTimeInDays,
    translations,
    mainImage,
    additionalImages,
    expiresAtUtc,
    comment,
    externalPid,
  }
  return (
    <Pane width="100%" display="grid" gridTemplateColumns="2fr 50px 1fr">
      <Pane>
        <TextInputField
          label="Title"
          disabled={isReadOnly}
          value={title || ''}
          onChange={({ target }) => {
            setTitle(target.value === '' ? undefined : target.value)
          }}
        />
        <MainImage
          mainImage={mainImage}
          setMainImage={setMainImage}
          isDisabled={isReadOnly}
        />
        <TextInputField
          label="Product ID"
          disabled={isReadOnly}
          value={externalPid || ''}
          onChange={({ target }) => {
            setExternalPid(target.value === '' ? undefined : target.value)
          }}
        />
        <Pane>
          <Label
            htmlFor="textarea-short-description"
            marginBottom={majorScale(1)}
            display="block"
          >
            Short Description
          </Label>
          <Textarea
            id="textarea-short-description"
            disabled={isReadOnly}
            value={shortDescription || ''}
            onChange={({ target }) => {
              setShortDescription(
                target.value === '' ? undefined : target.value
              )
            }}
          />
        </Pane>
        <Pane>
          <Label
            htmlFor="textarea-long-description"
            marginBottom={majorScale(1)}
            display="block"
          >
            Long Description
          </Label>
          <Textarea
            id="textarea-long-description"
            disabled={isReadOnly}
            value={longDescription || ''}
            onChange={({ target }) => {
              setLongDescription(target.value === '' ? undefined : target.value)
            }}
          />
        </Pane>
        <TextInputField
          label="Review Score"
          type="number"
          disabled={isReadOnly}
          step={0.5}
          min={0}
          max={5}
          value={reviewScore || ''}
          onChange={({ target }) => {
            setReviewScore(target.value === '' ? undefined : target.value)
          }}
        />
        <SelectField
          label="Size"
          disabled={isReadOnly}
          value={size || ''}
          onChange={({ target }) => {
            setSize(target.value === '' ? undefined : target.value)
          }}
        >
          <option value="">---Select size---</option>
          {SIZES_OPTIONS.map((sizeOption) => (
            <option key={sizeOption} value={sizeOption}>
              {sizeOption}
            </option>
          ))}
        </SelectField>
        <TextInputField
          label="Price"
          type="number"
          disabled={isReadOnly}
          value={price || ''}
          onChange={({ target }) => {
            setPrice(target.value === '' ? undefined : target.value)
          }}
        />
        <TextInputField
          label="Warehouse Delivery Time In Days"
          type="number"
          disabled={isReadOnly}
          step={1}
          value={warehouseDeliveryTimeInDays || ''}
          onChange={({ target }) => {
            setWarehouseDeliveryTimeInDays(
              target.value === '' ? undefined : target.value
            )
          }}
        />
        <Picker.DateTime
          onChangeDate={setExpiresAtUtc}
          date={expiresAtUtc}
          label="Expires At"
        />
        <SelectField
          label="Product Condition"
          value={productConditionId || ''}
          disabled={isReadOnly}
          onChange={({ target }) => {
            setProductConditionId(
              target.value === '' ? undefined : target.value
            )
          }}
        >
          <option value="">---Select Product Condition---</option>
          {productConditionsQuery.data &&
            productConditionsQuery.data.productConditions.map((condition) => (
              <option key={condition.id} value={condition.id}>
                {condition.title}
              </option>
            ))}
        </SelectField>
        {isReadOnly ? (
          <SellerInfo sellerId={sellerId} />
        ) : (
          <SellersSelect
            sellerId={sellerId}
            setSellerId={setSellerId}
            isReadOnly={isReadOnly}
          />
        )}
        <AdditionalImages
          isDisabled={isReadOnly}
          additionalImages={additionalImages}
          setAdditionalImages={setAdditionalImages}
        />
        <Translations
          isAddDisabled={isReadOnly}
          isEditDisabled={isReadOnly}
          translations={translations}
          handleSaveTranslations={setTranslations}
        />
        <Pane>
          <Label
            htmlFor="textarea-comment"
            marginBottom={majorScale(1)}
            display="block"
          >
            Comment
          </Label>
          <Textarea
            id="textarea-comment"
            disabled={isReadOnly}
            value={comment || ''}
            onChange={({ target }) => {
              setComment(target.value)
            }}
          />
        </Pane>
        <Pane marginTop={majorScale(2)} justifyContent="center" display="flex">
          <Pane>
            <Button
              intent="success"
              marginRight={majorScale(2)}
              onClick={async () => {
                if (data.id) {
                  const { error } = await executeUpdateMutation({
                    input: {
                      id: parseInteger(data.id),
                      ...formatDataIntoInput(dataToSave),
                    },
                  })
                  displayToastOnSave(error)
                } else {
                  const result = await executeCreateMutation({
                    input: {
                      ...formatDataIntoInput(dataToSave),
                      wishId: parseInteger(data.wishId),
                    },
                  })
                  displayToastOnSave(result.error)
                  if (!result.error) {
                    history.replace(
                      getPathToWishOffer({
                        wishId: data.wishId,
                        offerId: result.data.createWishOffer.id,
                      })
                    )
                  }
                }
              }}
            >
              {data.id ? 'Save' : 'Send an offer to a client'}
            </Button>
            {!data.id && (
              <Button intent="danger" onClick={() => history.goBack()}>
                Cancel an offer
              </Button>
            )}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

Form.propTypes = {
  data: PropTypes.object,
  isReadOnly: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
}

export default withRouter(Form)
