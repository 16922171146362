import React from 'react'
import { toaster } from 'evergreen-ui'

const handleError = ({ networkError, graphQLErrors = '' }) => {
  if (networkError) {
    toaster.danger(<>{networkError.message}</>)
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => toaster.danger(message))
  }
}

const displayToast = (error, successMessage) => {
  if (error) {
    handleError(error)
  } else {
    toaster.success(successMessage)
  }
}

const displayToastOnSave = (error) => {
  displayToast(error, 'Saved')
}

export default {
  handleError,
  displayToastOnSave,
  displayToast,
}
