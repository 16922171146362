import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SelectField } from 'evergreen-ui'
import get from 'lodash.get'
import { useMutation } from 'urql'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'

const UPDATE_TICKET_STATUS_MUTATION = `
  mutation threadStatus($input: UpdateThreadStatus!) {
    updateThreadStatus(input: $input) {
      threadStatus {
        id
      }
    }
  }
`
export const TicketStatus = (props) => {
  const { threadStatuses, threadStatus, threadId } = props
  const [internalThreadStatus, setInternalThreadStatus] = useState(
    get(threadStatus, 'id')
  )
  const [result, executeUpdateTicketStatusMutation] = useMutation(
    UPDATE_TICKET_STATUS_MUTATION
  )

  useEffect(() => {
    if (get(threadStatus, 'id')) {
      setInternalThreadStatus(threadStatus.id)
    }
  }, [threadStatus])

  useEffect(() => {
    const newThreadStatus = get(
      result,
      'data.updateThreadStatus.threadStatus.id'
    )
    if (newThreadStatus) {
      setInternalThreadStatus(newThreadStatus)
    }
  }, [result])

  const handleChange = async (e) => {
    const { error } = await executeUpdateTicketStatusMutation({
      input: {
        threadId: parseInteger(threadId),
        statusId: parseInteger(e.target.value),
      },
    })
    urql.displayToastOnSave(error)
  }

  return (
    <SelectField
      label="Ticket Status"
      value={internalThreadStatus}
      onChange={(e) => handleChange(e)}
    >
      <option key="-" value="">
        Select status
      </option>
      {threadStatuses.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </SelectField>
  )
}

TicketStatus.propTypes = {
  threadId: PropTypes.number.isRequired,
  threadStatus: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  threadStatuses: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}
