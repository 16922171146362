import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES, { WISH_MANAGEMENT_ROUTES } from 'constants/routes'
import { ThreadsConsumer } from 'ThreadsProvider'
import { withUser } from 'UserProvider'
import {
  withTheme,
  Pane,
  Tab,
  Tablist as DefaultTablist,
  Pill,
} from 'evergreen-ui'
import Inbox from './Inbox'
import Trash from './Trash'
import DecideLater from './DecideLater'
import Accepted from './Accepted'
import OfferCreated from './OfferCreated'
import Ordered from './Ordered'

const { INBOX, TRASH, DECIDE_LATER, ACCEPTED, OFFER_CREATED, ORDERED } =
  WISH_MANAGEMENT_ROUTES
const tabsData = [
  {
    tab: INBOX,
    name: 'Inbox',
    component: Inbox,
  },
  {
    tab: TRASH,
    name: 'Trash',
    component: Trash,
  },
  {
    tab: DECIDE_LATER,
    name: 'Decide Later',
    component: DecideLater,
  },
  {
    tab: ACCEPTED,
    name: 'Accepted',
    component: Accepted,
  },
  {
    tab: OFFER_CREATED,
    name: 'Offer created',
    component: OfferCreated,
  },
  {
    tab: ORDERED,
    name: 'Ordered',
    component: Ordered,
  },
]

const Management = ({ match, history, user, theme }) => {
  const tabs = tabsData.filter(
    ({ requiredAction }) => !requiredAction || user.hasAction(requiredAction)
  )
  const selectedTab = match.params.tab || (tabs.length && tabs[0].tab)
  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>Wishes</SideNavPage.Title>
      </SideNavPage.Header>
      <SideNavPage.Content>
        <ThreadsConsumer>
          {({ unseenWishesCount, unseenWishOffersCount }) => (
            <Pane background={theme.colors.background.tint1} paddingY="0.5em">
              <DefaultTablist>
                {tabs.map(({ tab, name }) => (
                  <Tab
                    key={tab}
                    id={tab}
                    onSelect={() => {
                      history.replace(`/${ROUTES.WISHES}/${tab}`)
                    }}
                    isSelected={selectedTab === tab}
                    aria-controls={`panel-${tab}`}
                  >
                    {name}
                    {tab === ACCEPTED && unseenWishesCount !== 0 && (
                      <Pill
                        display="inline-flex"
                        marginLeft={8}
                        color="red"
                        isSolid
                      >
                        {unseenWishesCount}
                      </Pill>
                    )}
                    {tab === OFFER_CREATED && unseenWishOffersCount !== 0 && (
                      <Pill
                        display="inline-flex"
                        marginLeft={8}
                        color="purple"
                        isSolid
                      >
                        {unseenWishOffersCount}
                      </Pill>
                    )}
                  </Tab>
                ))}
              </DefaultTablist>
            </Pane>
          )}
        </ThreadsConsumer>
        <Pane marginTop="1em" flex={1}>
          {tabs.map(
            ({ tab, component: Comp }) =>
              tab === selectedTab && <Comp key={tab} />
          )}
        </Pane>
      </SideNavPage.Content>
    </React.Fragment>
  )
}

Management.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
  theme: PropTypes.object.isRequired,
}

export default compose(withUser, withTheme)(Management)
