import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import format from 'date-fns/format'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'
import { TextInput, Label } from 'evergreen-ui'

const CustomInputElement = React.forwardRef(
  ({ value, onClick, label, disabled }, ref) => (
    <Label onClick={onClick}>
      {label}
      <TextInput
        style={{ width: '100%', marginTop: '5px' }}
        value={value}
        ref={ref}
        readOnly
        disabled={disabled}
      />
    </Label>
  )
)

CustomInputElement.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

const Datepicker = ({
  onChangeDate,
  date,
  label,
  minDate,
  maxDate = null,
  isDisabled,
}) => {
  let isValid = true
  let initialDate = null

  if (date) {
    initialDate = new Date(date)
    isValid = !Number.isNaN(initialDate.valueOf())
  }

  return (
    <DatePicker
      selected={isValid ? initialDate : null}
      onChange={(newDate) => {
        if (newDate) {
          const formated = format(newDate)
          onChangeDate(formated)
        } else {
          onChangeDate(null)
        }
      }}
      disabled={isDisabled}
      dateFormat="dd/MM/yyyy"
      customInput={<CustomInputElement disabled={isDisabled} label={label} />}
      minDate={minDate}
      maxDate={maxDate}
    />
  )
}

Datepicker.propTypes = {
  onChangeDate: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  isDisabled: PropTypes.bool,
}

const DateTime = ({
  onChangeDate,
  date,
  label,
  minDate,
  maxDate,
  isDisabled,
}) => (
  <DatePicker
    selected={date ? new Date(date) : null}
    onChange={(newDate) => {
      if (newDate) {
        const formated = format(newDate)
        onChangeDate(formated)
      } else {
        onChangeDate(null)
      }
    }}
    disabled={isDisabled}
    dateFormat="dd/MM/yyyy HH:mm"
    showTimeSelect
    timeFormat="HH:mm"
    minDate={minDate}
    maxDate={maxDate}
    customInput={<CustomInputElement disabled={isDisabled} label={label} />}
  />
)

DateTime.propTypes = {
  onChangeDate: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  isDisabled: PropTypes.bool,
}

const MonthPicker = ({
  date,
  onChangeDate,
  label,
  minDate,
  maxDate,
  isDisabled,
}) => (
  <DatePicker
    selected={date ? new Date(date) : null}
    onChange={(newDate) => {
      if (newDate) {
        const formated = format(newDate)
        onChangeDate(formated)
      } else {
        onChangeDate(null)
      }
    }}
    disabled={isDisabled}
    dateFormat="MMMM yyyy"
    showMonthYearPicker
    minDate={minDate}
    maxDate={maxDate}
    customInput={<CustomInputElement disabled={isDisabled} label={label} />}
  />
)

MonthPicker.propTypes = {
  onChangeDate: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  isDisabled: PropTypes.bool,
}

export default {
  Date: Datepicker,
  DateTime,
  MonthPicker,
}
