import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import UserDetail from 'routes/UserDetail'
import Users from 'routes/Users'
import CustomerDetail from 'routes/CustomerDetail'
import Customers from 'routes/Customers'
import Personalisation from 'routes/Personalisation'
import Orders from 'routes/Orders'
import OldOrders from 'routes/OldOrders'
import NewOldOrder from 'routes/OldOrders/NewOldOrder'
import Languages from 'routes/Languages'
import SignIn from 'routes/SignIn'
import ForgottenPassword from 'routes/ForgottenPassword'
import ResetPassword from 'routes/ResetPassword'
import Countries from 'routes/Countries'
import CountryDetail from 'routes/CountryDetail'
import SetPassword from 'routes/SetPassword'
import ProductImages from 'routes/ProductImages'
import DealOfTheDays from 'routes/DealOfTheDays'
import DealOfTheWeeks from 'routes/DealOfTheWeeks'
import BestSellers from 'routes/BestSellers'
import Sellers from 'routes/Sellers'
import ProductConditions from 'routes/ProductConditions'
import Currencies from 'routes/Currencies'
import CurrenciesRates from 'routes/CurrenciesRates'
import ROUTES from 'constants/routes'
import OrderDetail from 'routes/OrderDetail'
import Messages from 'routes/Messages'
import MessageDetail from 'routes/MessageDetail'
import TicketDetail from 'routes/TicketDetail'
import Categories from 'routes/Categories'
import Documents from 'routes/Documents'
import Wishes from 'routes/Wishes'
import Logs from 'routes/Logs'
import Home from 'routes/Home'
import WishCategories from 'routes/WishCategories'
import ThreadsProvider from 'ThreadsProvider'

import { Provider as UrqlProvider, createClient, defaultExchanges } from 'urql'
import { devtoolsExchange } from '@urql/devtools'
import Feedback from 'routes/Feedback'
import AdditionalServices from 'routes/AdditionalServices'
import HeroBanners from 'routes/HeroBanners'
import ThemesOfTheMonth from 'routes/ThemesOfTheMonth'
import Attributes from 'routes/Attributes'
import AttributeValues from 'routes/AttributeValues'
import Feeds from 'routes/Feeds'
import Product from 'routes/Product'
import PhoneService from 'routes/PhoneService'
import Settings from 'routes/Settings'
import ACTIONS from 'constants/actions'
import SideNavPage from 'sharedComponents/SideNavPage'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'

import 'react-datepicker/dist/react-datepicker.css'
import 'globalStyles.css'
import NewBestSeller from 'routes/BestSellers/NewBestSeller/NewBestSeller'
import EditBestSeller from 'routes/BestSellers/EditBestSeller/EditBestSeller'
import { UserProvider, UserConsumer, getAccessToken } from './UserProvider'

const client = createClient({
  url: process.env.REACT_APP_API_URL,
  requestPolicy: 'network-only',
  exchanges: [devtoolsExchange, multipartFetchExchange, ...defaultExchanges],
  fetchOptions: () => {
    const accessToken = getAccessToken()
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  },
})

const App = () => {
  const getAvailableRedirectPage = (hasAction) => {
    if (hasAction(ACTIONS.DEAL_OF_THE_DAY.PAGE)) {
      return ROUTES.DEAL_OF_THE_DAYS
    }
    if (hasAction(ACTIONS.DEAL_OF_THE_WEEK.PAGE)) {
      return ROUTES.DEAL_OF_THE_WEEKS
    }
    if (hasAction(ACTIONS.DEAL_OF_THE_MONTH.PAGE)) {
      return ROUTES.DEAL_OF_THE_MONTHS
    }
    if (hasAction(ACTIONS.WISH.PAGE)) {
      return ROUTES.WISHES
    }
    return ''
  }
  return (
    <UrqlProvider value={client}>
      <UserProvider>
        <UserConsumer>
          {({ isSignedIn, isUserChecked, accessToken, hasAction }) => {
            let routes
            if (!isUserChecked) {
              routes = null
            } else if (isSignedIn) {
              routes = (
                <ThreadsProvider accessToken={accessToken}>
                  <SideNavPage>
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <Route
                        path={`/${ROUTES.USERS}/:id`}
                        component={UserDetail}
                      />
                      <Route path={`/${ROUTES.USERS}`} component={Users} />
                      <Route
                        path={`/${ROUTES.CUSTOMERS}/:id`}
                        component={CustomerDetail}
                      />
                      <Route
                        path={`/${ROUTES.CUSTOMERS}`}
                        component={Customers}
                      />
                      <Route
                        path={`/${ROUTES.PERSONALISATION}`}
                        component={Personalisation}
                      />
                      <Route
                        path={`/${ROUTES.ORDERS}/:orderId`}
                        component={OrderDetail}
                      />
                      <Route path={`/${ROUTES.ORDERS}`} component={Orders} />
                      <Route
                        path={`/${ROUTES.OLD_ORDERS}/new`}
                        component={NewOldOrder}
                      />
                      <Route
                        path={`/${ROUTES.OLD_ORDERS}`}
                        component={OldOrders}
                      />
                      <Route
                        path={`/${ROUTES.LANGUAGES}`}
                        component={Languages}
                      />
                      <Route
                        path={`/${ROUTES.CURRENCIES}`}
                        component={Currencies}
                      />
                      <Route
                        path={`/${ROUTES.CURRENCIES_RATES}`}
                        component={CurrenciesRates}
                      />
                      <Route
                        path={`/${ROUTES.MESSAGES}/:threadId`}
                        component={MessageDetail}
                      />
                      <Route
                        path={`/${ROUTES.MESSAGES}`}
                        component={Messages}
                      />
                      <Route
                        path={`/${ROUTES.TICKETS}/:ticketId`}
                        component={TicketDetail}
                      />
                      <Route
                        path={`/${ROUTES.DEAL_OF_THE_DAYS}`}
                        component={DealOfTheDays}
                      />
                      <Route
                        path={`/${ROUTES.DEAL_OF_THE_WEEKS}`}
                        component={DealOfTheWeeks}
                      />
                      <Route
                        path={`/${ROUTES.BEST_SELLERS}/new`}
                        component={NewBestSeller}
                      />
                      <Route
                        path={`/${ROUTES.BEST_SELLERS}/edit/:id`}
                        component={EditBestSeller}
                      />
                      <Route
                        path={`/${ROUTES.BEST_SELLERS}`}
                        component={BestSellers}
                      />
                      <Route
                        path={`/${ROUTES.COUNTRIES}/:isoCode`}
                        component={CountryDetail}
                      />
                      <Route
                        path={`/${ROUTES.COUNTRIES}`}
                        component={Countries}
                      />
                      <Route
                        path={`/${ROUTES.PRODUCT_IMAGES}`}
                        component={ProductImages}
                      />
                      <Route path={`/${ROUTES.SELLERS}`} component={Sellers} />
                      <Route
                        path={`/${ROUTES.CATEGORIES}`}
                        component={Categories}
                      />
                      <Route
                        path={`/${ROUTES.PRODUCT_CONDITIONS}`}
                        component={ProductConditions}
                      />
                      <Route
                        path={`/${ROUTES.DOCUMENTS}`}
                        component={Documents}
                      />
                      <Route path={`/${ROUTES.WISHES}`} component={Wishes} />
                      <Route path={`/${ROUTES.LOGS}`} component={Logs} />
                      <Route
                        path={`/${ROUTES.WISH_CATEGORIES}`}
                        component={WishCategories}
                      />
                      <Route
                        path={`/${ROUTES.THEME_OF_THE_MONTH}`}
                        component={ThemesOfTheMonth}
                      />
                      <Route
                        path={`/${ROUTES.FEEDBACKS}`}
                        component={Feedback}
                      />
                      <Route
                        path={`/${ROUTES.ADDITIONAL_SERVICES}`}
                        component={AdditionalServices}
                      />
                      <Route
                        path={`/${ROUTES.ATTRIBUTE_VALUES}/:attributeId`}
                        component={AttributeValues}
                      />
                      <Route
                        path={`/${ROUTES.ATTRIBUTES}`}
                        component={Attributes}
                      />
                      <Route path={`/${ROUTES.FEEDS}`} component={Feeds} />
                      <Route path={`/${ROUTES.PRODUCTS}`} component={Product} />
                      <Route
                        path={`/${ROUTES.PHONE_SERVICE}`}
                        component={PhoneService}
                      />
                      <Route
                        path={`/${ROUTES.HERO_BANNERS}`}
                        component={HeroBanners}
                      />
                      <Route
                        path={`/${ROUTES.SETTINGS}`}
                        component={Settings}
                      />
                      <Redirect
                        to={`/${getAvailableRedirectPage(hasAction)}`}
                      />
                    </Switch>
                  </SideNavPage>
                </ThreadsProvider>
              )
            } else {
              routes = (
                <Switch>
                  <Route path={`/${ROUTES.SIGN_IN}`} component={SignIn} />
                  <Route
                    path={`/${ROUTES.FORGOTTEN_PASSWORD}`}
                    component={ForgottenPassword}
                  />
                  <Route
                    path={`/${ROUTES.RESET_PASSWORD}/:resetPasswordToken`}
                    component={ResetPassword}
                  />
                  <Route
                    path={`/${ROUTES.SET_PASSWORD}/:setPasswordToken`}
                    component={SetPassword}
                  />
                  <Redirect to={`/${ROUTES.SIGN_IN}`} />
                </Switch>
              )
            }
            return <Router>{routes}</Router>
          }}
        </UserConsumer>
      </UserProvider>
    </UrqlProvider>
  )
}

export default App
