import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'urql'
import { NavLink } from 'react-router-dom'
import format from 'date-fns/format'
import { withUser } from 'UserProvider'
import {
  Alert,
  Small,
  Heading,
  Text,
  Spinner,
  Pane,
  minorScale,
  BackButton,
  Button,
  majorScale,
  Dialog,
  Link,
  Badge,
  TextInputField,
  TextareaField,
  Icon,
} from 'evergreen-ui'

import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'
import SideNavPage from 'sharedComponents/SideNavPage'
import ROUTES, {
  BEST_SELLER_ROUTES,
  DOD_ROUTES,
  DOW_ROUTES,
  THEME_DEAL_ROUTES,
} from 'constants/routes'
import styled from 'styled-components/macro'
import {
  TIME_FORMAT,
  QUERY_TYPES,
  PUBLIC_ORDER_STATUSES,
  ORDER_STATUSES,
  RETURN_ORDER_STATUSES,
  PAYMENT_INTENT_STATUSES,
  STRIPE_URL,
} from 'constants/constants'
import OrderStatus from 'components/OrderStatus'
import ReturnOrderStatus from 'components/ReturnOrderStatus'
import { formatPrice } from 'utils/money'
import ACTIONS from 'constants/actions'
import { getPathToWishOffer } from 'routes/Wishes/utils'
import { getPathToProductVariant } from 'routes/Product/utils'
import Picker from 'components/Datepicker'
import Lightbox from 'react-image-lightbox'
import Panel from './components/Panel'
import ItemImage from './components/ItemImage'
import UpdateShippingAddressDialog from './components/UpdateShippingAddressDialog'
import UpdateBillingAddressDialog from './components/UpdateBillingAddressDialog'
import UpdateReturnDetailDialog from './components/UpdateReturnDetailDialog'
import UpdateAddressHeader from './components/UpdateAddressHeader'
import StripeInformation from './components/StripeInformation'
import 'react-image-lightbox/style.css'
import { CREATE_RETURN_REJECT_REASON } from './graphql'

export const UPDATE_ADDRESS_DIALOG_TYPE = {
  SHIPPING_ADDRESS: 'SHIPPING_ADDRESS',
  BILLING_ADDRESS: 'BILLING_ADDRESS',
  DETAIL_RETURN: 'DETAIL_RETURN',
}

const SUPPORTED_PACKETA_TRANSLATIONS = [
  'cs',
  'de',
  'hu',
  'sk',
  'pl',
  'ro',
  'uk',
  'es',
  'fr',
  'pt',
  'ru',
  'sv',
  'el',
  'it',
  'bg',
  'sl',
  'hr',
  'lv',
  'lt',
  'et',
  'da',
  'fi',
  'en',
]
const TRACKING_LINK_FALLBACK_LANGUAGE = 'en'

const PreviewImage = styled.div`
  height: 100px;
  margin-bottom: 8px;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

const PreviewListImage = styled.div`
  height: 100px;
  width: 100px;
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

const StyledPane = styled.div`
  display: flex;
  align-items: center;
  background-color: #0099ff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 8px;
  &:hover {
    cursor: pointer;
  }
`

const { SHIPPING_ADDRESS, BILLING_ADDRESS, DETAIL_RETURN } =
  UPDATE_ADDRESS_DIALOG_TYPE

const TABLE_MIN_HEIGHT = 500
const photoIndex = 0

const containsMinTwoWords = (text) => text.match(/^\s*\S+(?:\s+\S+){1,}\s*$/gm)

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}))

const getLabelByStatus = (status) => {
  switch (status) {
    case PUBLIC_ORDER_STATUSES.WAITING_PAYMENT:
      return 'Waiting Payment'
    case PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION:
      return 'Waiting Verification'
    case PUBLIC_ORDER_STATUSES.PROCESSING:
      return 'Processing'
    case PUBLIC_ORDER_STATUSES.SHIPPING:
      return 'Shipping'
    case PUBLIC_ORDER_STATUSES.COMPLETED:
      return 'Completed'
    case PUBLIC_ORDER_STATUSES.ON_HOLD:
      return 'On Hold'
    case PUBLIC_ORDER_STATUSES.REFUNDED:
      return 'Refunded'
    case PUBLIC_ORDER_STATUSES.CANCELLED_BY_USER:
      return 'Canceled By User'
    case PUBLIC_ORDER_STATUSES.CANCELLED:
      return 'Canceled'
    default:
      return status
  }
}

const getSteps = (publicOrderStatus) => {
  switch (publicOrderStatus) {
    case PUBLIC_ORDER_STATUSES.WAITING_PAYMENT:
      return [
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_PAYMENT),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.PROCESSING),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.SHIPPING),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.COMPLETED),
      ]
    case PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION:
      return [
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_PAYMENT),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.PROCESSING),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.SHIPPING),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.COMPLETED),
      ]
    case PUBLIC_ORDER_STATUSES.PROCESSING:
      return [
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_PAYMENT),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.PROCESSING),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.SHIPPING),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.COMPLETED),
      ]
    case PUBLIC_ORDER_STATUSES.SHIPPING:
      return [
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_PAYMENT),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.PROCESSING),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.SHIPPING),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.COMPLETED),
      ]
    case PUBLIC_ORDER_STATUSES.COMPLETED:
      return [
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_PAYMENT),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.PROCESSING),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.SHIPPING),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.COMPLETED),
      ]
    case PUBLIC_ORDER_STATUSES.ON_HOLD:
      return [
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_PAYMENT),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.ON_HOLD),
      ]
    case PUBLIC_ORDER_STATUSES.REFUNDED:
      return [
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_PAYMENT),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.REFUNDED),
      ]
    case PUBLIC_ORDER_STATUSES.CANCELLED_BY_USER:
    case PUBLIC_ORDER_STATUSES.CANCELLED:
      return [
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_PAYMENT),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION),
        getLabelByStatus(PUBLIC_ORDER_STATUSES.CANCELLED),
      ]
    default:
      return [getLabelByStatus(PUBLIC_ORDER_STATUSES.CANCELLED)]
  }
}

const setActiveStep = (publicOrderStatus) => {
  switch (publicOrderStatus) {
    case PUBLIC_ORDER_STATUSES.WAITING_PAYMENT:
      return 0
    case PUBLIC_ORDER_STATUSES.WAITING_VERIFICATION:
      return 1
    case PUBLIC_ORDER_STATUSES.PROCESSING:
      return 2
    case PUBLIC_ORDER_STATUSES.SHIPPING:
      return 3
    case PUBLIC_ORDER_STATUSES.COMPLETED:
      return 5
    case PUBLIC_ORDER_STATUSES.ON_HOLD:
      return 3
    case PUBLIC_ORDER_STATUSES.REFUNDED:
      return 3
    case PUBLIC_ORDER_STATUSES.CANCELLED_BY_USER:
    case PUBLIC_ORDER_STATUSES.CANCELLED:
      return 3
    default:
      return 0
  }
}

const getLabelByReturnStatus = (status) => {
  switch (status) {
    case RETURN_ORDER_STATUSES.REQUESTED:
      return 'Request Being Evaluated'
    case RETURN_ORDER_STATUSES.REJECTED:
      return 'Request Rejected'
    case RETURN_ORDER_STATUSES.ELIGIBLE:
      return 'Eligible for Return'
    case RETURN_ORDER_STATUSES.SENT:
      return 'Package Sent'
    case RETURN_ORDER_STATUSES.RECEIVED:
      return 'Package Investigated by Beneko'
    case RETURN_ORDER_STATUSES.APPROVED:
      return 'Return is Approved, Process Refund'
    case RETURN_ORDER_STATUSES.REFUNDED:
      return 'Refund Success'
    default:
      return ''
  }
}

const getLabelByPaymentIntentStatus = (status) => {
  switch (status) {
    case PAYMENT_INTENT_STATUSES.SUCCEEDED:
      return 'Success'
    case PAYMENT_INTENT_STATUSES.REQUIRES_PAYMENT_METHOD:
      return 'Waiting for Payment'
    case PAYMENT_INTENT_STATUSES.REQUIRES_ACTION:
      return 'Required action'
    default:
      return ''
  }
}

const getReturnSteps = () => [
  getLabelByReturnStatus(RETURN_ORDER_STATUSES.REQUESTED),
  getLabelByReturnStatus(RETURN_ORDER_STATUSES.REJECTED),
  getLabelByReturnStatus(RETURN_ORDER_STATUSES.ELIGIBLE),
  getLabelByReturnStatus(RETURN_ORDER_STATUSES.SENT),
  getLabelByReturnStatus(RETURN_ORDER_STATUSES.RECEIVED),
  getLabelByReturnStatus(RETURN_ORDER_STATUSES.APPROVED),
  getLabelByReturnStatus(RETURN_ORDER_STATUSES.REFUNDED),
]

const setActiveReturnStep = (returnOrderStatus) => {
  switch (returnOrderStatus) {
    case RETURN_ORDER_STATUSES.REQUESTED:
      return 0
    case RETURN_ORDER_STATUSES.ELIGIBLE:
      return 1
    case RETURN_ORDER_STATUSES.SENT:
      return 2
    case RETURN_ORDER_STATUSES.RECEIVED:
      return 3
    case RETURN_ORDER_STATUSES.APPROVED:
      return 4
    case RETURN_ORDER_STATUSES.REFUNDED:
      return 6
    case RETURN_ORDER_STATUSES.REJECTED:
      return 7
    default:
      return 0
  }
}

const openZasilkovnaTrackingPage = (
  trackingNumber,
  customerLanguageIsoCode
) => {
  const isLanguageSupported = SUPPORTED_PACKETA_TRANSLATIONS.includes(
    customerLanguageIsoCode
  )

  const zasielkovnaTrackingUrl = `https://tracking.packeta.com/${
    isLanguageSupported
      ? customerLanguageIsoCode
      : TRACKING_LINK_FALLBACK_LANGUAGE
  }/?id=${trackingNumber}`

  window.open(zasielkovnaTrackingUrl, '_blank')
}

const setReturnAttachments = (returnAttachment) => {
  const attachments = []
  if (returnAttachment) {
    returnAttachment.forEach(({ url }) => {
      attachments.push(url)
    })
  }
  return attachments
}

const initialDatetime = new Date()

const OrderDetail = (props) => {
  const {
    history,
    match: { params },
    user,
  } = props
  const [addressDialogType, setAddressDialogType] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [isUpdateShipping, setIsUpdateShipping] = useState(false)
  const [trackingNumber, setTrackingNumber] = useState(null)
  const [newOrderStatus, setNewOrderStatus] = useState(null)
  const [newReturnOrderStatus, setNewReturnOrderStatus] = useState(null)
  const [shippingDate, setShippingDate] = useState(initialDatetime)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const [returnAttachmentIndex, setReturnAttachmentIndex] = useState(0)

  const [isChangeOrderStatusModalOpened, setIsChangeOrderStatusModalOpened] =
    useState(false)
  const [
    isChangeReturnOrderStatusModalOpened,
    setIsChangeReturnOrderStatusModalOpened,
  ] = useState(false)
  const [isRejectReasonNotGiven, setIsRejectReasonNotGiven] = useState(true)
  const [rejectReason, setRejectReason] = useState('')
  const [isOpenLightImage, setIsOpenLightImage] = useState(false)
  const [
    isOpenLightImageReturnAttachment,
    setIsOpenLightImageReturnAttachment,
  ] = useState(false)
  const orderId = parseInteger(params.orderId)
  const [changeOrderStatusMutation, executeChangeOrderStatusMutation] =
    useMutation(`
    mutation($input: ChangeOrderStatusInput!) {
      changeOrderStatus(input: $input)
    }
  `)

  const [
    changeReturnOrderStatusMutation,
    executeChangeReturnOrderStatusMutation,
  ] = useMutation(`
    mutation($input: ChangeReturnOrderStatusInput!) {
      changeReturnOrderStatus(input: $input)
    }
  `)

  const [createReturnRejectReason, executeReturnRejectReason] = useMutation(
    CREATE_RETURN_REJECT_REASON
  )

  const [
    updateZasilkovnaTrackingNumberMutation,
    executeUpdateZasilkovnaTrackingNumber,
  ] = useMutation(`
    mutation($input: UpdateOrderZasilkovnaTrackingNumberInput!) {
      updateOrderZasilkovnaTrackingNumber(input: $input){
        status
        orderId
        trackingNumber
      }
    }
  `)

  const [returnOrderMutation, executeReturnOrderMutation] = useMutation(`
    mutation($input: ReturnOrderInput!) {
      returnOrder(input: $input)
    }
  `)

  const [canceledReturnOrderMutation, executeCanceledReturnOrderMutation] =
    useMutation(`
    mutation($input: CanceledReturnOrderInput!) {
      canceledReturnOrder(input: $input)
    }
  `)

  const [orderQuery, executeOrderQuery] = useQuery({
    requestPolicy: 'network-only',
    query: `
      query userOrder{
        userOrder(id: ${orderId}) {
            id
            shoppingCartItemId
            vatRate
            totalPriceTotal
            totalPricePaid
            priceBase
            priceVat
            priceTotal
            currencyIsoCode
            shippingCostBase
            shippingCostVat
            shippingCostTotal
            deliveryTimeInDays
            shippingFirstName
            shippingLastName
            shippingStreet
            shippingCity
            shippingHouseNumber
            shippingZipCode
            billingFirstName
            billingLastName
            billingStreet
            billingCity
            billingHouseNumber
            billingZipCode
            shippingPhoneNumber
            billingPhoneNumber
            shippingComment
            customerEmail
            billingCountry{
              title
              isoCode
            }
            shippingCountry{
              title
              isoCode
            }
            status
            publicOrderStatus
            proofPaymentFilename
            proofPaymentUrl
            proofPaymentFilenameExtension
            proofUploadedAtUtc
            sellerMarketplacePercentageFee
            sellerCompanyName
            sellerCompanyRegistrationNumber
            sellerVatGroup
            customer {
              id
              fullName
              email
              createdAtUtc
              language {
                isoCode
              }
            }
            availableStatuses
            availableReturnStatuses
            threadId
            device {
              appVersion
              brand
              model
              systemName
              systemVersion
            }
            product {
              __typename
              ...on OrderWishOffer {
                  id
                  wishId
                  longDescription
                  shortDescription
                  title
                  mainImage{
                    url
                    name
                  }
              }
              ...on OrderPublicDealOfTheDay {
                  id
                  longDescription
                  shortDescription
                  title
                  mainImage{
                    url
                    name
                  }
                }
                ...on OrderPublicDealOfTheWeek {
                  id
                  longDescription
                  shortDescription
                  title
                  mainImage{
                    url
                    name
                  }
                }
                ...on OrderPublicThemeDeal {
                  id
                  longDescription
                  shortDescription
                  title
                  mainImage{
                    url
                    name
                  }
                  themeOfTheMonthId
                }
                ...on OrderPublicBestSeller {
                  id
                  longDescription
                  shortDescription
                  title
                  mainImage {
                    url
                    name
                  }
                }
                ...on OrderPublicPhoneService {
                  id
                  productId
                  longDescription
                  shortDescription
                  title
                  variantImage {
                    url
                    name
                  }
              }
            }
            createdAtUtc
            zasilkovnaTrackingNumber
            shippedAtUtc
            serialNumber
            additionalServices {
              id
              additionalServiceId
              title
              priceBase
              priceVat
              priceTotal
            }
            reasonReturn {
              id
              text
            }
            returnStatus
            descProblem
            returnAttachment {
              id
              name
              url
            }
            returnRequestedAtUtc
            returnPackageSentAtUtc
            returnRecievedByBenekoAtUtc
            returnApprovedAtUtc
            returnIssuedAtUtc
            returnRefundedAtUtc
            returnTrackingNumber
            returnIban
            returnRecipientName
            stripePayment {
              paymentIntentId
              paymentIntentStatus
            }
        }
      }
    `,
  })

  useEffect(() => {
    const error =
      returnOrderMutation.error ||
      canceledReturnOrderMutation.error ||
      orderQuery.error ||
      changeReturnOrderStatusMutation.error ||
      changeOrderStatusMutation.error ||
      updateZasilkovnaTrackingNumberMutation.error ||
      createReturnRejectReason.error
    if (error) {
      urql.handleError(error)
    }
  }, [
    returnOrderMutation.error,
    canceledReturnOrderMutation.error,
    changeReturnOrderStatusMutation.error,
    changeOrderStatusMutation.error,
    updateZasilkovnaTrackingNumberMutation.error,
    orderQuery.error,
    createReturnRejectReason.error,
  ])

  const userOrder = (orderQuery.data && orderQuery.data.userOrder) || {}
  const { currencyIsoCode } = userOrder
  const closeAddressDialog = () => {
    setAddressDialogType(null)
    executeOrderQuery()
  }

  const returnStatusDialogOnConfirm = async () => {
    setIsFetching(true)
    await executeChangeReturnOrderStatusMutation({
      input: {
        id: orderId,
        status: newReturnOrderStatus,
      },
    })
    if (newReturnOrderStatus === RETURN_ORDER_STATUSES.REJECTED) {
      setIsRejectReasonNotGiven(true)
      executeReturnRejectReason({
        input: {
          orderId,
          reason: rejectReason,
        },
      })
    }
    await executeOrderQuery()
    setIsChangeReturnOrderStatusModalOpened(false)
    setNewReturnOrderStatus(null)
    setIsFetching(false)
  }

  const proofPaymentImage = [userOrder.proofPaymentUrl]

  const returnAttachments = setReturnAttachments(userOrder.returnAttachment)

  const activeStep = setActiveStep(userOrder.publicOrderStatus)
  const classesStepper = useStyles()
  const steps = getSteps(userOrder.publicOrderStatus)

  const returnSteps = getReturnSteps(userOrder.returnStatus)
  const activeReturnStep = setActiveReturnStep(userOrder.returnStatus)

  const { device, stripePayment } = userOrder

  const isNotCancelledStatus =
    userOrder.publicOrderStatus === PUBLIC_ORDER_STATUSES.CANCELLED_BY_USER ||
    userOrder.publicOrderStatus === PUBLIC_ORDER_STATUSES.CANCELLED

  return (
    <React.Fragment>
      <SideNavPage.Header>
        <SideNavPage.Title>{`Order #${orderId} Detail`}</SideNavPage.Title>
        <div>
          {userOrder.threadId && user.hasAction(ACTIONS.THREAD.DETAIL) && (
            <Button
              onClick={() =>
                history.push(`/${ROUTES.MESSAGES}/${userOrder.threadId}`)
              }
              marginRight={majorScale(1)}
            >
              Go to order messages
            </Button>
          )}
          {userOrder.returnStatus === RETURN_ORDER_STATUSES.NOT_RETURN &&
            userOrder.publicOrderStatus === PUBLIC_ORDER_STATUSES.COMPLETED &&
            user.hasAction(ACTIONS.ORDER.RETURN_ORDER) && (
              <Button
                marginRight={majorScale(1)}
                appearance="primary"
                intent="none"
                isLoading={isButtonLoading}
                onClick={async () => {
                  await setIsButtonLoading(true)
                  await executeReturnOrderMutation({
                    input: {
                      orderId: userOrder.id,
                    },
                  })

                  await executeOrderQuery()
                  await setIsButtonLoading(false)
                }}
              >
                Return Order
              </Button>
            )}
          {userOrder.returnStatus !== RETURN_ORDER_STATUSES.NOT_RETURN &&
            user.hasAction(ACTIONS.ORDER.CANCELED_RETURN) && (
              <Button
                marginRight={majorScale(1)}
                appearance="primary"
                intent="danger"
                isLoading={isButtonLoading}
                onClick={async () => {
                  await setIsButtonLoading(true)
                  await executeCanceledReturnOrderMutation({
                    input: {
                      orderId: userOrder.id,
                    },
                  })

                  await executeOrderQuery()
                  await setIsButtonLoading(false)
                }}
              >
                Canceled Return
              </Button>
            )}
          <BackButton onClick={() => history.goBack()} />
        </div>
      </SideNavPage.Header>
      <SideNavPage.Content>
        {orderQuery.fetching && (
          <Spinner marginX="auto" marginY={TABLE_MIN_HEIGHT / 2} size={80} />
        )}
        {userOrder && (
          <Pane
            maxWidth={780}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Pane
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              {isNotCancelledStatus && (
                <Alert
                  intent="danger"
                  title="Canceled Order"
                  margin={5}
                  style={{ width: '100%' }}
                />
              )}

              {userOrder.publicOrderStatus ===
                PUBLIC_ORDER_STATUSES.REFUNDED && (
                <Alert
                  intent="none"
                  title="Refunded Order"
                  margin={5}
                  style={{ width: '100%' }}
                />
              )}

              {userOrder.publicOrderStatus ===
                PUBLIC_ORDER_STATUSES.ON_HOLD && (
                <Alert
                  intent="warning"
                  title="Order On Hold"
                  margin={5}
                  style={{ width: '100%' }}
                />
              )}

              {(userOrder.publicOrderStatus !==
                PUBLIC_ORDER_STATUSES.CANCELLED ||
                userOrder.publicOrderStatus !==
                  PUBLIC_ORDER_STATUSES.CANCELLED_BY_USER) &&
                userOrder.publicOrderStatus !==
                  PUBLIC_ORDER_STATUSES.REFUNDED &&
                userOrder.publicOrderStatus !==
                  PUBLIC_ORDER_STATUSES.ON_HOLD && (
                  <div className={classesStepper.root}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                )}

              <Panel>
                <Pane paddingBottom={15}>
                  <Heading>Default Info</Heading>
                </Pane>
                <Text>{`Order Id: ${orderId}`}</Text>
                <Text>
                  {`Shopping Cart ID: ${userOrder.shoppingCartItemId}`}
                </Text>
                <Text>
                  {`Created: ${format(
                    userOrder.createdAtUtc,
                    TIME_FORMAT.LOCAL_DATETIME
                  )}`}
                </Text>
                <Text>
                  {`Delivery Time In Days: ${userOrder.deliveryTimeInDays}`}
                </Text>
                {userOrder.zasilkovnaTrackingNumber && (
                  <Text>
                    {`Tracking Number: ${userOrder.zasilkovnaTrackingNumber}`}
                  </Text>
                )}
                {userOrder.serialNumber && (
                  <Text>{`Serial Number: ${userOrder.serialNumber}`}</Text>
                )}
                {device && (
                  <Text>
                    {`Device info: ${device.brand}, ${device.model}, ${device.systemName}, ${device.systemVersion}, ${device.appVersion}`}
                  </Text>
                )}
                {userOrder.zasilkovnaTrackingNumber && (
                  <Button
                    width="50%"
                    marginTop="10px"
                    appearance="primary"
                    intent="success"
                    onClick={() => {
                      openZasilkovnaTrackingPage(
                        userOrder.zasilkovnaTrackingNumber,
                        userOrder.customer.language.isoCode
                      )
                    }}
                  >
                    Tracking Order
                  </Button>
                )}
              </Panel>
              <Panel>
                <Pane paddingBottom={15}>
                  <Heading>Seller Info</Heading>
                </Pane>
                {userOrder.sellerCompanyName && (
                  <Text>{userOrder.sellerCompanyName}</Text>
                )}
                {userOrder.sellerCompanyRegistrationNumber && (
                  <Text>{userOrder.sellerCompanyRegistrationNumber}</Text>
                )}
                {userOrder.sellerVatGroup && (
                  <Text>{userOrder.sellerVatGroup}</Text>
                )}
              </Panel>
              <Panel>
                <UpdateAddressHeader
                  onClick={() => {
                    setAddressDialogType(SHIPPING_ADDRESS)
                  }}
                  isWithButton={user.hasAction(ACTIONS.ORDER.UPDATE)}
                  title="Shipping Address"
                />
                {userOrder.shippingFirstName && (
                  <Text>
                    {`${userOrder.shippingFirstName} ${userOrder.shippingLastName}`}
                  </Text>
                )}
                {!!userOrder.shippingPhoneNumber && (
                  <Text>{userOrder.shippingPhoneNumber}</Text>
                )}
                {userOrder.shippingStreet && (
                  <Text>
                    {`${userOrder.shippingStreet} ${userOrder.shippingHouseNumber}`}
                  </Text>
                )}
                {userOrder.shippingCity && (
                  <Text>{userOrder.shippingCity}</Text>
                )}
                {userOrder.shippingZipCode && (
                  <Text>{userOrder.shippingZipCode}</Text>
                )}
                {userOrder.shippingCountry && (
                  <Text>{userOrder.shippingCountry.title}</Text>
                )}
                {userOrder.shippingComment && (
                  <Pane>
                    <Pane marginTop={10} marginBottom={2}>
                      <Heading size={400}>Addition informations:</Heading>
                    </Pane>
                    <Text>{userOrder.shippingComment}</Text>
                  </Pane>
                )}
              </Panel>
              <Panel>
                <UpdateAddressHeader
                  onClick={() => {
                    setAddressDialogType(BILLING_ADDRESS)
                  }}
                  isWithButton={user.hasAction(ACTIONS.ORDER.UPDATE)}
                  title="Billing Info"
                />
                {userOrder.billingFirstName && (
                  <Text>
                    {`${userOrder.billingFirstName} ${userOrder.billingLastName}`}
                  </Text>
                )}
                {!!userOrder.billingPhoneNumber && (
                  <Text>{userOrder.billingPhoneNumber}</Text>
                )}
                {userOrder.billingStreet && (
                  <Text>
                    {`${userOrder.billingStreet} ${userOrder.billingHouseNumber}`}
                  </Text>
                )}
                {userOrder.billingCity && <Text>{userOrder.billingCity}</Text>}
                {userOrder.billingZipCode && (
                  <Text>{userOrder.billingZipCode}</Text>
                )}
                {userOrder.billingCountry && (
                  <Text>{userOrder.billingCountry.title}</Text>
                )}
              </Panel>
              <Panel>
                <Pane paddingBottom={15}>
                  <Heading>Proof of Payment</Heading>
                </Pane>
                {userOrder.proofPaymentUrl &&
                userOrder.proofPaymentFilenameExtension === 'pdf' ? (
                  <StyledPane
                    onClick={() => {
                      window.open(userOrder.proofPaymentUrl, '_blank')
                    }}
                  >
                    <Icon
                      icon="download"
                      size={18}
                      marginRight={10}
                      color="#fff"
                    />
                    <Text color="#fff" textDecoration="underline">
                      {userOrder.proofPaymentFilename +
                        userOrder.proofPaymentFilenameExtension}
                    </Text>
                  </StyledPane>
                ) : (
                  <PreviewImage
                    src={userOrder.proofPaymentUrl}
                    onClick={() => setIsOpenLightImage(true)}
                  />
                )}
                {!userOrder.proofPaymentUrl && (
                  <Badge color="red" marginRight={8} marginBottom={8}>
                    NO PROOF OF PAYMENT
                  </Badge>
                )}
                <Pane paddingBottom={15}>
                  <Heading>Customer</Heading>
                </Pane>
                {userOrder.customer && (
                  <Text>
                    {user.hasAction(ACTIONS.CUSTOMER.DETAIL) ? (
                      <NavLink
                        to={`/${ROUTES.CUSTOMERS}/${userOrder.customer.id}`}
                      >
                        {userOrder.customer.fullName}
                      </NavLink>
                    ) : (
                      userOrder.customer.fullName
                    )}
                  </Text>
                )}
                {userOrder.customer && <Text>{userOrder.customer.email}</Text>}
                {userOrder.customer && (
                  <Text>
                    {`Created: ${format(
                      userOrder.customer.createdAtUtc,
                      TIME_FORMAT.LOCAL_DATETIME
                    )}`}
                  </Text>
                )}
                {!userOrder.customer && (
                  <Text>[DELETED] {userOrder.customerEmail} </Text>
                )}
              </Panel>
              <Panel>
                <Pane paddingBottom={5}>
                  <Heading>Status</Heading>
                </Pane>
                {userOrder.status && <OrderStatus status={userOrder.status} />}
                {userOrder.availableStatuses &&
                  userOrder.availableStatuses.length !== 0 && (
                    <>
                      <Pane paddingBottom={5}>
                        <Heading>Change status to:</Heading>
                      </Pane>
                      <Pane display="flex" flexDirection="column">
                        {(userOrder.availableStatuses || []).map((status) => (
                          <OrderStatus
                            key={status}
                            status={status}
                            {...(user.hasAction(
                              ACTIONS.ORDER.CHANGE_STATUS
                            ) && {
                              onClick: () => {
                                setNewOrderStatus(status)
                                setIsChangeOrderStatusModalOpened(true)
                                if (
                                  status === ORDER_STATUSES.IN_CARRIER_HANDS
                                ) {
                                  setIsUpdateShipping(true)
                                  setTrackingNumber(
                                    userOrder.zasilkovnaTrackingNumber
                                  )
                                  setShippingDate(userOrder.shippedAtUtc)
                                } else {
                                  setIsUpdateShipping(false)
                                  setTrackingNumber('')
                                }
                              },
                            })}
                          />
                        ))}
                      </Pane>
                    </>
                  )}
              </Panel>
              <Panel>
                {stripePayment && (
                  <StripeInformation
                    paymentIntentUrl={`${STRIPE_URL}payments/${stripePayment.paymentIntentId}`}
                    paymentIntentStatusLabel={getLabelByPaymentIntentStatus(
                      stripePayment.paymentIntentStatus
                    )}
                  />
                )}
              </Panel>
            </Pane>
            {userOrder.returnStatus !== RETURN_ORDER_STATUSES.NOT_RETURN && (
              <div>
                <Heading
                  style={{ textAlign: 'center' }}
                  marginLeft={5}
                  marginRight={5}
                  marginBottom={5}
                  marginTop={20}
                >
                  Return Process
                </Heading>
                <div className={classesStepper.root}>
                  <Stepper activeStep={activeReturnStep} alternativeLabel>
                    {returnSteps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                <Pane
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <Panel>
                    <UpdateAddressHeader
                      onClick={() => {
                        setAddressDialogType(DETAIL_RETURN)
                      }}
                      isWithButton={user.hasAction(
                        ACTIONS.ORDER.UPDATE_RETURN_ORDER
                      )}
                      title="Return Info"
                    />
                    {userOrder.returnTrackingNumber && (
                      <Text>
                        {' '}
                        {`Tracking Number: ${userOrder.returnTrackingNumber}`}
                      </Text>
                    )}
                    {userOrder.returnRecipientName && (
                      <Text>
                        {' '}
                        {`Recipient Name: ${userOrder.returnRecipientName}`}
                      </Text>
                    )}
                    {userOrder.returnIban && (
                      <Text> {`IBAN: ${userOrder.returnIban}`}</Text>
                    )}
                    {userOrder.returnRequestedAtUtc && (
                      <Text>
                        {' '}
                        {`Requested: ${format(
                          userOrder.returnRequestedAtUtc,
                          TIME_FORMAT.LOCAL_DATETIME
                        )}`}
                      </Text>
                    )}
                    {userOrder.returnPackageSentAtUtc && (
                      <Text>
                        {' '}
                        {`Package Sent: ${format(
                          userOrder.returnPackageSentAtUtc,
                          TIME_FORMAT.LOCAL_DATETIME
                        )}`}
                      </Text>
                    )}
                    {userOrder.returnRecievedByBenekoAtUtc && (
                      <Text>
                        {' '}
                        {`Recieved by Beneko: ${format(
                          userOrder.returnRecievedByBenekoAtUtc,
                          TIME_FORMAT.LOCAL_DATETIME
                        )}`}
                      </Text>
                    )}
                    {userOrder.returnApprovedAtUtc && (
                      <Text>
                        {' '}
                        {`Return Approved: ${format(
                          userOrder.returnApprovedAtUtc,
                          TIME_FORMAT.LOCAL_DATETIME
                        )}`}
                      </Text>
                    )}
                    {userOrder.returnIssuedAtUtc && (
                      <Text>
                        {' '}
                        {`Refund Issued: ${format(
                          userOrder.returnIssuedAtUtc,
                          TIME_FORMAT.LOCAL_DATETIME
                        )}`}
                      </Text>
                    )}
                    {userOrder.returnRefundedAtUtc && (
                      <Text>
                        {' '}
                        {`Refund Received: ${format(
                          userOrder.returnRefundedAtUtc,
                          TIME_FORMAT.LOCAL_DATETIME
                        )}`}
                      </Text>
                    )}
                    <Pane marginTop={10} marginBottom={10}>
                      <Heading>Reasons</Heading>
                      {userOrder.reasonReturn && (
                        <Text>{userOrder.reasonReturn.text}</Text>
                      )}
                    </Pane>
                    <Pane marginBottom={10}>
                      <Heading>Desc Problems</Heading>
                      {userOrder.descProblem && (
                        <Text>{userOrder.descProblem}</Text>
                      )}
                    </Pane>
                    {userOrder.returnAttachment &&
                      userOrder.returnAttachment.length > 0 && (
                        <Pane marginBottom={10}>
                          <Heading>Attachments</Heading>
                          <Pane
                            width="100%"
                            height="100%"
                            position="relative"
                            display="flex"
                          >
                            {userOrder.returnAttachment &&
                              userOrder.returnAttachment.map(
                                ({ url }, index) => (
                                  <PreviewListImage
                                    key={url}
                                    src={url}
                                    onClick={() => {
                                      setReturnAttachmentIndex(index)
                                      setIsOpenLightImageReturnAttachment(true)
                                    }}
                                  />
                                )
                              )}
                          </Pane>
                        </Pane>
                      )}
                  </Panel>
                  <Panel>
                    <Pane paddingBottom={5}>
                      <Heading>Return Status</Heading>
                    </Pane>
                    {userOrder.returnStatus && (
                      <ReturnOrderStatus status={userOrder.returnStatus} />
                    )}
                    {userOrder.availableReturnStatuses &&
                      userOrder.availableReturnStatuses.length !== 0 && (
                        <>
                          <Pane paddingBottom={5}>
                            <Heading>Change status to:</Heading>
                          </Pane>
                          <Pane display="flex" flexDirection="column">
                            {(userOrder.availableReturnStatuses || []).map(
                              (status) => (
                                <ReturnOrderStatus
                                  key={status}
                                  status={status}
                                  {...(user.hasAction(
                                    ACTIONS.ORDER.CHANGE_RETURN_STATUS
                                  ) && {
                                    onClick: () => {
                                      setNewReturnOrderStatus(status)
                                      setIsChangeReturnOrderStatusModalOpened(
                                        true
                                      )
                                    },
                                  })}
                                />
                              )
                            )}
                          </Pane>
                        </>
                      )}
                  </Panel>
                </Pane>
              </div>
            )}
            {userOrder.product && (
              <Pane
                elevation={1}
                backgroundColor="white"
                margin={5}
                display="flex"
                padding={10}
                flexDirection="column"
              >
                <Pane paddingBottom={15}>
                  <Heading>Item:</Heading>
                </Pane>
                <Pane display="flex" flexDirection="row">
                  <ItemImage
                    image={
                      userOrder.product.variantImage ||
                      userOrder.product.mainImage
                    }
                  />
                  <Pane display="flex" flexDirection="row" alignItems="center">
                    <Pane marginLeft={15} display="flex" flexDirection="column">
                      <Pane paddingBottom={5}>
                        <Text size={500}>
                          {
                            // eslint-disable-next-line no-underscore-dangle
                            userOrder.product.__typename ===
                              QUERY_TYPES.DEAL_OF_THE_DAY && (
                              <NavLink
                                to={`/${ROUTES.DEAL_OF_THE_DAYS}/${DOD_ROUTES.EDIT}/${userOrder.product.id}`}
                              >
                                {userOrder.product.title}
                              </NavLink>
                            )
                          }
                          {
                            // eslint-disable-next-line no-underscore-dangle
                            userOrder.product.__typename ===
                              QUERY_TYPES.BEST_SELLER && (
                              <NavLink
                                to={`/${ROUTES.BEST_SELLERS}/${BEST_SELLER_ROUTES.EDIT}/${userOrder.product.id}`}
                              >
                                {userOrder.product.title}
                              </NavLink>
                            )
                          }
                          {
                            // eslint-disable-next-line no-underscore-dangle
                            userOrder.product.__typename ===
                              QUERY_TYPES.DEAL_OF_THE_WEEK && (
                              <NavLink
                                to={`/${ROUTES.DEAL_OF_THE_WEEKS}/${DOW_ROUTES.EDIT}/${userOrder.product.id}`}
                              >
                                {userOrder.product.title}
                              </NavLink>
                            )
                          }
                          {
                            // eslint-disable-next-line no-underscore-dangle
                            userOrder.product.__typename ===
                              QUERY_TYPES.THEME_DEAL && (
                              <NavLink
                                to={`/${ROUTES.THEME_OF_THE_MONTH}/${userOrder.product.themeOfTheMonthId}/${THEME_DEAL_ROUTES.EDIT}/${userOrder.product.id}`}
                              >
                                {userOrder.product.title}
                              </NavLink>
                            )
                          }
                          {
                            // eslint-disable-next-line no-underscore-dangle
                            userOrder.product.__typename ===
                              QUERY_TYPES.WISH_OFFER && (
                              <NavLink
                                to={getPathToWishOffer({
                                  wishId: userOrder.product.wishId,
                                  offerId: userOrder.product.id,
                                })}
                              >
                                {userOrder.product.title}
                              </NavLink>
                            )
                          }
                          {
                            // eslint-disable-next-line no-underscore-dangle
                            userOrder.product.__typename ===
                              QUERY_TYPES.PHONE_SERVICE && (
                              <NavLink
                                to={getPathToProductVariant({
                                  id: userOrder.product.id,
                                  productId: userOrder.product.productId,
                                })}
                              >
                                {userOrder.product.title}
                              </NavLink>
                            )
                          }
                        </Text>
                      </Pane>
                      <Small>{userOrder.product.shortDescription}</Small>
                    </Pane>
                  </Pane>
                </Pane>
              </Pane>
            )}
            {userOrder.additionalServices &&
              userOrder.additionalServices.length > 0 && (
                <Pane
                  elevation={1}
                  backgroundColor="white"
                  margin={5}
                  display="flex"
                  padding={10}
                  flexDirection="column"
                >
                  <Pane>
                    <Heading>Additional services:</Heading>
                  </Pane>
                  <Pane>
                    {userOrder.additionalServices.map((additionalService) => (
                      <Pane
                        key={additionalService.id}
                        display="flex"
                        flexDirection="column"
                        flexGrow={1}
                        marginTop={15}
                      >
                        <Pane paddingBottom={5}>
                          <Text size={500}>
                            {user.hasAction(
                              ACTIONS.ADDITIONAL_SERVICE.DETAIL
                            ) && additionalService.additionalServiceId ? (
                              <Link
                                size={500}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  history.push(
                                    `/${ROUTES.ADDITIONAL_SERVICES}/edit/${additionalService.additionalServiceId}`
                                  )
                                }}
                              >
                                {additionalService.title}
                              </Link>
                            ) : (
                              additionalService.title
                            )}
                          </Text>
                        </Pane>
                        <Pane display="flex" justifyContent="space-between">
                          <Text>Price without tax</Text>
                          <Text>
                            {formatPrice({
                              value: additionalService.priceBase,
                              currencyIsoCode,
                            })}
                          </Text>
                        </Pane>
                        <Pane display="flex" justifyContent="space-between">
                          <Text>Tax</Text>
                          <Text>
                            {`(${userOrder.vatRate}%) ${formatPrice({
                              value: additionalService.priceVat,
                              currencyIsoCode,
                            })}`}
                          </Text>
                        </Pane>
                        <Pane display="flex" justifyContent="space-between">
                          <Text>Price with tax</Text>
                          <Text>
                            {formatPrice({
                              value: additionalService.priceTotal,
                              currencyIsoCode,
                            })}
                          </Text>
                        </Pane>
                      </Pane>
                    ))}
                  </Pane>
                </Pane>
              )}
            <Pane
              elevation={1}
              backgroundColor="white"
              margin={5}
              display="flex"
              padding={10}
              flexDirection="column"
            >
              <Pane display="flex" justifyContent="space-between">
                <Text>Shipping cost without tax</Text>
                <Text>
                  {formatPrice({
                    value: userOrder.shippingCostBase,
                    currencyIsoCode,
                  })}
                </Text>
              </Pane>
              <Pane display="flex" justifyContent="space-between">
                <Text>Shipping cost tax</Text>
                <Text>
                  {formatPrice({
                    value: userOrder.shippingCostVat,
                    currencyIsoCode,
                  })}
                </Text>
              </Pane>
              <Pane display="flex" justifyContent="space-between">
                <Text>Shipping with tax</Text>
                <Text>
                  {formatPrice({
                    value: userOrder.shippingCostTotal,
                    currencyIsoCode,
                  })}
                </Text>
              </Pane>
              <Pane display="flex" justifyContent="space-between">
                <Text>Product price without tax</Text>
                <Text>
                  {formatPrice({ value: userOrder.priceBase, currencyIsoCode })}
                </Text>
              </Pane>
              <Pane display="flex" justifyContent="space-between">
                <Text>Product tax</Text>
                <Text>
                  {`(${userOrder.vatRate}%) ${formatPrice({
                    value: userOrder.priceVat,
                    currencyIsoCode,
                  })}`}
                </Text>
              </Pane>
              <Pane display="flex" justifyContent="space-between">
                <Text>Product price with tax</Text>
                <Text>
                  {formatPrice({
                    value: userOrder.priceTotal,
                    currencyIsoCode,
                  })}
                </Text>
              </Pane>
              <Pane
                display="flex"
                justifyContent="space-between"
                paddingBottom={minorScale(1)}
              >
                <Heading>Price total</Heading>
                <Heading>
                  {formatPrice({
                    value: userOrder.totalPriceTotal,
                    currencyIsoCode,
                  })}
                </Heading>
              </Pane>
              <Pane
                display="flex"
                justifyContent="space-between"
                borderTop
                paddingTop={minorScale(1)}
              >
                <Heading>Price paid</Heading>
                <Heading>
                  {formatPrice({
                    value: userOrder.totalPricePaid,
                    currencyIsoCode,
                  })}
                </Heading>
              </Pane>
              <Pane
                display="flex"
                justifyContent="space-between"
                borderTop
                paddingTop={minorScale(1)}
              >
                <Heading>Marketplace fee</Heading>
                <Heading>
                  {`(${
                    userOrder.sellerMarketplacePercentageFee
                  }%) ${formatPrice({
                    value:
                      (userOrder.sellerMarketplacePercentageFee / 100) *
                      userOrder.totalPriceTotal,
                    currencyIsoCode,
                  })}`}
                </Heading>
              </Pane>
              <Pane display="flex" justifyContent="space-between">
                <Heading>Seller fee</Heading>
                <Heading>
                  {`(${
                    100 - userOrder.sellerMarketplacePercentageFee
                  }%) ${formatPrice({
                    value:
                      ((100 - userOrder.sellerMarketplacePercentageFee) / 100) *
                      userOrder.totalPriceTotal,
                    currencyIsoCode,
                  })}`}
                </Heading>
              </Pane>
            </Pane>
            {addressDialogType === SHIPPING_ADDRESS && (
              <UpdateShippingAddressDialog
                id={orderId}
                close={closeAddressDialog}
                shippingFirstName={userOrder.shippingFirstName}
                shippingLastName={userOrder.shippingLastName}
                shippingStreet={userOrder.shippingStreet}
                shippingCity={userOrder.shippingCity}
                shippingCountryIsoCode={userOrder.shippingCountry.isoCode}
                shippingHouseNumber={userOrder.shippingHouseNumber}
                shippingZipCode={userOrder.shippingZipCode}
                shippingComment={userOrder.shippingComment}
                shippingPhoneNumber={userOrder.shippingPhoneNumber}
              />
            )}
            {addressDialogType === BILLING_ADDRESS && (
              <UpdateBillingAddressDialog
                id={orderId}
                close={closeAddressDialog}
                billingFirstName={userOrder.billingFirstName}
                billingLastName={userOrder.billingLastName}
                billingStreet={userOrder.billingStreet}
                billingCity={userOrder.billingCity}
                billingCountryIsoCode={userOrder.billingCountry.isoCode}
                billingHouseNumber={userOrder.billingHouseNumber}
                billingZipCode={userOrder.billingZipCode}
                billingPhoneNumber={userOrder.billingPhoneNumber}
              />
            )}
            {addressDialogType === DETAIL_RETURN && (
              <UpdateReturnDetailDialog
                id={orderId}
                close={closeAddressDialog}
                trackingNumberCustomer={userOrder.returnTrackingNumber}
                recipientNameCustomer={userOrder.returnRecipientName}
                ibanCustomer={userOrder.returnIban}
                reasonReturn={userOrder.reasonReturn}
                descProblem={userOrder.descProblem}
                attachments={userOrder.returnAttachment}
              />
            )}
            <Dialog
              isConfirmLoading={isFetching}
              isShown={isChangeOrderStatusModalOpened}
              title="Change order status"
              onConfirm={async () => {
                let errorFlag = false
                if (newOrderStatus === ORDER_STATUSES.IN_CARRIER_HANDS) {
                  if (trackingNumber) {
                    errorFlag = false
                  } else {
                    errorFlag = true
                    urql.handleError({
                      networkError: {
                        message: 'Tracking Number is empty.',
                      },
                    })
                  }

                  if (shippingDate) {
                    errorFlag = false
                  } else {
                    errorFlag = true
                    urql.handleError({
                      networkError: {
                        message: 'Shipping Date is empty',
                      },
                    })
                  }
                } else {
                  errorFlag = false
                }

                if (!errorFlag) {
                  setIsFetching(true)
                  if (newOrderStatus === ORDER_STATUSES.IN_CARRIER_HANDS) {
                    await executeUpdateZasilkovnaTrackingNumber({
                      input: {
                        orderId,
                        trackingNumber,
                        shippingDate: format(shippingDate, 'YYYY-MM-DD'),
                      },
                    })
                  }
                  await executeChangeOrderStatusMutation({
                    input: {
                      id: orderId,
                      status: newOrderStatus,
                    },
                  })
                  await executeOrderQuery()
                  setIsChangeOrderStatusModalOpened(false)
                  setNewOrderStatus(null)
                  setIsFetching(false)
                }
              }}
              onCloseComplete={() => {
                setIsChangeOrderStatusModalOpened(false)
                setNewOrderStatus(null)
              }}
              intent="success"
              preventBodyScrolling
            >
              <Pane marginBottom="10px">
                <Text fontSize="16px">Please confirm order status change:</Text>
              </Pane>
              <Pane display="flex" alignItems="baseline">
                <Pane width="50px">
                  <Text>From:</Text>
                </Pane>
                <OrderStatus status={userOrder.status} />
              </Pane>
              <Pane display="flex" alignItems="baseline" marginBottom="10px">
                <Pane width="50px">
                  <Text>To:</Text>
                </Pane>
                <OrderStatus status={newOrderStatus} />
              </Pane>
              <Pane display="flex" alignItems="baseline">
                <Pane width="100%">
                  {isUpdateShipping && (
                    <TextInputField
                      required
                      label="Zasilkovna Tracking Number"
                      value={trackingNumber}
                      onChange={({ target }) => {
                        setTrackingNumber(
                          target.value === '' ? undefined : target.value
                        )
                      }}
                    />
                  )}
                  {isUpdateShipping && (
                    <Picker.Date
                      required
                      onChangeDate={(changedShippingDate) =>
                        setShippingDate(changedShippingDate)
                      }
                      date={shippingDate}
                      label="Shipping Date"
                    />
                  )}
                </Pane>
              </Pane>
            </Dialog>
            <Dialog
              isConfirmLoading={isFetching}
              isShown={isChangeReturnOrderStatusModalOpened}
              isConfirmDisabled={
                newReturnOrderStatus === RETURN_ORDER_STATUSES.REJECTED &&
                isRejectReasonNotGiven
              }
              title="Change Return Order Status"
              onConfirm={() => returnStatusDialogOnConfirm()}
              onCloseComplete={() => {
                setIsChangeReturnOrderStatusModalOpened(false)
                setNewReturnOrderStatus(null)
              }}
              intent="success"
              preventBodyScrolling
            >
              <Pane marginBottom="10px">
                <Text fontSize="16px">
                  Please confirm return order status change:
                </Text>
              </Pane>
              <Pane display="flex" alignItems="baseline">
                <Pane width="50px">
                  <Text>From:</Text>
                </Pane>
                <ReturnOrderStatus status={userOrder.returnStatus} />
              </Pane>
              <Pane display="flex" alignItems="baseline">
                <Pane width="50px">
                  <Text>To:</Text>
                </Pane>
                <ReturnOrderStatus status={newReturnOrderStatus} />
              </Pane>
              {newReturnOrderStatus === RETURN_ORDER_STATUSES.REJECTED && (
                <Pane marginTop="10px">
                  <TextareaField
                    isInvalid={isRejectReasonNotGiven}
                    label="Please create a message to a client with a reason why are you rejecting his/her return request"
                    required
                    onChange={(e) => {
                      const text = e.target.value
                      setRejectReason(text)
                      setIsRejectReasonNotGiven(!containsMinTwoWords(text))
                    }}
                    placeholder="Write a reason"
                  />
                </Pane>
              )}
            </Dialog>

            {isOpenLightImage && (
              <Lightbox
                mainSrc={proofPaymentImage[photoIndex]}
                onCloseRequest={() => setIsOpenLightImage(false)}
              />
            )}

            {isOpenLightImageReturnAttachment && (
              <Lightbox
                mainSrc={returnAttachments[returnAttachmentIndex]}
                nextSrc={
                  returnAttachments[
                    (returnAttachmentIndex + 1) % returnAttachments.length
                  ]
                }
                prevSrc={
                  returnAttachments[
                    (returnAttachmentIndex + returnAttachments.length - 1) %
                      returnAttachments.length
                  ]
                }
                onCloseRequest={() =>
                  setIsOpenLightImageReturnAttachment(false)
                }
                onMovePrevRequest={() =>
                  setReturnAttachmentIndex(
                    (returnAttachmentIndex + returnAttachments.length - 1) %
                      returnAttachments.length
                  )
                }
                onMoveNextRequest={() =>
                  setReturnAttachmentIndex(
                    (returnAttachmentIndex + 1) % returnAttachments.length
                  )
                }
              />
            )}
          </Pane>
        )}
      </SideNavPage.Content>
    </React.Fragment>
  )
}

OrderDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    hasAction: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(OrderDetail)
