import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SelectField } from 'evergreen-ui'
import get from 'lodash.get'

import { useMutation } from 'urql'
import { parseInteger } from 'utils/numbers'
import urql from 'utils/urql'

const UPDATE_THREAD = `
  mutation assignUserToThread($input: AssignUserToThreadInput!) {
    assignUserToThread(input: $input) {
      user {
        id
      }
    }
  }
`

const EMPTY_VALUE = '-'

export const ThreadAssignedUser = (props) => {
  const { users, threadUser, threadId } = props
  const [internalThreadStatus, setInternalThreadStatus] = useState(
    get(threadUser, 'id')
  )
  const [result, executeUpdateTicketStatusMutation] = useMutation(UPDATE_THREAD)

  useEffect(() => {
    if (threadUser && threadUser.id) {
      setInternalThreadStatus(threadUser.id)
    }
  }, [threadUser])

  useEffect(() => {
    const assignedUserId = get(result, 'data.assignUserToThread.user.id')
    setInternalThreadStatus(assignedUserId || EMPTY_VALUE)
  }, [result])

  const handleChange = async ({ target }) => {
    const { error } = await executeUpdateTicketStatusMutation({
      input: {
        threadId: parseInteger(threadId),
        assignedUserId:
          target.value !== EMPTY_VALUE ? parseInteger(target.value) : null,
      },
    })
    urql.displayToastOnSave(error)
  }
  return (
    <SelectField
      label="Assigned to"
      value={internalThreadStatus}
      onChange={handleChange}
    >
      <option key={EMPTY_VALUE} value={EMPTY_VALUE}>
        Select user
      </option>
      {users
        .filter((item) => item.isActive)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => (
          <option key={item.id} value={item.id}>
            {item.name} - {item.email}
          </option>
        ))}
    </SelectField>
  )
}

ThreadAssignedUser.propTypes = {
  threadId: PropTypes.number.isRequired,
  threadUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  users: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
}
